import DanceAPI from "./DanceAPI";


export const getScenarioComparisonTemplates = () => {
    return (dispatch, getState) => {
        return DanceAPI.projectScenarioComparisonTemplates.get(getState().project.id).then(result => {
            dispatch(initScenarioComparisonTemplates(result.templates));
            return result;
        })

    }
}

export const initScenarioComparisonTemplates = (templates) => ({
    type:"SCENARIOCOMPARISONTEMPLATES_INIT",
    templates,
});
