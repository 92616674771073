import React from 'react'
import PropTypes from "prop-types"
import {connect} from "react-redux";
import {build_legend} from "../map_styling/util";
import LegendItem from "./LegendItem";
import OutlinedInput from "@material-ui/core/OutlinedInput/OutlinedInput";
import Select from "@material-ui/core/Select/Select";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import ComparisonLegendItem from "./ComparisonLegendItem";
import {Typography} from "@material-ui/core";


class ComparisonLegend extends React.Component {

    constructor(props) {
        super(props);
    }


    setPerspective = (layer, perspective) => {
        this.props.perspectiveCallback(layer.id, perspective);
    }

    render() {
        let comparison_perspective_map = this.props.comparison_perspective_map;
        return <div style={{width:"100%", height:"100%"}}>
            {
                this.props.comparisons.map(comparison => {
                    return <div style={{ width:"100%",
                        backgroundColor:"#F0F4F8",
                        padding:"5px",
                        margin:"0px",
                        fontSize: "14px"}}>
                        <Typography variant={"body2"}>
                        {comparison.template.data.name}
                        {Object.keys(comparison.template.data.perspectives).length === 1 ? "" :
                            <Select
                                style={{float:'right', height:'15px', fontSize: "13px"}}
                                onChange={e=>{
                                this.setPerspective(comparison, e.target.value)
                            }}
                                value={comparison_perspective_map[comparison.id]}
                                input={<OutlinedInput labelWidth={0} />}
                            >
                                { Object.keys(comparison.template.data.perspectives).map(k => {
                                        return <MenuItem value={k}>
                                            {comparison.template.data.perspectives[k].name}</MenuItem>
                                    })
                                }
                            </Select>
                        }
                        <ComparisonLegendItem legend_data={
                            comparison.template.data.perspectives[comparison_perspective_map[comparison.id]].legend}/>
                        </Typography>
                    </div>
                })
            }
        </div>;
    }
}


ComparisonLegend.propTypes = {
    comparisons: PropTypes.array.isRequired,
    comparison_perspective_map: PropTypes.object.isRequired,
    perspectiveCallback: PropTypes.func.isRequired,
}

const mapStateToProps = (state, ownProps) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {

    return {}
}


export default connect(mapStateToProps, mapDispatchToProps)(ComparisonLegend);
