import React from 'react';
import './App.css';
import {BrowserRouter} from "react-router-dom";
import {Route} from "react-router";
import ProjectsScreen from "./Screens/ProjectsScreen";
import ProjectScreen from "./Screens/ProjectScreen";
import {applyMiddleware, compose, createStore} from "redux";
import reducers from "./reducers"
import thunk from "redux-thunk";
import {Provider} from "react-redux";
import DanceAPI, {constructDanceAPI} from "./actions/DanceAPI";
import {inject_store} from "./SocketHandler";
import LoginWrapper from "./Components/Login/LoginWrapper";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import SnackbarContent from "@material-ui/core/SnackbarContent";
import AdminManagementScreen from "./Screens/AdminManagementScreen";
import Playground from "./Screens/Playground";
import Login from "./Screens/Login";


const composeEnhancers =
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
            // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
        }) : compose;
const enhancer = composeEnhancers(
    applyMiddleware(thunk),
    // other store enhancers if any
);


const store = createStore(reducers,{}, enhancer);

constructDanceAPI(store);
inject_store(store);

class App extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            userAuthenticated: false,
            loggingIn: true,
            snackbarOpen: false,
            snackMessage: ""
        }
    }

    componentDidMount() {


        const access_token = localStorage.getItem("access_token");
        const access_level = localStorage.getItem("access_level");
        if(
            (access_token === null || access_token === undefined) ||
            (access_level === null || access_level === undefined)
        ){
            this.setState({
                loggingIn: false,
                userAuthenticated: false,
            })
        }else{
            this.setState({
                loggingIn:false,
                userAuthenticated:true,
            })

        }
    }



    componentDidCatch(error, info) {
        // Display fallback UI
        this.setState({ snackbarOpen: true , snackMessage: info});

        // console.error(error, info);

    };

    handleLogout = () => {
        localStorage.removeItem("access_token");
        this.props.history.push('/');
        window.location.reload();

    };

    handleClose = () => {
        this.setState({
            snackbarOpen: false,
        })

        this.handleLogout()

    };

    renderLogin = () => {

    }


    render() {
        return (
            <Provider store={store}>
                <div className="App">
                        <BrowserRouter>
                            <Route path={"/project/:projectID"} component={ProjectScreen}/>
                            <Route exact path='/' component={ProjectsScreen}/>
                            <Route path={"/bad"} component={_ => <div>Router Test</div>}/>
                            <Route path={"/test"} component={_ => <Playground/>}/>
                            <Route path={"/admin"} component={_ => <AdminManagementScreen/>}/>
                            <Route path={"/login"} component={_ => <Login/>}/>
                        </BrowserRouter>

                </div>
            </Provider>
        );
    }
}



export default App
