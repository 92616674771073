import React from 'react'
import PropTypes from "prop-types"
import FilterList from "@material-ui/icons/FilterList";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {connect} from "react-redux";
import {Grid} from "@material-ui/core";


function scale_svg_in_place(x, y, scale) {
    let scale_parameters = [-x * (scale - 1), -y * (scale - 1), scale]
    return "translate(" + scale_parameters[0] + " " + scale_parameters[1] + ") scale(" + scale_parameters[2] + ")"
}

class WaterBalanceDiagram extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            left_id: 0,
            left_scenario: null,
            right_id: 0,
            right_scenario: null
        }
    }

    renderDiagram = (scenario) => {
        let data_queries = null
        let ref_name = null
        // console.log("wb_diagram", this.props.reference_scenario)
        if (scenario)
            data_queries = this.props.query_data.filter((q) => q.name === scenario.name)[0]
        if (!data_queries)
            data_queries = this.props.query_data[0];

        let values = {}
        console.log("?",data_queries)

        // data_queries.queries[0].data.length
        // console.log("data_queries", data_queries.queries[0])
        // Extract subcatchment values
        this.extract_subcatchment(data_queries.queries[0], values);
        this.extract_lot_storage_catchment(data_queries.queries[1], values);

        if (data_queries.queries.length > 2)
            this.extract_catchment_storage_catchment(data_queries.queries[2], values);

        let max_value = values["water"]  > values["rainfall"] ?  values["water"] : values["rainfall"]

        return <svg xmlns="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/1999/xlink" version="1.1"
                    viewBox="15 8 378 376"
                    width="100%" height="340px" xmlns="http://purl.org/dc/elements/1.1/">
            <metadata>Produced by OmniGraffle 6.6.2
                <date>2020-07-18 13:09:27 +0000</date>
            </metadata>
            <defs>
                <filter id="Shadow" filterUnits="userSpaceOnUse">
                    <feGaussianBlur in="SourceAlpha" result="blur" stdDeviation="1.308"/>
                    <feOffset in="blur" result="offset" dx="0" dy="2"/>
                    <feFlood flood-color="black" flood-opacity=".4" result="flood"/>
                    <feComposite in="flood" in2="offset" operator="in" result="color"/>
                    <feMerge>
                        <feMergeNode in="color"/>
                        <feMergeNode in="SourceGraphic"/>
                    </feMerge>
                </filter>
                <font-face font-family="Helvetica" font-size="11" units-per-em="1000" underline-position="-75.683594"
                           underline-thickness="49.316406" slope="0" x-height="522.94922" cap-height="717.28516"
                           ascent="770.01953" descent="-229.98047" font-weight="500">
                    <font-face-src>
                        <font-face-name name="Helvetica"/>
                    </font-face-src>
                </font-face>
                <font-face font-family="Helvetica Neue" font-size="11" panose-1="2 0 8 3 0 0 0 9 0 4"
                           units-per-em="1000"
                           underline-position="-100" underline-thickness="50" slope="0" x-height="517" cap-height="714"
                           ascent="975.0061" descent="-216.99524" font-weight="bold">
                    <font-face-src>
                        <font-face-name name="HelveticaNeue-Bold"/>
                    </font-face-src>
                </font-face>
                <linearGradient x1="0" x2="1" id="Gradient" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stop-color="#222779"/>
                    <stop offset="1" stop-color="#684c0e"/>
                </linearGradient>
                <linearGradient id="Obj_Gradient" href="#Gradient"
                                gradientTransform="translate(102.04159 159.03491) rotate(180) scale(46.474077)"/>
                <linearGradient x1="0" x2="1" id="Gradient_2" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stop-color="#263c55"/>
                    <stop offset="1" stop-color="#27318b"/>
                </linearGradient>
                <linearGradient id="Obj_Gradient_2" href="#Gradient_2"
                                gradientTransform="translate(329.38442 183.13222) rotate(-90) scale(48.194628)"/>
                <font-face font-family="Helvetica Neue" font-size="11" panose-1="2 0 5 3 0 0 0 2 0 4"
                           units-per-em="1000"
                           underline-position="-100" underline-thickness="50" slope="0" x-height="517" cap-height="714"
                           ascent="951.99585" descent="-212.99744" font-weight="500">
                    <font-face-src>
                        <font-face-name name="HelveticaNeue"/>
                    </font-face-src>
                </font-face>
                <font-face font-family="Helvetica" font-size="12" units-per-em="1000" underline-position="-75.683594"
                           underline-thickness="49.316406" slope="0" x-height="522.94922" cap-height="717.28516"
                           ascent="770.01953" descent="-229.98047" font-weight="500">
                    <font-face-src>
                        <font-face-name name="Helvetica"/>
                    </font-face-src>
                </font-face>
            </defs>
            <g stroke="none" stroke-opacity="1" stroke-dasharray="none" fill="none" fill-opacity="1">
                <title>Canvas 1</title>
                <rect fill="white" width="806" height="536.00003"/>
                <g>
                    <title>Layer 1</title>
                    <g transform={scale_svg_in_place(283.65468, 240.11417, values["stormwater"] /max_value / 2. + 0.75)}>
                        <g filter="url(#Shadow)">
                            <path
                                d="M 263.62205 260.7874 L 263.62205 232.44094 L 363.88316 232.44094 L 363.88316 218.26772 L 383.88316 246.61417 L 363.88316 274.96063 L 363.88316 260.7874 Z"
                                fill="#263c55"/>
                            <text transform="translate(283.65468 240.11417)" fill="white">
                                <tspan font-family="Helvetica" font-size="11" font-weight="500" fill="white"
                                       x="1.08229205" y="10">{values["stormwater"].toFixed(1)} ML
                                </tspan>
                            </text>
                        </g>
                        <text transform="translate(283.65468  263.29322)" fill="#1b2c41">
                            <tspan font-family="Helvetica Neue" font-size="11" font-weight="bold" fill="#1b2c41"
                                   x=".1475" y="11">Runoff
                            </tspan>
                        </text>
                    </g>
                    <g transform={scale_svg_in_place(193.78346, 353.937, values["infiltration"] / max_value/ 2. + 0.75)}>
                        <g filter="url(#Shadow)">
                            <path
                                d="M 186.11023 252.28346 L 214.4567 252.28346 L 214.4567 354.17322 L 228.62992 354.17322 L 200.28346 374.17323 L 171.93701 354.17322 L 186.11023 354.17322 Z"
                                fill="#263c55"/>
                            <text transform="translate(193.78346 353.937) rotate(-90)" fill="white">
                                <tspan font-family="Helvetica" font-size="11" font-weight="500" fill="white"
                                       x="3.9354678" y="11">{values["infiltration"].toFixed(1)} ML
                                </tspan>
                            </text>
                        </g>
                        <text transform="translate(166.91526 331.89764) rotate(-90)" fill="#263c55">
                            <tspan font-family="Helvetica Neue" font-size="11" font-weight="bold" fill="#263c55"
                                   x=".127" y="11">Infiltration
                            </tspan>
                        </text>
                    </g>
                    {values["waste_water"] > 0 &&
                    <g transform={scale_svg_in_place(44.338455, 240.11417, values["waste_water"] / max_value / 2. + 0.75)}>
                        <g filter="url(#Shadow)">
                            <path
                                d="M 144.56693 232.44094 L 144.56693 260.7874 L 44.305818 260.7874 L 44.305818 274.96063 L 24.305816 246.61417 L 44.305818 218.26772 L 44.305818 232.44094 Z"
                                fill="#684c0e"/>
                            <text transform="translate(44.338455 240.11417)" fill="white">
                                <tspan font-family="Helvetica" font-size="11" font-weight="500" fill="white"
                                       x="20.743181" y="10">{values["waste_water"].toFixed(1)} ML
                                </tspan>
                            </text>
                        </g>
                        <text transform="translate(60.3937 263.89164)" fill="#684c0e">
                            <tspan font-family="Helvetica Neue" font-size="11" font-weight="bold" fill="#684c0e"
                                   x=".401" y="11">Waste Water
                            </tspan>
                        </text>
                    </g>
                    }


                    <rect x="161.5748" y="160.59842" width="96.889764" height="77.51181" id="path"/>
                    <clipPath id="clip_path">
                        <use href="#path"/>
                    </clipPath>
                    <g clip-path="url(#clip_path)">
                        <image href="../../resources/images/city.svg" width="65" height="52"
                               transform="translate(161.5748 160.59842) scale(1.49061175)"/>
                    </g>
                    {values["reuse_greywater"] > 0 &&
                    <g transform={scale_svg_in_place(95.56693, 187.1019, values["reuse_greywater"] / max_value / 2. + 0.75)}>
                        <g filter="url(#Shadow)">
                            <path
                                d="M 102.04159 229.6063 L 98.87618 229.49837 L 95.72547 229.1751 L 92.604096 228.63797 L 89.526555 227.8895 L 86.50714 226.93314 L 83.559876 225.77335 L 80.69845 224.41551 L 77.936156 222.86593 L 75.28582 221.1318 L 72.75975 219.22119 L 70.369683 217.14295 L 68.126717 214.90676 L 66.04127 212.52298 L 64.12303 210.0027 L 62.380902 207.35761 L 53.846964 212.57028 L 65.567515 183.13222 L 87.982717 191.71961 L 79.44878 196.93228 L 80.441186 198.43906 L 81.533917 199.87474 L 82.721897 201.23267 L 83.99961 202.50652 L 85.361116 203.69039 L 86.800097 204.77878 L 88.30987 205.76663 L 89.883417 206.64935 L 91.513435 207.42285 L 93.19235 208.08353 L 94.91237 208.62832 L 96.6655 209.05469 L 98.4436 209.36066 L 100.23841 209.54482 L 102.04159 209.6063 Z"
                                fill="#684c0e"/>
                            <path
                                d="M 55.567515 183.13222 L 55.67544 179.96681 L 55.998716 176.8161 L 56.53584 173.69472 L 57.284317 170.61718 L 58.24067 167.59777 L 59.40046 164.650505 L 60.7583 161.78908 L 62.30788 159.02678 L 64.042007 156.37645 L 65.952624 153.85038 L 68.030858 151.46031 L 70.267056 149.21735 L 72.650833 147.1319 L 75.171116 145.21366 L 77.8162 143.47153 L 72.603534 134.93759 L 102.04159 146.65814 L 93.454203 169.07335 L 88.241536 160.53941 L 86.734757 161.53181 L 85.29907 162.62455 L 83.941146 163.81253 L 82.66729 165.09024 L 81.48342 166.45174 L 80.39503 167.89073 L 79.40718 169.4005 L 78.52446 170.97405 L 77.750962 172.60406 L 77.090285 174.28298 L 76.545495 176.003 L 76.119123 177.75613 L 75.81315 179.53423 L 75.628995 181.32904 L 75.567515 183.13222 Z"
                                fill="url(#Obj_Gradient)"/>
                            <path
                                d="M 102.04159 136.65814 L 105.207004 136.76607 L 108.357713 137.089344 L 111.47909 137.62647 L 114.55663 138.374945 L 117.576044 139.3313 L 120.52331 140.49109 L 123.38473 141.84893 L 126.14703 143.39851 L 128.797365 145.132636 L 131.32343 147.04325 L 133.7135 149.12149 L 135.95647 151.357685 L 138.04191 153.74146 L 139.960155 156.261745 L 141.70228 158.90683 L 150.23622 153.69416 L 138.51567 183.13222 L 116.10047 174.54483 L 124.634406 169.33216 L 123.642 167.82539 L 122.54927 166.3897 L 121.36129 165.03177 L 120.083576 163.75792 L 118.72207 162.57405 L 117.28309 161.48566 L 115.773316 160.49781 L 114.19977 159.61509 L 112.56975 158.84159 L 110.89083 158.18091 L 109.170815 157.63612 L 107.417686 157.20975 L 105.639587 156.90378 L 103.844777 156.71962 L 102.04159 156.65814 Z"
                                fill="#222779"/>
                        </g>
                        <text transform="translate(95.56693 187.1019)" fill="black">
                            <tspan font-family="Helvetica Neue" font-size="11" font-weight="500" x=".186"
                                   y="10">{values["reuse_greywater"].toFixed(1)} ML
                            </tspan>
                        </text>
                    </g>
                    }
                    {values["reuse_rwht"] > 0 &&
                    <g transform={scale_svg_in_place(266.62205, 187.1019, values["reuse_rwht"] / max_value / 2. + 0.75)}>
                        <g filter="url(#Shadow)">
                            <path
                                d="M 306.14738 229.6063 L 309.3128 229.49837 L 312.4635 229.1751 L 315.58488 228.63797 L 318.66242 227.8895 L 321.68183 226.93314 L 324.6291 225.77335 L 327.49052 224.41551 L 330.25282 222.86593 L 332.90316 221.1318 L 335.42922 219.22119 L 337.8193 217.14295 L 340.06226 214.90676 L 342.1477 212.52298 L 344.06594 210.0027 L 345.80807 207.35761 L 354.342 212.57028 L 342.62146 183.13222 L 320.20626 191.71961 L 328.7402 196.93228 L 327.74779 198.43906 L 326.65506 199.87474 L 325.46708 201.23267 L 324.18937 202.50652 L 322.82786 203.69039 L 321.38888 204.77878 L 319.8791 205.76663 L 318.30556 206.64935 L 316.67554 207.42285 L 314.99662 208.08353 L 313.2766 208.62832 L 311.52348 209.05469 L 309.74538 209.36066 L 307.95057 209.54482 L 306.14738 209.6063 Z"
                                fill="#263c55"/>
                            <path
                                d="M 352.62146 183.13222 L 352.51353 179.96681 L 352.19026 176.8161 L 351.65313 173.69472 L 350.90466 170.61718 L 349.9483 167.59777 L 348.78851 164.650505 L 347.43067 161.78908 L 345.8811 159.02678 L 344.14697 156.37645 L 342.23635 153.85038 L 340.15812 151.46031 L 337.92192 149.21735 L 335.53814 147.1319 L 333.01786 145.21366 L 330.37277 143.47153 L 335.58544 134.93759 L 306.14738 146.65814 L 314.73477 169.07335 L 319.94744 160.53941 L 321.45422 161.53181 L 322.8899 162.62455 L 324.24783 163.81253 L 325.52168 165.09024 L 326.70556 166.45174 L 327.79394 167.89073 L 328.7818 169.4005 L 329.66452 170.97405 L 330.43801 172.60406 L 331.09869 174.28298 L 331.64348 176.003 L 332.06985 177.75613 L 332.37582 179.53423 L 332.55998 181.32904 L 332.62146 183.13222 Z"
                                fill="url(#Obj_Gradient_2)"/>
                            <path
                                d="M 306.14738 136.65814 L 302.98197 136.76607 L 299.83126 137.089344 L 296.70989 137.62647 L 293.63235 138.374945 L 290.61293 139.3313 L 287.66567 140.49109 L 284.80424 141.84893 L 282.04195 143.39851 L 279.39161 145.132636 L 276.86554 147.04325 L 274.47547 149.12149 L 272.2325 151.357685 L 270.14706 153.74146 L 268.22882 156.261745 L 266.4867 158.90683 L 257.95275 153.69416 L 269.6733 183.13222 L 292.0885 174.54483 L 283.55457 169.33216 L 284.54698 167.82539 L 285.6397 166.3897 L 286.82769 165.03177 L 288.1054 163.75792 L 289.4669 162.57405 L 290.90589 161.48566 L 292.41566 160.49781 L 293.9892 159.61509 L 295.61923 158.84159 L 297.29814 158.18091 L 299.01816 157.63612 L 300.77129 157.20975 L 302.54939 156.90378 L 304.3442 156.71962 L 306.14738 156.65814 Z"
                                fill="#27318b"/>
                        </g>
                        <text transform="translate(266.62205 187.1019)" fill="black">
                            <tspan font-family="Helvetica Neue" font-size="11" font-weight="500" x=".186"
                                   y="10">{values["reuse_rwht"].toFixed(1)} ML
                            </tspan>
                        </text>
                    </g>
                    }

                    <g transform={scale_svg_in_place(193.24803, 121.496064, values["rainfall"] / max_value / 2. + 0.75)}>
                        <g filter="url(#Shadow)">
                            <path
                                d="M 185.5748 19.842517 L 213.92126 19.842517 L 213.92126 121.73228 L 228.09449 121.73228 L 199.74803 141.732285 L 171.40157 121.73228 L 185.5748 121.73228 Z"
                                fill="#27318b"/>
                            <text transform="translate(193.24803 121.496064) rotate(-90)" fill="white">
                                <tspan font-family="Helvetica" font-size="11" font-weight="500" fill="white"
                                       x="4.953292" y="10">{values["rainfall"].toFixed(1)} ML
                                </tspan>
                            </text>
                        </g>
                        <text transform="translate(169.74991 111.452755) rotate(-90)" fill="#222779">
                            <tspan font-family="Helvetica Neue" font-size="11" font-weight="bold" fill="#222779"
                                   x=".2085" y="11">Precipitation
                            </tspan>
                        </text>
                    </g>
                    <g transform={scale_svg_in_place(193.24803, 121.496064, values["evapotranspiration"] /max_value / 2. + 0.75)}>
                        <g filter="url(#Shadow)">
                            <path
                                d="M 267.77953 138.89764 L 239.43306 138.89764 L 239.43306 37.007876 L 225.25984 37.007876 L 253.6063 17.007874 L 281.95275 37.007876 L 267.77953 37.007876 Z"
                                fill="#5163bf"/>
                            <text transform="translate(247.1063 118.66142) rotate(-90)" fill="white">
                                <tspan font-family="Helvetica" font-size="11" font-weight="500" fill="white"
                                       x="22.162274" y="10">{values["evapotranspiration"].toFixed(1)} ML
                                </tspan>
                            </text>
                        </g>
                        <text transform="translate(277.46644 130.7874) rotate(-90)" fill="#687ed3">
                            <tspan font-family="Helvetica Neue" font-size="11" font-weight="bold" fill="#687ed3"
                                   x=".1975" y="11">Evapotranspiration
                            </tspan>
                        </text>
                    </g>
                    {values["water"] > 0 &&
                    <g transform={scale_svg_in_place(86.09645, 51.13783, values["water"] / max_value / 2. + 0.75)}>
                        <g filter="url(#Shadow)">
                            <path
                                d="M 56.9595 51.944353 L 77.00348 31.900375 L 147.89879 102.795686 L 157.92077 92.773703 L 152.01894 126.95981 L 117.83283 132.86165 L 127.85481 122.839664 Z"
                                fill="#222779"/>
                            <text transform="translate(86.09645 51.13783) rotate(45)" fill="white">
                                <tspan font-family="Helvetica" font-size="11" font-weight="500" fill="white"
                                       x="8.5363936" y="11">{values["water"].toFixed(1)} ML
                                </tspan>
                            </text>
                        </g>
                        <text transform="translate(91.26321 27.244499) rotate(45)" fill="#222779">
                            <tspan font-family="Helvetica Neue" font-size="11" font-weight="bold" fill="#222779"
                                   x=".1735" y="11">Imported Water
                            </tspan>
                        </text>
                    </g>
                    }
                </g>
            </g>
        </svg>
    }


    extract_catchment_storage_catchment = (data_query, values) => {
         for (let i = 0; i < data_query.data.length; i++) {
            if (data_query.data[i]["inflow_stream"] == 'stormwater runoff') {
                values["reuse_rwht"] += data_query.data[i]["provided_volume"] / 1000.
            }
            if (data_query.data[i]["inflow_stream"] == "sewerage") {
                values["reuse_greywater"] += data_query.data[i]["provided_volume"] / 1000.
            }
            if (data_query.data[i]["inflow_stream"] == "grey water") {
                values["reuse_greywater"] += data_query.data[i]["provided_volume"] / 1000.
            }
        }


    }

    extract_lot_storage_catchment = (data_query, values) => {

        values["reuse_rwht"] = 0
        values["reuse_greywater"] = 0

        for (let i = 0; i < data_query.data.length; i++) {
            if (data_query.data[i]["inflow"] == "roof runoff") {
                values["reuse_rwht"] += data_query.data[i]["volume"] / 1000.
            }
            if (data_query.data[i]["inflow"] == "impervious runoff") {
                values["reuse_rwht"] += data_query.data[i]["volume"] / 1000.
            }
            if (data_query.data[i]["inflow"] == "pervious runoff") {
                values["reuse_rwht"] += data_query.data[i]["volume"] / 1000.
            }
            if (data_query.data[i]["inflow"] == "stormwater runoff") {
                values["reuse_rwht"] += data_query.data[i]["volume"] / 1000.
            }
            if (data_query.data[i]["inflow"] == "black water") {
                values["reuse_greywater"] += data_query.data[i]["volume"] / 1000.
            }
            if (data_query.data[i]["inflow"] == "grey water") {
                values["reuse_greywater"] += data_query.data[i]["volume"] / 1000.
            }
        }

        console.log("extract_lot_storage_catchment", data_query, values["reuse_rwht"])
    }

    extract_subcatchment = (data_query, values) => {

        values["water"] = 0
        values["waste_water"] = 0
        values["stormwater"] = 0
        values["infiltration"] = 0
        values["evapotranspiration"] = 0
        values["rainfall"] = 0

        for (let i = 0; i < data_query.data.length; i++) {
            if (data_query.data[i]["stream"] == "rainfall") {
                values["rainfall"] += data_query.data[i]["annual_flow"] / 1000.
            }
            if (data_query.data[i]["stream"] == "potable demand") {
                values["water"] += data_query.data[i]["annual_flow"] / 1000.
            }
            if (data_query.data[i]["stream"] == "non potable demand") {
                values["water"] += data_query.data[i]["annual_flow"] / 1000.
            }
            if (data_query.data[i]["stream"] == "outdoor demand") {
                values["water"] += data_query.data[i]["annual_flow"] / 1000.
            }
            if (data_query.data[i]["stream"] == "stormwater runoff") {
                values["stormwater"] += data_query.data[i]["annual_flow"] / 1000.
            }
            if (data_query.data[i]["stream"] == "evapotranspiration") {
                values["evapotranspiration"] += data_query.data[i]["annual_flow"] / 1000.
            }
            if (data_query.data[i]["stream"] == "infiltration") {
                values["infiltration"] += data_query.data[i]["annual_flow"] / 1000.
            }
            if (data_query.data[i]["stream"] == "sewerage") {
                values["waste_water"] += data_query.data[i]["annual_flow"] / 1000.
            }
            if (data_query.data[i]["stream"] == "grey water") {
                values["waste_water"] += data_query.data[i]["annual_flow"] / 1000.
            }
        }
    }

    handleLeftScenarioChange = (event) => {
        this.setState({left_id: event.target.value})
        let scenario = null;
        if (event.target.value > 0) {
            scenario = this.props.scenarios.filter((s) => s.id === event.target.value)[0]
        }
        this.setState({left_scenario: scenario})
    };

    handleRightScenarioChange = (event) => {
        this.setState({right_id: event.target.value})
        let scenario = null;
        if (event.target.value > 0) {
            scenario = this.props.scenarios.filter((s) => s.id === event.target.value)[0]
        }
        this.setState({right_scenario: scenario})
    };


    render() {

        if (this.props.query_data.length === 0 || this.props.query_data[0].queries.length === 0 || this.props.all_loaded === false)
            return <div></div>
        console.log("?", this.props.query_data)

        // if (this.props.reference_scenario)

        return <div>
            <Grid container spacing={2}>
                <Grid item xs={6} md={6} lg={6}>
                    <FormControl>

                        <Select
                            style={{
                                fontSize: "12px",
                                color: "#243B53",
                                backgroundColor: "#F0F4F8",
                                paddingLeft: "3px",
                                marginLeft: "5px",
                                border: '1px solid #BCCCDC',
                                borderRadius: "5px"
                            }}
                            value={this.state.left_id}
                            onChange={this.handleLeftScenarioChange}

                        >
                            <MenuItem value={0}>None</MenuItem>
                            {this.props.scenarios.map((s) => {
                                return <MenuItem value={s.id}>{s.name}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                    {this.renderDiagram(this.state.left_scenario)}
                </Grid>


                <Grid item xs={6} md={6} lg={6}>
                    <FormControl>
                        <Select
                            style={{
                                fontSize: "12px",
                                color: "#243B53",
                                backgroundColor: "#F0F4F8",
                                paddingLeft: "3px",
                                marginLeft: "5px",
                                border: '1px solid #BCCCDC',
                                borderRadius: "5px"
                            }}
                            value={this.state.right_id}
                            onChange={this.handleRightScenarioChange}

                        >
                            <MenuItem value={0}>None</MenuItem>
                            {this.props.scenarios.map((s) => {
                                return <MenuItem value={s.id}>{s.name}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                    {this.renderDiagram(this.state.right_scenario)}
                </Grid>
            </Grid>
        </div>


    }
}


WaterBalanceDiagram.propTypes = {
    query_data: PropTypes.any,
    reference_scenario: PropTypes.any,
    scenario: PropTypes.object
}

const mapStateToProps = (state, ownProps) => {
    return {
        scenarios: state.scenarios,
    }
}
const mapDispatchToProps = (dispatch) => {

    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(WaterBalanceDiagram);

