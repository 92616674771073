


const geojson = (state, action) => {
    switch (action.type) {
        case 'GEOJSON_ADD':
        case 'GEOJSON_INIT':{
            return {
                id: action.id,
                name: action.name,
                active: action.active,
                geojson: action.geojson,
                highlight: false,
            }
        }
        case 'GEOJSON_HIGHLIGHT': {
            if(state.id === action.id){
                return {
                    ...state,
                    highlight: action.state
                }
            }
            return state;
        }
        case 'GEOJSON_UPDATE': {
            if(state.id === action.id){
                return {
                    id:action.id,
                    ...action.new_state
                }
            }
            return state;
        }
        default:
            return state;
    }
}

const geojsons = (state=[], action) => {

    switch (action.type) {
        case 'GEOJSON_INIT':{
            return action.geojsons.map((data) => geojson({}, {type: action.type, ...data}))
        }
        case 'GEOJSON_ADD':{
            return [...state, geojson({}, action)]
        }
        case 'GEOJSON_HIGHLIGHT':{
            return state.map(gj=>geojson(gj, action))
        }
        case 'GEOJSON_UPDATE':{
            return state.map(gj=>geojson(gj, action))
        }
        default:
            return state;
    }
}

export default geojsons;
