import React from 'react'
import PropTypes from "prop-types"
import {connect} from "react-redux";
import {build_legend} from "../map_styling/util";
import LegendItem from "./LegendItem";
import OutlinedInput from "@material-ui/core/OutlinedInput/OutlinedInput";
import Select from "@material-ui/core/Select/Select";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import Divider from "@material-ui/core/Divider";
import {Typography} from "@material-ui/core";
import {
    setLayerPerspective,
    setLayerPerspectiveMultiple,
    setLayerPresentationData,
    toggleDisplayLayers
} from "../actions/layer_actions";


class Legend extends React.Component {

    constructor(props) {
        super(props);
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {

        if(nextProps.layers !== this.props.layers){
            return true;
        }

        return false;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let problem_layers = this.props.layers.filter(l => {
            if(this.props.perspective_filters[l.id] !== undefined) {
                return !this.props.perspective_filters[l.id].includes(l.perspective) || (
                    this.props.perspective_filters[l.id][0] !== l.perspective && l.display === false
                );
            }
            return false;
        })

        let per_map = {};
        this.props.layers.map(l => {
            let force_show = !l.display && l.data.default_shown;

            if(problem_layers.find(l2 => l.id === l2.id)){
                per_map[l.id] = {
                    perspective: this.props.perspective_filters[l.id][0],
                    display:  this.props.perspective_filters[l.id] === undefined ? false : (force_show || l.display)
                }
            }else{
                per_map[l.id] = {
                    perspective: l.perspective,
                    display: this.props.perspective_filters[l.id] === undefined ? false : (force_show || l.display),
                }
            }
        })

        if(problem_layers.length > 0 || prevProps.perspective_filters !== this.props.perspective_filters){

            this.props.setLayerPresentationData(per_map)

        }

    }

    setPerspective = (layer, perspective) => {
        this.props.perspectiveCallback(layer.id, perspective);
    }

    render() {
        const perspective_filters = this.props.perspective_filters;
        return <div style={{width:"100%", height:"100%", zIndex: 300}}>
            {
                this.props.layers
                    .filter(l => l.display)
                    .map(layer => {
                    let layerData = layer.data;


                    if (Object.keys(layerData.perspectives).length === 0) {
                        return "";
                    }

                    let legendData = build_legend(layer, layer.perspective, layerData.perspectives[layer.perspective], this.props.context)

                    if (Object.keys(legendData).length === 0 && Object.keys(layerData.perspectives).length === 1) {
                        return "";
                    }

                    return <div style={{ width:"100%",
                        backgroundColor:"#F0F4F8",
                        padding:"5px",
                        margin:"0px",
                        fontSize: "14px"}}>

                        <Typography variant={"body2"}>{layerData.display_name}

                                <Select
                                    style={{
                                        float: 'right',
                                        height: '15px',
                                        fontSize: "13px",
                                        backgroundColor: "#FFFFFF"
                                    }}
                                    onChange={e => {
                                        this.props.setLayerPerspective(layer, e.target.value)
                                    }}
                                    value={layer.perspective}
                                    input={<OutlinedInput labelWidth={0}/>}
                                >
                                    {Object.keys(layerData.perspectives).filter(p => {
                                            if(perspective_filters[layer.id] === undefined){
                                                return false;
                                            }
                                            return Object.keys(perspective_filters).length === 0 || perspective_filters[layer.id].includes(p)
                                        }
                                    ).map(k => {
                                        return <MenuItem value={k}>
                                            {layerData.perspectives[k].name}</MenuItem>
                                    })
                                    }
                                </Select>

                        <LegendItem legend_data={legendData}/>
                        </Typography>

                    </div>
                })
            }
        </div>;
    }
}


Legend.propTypes = {
    layers: PropTypes.array.isRequired,
    context: PropTypes.object,
    perspective_filters: PropTypes.object,
}

const mapStateToProps = (state, ownProps) => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => {

    return {
        setLayerPerspective:(l, p) => {
            dispatch(setLayerPerspective(l.id, p))
        },
        setLayerPerspectiveMultiple:(map) => {
            dispatch(setLayerPerspectiveMultiple(map))
        },
        setLayerPresentationData:(map) => {
            dispatch(setLayerPresentationData(map))
        },
        toggleLayers: (layers) => {
            dispatch(toggleDisplayLayers(layers))
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Legend);
