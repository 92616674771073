import React from 'react'
import PropTypes from "prop-types"
import ReactEcharts from "echarts-for-react/lib/index";

class StackedBarChart extends React.Component {

    constructor(props) {
        super(props);
    }

    getGraph = () => {
        // if(!this.props.all_loaded){
        //     return {};
        // }
        return {
            tooltip:{confine: true},

            toolbox: {
                feature: {

                    dataZoom: {
                        title:{
                            zoom:"zoom",
                            back:"restore zoom"
                        }
                    },
                    brush: {
                        title:{
                            lineX: "range",
                            clear: "clear range"
                        },
                        type: ['lineX', 'clear']
                    },
                    dataView: {title: 'data', lang: ['data', 'close', 'refresh'], readOnly: true,
                        optionToContent: function(opt) {
                            const axisData = opt.xAxis[0].data;
                            const series = opt.series;
                            let toprow_csv = "Index, " + (series.map(s => s.name)).join(", ");
                            let toprow = "<td>Index, </td><td>" + (series.map(s => s.name)).join(",</td> <td>") + "</tr>"

                            let data;
                            let data_csv;

                            data_csv = toprow_csv;
                            data = "<table>" + toprow;
                            for(let i = 0; i < axisData.length; i++){
                                let line = "<tr><td>";
                                let line_csv;
                                let cat = axisData[i];
                                line_csv = cat + "," + series.map(s => s.data[i]).join(", ");
                                line += cat + ",</td><td>";
                                line += (series.map(s => s.data[i])).join(",</td> <td>");
                                data_csv += "\n" + line_csv;
                                data += "</tr>" + line;
                            }
                            data += "</tr></table>";
                            let dl_link = "<a download='data.csv' href='data:text/plain;charset=utf-8,"+encodeURIComponent(data_csv)+"'>" +
                                "<button>save</button>" +
                                "</a>"
                            return dl_link + data;
                        }},
                    saveAsImage: {backgroundColor: '#FFFFFF', title: 'save'},

                }
            },
            brush: {
                xAxisIndex: 'all',
                brushLink: 'all',
                outOfBrush: {
                    colorAlpha: 0.1
                }
            },
            legend: {
                bottom: 10,
                type: 'scroll',
                orient: 'horizontal',
                data: this.props.scenarios.map((scenario,i) => scenario.name)
            },
            grid: {
                left: '3%',
                right: '3%',
                bottom: 40,
                containLabel: true
            },
            xAxis : [
                {
                    type : "category",
                    name: this.props.results[0][0].x_units,
                    data: this.props.results[0][0].categories
                }
            ],
            yAxis : [
                {
                    name: this.props.results[0][0].y_units,
                    type:"value",
                }
            ],
            series : this.props.results.flatMap((v,j) => {
                return v.map(  (f,k)=> {
                    return {
                        type:"bar",
                        data: f.data,
                        symbolSize: 3,
                        name: this.props.scenarios[j].name,
                    }
                })
            }),
        };
    };
    // let xAxisLabelName = (xAxisLabel.map(q => q.replace(" ","\n"))).map(q => q.replace(" ","\n"))
    getGraph = () => {
        let query_prototype_data = {};
        let query_type = ""
        this.props.query_prototypes.map(qp => {
            query_type = qp.query_data.query_type
            query_prototype_data[qp.id] = {
                xKey: Object.keys(qp.query_data.field_information).map(k => ({name:k, data:qp.query_data.field_information[k]})).find(fi => fi.data.hasOwnProperty("xKey")).name,
                yKey: Object.keys(qp.query_data.field_information).map(k => ({name:k, data:qp.query_data.field_information[k]})).find(fi =>  fi.data.hasOwnProperty("yKey")).name,
            }
        })


        let transpose_data = []
        let labels = []

        if (query_type === "ARRAY") {

            let all_names = this.props.query_data.map((dataset, j) => {
                if (dataset.queries.length === 0 || dataset.queries[0].data === null) {
                    return {
                        name: dataset.name,
                        type: "bar",
                    }
                }
                return dataset.queries[0].data.map(entry => {
                    let prototype = query_prototype_data[dataset.queries[0].query_prototype.id]
                    return entry[prototype.xKey];
                })

            }).flat()

            let uniques_names = [...new Set(all_names)];

            let all_data = this.props.query_data.map((dataset, j) => {
                if (dataset.queries.length === 0 || dataset.queries[0].data === null) {
                    return {
                        name: dataset.name,
                    }
                }
                return {
                    name: dataset.name,
                    data: dataset.queries[0].data.map(entry => {
                        let prototype = query_prototype_data[dataset.queries[0].query_prototype.id]
                        return [entry[prototype.xKey], entry[prototype.yKey]];
                    }),
                }
            })


            for (const n of uniques_names) {
                let entry = {}
                entry["name"] = n
                entry["type"] = "bar"
                entry["data"] = []
                entry["stack"] = "stackbar"
                transpose_data.push(entry)
                labels.push(n)
            }
            console.log("temp","all", all_data)
            for (const single_data of all_data) {
                for (const d of single_data['data']) {

                    let entry = []
                    entry.push(single_data['name'])
                    entry.push(d[1])

                    for (const td of transpose_data) {
                        if (td["name"] === d[0]) {
                            td['data'].push(entry)
                        }
                    }
                }
            }
        } else {

            let columns = [];
            this.props.query_prototypes.map(prototype => {
                prototype.query_data.column_keys.map(kc => {
                    columns.push(kc)
                });
            })

            let table_data = [];



            this.props.query_data.map(qd => {
                let scen_data = []
                qd.queries[0].data.map(d => scen_data.push({"scenario": qd.name}));
                qd.queries.map(q => {
                    q.data.map((data,i) => {
                        for(let j in data){
                            if (columns.includes(j)){
                                scen_data[i][j] = data[j]
                            }
                        }
                    })
                })
                table_data = table_data.concat(scen_data);
            })
            for (const n of columns) {
                let entry = {}
                entry["name"] = n
                entry["type"] = "bar"
                entry["stack"] = "stackbar"
                entry["data"] = []
                for (const single_data of table_data) {
                    entry["data"].push([single_data["scenario"],single_data[n]])
                }
                transpose_data.push(entry)
            }
            labels = columns
        }


        let temp = {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                },
            },
            toolbox: {
                feature: {
                    dataZoom: {
                        title:{
                            zoom:"zoom",
                            back:"restore zoom"
                        }
                    },
                    brush: {
                        title:{
                            lineX: "range",
                            clear: "clear range"
                        },
                        type: ['lineX', 'clear']
                    },
                    dataView: {title: 'data', lang: ['data', 'close', 'refresh'], readOnly: true,},
                    saveAsImage: {backgroundColor: '#FFFFFF', title: 'save'},

                }
            },
            grid: {
                top:30,
                bottom: 22,
                left:0,
                right:0,
                containLabel: true
            },legend: {
                bottom: 0,
                type: 'scroll',
                orient: 'horizontal',
                data: labels
            },
            xAxis: [{
                type: 'category',
                axisLabel: {
                    formatter: (function(label){
                        if (typeof label === 'string' || label instanceof String)
                            return label.replace(/ /g,"\n")
                        return label;
                    }),

                    interval: 0,
                    textStyle: {
                        baseline: "top",
                        fontSize: 10
                    }
                }
            }],
            yAxis: [{
                type: 'value'
            }],
            series: transpose_data

        };
        return temp;
    };

    render() {
        if(this.props.query_data.length === 0 || this.props.query_data[0].queries.length === 0){
            return <div></div>
        }
        return <div>

            <ReactEcharts
                option={this.getGraph()}
                lazyUpdate={true}
                notMerge={true}
                style={{height: '270px', width: '95%'}}
                className='react_for_echarts' />
        </div>
    }
}


StackedBarChart.propTypes = {
    query_data: PropTypes.any,
}

export default StackedBarChart;
