


const filter = (state, action) => {
    switch (action.type) {
        case 'FILTERS_ADD':
        case 'FILTERS_INIT':{
            return {
                id: action.id,
                name: action.name,
                active: action.active,
                filter_data: action.filter_data,
                highlight: false,
            }
        }
        case 'HIGHLIGHT': {
            if(state.id === action.id){
                return {
                    ...state,
                    highlight: action.state
                }
            }
            return state;
        }
        default:
            return state;
    }
}

const filters = (state=[], action) => {

    switch (action.type) {
        case 'FILTERS_INIT':{
            return action.filters.map((data) => filter({}, {type: action.type, ...data}))
        }
        case 'FILTERS_ADD':{
            return [...state, filter({}, action)]
        }
        default:
            return state;
    }
}

export default filters;
