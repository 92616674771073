import DanceAPI from "./DanceAPI";


export const createGeoJSON = (project_id, geojson, name) => {
    return (dispatch, getState) => {
        return DanceAPI.geojson.post(project_id, geojson, name).then(result => {
            dispatch(addGeoJSON(result.id, name, geojson, true))
            return result;
        });
    }
}

export const initGeoJSON = (geojsons) => ({
    type: "GEOJSON_INIT",
    geojsons
});

export const updateGeoJSON = (id, state) => ({
    type: "GEOJSON_UPDATE",
    id,
    new_state: state,
});

export const addGeoJSON = (id, name, geojson, active) => ({
    type: "GEOJSON_ADD",
    id,
    name,
    geojson,
    active,
});

export const highlightGeojson = (id, state) => ({
    type: "GEOJSON_HIGHLIGHT",
    id,
    state,
});
