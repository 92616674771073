


const query_prototype = (state, action) => {
    switch (action.type) {
        case 'QUERY_PROTOTYPE_INIT':{
            return {
                id: action.id,
                query_data: action.query_data,
            }
        }
        default:
            return state;
    }
}

const query_prototypes = (state=[], action) => {

    switch (action.type) {
        case 'QUERY_PROTOTYPE_INIT':{
            return action.prototypes.map((data) => query_prototype({}, {type: action.type, ...data}))
        }
        default:
            return state;
    }
}

export default query_prototypes;