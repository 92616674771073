

const geojson_filter_preset = (state, action) => {
    switch (action.type) {
        case 'GEOJSON_FILTER_PRESETS_ADD':
        case 'GEOJSON_FILTER_PRESETS_INIT':{
            return {
                id: action.id,
                name: action.name,
                active: action.active,
                geojson: action.geojson_data,
                filters: action.filters,
            }
        }
        case 'GEOJSON_FILTER_PRESETS_HIGHLIGHT': {
            if(state.id === action.id){
                return {
                    ...state,
                    highlight: action.state
                }
            }
            return state;
        }
        case 'GEOJSON_FILTER_PRESETS_UPDATE': {
            if(state.id === action.id){
                return {
                    id:action.id,
                    ...action.new_state
                }
            }
            return state;
        }
        default:
            return state;
    }
}

const geojson_filter_presets = (state=[], action) => {

    switch (action.type) {
        case 'GEOJSON_FILTER_PRESETS_INIT':{
            return action.geojson_filter_presets.map((data) => geojson_filter_preset({}, {type: action.type, ...data}))
        }
        case 'GEOJSON_FILTER_PRESETS_ADD':{
            return [...state, geojson_filter_preset({}, action)]
        }
        case 'GEOJSON_FILTER_PRESETS_HIGHLIGHT':{
            return state.map(gj=>geojson_filter_preset(gj, action))
        }
        case 'GEOJSON_FILTER_PRESETS_UPDATE':{
            return state.map(gj=>geojson_filter_preset(gj, action))
        }
        default:
            return state;
    }
}

export default geojson_filter_presets;
