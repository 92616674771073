
import DanceAPI from "./DanceAPI"

export const fetchAssessmentModels = () => {

    return (dispatch, getState) => {
        return DanceAPI.assessment_models.get().then(result => {
            result = result["assessment_models"].map(o => ({...o, stage: o.active_version_data.stage}));
            dispatch(setAssessmentModels(result))
            return result;
        })
    }
}


const setAssessmentModels = (models) => ({
    type: "ASS_MODS_SET",
    models,
})