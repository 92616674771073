import React from 'react'
import PropTypes from "prop-types"
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import {DialogContent} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import CircularProgress from "@material-ui/core/CircularProgress";
import ListItemText from "@material-ui/core/ListItemText";
import CheckIcon from "@material-ui/icons/Check"

const styles = {
    root: {
        width:"100%",
        height:"100%",
        display:"flex",
        position:"relative"
    },
};

class InitializingProjectScreen extends React.Component {

    constructor(props) {
        super(props);
    }


    renderIcon = (step) => {
        return <ListItemIcon>
            { step < this.props.step ?
                <CheckIcon style={{color: "green"}}/>
                : (step === this.props.step ?
                    <ListItemIcon>
                        <CircularProgress size={20}/>
                    </ListItemIcon>:
                    <ListItemIcon>
                    </ListItemIcon>)
            }
        </ListItemIcon>
    }

    renderStep = (text, step) => {
        return <ListItem>
            {this.renderIcon(step)}
            <ListItemText>
                {text} - <span style={{color:"#5f5f5f"}}>{
                    this.props.step === step ?
                        "("+this.props.percentage + "%)"
                        :""
            }</span>
            </ListItemText>
        </ListItem>
    }

    render() {

        return <Dialog open={true}>
                <DialogTitle>
                    Setting up project
                </DialogTitle>
                <DialogContent style={{position:"relative"}}>
                    <List dense disablePadding>
                        {this.renderStep("Compiling Models",0)}
                        {this.renderStep("Running Baseline",1)}
                        {this.renderStep("Running Performance Assessment",2)}

                    </List>
                </DialogContent>
            </Dialog>
     }
}


InitializingProjectScreen.propTypes = {
    mapRef: PropTypes.object,
    step: PropTypes.number,
    percentage: PropTypes.number,
}

export default InitializingProjectScreen;