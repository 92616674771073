import React from 'react'
import PropTypes from "prop-types"
import {connect} from "react-redux";
import Paper from "@material-ui/core/Paper/index";
import Slide from "@material-ui/core/Slide/index";
import Check from "@material-ui/icons/Check"
import {CircularProgress} from "@material-ui/core/index";

const styles = {
    root: {
        position: "absolute",
        left:0,
        bottom:0,
        paddingLeft:"20px",
        paddingBottom:"20px",
        width:"200px",
        pointerEvents: "none",
    },

    inner:{
        position:"relative",
    },

    translationWrapper: {
        transition:"all 225ms cubic-bezier(0, 0, 0.2, 1) 0ms",
        position:"absolute",
        width:"100%"

    },

    itemWrapper: {
        borderRadius:"4px",
        fontSize:"0.8em",
        position:"relative",
        background:"rgb(50, 50, 50)",
        color:"#f5f5f5",
        display:"flex",
        flexDirection:"column",
        justifyContent:"center",
        height:"30px",
        width:"100%",
        padding:"4px",
        pointerEvents: "auto",
    }
};

class ScenarioStateNotifications extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            filter: props.scenarios.filter(s => s.status === 7).map(s => s.id),
            scenario_styling_map: {},
        }
    }

    updateFilter = (id) => {
        let index = this.state.filter.indexOf(id);
        let new_state = [...this.state.filter];
        if(index === -1){
            new_state.push(id);
        }else{
            new_state.splice(index, 1);
        }

        this.setState({
            filter: new_state,
        })
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        this.props.scenarios.map(scenario => {
            let prev = prevProps.scenarios.find(s => s.id === scenario.id);
            if((prev === undefined || prev.status !== 7) && scenario.status === 7){
                (new Promise(resolve => {
                    setTimeout(resolve, 1000)
                })).then(_=>{
                    this.setState({
                        scenario_styling_map: {
                            ...this.state.scenario_styling_map,
                            [scenario.id]: "done"
                        }
                    })
                }).then(_=>{
                    (new Promise(resolve => {
                        setTimeout(resolve, 1000)
                    })).then(_=>{

                        this.updateFilter(scenario.id)
                    })
                })
            }

        })
    }

    renderScenarioPanel = (scenario, index, arr) => {
        let height = 0;

        for (let j = 0; j < index; j++) {
            height += this.state.filter.indexOf(arr[j].id) === -1;
        }

        let styling = this.state.scenario_styling_map[scenario.id] || "default";
        let style = {
            "done": {
                background:"darkgreen",
            },
            "default": {

            }
        }[styling];

        let icon = {
            "default":<CircularProgress style={{
                right:5,
                position:"absolute"
            }} size={20}/>,
            "done":<Check color={"white"} style={{
                right:5,
                position:"absolute"
            }} />
        }[styling];

        return <div style={{...styles.translationWrapper, bottom:(height*40)+"px"}}>
            <Slide key={scenario.id} mountOnEnter unmountOnExit direction={"right"} in={this.state.filter.indexOf(scenario.id)=== -1}>
                <div style={{...styles.itemWrapper, ...style}} onClick={_=>this.updateFilter(scenario.id)}>
                    <div>{scenario.name}</div>
                    {
                        icon
                    }
                </div>
            </Slide>
        </div>
    }

    render() {
        return <div style={styles.root}>
            <div style={styles.inner}>
            {
                this.props.scenarios.filter(scenario => scenario.status > 1).map(this.renderScenarioPanel)
            }
            </div>
        </div>;
    }
}


ScenarioStateNotifications.propTypes = {}

const mapStateToProps = (state, ownProps) => {
    return {
        scenarios: state.scenarios,
    }
}

const mapDispatchToProps = (dispatch) => {

    return {}
}


export default connect(mapStateToProps, mapDispatchToProps)(ScenarioStateNotifications);
