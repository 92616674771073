import React from 'react'
import PropTypes from "prop-types"
import {ListItemText, TextField, Typography} from "@material-ui/core/index";
import Divider from "@material-ui/core/Divider";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import DataSourceDialog from "../DataSourceDialog";
import AddIcon from "@material-ui/icons/Add"
import GeoJSONSelector from "../GeoJSONSelector";

const styles = {
    panel:{
        height:"100%",
        position:"relative",
    },
    panelInner:{
        display:"flex",
        width:"100%",
        height:"100%",
        position:"absolute",
        flexDirection:"column",
        pointerEvents:"all",
        overflow:"hidden",
        bozSizing:"border-box",
    },

    areaListWrapper: {
        maxHeight:"400px"
    }
};


class CreateProjectScenarioMakerNodeProperties extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectingArea: false,
            addingArea: false,
            areas_open: false,
            boundaryFile:null,
            area_color: "#7586fe",
            openDeleteAreaDialog:false,
            deleteAreaConfirmation:false,
            color_picker_open: false,
            color_picker_anchor: null,
            deleteAreasList:[],
            dataSourceDialogOpen: false,
            parameters: {},
        };
    }

    componentDidUpdate = (prevProps, prevState, snapshot) => {
        if (prevProps.node !== this.props.node)
            this.setState({parameters: {}})
    }

    hasError = (parameter, value ) => {


        let v = value
        if (v === undefined) {
            v = this.state["parameters"][parameter.parameter]
        }
        if (v === undefined) {
            v = this.props.node.parameters[parameter.parameter]
        }
        if (v === undefined && parameter.type === "number")
            return false
        if (v === undefined)
            return false
        if (parameter.value_range === undefined)
            return false

        if (parameter.type === "number") {

            if (parameter.value_range[0] > Number(v))
                return true
            if (parameter.value_range[1] < Number(v))
                return true
        }
        return false
    }

    errorMessage = (parameter,  value) => {
        if (this.hasError(parameter,  value)) {
            if (parameter.type === "number") {
                return "Values should be " + parameter.value_range.join("-");
            }
        }
        return ""
    }
    handleOnChange = (parameter,  value) => {

        let updateParameter = {};
        updateParameter[parameter.parameter] = value;


        if (this.hasError(parameter, value) === false) {
            this.props.parameterCallback(parameter.parameter,  value)
        }
        this.setState({parameters: updateParameter});
    }


    renderParameter = (parameter) => {
        let has_error = this.hasError(parameter);
        let errorMessage = this.errorMessage(parameter);

        if (parameter.advanced_setting !== undefined)
            return <div/>


        let is_derived = parameter.derived;

        if(is_derived === true){
            if(parameter.type === "discrete"){
                let source = parameter.source;
                let source_parts = source.split("@");
                let source_field = source_parts.slice(0, -1).join("");
                let source_key = source_parts[source_parts.length-1];

                let source_ds =this.state["parameters"][source_field] !== undefined ?
                    this.state["parameters"][source_field]:
                    this.props.node.parameters[source_field] !== undefined ?
                        this.props.node.parameters[source_field]: undefined;

                if(source_ds === undefined){
                    return "";
                }
                source_ds = this.props.data_sources.find(ds => ds.id === source_ds);
                console.log(source_ds.data);
                let ds_data = JSON.parse(source_ds.data);

                if(ds_data[source_key] === undefined){
                    return "";
                }

                console.log(source_ds);
                return <div style={{marginLeft:"10px", display:"flex"}}>
                    <div style={{flex:"1 0 0", display:"flex", alignItems:"center"}}>
                        {parameter.name}
                    </div>
                    <div style={{width:"10px"}}/>
                    <div style={{flex:"2 0 0"}}>
                        <Select

                            input={<OutlinedInput margin="dense" name={parameter.name} id={parameter.parameter}/>}
                            style={{width:"100%", flex:"1"}}
                            label={parameter.name}
                            onChange={e=> {
                                if(e.target.value === -2){
                                    this.setState({
                                        dataSourceDialogOpen: true
                                    })
                                    return;
                                }
                                this.handleOnChange(parameter, e.target.value)
                            }}
                            value={this.state["parameters"][parameter.parameter] !== undefined ? this.state["parameters"][parameter.parameter]:
                                this.props.node.parameters[parameter.parameter] !== undefined ?
                                    this.props.node.parameters[parameter.parameter] :
                                    -1}
                        >
                            {
                                ds_data[source_key].map(d => {
                                    return <MenuItem value={d}>
                                        {d}
                                    </MenuItem>
                                })


                            }
                        </Select>
                    </div>

                </div>
            }
        }

        if(parameter.type === "group"){
            return <div style={{ marginLeft:"4px"}}>
                <Typography variant={"subtitle1"}>
                    {parameter.name}
                </Typography>
                <div>
                    {
                        parameter.fields.map(this.renderParameter)
                    }
                </div>
            </div>
        }else if(parameter.type === "text" || parameter.type === "number") {

            return <div style={{marginLeft:"10px", display:"flex"}}>
                <div style={{flex: "1 0 0", display:"flex", alignItems:"center"}}>
                    <div>{parameter.name}</div>
                </div>
                <div style={{width:"10px"}}/>

                <div style={{flex:"2 0 0"}}>
                    <TextField
                        error={has_error}
                        style={{flex:1, textAlign:"right", marginRight:"10px"}}
                        margin={"dense"}
                        inputProps={{
                            style:{
                                "padding": "10px",
                                textAlign:"right",
                            }
                        }}
                        type={parameter.type}
                        variant={"outlined"}
                        helperText={errorMessage}
                        fullWidth
                        onChange={e=>this.handleOnChange(parameter, e.target.value)}
                        value={this.state["parameters"][parameter.parameter] !== undefined ? this.state["parameters"][parameter.parameter]:
                            this.props.node.parameters[parameter.parameter] !== undefined ?
                                this.props.node.parameters[parameter.parameter] :
                                parameter.default}/>
                </div>
            </div>
        }else if(parameter.type === "data_source") {

            return <div style={{marginLeft:"10px", display:"flex"}}>
                <div style={{flex:"1 0 0", display:"flex", alignItems:"center"}}>
                    {parameter.name}
                </div>
                <div style={{width:"10px"}}/>
                <div style={{flex:"2 0 0"}}>
                    <Select

                        input={<OutlinedInput margin="dense" name={parameter.name} id={parameter.parameter}/>}
                        style={{width:"100%", flex:"1"}}
                        label={parameter.name}
                        onChange={e=> {
                            if(e.target.value === -2){
                                this.setState({
                                    dataSourceDialogOpen: true
                                })
                                return;
                            }
                            this.handleOnChange(parameter, e.target.value)
                        }}
                        value={this.state["parameters"][parameter.parameter] !== undefined ? this.state["parameters"][parameter.parameter]:
                            this.props.node.parameters[parameter.parameter] !== undefined ?
                                this.props.node.parameters[parameter.parameter] :
                                -1}
                    >
                        {
                            this.props.data_sources.map(d => {
                                return <MenuItem value={d.id}>
                                    {d.name}
                                </MenuItem>
                            })


                        }
                        <MenuItem value={-2}>
                            <AddIcon/> Add Data Source
                        </MenuItem>
                    </Select>
                </div>

            </div>
        }
    }

    renderParameters = (parameters) => {
        return <div >
            {
                parameters.map(this.renderParameter)
            }
        </div>
    }

    hasParameters = (parameter_description) => {
        for (var i = 0; i < parameter_description.length;  i++) {
            if (parameter_description[i].advanced_setting === undefined)
                return true;
        }
        return false
    }

    renderModels = (models) => {
        return <div>
            {

                models.map((model,i) => {
                    if (!this.hasParameters(model.parameter_description))
                        return <div/>
                    return <div><div  style={{color: "#513C06",fontSize:"1.1em", padding: "4px"}}>
                        {model.name}
                    </div>
                        <div style={{marginBottom:"30px"}}>
                            {
                                this.renderParameters(model.parameter_description)
                            }

                            <Divider variant={"fullWidth"} />
                        </div>
                    </div>

                })
            }
        </div>
    }



    handleDrop = (acceptedFiles) => {
        if(acceptedFiles.length !== 1){
            this.setState({
                errorText: "You can only upload a single boundary",
                errorDialogOpen:true,
            })
        }
        let file = acceptedFiles[0];
        this.setState({
            boundaryFile: file.name,
        })

        let reader = new FileReader();
        reader.addEventListener("loadend", ()=> {
            const geojson = JSON.parse(reader.result);
            if ("crs" in geojson) {
                delete geojson.crs;
            }
            this.props.map.mapdraw.set(geojson);
        });
        reader.readAsText(file);
    }


    render() {
        if(this.props.node == null){
            return <div></div>
        }

        return <div style={styles.panel}>

            <DataSourceDialog
                open={this.state.dataSourceDialogOpen}
                dismissCallback={_=>this.setState({dataSourceDialogOpen: false})}
            />
            <div style={styles.panelInner}>

                <div style={{flex:1, overflowY:"auto",
                    paddingLeft:"10%",
                    paddingRight:"10%",}}>
                    <div>
                        {

                            this.renderModels(this.props.node.node_version.models)
                        }
                    </div>
                </div>
            </div>

        </div>;
    }
}



CreateProjectScenarioMakerNodeProperties.propTypes = {
    open: PropTypes.bool,
    node: PropTypes.object,
    map: PropTypes.object,
    noArea: PropTypes.bool,
    parameterCallback: PropTypes.func,
    setAreaCallback: PropTypes.func,
    geojsons: PropTypes.array,
    data_sources: PropTypes.array,
}


export default CreateProjectScenarioMakerNodeProperties;
