import React from 'react'
import PropTypes from "prop-types"
import {connect} from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import {DialogTitle} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Dropzone from "react-dropzone";
import TextField from "@material-ui/core/TextField";
import DanceAPI from "../actions/DanceAPI";
import {withRouter} from "react-router";
import {createDataSource} from "../actions/data_source_actions";




class DataSourceDialog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            sourceType: null,
            data_source_name: "",
            input_name: "",
            files: []
        }
    }

    handleFileDrop = (files) => {
        this.setState({
            files:files
        })
    }

    renderProperties = () => {
        const type = this.state.sourceType;
        if(type === "file"){
            return <div style={{height:"100%"}}>
                <Dropzone style={{width:"100%", height:"400px", marginLeft:"20px", marginRight:"20px"}} onDrop={this.handleFileDrop}>
                    {({getRootProps, getInputProps}) => (
                        <section style={{
                            borderRadius: "5px",
                            padding: "5px",
                            border: "dashed 2px grey"
                        }}>
                            <div {...getRootProps()}>
                                <div>
                                    <input {...getInputProps()}/>
                                < Typography style={{width:"100%", height:"40px", textAlign:"center"}} variant={"body1"}>
                                Click here or drag and drop a file
                                </Typography>
                                </div>
                            </div>
                        </section>
                    )}
                </Dropzone>
                    <div>
                        {
                            (this.state.files.length !== 0 ? this.state.files : [{name:" "}]).map(f => {
                                return <div style={{display:"inline-block", padding:"5px"}}>
                                    {f.name}
                                </div>
                            })
                        }
                    </div>
                <div style={{paddingLeft:"20px", paddingRight:"20px"}}>
                    <TextField onChange={e=>this.setState({data_source_name:e.target.value})} fullWidth label={"Data Source Name"}/>
                </div>
                </div>
        }else if(type === "sql"){
            return <div style={{height:"100%"}}>
                <div style={{paddingLeft:"20px", paddingRight:"20px"}}>
                    <TextField fullWidth onChange={e=>this.setState({sql_field:e.target.value})} label={"SQL Connection String"}/>
                </div>

                    <div style={{paddingLeft:"20px", paddingRight:"20px"}}>
                        <TextField onChange={e=>this.setState({data_source_name:e.target.value})} fullWidth label={"Data Source Name"}/>
                    </div>
            </div>
        }
    }



    handleSubmit = () => {
        let project_id = parseInt(this.props.match.params.projectID);

        if(this.state.sourceType === "file"){
            this.props.submitFileSource(
                project_id,
                this.state.files[0],
                {
                    name:this.state.data_source_name
                }
            )
                .then(this.props.dismissCallback)
        }else{
            this.props.submitSource(
                project_id,
                {
                    sql_connection_string:this.state.sql_field,
                    name:this.state.data_source_name
                }
            )
                .then(this.props.dismissCallback)
        }
    }


    render() {
        return <Dialog open={this.props.open} fullWidth maxWidth={"md"}>
            <DialogTitle>
                Data Sources
            </DialogTitle>

            <DialogContent dividers style={{padding:0}}>
                <div style={{display:"flex", height:"50vh", flexDirection:"row"}}>
                    <div style={{width:"30%", height:"100%", borderRight:"1px solid rgba(0, 0, 0, 0.12)"}}>
                        <List>
                            <ListItem dense divider>
                                <ListItemText primary={"Data Source Type"}/>
                            </ListItem>

                            <ListItem
                                onClick={_=>this.setState({sourceType: "file"})}
                                dense
                                button
                                selected={this.state.sourceType === "file"}>
                                <ListItemText primary={"File"}/>
                            </ListItem>

                            <ListItem

                                onClick={_=>this.setState({sourceType: "sql"})}
                                dense
                                button
                                selected={this.state.sourceType === "sql"}>
                                <ListItemText primary={"SQL"}/>
                            </ListItem>

                        </List>
                    </div>
                    <div style={{flex:1, padding:"10px"}}>
                        {this.renderProperties()}
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.handleSubmit}>
                    submit
                </Button>
                <Button onClick={this.props.dismissCallback}>
                    dismiss
                </Button>
            </DialogActions>
        </Dialog>;
    }
}


DataSourceDialog.propTypes = {
    dismissCallback: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
}

const mapStateToProps = (state, ownProps) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {

    return {
        submitFileSource: (project_id, file, data) => {
            return DanceAPI.upload.post(project_id, file)
                .then(f => {
                    dispatch(createDataSource({
                        source_type:"file",
                        project_id:project_id,
                        upload_id: f.id,
                        ...data,
                    }));
                })
        },
        submitSource: (project_id, data) => {

            return dispatch(createDataSource({
                source_type:"sql",
                project_id: project_id,
                ...data,
            }));
        }
    }
}

DataSourceDialog = withRouter(DataSourceDialog)
export default connect(mapStateToProps, mapDispatchToProps)(DataSourceDialog);
