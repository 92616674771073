import React from 'react'
import PropTypes from "prop-types"
import {connect} from "react-redux";
import GeoJSONListItem from "./Views/GeoJSONListItem";
import GeoJSONList from "./GeoJSONList";
import Button from "@material-ui/core/Button/index";
import {TextField, Typography} from "@material-ui/core/index";
import {createGeoJSON} from "../actions/geojson_actions";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import DynamicList from "./Views/DynamicList";
import Slide from "@material-ui/core/Slide";
import BackIcon from "@material-ui/icons/NavigateBefore";
import Fab from "@material-ui/core/Fab";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";

const styles = {
    wrapper: {
        position:"relative",
        flex:1,
        overflowY:"auto",
        width:"100%",
        height:"100%",
        pointerEvents: "all",
        background:"#f5f5f5"
    }
}

class ScenarioMakerCreateGeoJSON extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            addingBoundary: false,
            addType: null,
            areaName: "",
            uploadingArea: false,
        }
    }

    handleSetDraw = () => {
        this.setState({
            addType: "draw"
        });

        console.log("map", this.props.map)
        this.props.map.map.addControl(this.props.map.mapdraw,'top-right');
        this.props.map.mapdraw.changeMode("draw_polygon");

    }

    handleSubmitGeojson = () => {
        let project_id = this.props.project.id;
        let geojson = null;
        if(this.state.addType === "draw"){
            geojson = this.props.map.mapdraw.getAll()
            this.props.map.mapdraw.deleteAll();
            this.props.map.mapdraw.changeMode("simple_select");
            this.props.map.map.removeControl(this.props.map.mapdraw);
        }

        let name = this.state.areaName;
        this.setState({
            uploadingArea: true,
            addingBoundary: false,
        })
        this.props.createGeoJSON(project_id, geojson, name, _=>{
            this.setState({
                uploadingArea: false,
            })
        });
    }

    renderAddingBoundary = () => {
        if(this.state.addType === "draw"){
            return <div>
                <Typography>
                    Draw polygon
                </Typography>
                <div>
                    <Typography variant={"subtitle2"}>
                        Area name
                    </Typography>
                    <TextField fullWidth
                               value={this.state.areaName}
                               onChange={e => this.setState({
                        areaName: e.target.value
                    })}/>
                    <br/>
                    <br/>
                    <Button onClick={this.handleSubmitGeojson} variant={"outlined"} fullWidth color={"primary"}>
                        submit
                    </Button>
                </div>
            </div>
        }else if(this.state.addType === "upload"){
            return <div>
                kmsdflkm
            </div>
        }
        return  <div>
            <Button
                onClick={this.handleSetDraw}
                color={"primary"} fullWidth variant={"outlined"}>
                draw
            </Button>
            <Button
                onClick={_=>{
                    this.setState({
                        addType: "upload"
                    })}
                }
                color={"primary"} fullWidth variant={"outlined"}>
                upload
            </Button>
        </div>
    }

    handleAddAreaClick = () => {
        this.setState({
            addingBoundary: true,
        })
    }

    render() {
        let listTitle;
        if(this.state.addingBoundary && !this.state.addType){
            listTitle = "Adding boundary"
        }else if(this.state.addingBoundary && this.state.addType === "draw"){
            listTitle = "Drawing Boundary"
        }else if(this.state.addingBoundary && this.state.addType === "upload"){
            listTitle = "Uploading Boundary"
        }else{
            listTitle = "Boundaries"
        }

        return <div style={styles.wrapper}>
            <Dialog open={this.state.uploadingArea}>
                <DialogTitle>
                    Uploading Area
                </DialogTitle>
                <DialogContent style={{textAlign:"center"}}>
                    <CircularProgress variant={"indeterminate"}/>
                </DialogContent>
            </Dialog>
            <DynamicList title={listTitle}
                         noFAB={this.state.addingBoundary}
                         onAddClick={this.handleAddAreaClick}
                         extraFAB={this.state.addingBoundary ?
                             <Fab size={"small"}
                                  style={{background: "red"}}
                                  onClick={_=>this.setState({addingBoundary: false})}>
                                 <BackIcon style={{color:"white"}}/>
                             </Fab>:null
                         }
                         fabDisabled={false}>
                {

                }
                {!this.state.addingBoundary && <GeoJSONList buttons={false} onItemClick={_=>{}} subheader={""}/>}
            </DynamicList>


        </div>;
    }
}


ScenarioMakerCreateGeoJSON.propTypes = {
    map: PropTypes.object,
    project: PropTypes.object,
}

const mapStateToProps = (state, ownProps) => {
    return {
        project: state.project,
    }
}

const mapDispatchToProps = (dispatch) => {

    return {
        createGeoJSON: (project_id, boundary, name, callback) => {
            dispatch(createGeoJSON(project_id, boundary, name)).then(callback)
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ScenarioMakerCreateGeoJSON);
