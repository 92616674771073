import React from 'react'
import PropTypes from "prop-types"
import {connect} from "react-redux";
import Card from "@material-ui/core/Card/index";
import {CardContent, CardHeader, DialogContent, Select, TextField, Typography} from "@material-ui/core/index";
import DashboardTile from "./DashboardTile";
import queryHandler from "../QueryHandler";
import Tabs from "@material-ui/core/Tabs/index";
import Tab from "@material-ui/core/Tab/index";
import DashboardTiles from "./ScenarioViewDashboardTiles";
import ScenarioOverview from "./ScenarioOverview";
import Divider from "@material-ui/core/Divider/index";
import Dialog from "@material-ui/core/Dialog/index";
import DialogTitle from "@material-ui/core/DialogTitle/index";
import MenuItem from "@material-ui/core/MenuItem/index";
import DialogActions from "@material-ui/core/DialogActions/index";
import Button from "@material-ui/core/Button/index";


const styles = {
    wrapper: {
        position:"absolute",
        right:0,
        top:0,
        bottom:0,
        width:"30%",
        display:"flex",
        flexDirection:"column",
        overflow:"hidden",
        boxSizing:"border-box",
        minWidth:"200px",
        background:"#f5f5f5"
    },
    inner:{
        width:"100%",
        display:"flex",
        height:"100%",
        flexDirection:"column",
        boxSizing:"border-box",
    },
    innerScroll:{
        width:"100%",
        flex:1,
        boxSizing:"border-box",
        height:"100%",
        overflowY:"auto",
    },
    panel: {
        flex:1,
        display:"flex",
        flexDirection:"column",
        overflow:"hidden",
    },

    panelInner: {
        width:"100%",
        flex:1,
        overflowY:"auto"
    },

    panelHeader: {
        background:"#fff",
        position:"sticky",
        top:0,
    }

}


class DashboardPanel extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            current_tab: 0,
            new_scenario_name: "",
            new_scenario_parent: -1,
            new_scenario_dialog_open: false,
        }
    }

    renderTab = (label) => {
        return <Tab
            style={{minHeight:"0px"}}
            label={<span style={{fontSize:"0.8em"}}>{label}</span>}
        />
    }


    render() {
        return <div style={styles.wrapper}>

            <Divider/>
            <div style={styles.inner}>
                <div style={{height:"100%", overflowY:"auto"}}>
                 <DashboardTiles />

                </div>
            </div>
        </div>;
    }
}


DashboardPanel.propTypes = {
}

const mapStateToProps = (state, ownProps) => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => {

    return {}
}


export default connect(mapStateToProps, mapDispatchToProps)(DashboardPanel);
