import DanceAPI from "./DanceAPI";


export const shareProject = (project_id, username) => {
    return (dispatch, getState) => {
        return DanceAPI.share.post(project_id, username).then(result => {
            return result;
        });
    }
}

// export const initFilters = (filters) => ({
//     type: "FILTERS_INIT",
//     filters
// });
//
// export const updateFilter = (id, state) => ({
//     type: "FILTERS_UPDATE",
//     id,
//     new_state: state,
// });
//
// export const addFilter = (id, data, active) => ({
//     type: "FILTERS_ADD",
//     id,
//     ...data,
//     active,
// });
