import DanceAPI from "./DanceAPI";
import {get_comparison_perspective_column_keys} from "../map_styling/util";
import {tile_server} from "../config";
import {setLayerRangeData} from "./layer_actions";


export const createScenarioComparison = (template_id, scenarios) => {
    return (dispatch, getState) => {
        return DanceAPI.scenarioComparison.post(getState().project.id, template_id, scenarios).then(result => {
            DanceAPI.scenarioComparison.get(getState().project.id).then(result => {
                dispatch(initScenarioComparisons(result.comparisons))
            })
            return result;
        })

    }
}


export const getComparisonRangeData = (comparisons) => {
    return (dispatch, getState) => {
        comparisons.map(comparison => {
            if(comparison.layer.range_data !== null){
                return;

            }else{

                let query_string = {};
                query_string.sources = {};
                let template = comparison.layer.template.data;
                let inputs = comparison.layer.scenarios;

                for(let i = 0; i < template.number_of_inputs; i++){
                    if (inputs[i] === undefined){
                        continue
                    }
                    query_string.sources[inputs[i].id + template.suffix] = {name: template.input_aliases[i]}
                }

                query_string.sinks = Object.assign({}, template.query);
                let cols = get_comparison_perspective_column_keys(comparison);
                fetch(tile_server + "range_query/" + encodeURIComponent(JSON.stringify(cols)) +"?q="+encodeURIComponent(JSON.stringify(query_string)))
                    .then(v => {
                        console.log(v)
                    })
                console.log(cols)
            }
        })
        // layers.map(layer => {
        //
        //     if (layer.layer.range_data !== null) {
        //         return;
        //     } else {
        //         let cols = get_perspective_column_keys(layer);
        //         fetch(tile_server + "range_query/" + encodeURIComponent(JSON.stringify(cols)))
        //             .then(f => {
        //                 return f.json()
        //             }).then(f => {
        //             console.log(f)
        //             dispatch(setLayerRangeData(layer.layer.id, f))
        //         }).catch(error => {
        //             console.error("range query error ("+layer.layer.name+")", error)
        //         })
        //     }
        // })
    }
}

export const initScenarioComparisons = (comparisons) => ({
    type:"SCENARIOCOMPARISON_INIT",
    comparisons,
});
