



export const openHelp = (url) => ({
    type:"HELP_OPEN",
    url,
})

export const closeHelp = () => ({
    type:"HELP_CLOSE",
})