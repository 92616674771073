import React from 'react'
import PropTypes from "prop-types"
import Layer from "./Layer";


class Source extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {

        if(!this.props.map || !this.props.source){
            return;
        }

        if(!this.props.mapLoaded){
            return;
        }
        let temp;
        try {
            temp = this.props.map.getSource(this.props.source.id);
        } catch {
            return;
        }
        if(!temp){
            this.props.map.addSource(this.props.source.id, this.props.source.source)
        }else{
            if(temp.setData) {
                temp.setData(this.props.source.source.data)
            }
        }

        let layers = [];

        React.Children.map(this.props.children, child => {
            if(child.type !== Layer){
                return;
            }

            let description = child.props.description;
            layers.push(description);
        });


        let prev_layers = this.props.map.getStyle().layers.filter(
            layer => layer.source === this.props.source.id
        )

        layers.map(layer => {
            if(!layer){
                console.warn("Layer with no description")
                return
            }


            let existing = this.props.map.getLayer(layer.id);
            if(existing !== undefined){
                console.log(existing)
                this.props.map.removeLayer(existing.id)
            }
            let index = prev_layers.findIndex(l => l.id === layer.id);
            if(index >= 0){
                prev_layers.splice(index, 1);
            }
            this.props.map.addLayer(layer);
        })

        prev_layers.map(layer => {
            this.props.map.removeLayer(layer.id);
        })

    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if(!this.props.map || !this.props.source){
            return;
        }

        if(!this.props.mapLoaded){
            return;
        }
        let temp;
        try {
            temp = this.props.map.getSource(this.props.source.id);
        } catch (e) {
            return;
        }

        if(!temp){
            this.props.map.addSource(this.props.source.id, this.props.source.source)
        }else{
            if(temp.setData) {
                temp.setData(this.props.source.source.data)
            }
        }

        let layers = [];

        React.Children.map(this.props.children, child => {
            if(child.type !== Layer){
                return;
            }

            let description = child.props.description;
            layers.push(description);
        });



        let prev_layers = this.props.map.getStyle().layers.filter(
            layer => layer.source === this.props.source.id
        )

        layers.map(layer => {
            if(!layer){
                console.warn("Layer with no description")
                return
            }



            let existing = this.props.map.getLayer(layer.id);
            if(existing !== undefined){
                this.props.map.removeLayer(existing.id)
            }
            let index = prev_layers.findIndex(l => l.id === layer.id);
            if(index >= 0){
                prev_layers.splice(index, 1);
            }

            console.log(layer)
            this.props.map.addLayer(layer);
        })

        prev_layers.map(layer => {
            this.props.map.removeLayer(layer.id);
        })
    }

    componentWillUnmount() {

        if(!this.props.map || !this.props.source){
            return;
        }

        if(!this.props.mapLoaded){
            return;
        }
        let temp;
        try {
            //required for some reason
            temp = this.props.map.getSource(this.props.source.id);
        } catch (e) {
            return;
        }
        if(!temp){
            this.props.map.addSource(this.props.source.id, this.props.source.source)
        }else{
            if(temp.setData) {
                temp.setData(this.props.source.source.data)
            }
        }

        let layers = [];

        React.Children.map(this.props.children, child => {
            if(child.type !== Layer){
                return;
            }

            let description = child.props.description;
            layers.push(description);
        });


        layers.map(layer => {
            this.props.map.removeLayer(layer.id);
        })
    }

    render() {
        return <div>
            {
                this.props.children
            }
        </div>;
    }
}


Source.propTypes = {
    layer: PropTypes.object,
    source: PropTypes.object,
    mapLoaded: PropTypes.bool,
}

export default Source;
