import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import PropTypes from "prop-types";
import Divider from "@material-ui/core/Divider";
import {ListItemSecondaryAction} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit"
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import {connect} from "react-redux";
import {updateScenario} from "../../actions/scenario_actions";
import CircularProgress from "@material-ui/core/CircularProgress";
import DeleteIcon from "@material-ui/icons/Delete";
import WarningIcon from "@material-ui/icons/Warning"
import DanceAPI from "../../actions/DanceAPI";

const styles = {

    drawer_subList : {
        backgroundColor: "#FFFFFF",
        color:"#243B53",
        padding: "3px",
        paddingLeft: "45px",
        borderWidth: "3px",
        borderColor: "#243B53",
        borderStyle:"none none solid none",
    }
};


class PanelScenarioListItem extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            hovered: false,
            dialogOpen: false,
            dirty: false,
            processing: false,
            confirmTextBox:"",
            new_data:{
                name: props.scenario.name,
            }
        }
    }


    highlight = () => {
        this.setState({
            hovered: true,
        })
    }

    lowlight = () => {
        this.setState({
            hovered:false,
        })
    }

    renderTabLabel = (scenario) => {
        let style = {
            position: "absolute",
            left: "0px",
            top: "0px",
            fontSize: "10px",
            padding: "3px",
            borderRadius: "6px"
        };
        let chip = (<div style={{
            ...style,
            backgroundColor: "#FCEFC7",
            color: "#513C06",
        }}>setup</div>)

        if (scenario.status < 0)
            chip = (<div style={{
                ...style,
                backgroundColor: "#d32f2f",
                color: "#f1f1f1",
            }}>error</div>)

        if (scenario.status > 2 && scenario.status < 7)
            chip = (<div style={{
                ...style,
                backgroundColor: "#2D3A8C",
                color: "#BED0F7",
            }}>running</div>)
        if (scenario.status > 6)
            chip = (<div/>)

        return (<div style={{position:"relative", display:"flex"}}>
            <div style={{flex:"0"}}>
                {scenario.name}
            </div>
            <div style={{position:"relative", flex:"1"}}>
                {chip}
            </div>
        </div>)
    }


    updateScenario = () => {
        this.setState({
            processing: true,
        });

        this.props.updateScenarioData(this.props.scenario.id, this.state.new_data).then(v => {
            this.setState({
                processing:false
            })
        })
    }

    handleOpen = () => {
        this.setState({
            new_data: {
                name: this.props.scenario.name
            },
            dialogOpen: true,
        })
    }

    handleClose = () => {
        this.setState({
            new_data: {
                name: ""
            },
            dialogOpen:false,
        })
    }

    handleDeleteConfirm = () => {
        this.setState({
            deleting: true,
        })
        this.props.deleteScenario(this.props.scenario.id).then(f => {
            this.setState({
                deleting:false,
                confirmingDialogOpen: false,
                dialogOpen:false,
            })
        }).then(f => {
            window.location.reload();
        })
    }

    handleSetData = (k) => (v) => {
        this.setState({
            dirty:true,
            new_data: {
                ...this.state.new_data,
                [k]:v
            }
        })
    }

    renderDialogContent = () => {
        return <div>
            {this.state.processing && <div style={{
                display:"flex",
                alignItems: "center",
                justifyContent: "center",
                width:"100%",
                height:"100%",
                position:"absolute",
                background:"rgba(0,0,0,0.23)"}}>
                <div>
                    <CircularProgress size={200}/>
                    <div style={{textAlign:"center"}}>
                        loading..
                    </div>
                </div>
            </div>}

            <div style={{
                minHeight:"60vh",
                marginLeft:"20%",
                marginRight:"20%",
                position:"relative",
            }}>


                <TextField
                    onChange={e => this.handleSetData("name")(e.target.value)}
                    value={this.state.new_data.name}
                    fullWidth
                    label={"Scenario Name"}
                />
                <br/>

                <Button
                    fullWidth
                    onClick={_=>this.setState({
                        confirmingDialogOpen: true
                    })}
                    style={{marginTop:"10px"}}
                    variant={"contained"} color={"secondary"}>
                    <DeleteIcon/>Delete
                </Button>
            </div>
        </div>
    }

    render() {
        let id = -1;
        if (this.props.currentScenario)
            id = this.props.currentScenario.id
        return <div>
                <Dialog
                    maxWidth={"lg"}
                    fullWidth
                    open={this.state.dialogOpen}>
                    <DialogTitle>
                        Edit Scenario ({this.props.scenario.name})
                    </DialogTitle>
                    <DialogContent dividers style={{position:"relative", padding:"0px"}}>
                        {this.renderDialogContent()}
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={this.handleClose}
                            color={"secondary"}>
                            cancel
                        </Button>
                        <Button
                            disabled={!this.state.dirty}
                            onClick={this.updateScenario}
                            color={"primary"}>
                            submit
                        </Button>
                    </DialogActions>
                </Dialog>
                <ListItem dense  button key={this.props.scenario.id} value={this.props.scenario.id}
                             onClick={_=>this.props.setScenarioCallback(this.props.scenario.id)}
                              onMouseEnter={this.highlight}
                              onMouseLeave={this.lowlight}
                             style={{...styles.drawer_subList,
                                 backgroundColor: !this.state.hovered && this.props.scenario.id !== id ? "#FFFFFF":"#F0F4F8",
                                 borderStyle: this.props.scenario.id === id ? "none solid none none": "none"}}
            >

                <ListItemText
                    secondaryTypographyProps={{variant:"caption"}}
                    secondary={this.props.parentScenario ? this.props.parentScenario.name : ""}
                    primary={this.renderTabLabel(this.props.scenario)}/>
                <ListItemSecondaryAction>
                    <IconButton
                        onClick={this.handleOpen}
                        size={"small"}>
                        <EditIcon fontSize={"small"}/>
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>

            <Dialog  open={this.state.confirmingDialogOpen}>
                <DialogTitle>
                    Confirm Delete
                    <WarningIcon style={{float:"right"}} color={"error"} fontSize={"large"}/>

                </DialogTitle>
                <DialogContent style={{position:"relative", padding:"0px"}}>
                    {this.state.deleting && <div style={{
                        display:"flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width:"100%",
                        height:"100%",
                        position:"absolute",
                        background:"rgba(0,0,0,0.23)"}}>
                        <div>
                            <CircularProgress size={100}/>
                            <div style={{textAlign:"center"}}>
                                loading..
                            </div>
                        </div>
                    </div>}
                    <div style={{padding:"10px"}}>
                        Are you sure you want to delete this scenario?
                        <br/>
                        <br/>
                        This cannot be undone, and may require admin assistance.
                        <br />
                        This will cause a refresh of the page
                        <br/>
                        <br/>
                        Type "delete" in the box below to enable delete button.

                        <div style={{marginLeft:"10%", marginRight:"10%", marginTop:"10px"}}>
                            <TextField fullWidth
                                       error={this.state.confirmTextBox !== "delete"}
                                       margin={"dense"}
                                       value={this.state.confirmTextBox}
                                       variant={"outlined"}
                                       onChange={e=>this.setState({confirmTextBox:e.target.value})}/>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={_=>this.setState({
                            confirmingDialogOpen: false
                        })}
                        color={"primary"}>
                        DO NOT DELETE
                    </Button>
                    <Button
                        onClick={this.handleDeleteConfirm}
                        disabled={this.state.confirmTextBox !== "delete"}
                        color={"secondary"}>
                        DELETE
                    </Button>
                </DialogActions>
            </Dialog>
        </div>;


    }
}



PanelScenarioListItem.propTypes = {
    scenario: PropTypes.object.isRequired,
    setScenarioCallback: PropTypes.func.isRequired,
    currentScenario: PropTypes.object.isRequired,
}


const mapStateToProps = (state, ownProps) => {
    return {
        parentScenario: state.scenarios.find(f => f.id === ownProps.scenario.parent)
    }
}

const mapDispatchToProps = (dispatch) => {

    return {
        updateScenarioData: (scenario_id, data)=>{
            return DanceAPI.scenario.put(scenario_id, data).then(d => {
                return dispatch(updateScenario(scenario_id, d));
            })
        },
        deleteScenario: (scenario_id) => {
            return DanceAPI.scenarioEnableStatus.post(scenario_id, {
                enabled_status: 2
            })
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PanelScenarioListItem);
