import React from 'react'
import PropTypes from "prop-types"
import {connect} from "react-redux";
import {withRouter} from "react-router";
import Paper from "@material-ui/core/Paper";
import {DialogActions, DialogContent, DialogContentText, DialogTitle, Slide, Typography} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import {loadScenarioMakerNodes} from "../actions/scenario_maker_node_actions";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Container from "@material-ui/core/Container";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ScenarioMakerNodePropertiesPanel from "../Components/ScenarioMakerNodePropertiesPanel";
import {highlightGeojson} from "../actions/geojson_actions";
import GeoJSONList from "../Components/GeoJSONList";
import ScenarioMakerCreateGeoJSON from "../Components/ScenarioMakerCreateGeoJSON";
import {createScenarioRevision, getScenario} from "../actions/scenario_actions";
import DanceAPI from "../actions/DanceAPI";
import LinearProgress from "@material-ui/core/LinearProgress";
import {openHelp} from "../actions/help_actions";
import WorkflowNodePanel from "../Components/WorkflowNodePanel";
import Tabs from "@material-ui/core/Tabs";
import ScenarioOverview from "../Components/ScenarioOverview";
import ScenarioViewDashboardTiles from "../Components/ScenarioViewDashboardTiles";
import Tab from "@material-ui/core/Tab";
import InitializingProjectScreen from "./InitializingProjectScreen";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import CheckIcon from "@material-ui/icons/Check";
import CircularProgress from "@material-ui/core/CircularProgress";
import {getLayers} from "../actions/layer_actions";
import DynamicList from "../Components/Views/DynamicList";
import LayerList from "../Components/LayerList";
import Legend from "../Components/Legend";
import Chip from '@material-ui/core/Chip';
import SearchableTags from "../Components/Views/SearchableTags";
import HelpDrawer from "../Components/HelpDrawer";
import MenuItem from "@material-ui/core/MenuItem";
import UploadWorkflowDialog from "../Components/Views/UploadWorkflowDialog";


const styles = {

    root: {

    },
    wrapper: {
        position:"absolute",
        left:0,
        top:0,
        bottom:0,
        width:"250px",
        display:"flex",
        flexDirection:"column",
        boxSizing:"border-box",
        minWidth:"200px",
        background:"#f5f5f5"
    },
    titleWrapper: {
        position:"absolute",
        left:"265px",
        top:"15px",
        background:"#FCEFC7",
        color: "#7C5E10",
        padding: "5px"
    },
    mainPanelWrapper:{
        width:"100%",
        display:"flex",
        flex:1,
        position:"relative",
        flexDirection:"column",
        bozSizing:"border-box",
        background:"#f5f5f5"
    },
    mainPanelContent:{
        width:"100%",
        display:"flex",
        flex:1,
        position:"relative",
        flexDirection:"column",
        bozSizing:"border-box",
        background:"#F0F4F8",
    },
    panel: {
        padding:"10px",
        flex:1,
        display:"flex",
        position:"relative",
        flexDirection:"column",
        overflow:"hidden",
    },

    panelInner: {
        width:"100%",
        flex:1,
        overflowY:"auto"
    },

    panelHeader: {
        background:"#fff",
        position:"sticky",
        top:0,
    },

    propertiesPanel: {
        overflow:"hidden",
        pointerEvents: "none",
        position:"absolute",
        top:0,
        bottom:0,
        right:0,
        width:"25%",
    },

    propertiesPanelInner: {
        overflow:"hidden",
        height:"100%",
        background:"#f5f5f5",
        pointerEvents: "all",
    },

    runningWrapper: {
        position:"absolute",
        width:"100%",
        height:"100%",
        display:"flex",
        justifyContent: "center",
        alignItems: "center",
    },


    legendWrapper: {
        left:"260px",
        paddingTop:"20px",
        width: "300px",
        top:"85vh",
        position:"absolute",
    },

    runningInner: {
        flex:"0 1 20%",
    },


}


class ScenarioScreen extends React.Component {

    constructor(props) {
        super(props);
        let layer_perspective_map = {};
        props.layers.map(l => layer_perspective_map[l.id] = "default");
        this.state = {
            layer_perspective_map,
            loadingScenarioMakerNodes: true,
            selectingNodeType: false,
            layer_state: [...this.props.layers],
            workflowNodes: [],
            executing: false,
            editable: false,
            addingBoundary: false,
            currentNode: null,
            nodePropertiesOpen: false,
            dialogOpen: true,
            filterOpen: false,
            selectedFilterTags: null,
            hovered: null,
            pendingImportData: null,
            importDialogOpen: false,
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevState.loadingScenarioMakerNodes !== this.state.loadingScenarioMakerNodes || prevProps.scenario_id !== this.props.scenario_id){
            let layer_perspective_map = {};
            this.props.layers.map(l => layer_perspective_map[l.id] = "default");
            //if this is the baseline scenario
            if(this.props.scenario.parent === null || this.props.scenario.parent === undefined){
                this.setState({
                    currentNode: null,
                    workflowNodes: [],
                    editable: false,
                    layer_state: [...this.props.layers],
                    layer_perspective_map,
                });
                return;
            }
            let active_rev = this.props.scenario.revisions.find(r => r.id === this.props.scenario.active_revision);

            if(!Array.isArray(active_rev.data)){
                this.setState({
                    currentNode: null,
                    workflowNodes: [],
                    layer_state: [...this.props.layers],
                    editable: true,
                    layer_perspective_map,
                });
                return;
            }

            let workflow_nodes = active_rev.data.map(n => {
                let node_type = this.props.scenario_maker_nodes.find(
                    node => node.id === n.node_type_id
                );
                let area;
                if(n.filter_preset !== null && n.filter_preset !== undefined){
                    area = {
                        type:"geojson_filter_preset",
                        data: this.props.geojson_filter_presets.find(f => f.id === n.filter_preset)
                    }
                }else{
                    area = this.props.geojsons.find(g => g.id === n.area);
                    area = {
                        type:"area",
                        data:area
                    }
                }
                return this.createNode(node_type, area, n.parameters)
            });

            this.setState({
                currentNode: null,
                editable: true,
                layer_state: [...this.props.layers],
                workflowNodes: workflow_nodes,
                layer_perspective_map,
            })
        }
    }

    componentDidMount() {
        this.props.getScenarioMakerNode().then(result => {
            this.setState({
                loadingScenarioMakerNodes: false,
            })
        });
    }


    loadWorkflowFromJSON = (data) => {
        try {
            data = JSON.parse(data);
        } catch (e) {
            console.log("could not load json workflow")
            return;
        }
        let errors = [];
        let nodes = data.map(d => {
            let error_data = [];
            errors.push(error_data);
            let area = this.props.geojsons.find(g => g.id === d.area);
            if(!area){
                error_data.push("area");
            }

            let node_type = this.props.scenario_maker_nodes
                .find(nt => nt.id === d.node_id);

            let node_version = null;
            if(!node_type){
                error_data.push("node_type")
            }else{
                node_version = node_type.versions.find(v => v.id === d.node_version_id);

                if(!node_version){
                    error_data.push("node_version");
                }

            }
            return {
                meta_data: d.meta_data,
                node_type: node_type,
                node_version: node_version,
                area: area ? area : null,
                parameters: d.parameters
            }

        })

        this.setState({

            pendingImportData: {
                nodes:nodes,
                errors:errors,
            },
            importDialogOpen: true,
        })
    }

    handleSelectingNodeType = () => {
        this.setState({
            selectingNodeType: true,
        })
    };

    createNode = (node_type, area=null, parameters={}) => {
        return {
            node_type: node_type,
            node_version: node_type.versions.find(v => v.id === node_type.active_version),
            area: area,
            parameters: parameters,
        }

    };

    handleSelectedNodeType = (node) => {
        this.setState({
            workflowNodes: [...this.state.workflowNodes, this.createNode(node)],
            selectingNodeType: false,
        })
    };

    setCurrentNode = (index) => {
        this.setState({
            nodePropertiesOpen:  this.state.nodePropertiesOpen === false ? true : this.state.currentNode !== index,
            currentNode: index,
        })
    };

    deleteNode = (index) => {
        let nodes = [...this.state.workflowNodes];
        nodes.splice(index, 1);

        this.setState({
            workflowNodes: nodes,
            nodePropertiesOpen: false,
            currentNode: null,
        })

    };

     handleClose= () => {
         this.setState({
             selectingNodeType: false,
             selectedFilterTags: null,
         })
    };

    handleParameterChange = (parameter, value) => {
        let node = {
            ...this.state.workflowNodes[this.state.currentNode]
        };
        node.parameters[parameter] = value;
        let new_nodes = [...this.state.workflowNodes];
        new_nodes[this.state.currentNode] = node;

        this.setState({
            workflowNodes: new_nodes,
        })
    };


    handleSetArea = (area) => {

        let node = {
            ...this.state.workflowNodes[this.state.currentNode]
        };
        node.area = area;
        let new_nodes = [...this.state.workflowNodes]
        new_nodes[this.state.currentNode] = node;

        this.setState({
            workflowNodes: new_nodes,
        })
    };

    handleSetFilter = (filter) => {
        let node = {
            ...this.state.workflowNodes[this.state.currentNode]
        };
        node.filter = filter;
        let new_nodes = [...this.state.workflowNodes]
        new_nodes[this.state.currentNode] = node;

        this.setState({
            workflowNodes: new_nodes,
        })
    };


    renderNodeTypeDialog = () => {
        let tags = [...new Set(this.props.scenario_maker_nodes.flatMap(v => v.tags))].map(v=>({label:v, value:v}));
        return <Dialog
            maxWidth={"lg"}
            fullWidth
            open={this.state.selectingNodeType}
            onClose={(this.handleClose)}>
            <div style={{display:"flex", flexDirection:"row", padding:"10px"}}>
                <div style={{flexBasis:"33.33%"}}>
                    <Typography variant={"h5"}>
                        Select node type
                    </Typography>
                </div>
                {/*<div style={{flexBasis:"66.67%", display:"flex"}}>*/}
                {/*    /!*<div style={{flex:1, overflow:"hidden"}}>*/}
                {/*        <div style={{width:"100%"}}>*/}
                {/*        { this.state.selectedFilterTags.map (t=><Chip*/}
                {/*            label={t.label}*/}
                {/*            color={"primary"}*/}
                {/*            onDelete={_=>this.setState({selectedFilterTags:selectedTags.filter(f=>f!==t)})}/>)}*/}
                {/*        </div>*/}
                {/*    </div>*!/*/}
                {/*    <SearchableTags*/}
                {/*        textLabel={"Filter"}*/}
                {/*        onChange={v => this.setState({selectedFilterTags: v})} value={this.state.selectedFilterTags} tags={tags}>*/}

                {/*    </SearchableTags>*/}
                {/*</div>*/}

            </div>

            <DialogContent dividers style={{height:"68vh", overflow:"hidden"}}>
                        <Grid container style={{height:"100%", overflowY:"auto", alignItems:"stretch"}}>
                            <Grid xs={6} sm={2}>
                                <div style={{position:"sticky", top:"1px"}}>
                                    <Typography variant={"h6"}>
                                        Categories
                                    </Typography>
                                    <ListItem style={{
                                        backgroundColor:this.state.selectedFilterTags===null?"#D9E2EC":
                                            (this.state.hovered==="all"?"#F0F4F8":"transparent")}}
                                              button
                                              onMouseEnter={_=>this.setState({hovered:"all"})}
                                              onMouseLeave={_=>this.setState({hovered:null})}
                                              onClick={_=>this.setState({selectedFilterTags:null})}>
                                        All
                                    </ListItem>
                                    {tags.map(t => <ListItem style={{
                                        backgroundColor:this.state.selectedFilterTags===null?(this.state.hovered===t.label?"#F0F4F8":"transparent"):
                                            (this.state.selectedFilterTags.label===t.label?"#D9E2EC":(this.state.hovered===t.label?"#F0F4F8":"transparent"))}}
                                                             onMouseEnter={_=>this.setState({hovered:t.label})}
                                                             onMouseLeave={_=>this.setState({hovered:null})}
                                                             onClick={_=>this.setState({selectedFilterTags:t})}
                                                             button>{t.label}</ListItem>)}
                                </div>
                            </Grid>
                            <Grid container xs={6} sm={10} spacing={2}>
                                {this.props.scenario_maker_nodes.filter(smo => smo.tags.map(
                                    tag => (this.state.selectedFilterTags !== null && this.state.selectedFilterTags.label===tag)
                                    ).some(v=>v) || this.state.selectedFilterTags === null
                                ).map(node => {
                                    if(!node.versions){
                                        return;
                                    }
                                return <Grid key={node.id} item xs={6} sm={4} md={3} xl={3}>
                                    <Card style={{position:"relative", height:"100%", display:"flex", flexDirection:"column",}}>
                                        <CardContent style={{position:"relative", backgroundColor: "white", flex:1}}>
                                            <small style={{float:"right", color:"#555"}}>{
                                                node.versions.find(v => v.id === node.active_version).version_tag
                                            }</small>
                                            <Typography gutterBottom variant="body2" component="h2">
                                                {node.name}
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary" component="p">{
                                                node.versions.find(v => v.id === node.active_version).description
                                            }</Typography>
                                        </CardContent>
                                        <CardActions  style={{backgroundColor:"white"}}>
                                            <Button onClick={_=>this.props.openHelp( node.versions.find(v => v.id === node.active_version).metadata.help_url)} color={"secondary"}>more info</Button>
                                            <Button style={{marginLeft:"auto"}} onClick={_=>this.handleSelectedNodeType(node)} color={"primary"}>select</Button>
                                        </CardActions>
                                    </Card>
                                </Grid>
                            })}
                        </Grid>
                        </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={this.handleClose} color="primary">
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    };


    renderIcon = (step, current_step) => {
        return <ListItemIcon>
            { step < current_step ?
                <CheckIcon style={{color: "green"}}/>
                : (step === current_step ?
                    <ListItemIcon>
                        <CircularProgress size={20}/>
                    </ListItemIcon>:
                    <ListItemIcon>
                    </ListItemIcon>)
            }
        </ListItemIcon>
    };

    renderStep = (text, step, current_step, percentage) => {
        return <ListItem>
            {this.renderIcon(step, current_step)}
            <ListItemText>
                {text} - <span style={{color:"#5f5f5f"}}>{
                current_step === step ?
                    "("+percentage + "%)"
                    :""
            }</span>
            </ListItemText>
        </ListItem>
    };


    handleRun = () => {
        let temp = this.state.workflowNodes.map(node => {
            
            if(node.area.type !== "area"){
                return {
                    node_type_id: node.node_type.id,
                    area: node.area.data.geojson.id,
                    filter_preset: node.area.data.id,
                    filters: node.area.data.filters.map(f => f.id),
                    parameters: node.parameters,
                }
            }
            return {
                node_type_id: node.node_type.id,
                area: node.area.data.id,
                filter: node.filter,
                parameters: node.parameters,
            }
        });
        this.setState({
            executing: true
        });
        this.props.createAndExecute(this.props.scenario.id, temp, _=>{
            this.setState({
                executing: false,
            })
        })

    };

    perspectiveCallback = (layer_id, perspective) => {
        let new_lpm = {...this.state.layer_perspective_map};
        new_lpm[layer_id] = perspective
        this.setState({
            layer_perspective_map:new_lpm,
        })
    }
    handleConfirmImport = () => {
        let nodes = this.state.pendingImportData.nodes.map(data => {
            console.log(data);
            return {
                node_type: data.node_type,
                node_version: data.node_version,
                parameters: data.parameters,
                area: null
            }
        })

        this.setState({
            importDialogOpen: false,
            workflowNodes: nodes
        })
    }
    render() {
        if(!this.props.scenario){
            return <Dialog open>
                    <DialogTitle>
                        Hold up
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            There scenario id and project id dont match
                        </DialogContentText>

                    </DialogContent>
                <DialogActions>
                    <Button onClick={_=>this.props.history.push("/project/" + this.props.project.id)} color={"primary"} variant={"outlined"}>Go back to project</Button>
                </DialogActions>
                </Dialog>
        }

        let running = this.props.scenario.status < 7 && this.props.scenario.status > 1;
        if(running){

            let step=[0,1,1,1,2,2,2,3][this.props.scenario.status]
            let percentage=this.props.scenario.current_stage_progress
            return <div style={styles.runningWrapper}>
                <Paper style={styles.runningInner}>
                    <Typography variant={"h6"} style={{padding:"10px"}}>
                        Scenario Running
                    </Typography>
                    <Divider />
                    <List dense disablePadding>
                        {this.renderStep("Compiling Models",0, step, percentage)}
                        {this.renderStep("Running Baseline",1, step, percentage)}
                        {this.renderStep("Running Performance Assessment",2, step, percentage)}

                    </List>
                </Paper>
            </div>
        }

        // let display_scenario = this.props.scenario.status > 6 ? this.props.scenario : this.props.parentScenario;
        let display_scenario = this.props.scenario;
        let editable = (this.props.scenario.status === 1);

        return <div style={styles.root}>

            <UploadWorkflowDialog
                onCancel={_=>this.setState({importDialogOpen: false})}
                onConfirm={this.handleConfirmImport}
                uploadData={this.state.pendingImportData}
                open={this.state.importDialogOpen}
            />
            <HelpDrawer openDrawer={this.props.scenario_id !== -3 && this.state.nodePropertiesOpen}/>
            { display_scenario.status === 7 &&
                <div style={styles.titleWrapper}>
                    <h2>Results for {display_scenario.name}</h2>
                </div>
            }
            <div style={styles.wrapper}>

                <Dialog open={this.state.loadingScenarioMakerNodes}>
                    <DialogTitle>
                        Loading Scenario Maker Nodes
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            please wait
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
                <Dialog open={this.state.executing}>
                    <DialogTitle>
                        Dispatching your scenario
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            please wait
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
                {
                    this.renderNodeTypeDialog()
                }
                <div style={styles.mainPanelWrapper}>

                    <div style={styles.mainPanelContent}>
                        <div style={{height:"100%", overflowY:"auto"}}>
                             <WorkflowNodePanel
                                    editable={ editable }
                                    runScenarioCallback={this.handleRun}
                                    workflowUpload={this.loadWorkflowFromJSON}
                                    deleteNodeCallback={this.deleteNode}
                                    selectNodeCallback={this.setCurrentNode}
                                    workflowNodes={this.state.workflowNodes}
                                    addNodeCallback={this.handleSelectingNodeType}
                                    selectedNode={this.state.nodePropertiesOpen ? this.state.currentNode : null}
                                    geojsons={this.props.geojsons}
                                />


                        </div>
                    </div>


                </div>
            </div>

            { display_scenario.status === 7 &&
                <div style={{...styles.propertiesPanel, zIndex: 100}}>
                    <div style={styles.propertiesPanelInner}>
                        <div style={{height: "100%", overflowY: "auto"}}>
                            <ScenarioViewDashboardTiles map={this.props.map} scenario={display_scenario}/>
                        </div>
                    </div>
                </div>
            }


            <div style={{...styles.propertiesPanel, zIndex:101}}>
                <Slide direction={"left"} unmountOnExit mountOnEnter in={this.state.nodePropertiesOpen}>
                    <div style={{height:"100%"}}>
                        <ScenarioMakerNodePropertiesPanel
                            map={this.props.map}
                            open={this.state.nodePropertiesOpen}
                            setFilterCallback={this.handleSetFilter}
                            parameterCallback={this.handleParameterChange}
                            setAreaCallback={this.handleSetArea}
                            node={this.state.currentNode === null ? null :this.state.workflowNodes[this.state.currentNode]}
                            openHelp={this.props.openHelp}/>
                    </div>
                </Slide>
            </div>

        </div>
    }
}


ScenarioScreen.propTypes = {
    map: PropTypes.object,
    scenario_id: PropTypes.number,
    geojsons: PropTypes.array,
    setLayersCallback: PropTypes.func,
    scenario_maker_nodes: PropTypes.array,
    layers: PropTypes.array,
};

const mapStateToProps = (state, ownProps) => {
    let scenario = state.scenarios.find(s => s.id === parseInt(ownProps.scenario_id))
    let parentScenario = state.scenarios.find(s => s.id === parseInt(scenario.parent))

    let layers = [];
    if (scenario.status > 6) {
        layers = state.layers.filter(l => l.scenario_id === ownProps.scenario_id)
    } else {
        layers = state.layers.filter(l => l.scenario_id === scenario.parent)
    }

    return {
        geojsons: state.geojsons,
        project: state.project,
        layers: layers,
        scenario_maker_nodes: state.scenario_maker_nodes,
        scenario: scenario,
        parentScenario: parentScenario,
        geojson_filter_presets: state.geojson_filter_presets,

    }
};

const mapDispatchToProps = (dispatch) => {

    return {
        getScenarioMakerNode: _ => {
            return dispatch(loadScenarioMakerNodes())
        },
        createAndExecute: (scenario_id, nodes
                           , callback) => {
            console.log(nodes)
            dispatch(createScenarioRevision(scenario_id, nodes)).then(_=>{
                return DanceAPI.scenarioExecute.post(scenario_id);
            }).then(_=> {
                return dispatch(getScenario(scenario_id))
            }).then(_=>{
                return dispatch(getLayers())
            }).then(callback)
        },
        openHelp: (url) => {
            dispatch(openHelp(url))
        }
    }
}

ScenarioScreen = withRouter(ScenarioScreen);
export default connect(mapStateToProps, mapDispatchToProps)(ScenarioScreen);
