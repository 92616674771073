import React from 'react'
import PropTypes from "prop-types"
import Card from "@material-ui/core/Card";
import {CardHeader} from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CardActions from "@material-ui/core/CardActions";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";
import Check from "@material-ui/icons/Check"
import Error from "@material-ui/icons/Error"
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CircularProgress from "@material-ui/core/CircularProgress";
import DanceAPI from "../../actions/DanceAPI";
import {help_url} from "../../config";



const styles = {
    root: {
        minWidth:"300px",
        width:"40vw",
        margin:"auto",
        position:"relative",
    },
    loadingOverlay:{
        margin:"auto",
        height:"100%",
        width:"100%",
        left:0,
        display:"flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        position:"absolute",
        top:0,
        background:"rgba(90,90,90,0.8)"
    }
}

const delayPromise = (promise, delay) => {
    let p = new Promise(resolve => {
        setTimeout(resolve, delay);
    });

    return Promise.all([promise, p]).then(res => res[0]);
}

class LoginWrapper extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            current_screen: "login",
            screen_state: {},
            screen_error_state: {
            },
            nda_open: false,
            crc_participant: false,
            disclaimer_open: false,
            nda_signed: false,
            loading_state: {
                open: false,
                type: "Progress",
                text: "Error"
            }
        };
    }

    setField = (key) => (e) => {
        this.setState({
            screen_state: {...this.state.screen_state, [key]: e.target.value}
        });
    }

    setScreen = (value) => () => {
        this.setState({
            current_screen: value
        })
    }

    handleRegister = () => {
        this.setState({
            nda_open: true,
        })
    }

    handleHelp = () => {
        window.open(help_url,"_blank")
    }

    handleSubmitNda = () => {
        let email = this.state.screen_state["email"];
        let password = this.state.screen_state["password"];
        let confirm = this.state.screen_state["confirm_password"];

        if(password !== confirm){
            this.setState({
                nda_open: false,
                screen_error_state: {
                    confirm_password: "Passwords do not match"
                }
            })
        }else {

            this.setState({
                nda_open: false,
                loading_state: {
                    open: true,
                    type: "Progress",
                    text: "loading"
                }
            })
            delayPromise(DanceAPI.register.post(email, password, this.state.crc_participant, this.state.nda_signed), 1000).then(result => {
                if(result.success === false){
                    this.setState({
                        loading_state: {
                            open: true,
                            type: "Error",
                            text: "something went wrong"
                        }
                    })
                    return delayPromise(result, 1000).then(result => {
                        this.setState({
                            screen_error_state: {
                                global: result.message,
                            },
                            loading_state: {
                                open: false,
                                type: "Error",
                                text: "something went wrong"
                            }
                        })
                    })
                }
                this.setState({
                    loading_state: {
                        open: true,
                        type: "Success",
                        text: "success"
                    }
                })
                return delayPromise(result, 1000).then(result => {
                    this.setState({
                        current_screen:"login",
                        screen_state: {
                            email:"",
                            password:"",
                            token:"",
                            confirm_password: ""
                        },
                        loading_state: {
                            open: false,
                            type: "Success",
                            text: "success",
                        }
                    })
                })
            })
        }
    }

    handleLogin = () => {
        let email = this.state.screen_state["email"];
        let password = this.state.screen_state["password"];
        this.setState({
            loading_state: {
                open: true,
                type:"Progress",
                text:"authenticating"
            }
        })
        delayPromise(DanceAPI.login.post(email, password), 1000).then(result => {
            if(result.success === false){
                this.setState({
                    screen_error_state: {
                        global: result.message
                    },
                    loading_state: {
                        open: true,
                        type:"Error",
                        text:"something went wrong"
                    }
                })
                delayPromise(result, 1000).then(result => {
                    this.setState({
                        loading_state: {
                            open: false
                        }
                    })
                })
                return;
            }else{
                this.setState({
                    loading_state: {
                        open: true,
                        type: "Success",
                        text: "success",
                    }
                })
                delayPromise(result, 1000).then(result => {
                    this.props.loginCallback(result);
                })
            }

        })
    }

    handleRequest = () => {
        let email = this.state.screen_state["email"];
        this.setState({
            loading_state: {
                open: true,
                type:"Progress",
                text:"authenticating"
            }
        })
        delayPromise(DanceAPI.request_reset.post(email), 1000).then(result => {
            if(result.success === false){
                this.setState({
                    screen_error_state: {
                        global: result.message
                    },
                    loading_state: {
                        open: true,
                        type:"Error",
                        text:"something went wrong"
                    }
                })
                delayPromise(result, 1000).then(result => {
                    this.setState({
                        loading_state: {
                            open: false
                        }
                    })
                })
                return;
            }else{
                this.setState({
                    loading_state: {
                        open: true,
                        type: "Success",
                        text: "success",
                    }
                })
                delayPromise(result, 1000).then(result => {
                    this.setState({
                        loading_state: {
                            open: false,
                        },
                        current_screen: "reset_password"
                    })
                })
            }

        })
    }

    resetPassword = () => {
        let email = this.state.screen_state["email"];
        let token = this.state.screen_state["token"];
        let password = this.state.screen_state["password"];
        let confirm_password = this.state.screen_state["confirm_password"];

        this.setState({
            loading_state: {
                open: true,
                type:"Progress",
                text:"authenticating"
            }
        })
        delayPromise(DanceAPI.reset_password.post(email, token, password), 1000).then(result => {
            if(result.success === false){
                this.setState({
                    screen_error_state: {
                        global: result.message
                    },
                    loading_state: {
                        open: true,
                        type:"Error",
                        text:"something went wrong"
                    }
                })
                delayPromise(result, 1000).then(result => {
                    this.setState({
                        loading_state: {
                            open: false
                        }
                    })
                })
                return;
            }else{
                this.setState({
                    loading_state: {
                        open: true,
                        type: "Success",
                        text: "success",
                    }
                })
                delayPromise(result, 1000).then(result => {
                    this.setState({
                        loading_state: {
                            open:false
                        },
                        current_screen: "login"
                    })
                })
            }

        })
    }


    renderRegister = () => {
        return <Card style={styles.root} elevation={8}>
            <CardHeader
                title="Register"
                style={{
                    backgroundColor:"#d4c849",
                }}
            />
            <CardContent>
                <small>{this.state.screen_error_state.global}</small>
                {this.renderField("Email", "email", "email")}
                {this.renderField("Password", "password", "password")}
                {this.renderField("Confirm Password", "confirm_password", "password")}
                <FormControlLabel control={<Checkbox value={this.state.crc_participant} onChange={_=>this.setState({crc_participant: !this.state.crc_participant})} />} label={"I am a crc participant"}/>
            </CardContent>
            <CardActions>
                <Button onClick={this.setScreen("login")} size={"small"} variant={"outlined"}>login</Button>
                <Button onClick={this.handleRegister} size={"small"} color="primary" variant={"outlined"} style={{marginLeft:"auto"}}>submit</Button>
            </CardActions>
        </Card>
    }

    renderField = (label, key, type) => {
        return <div>
            <div style={{
                width:"100%",
                margin:"auto",
            }}>
            <Typography>
                {label}
            </Typography>
            <TextField
                error={!!this.state.screen_error_state[key]}
                helperText={this.state.screen_error_state[key]}
                fullWidth
                value={this.state.screen_state[key]}
                onChange={this.setField(key)}
                type={type}
            />
            </div>
        </div>
    }

    renderLogin = () => {
        return <Card style={styles.root} elevation={8}>
            <CardHeader
                title="Log In | Scenario Tool"
                subheader={
                    <div>
                    <span style={{
                        color:"#f4f4f4",
                    }}>CRC for Water Sensitive Cities</span>
                        <span style={{
                            float: "right"
                        }}>
                    <Button onClick={this.handleHelp} size={"small"} variant={"outlined"} style={{color:"#f4f4f4", borderColor:"#f4f4f4"}}>Documentation</Button>
                            </span>
                    </div>
                }
                style={{
                    color:"#f4f4f4",
                    backgroundColor:"#00616F"
                }}
            />
            <CardContent>
                <div style={ {textAlign: "right"} } > <small> Build {process.env.REACT_APP_VERSION} </small><br /></div>
                <small style={{color:"red"}}>{this.state.screen_error_state.global}</small>

                {this.renderField("Email", "email", "email")}
                <br/>
                {this.renderField("Password", "password", "password")}
            </CardContent>
            <CardActions>

                <Button onClick={this.setScreen("register")} size={"small"} variant={"outlined"}>Register</Button>
                <Button onClick={this.setScreen("request_reset")} size={"small"} variant={"outlined"}>Forgotten Password</Button>
                <Button onClick={this.handleLogin} size={"small"} color="primary" variant={"outlined"} style={{marginLeft:"auto"}}>login</Button>
            </CardActions>
        </Card>
    }

    renderRequestReset = () => {
        return <Card style={styles.root} elevation={8}>
            <CardHeader
                title="Request Password Reset"
                style={{
                    color:"#f4f4f4",
                    backgroundColor:"#e42f47"
                }}
            />
            <CardContent>
                {this.renderField("Email", "email", "email")}
            </CardContent>
            <CardActions>
                <Button onClick={this.setScreen("login")} size={"small"} variant={"outlined"}>back</Button>
                <Button onClick={this.setScreen("reset_password")} size={"small"} variant={"outlined"}>have token</Button>
                <Button onClick={this.handleRequest} size={"small"} color="primary" variant={"outlined"} style={{marginLeft:"auto"}}>request</Button>
            </CardActions>
        </Card>
    }

    renderResetPassword = () => {
        return <Card style={styles.root} elevation={8}>
            <CardHeader
                title="Reset Password"
                style={{
                    color:"#f4f4f4",
                    backgroundColor:"#e42f47"
                }}
            />
            <CardContent>
                {this.renderField("Email", "email", "email")}
                {this.renderField("Token", "token", "text")}
                {this.renderField("New Password", "password", "password")}
                {this.renderField("Confirm New Password", "confirm_password", "password")}
            </CardContent>
            <CardActions>
                <Button onClick={this.setScreen("login")} size={"small"} variant={"outlined"}>back</Button>
                <Button onClick={this.setScreen("reset_password")} size={"small"} variant={"outlined"}>have token</Button>
                <Button onClick={this.resetPassword} size={"small"} color="primary" variant={"outlined"} style={{marginLeft:"auto"}}>submit</Button>
            </CardActions>
        </Card>
    }

    renderCard = () => {
        switch (this.state.current_screen) {
            case "login":
                return this.renderLogin()
            case "register":
                return this.renderRegister()
            case "request_reset":
                return this.renderRequestReset()
            case "reset_password":
                return this.renderResetPassword()
        }
    }

    renderProgress = () => {
        return <Slide direction={"down"} in={this.state.loading_state.open}>
            <div style={styles.loadingOverlay}>
                <div style={{textAlign:"center", color:"white"}}>
                {
                    this.state.loading_state.type === "Progress" ?
                    <CircularProgress variant={"indeterminate"} style={{color:"#00616F"}}/>:
                    this.state.loading_state.type === "Success"?
                    <Check style={{color:"lightgreen"}} fontSize={"large"}/>:
                    this.state.loading_state.type === "Error"?
                    <Error style={{color:"#FE2E64"}} fontSize={"large"}/>:""

                }
                <div>
                    {
                        this.state.loading_state.text
                    }
                </div>
                </div>
            </div>
        </Slide>
    }

    render() {
        return <div style={{
            width:"100vw",
            height:"100vh",
            display:"flex",
            justifyContent: "center",
            flexDirection: "column",
        }}>

            <div style={styles.root}>
                {this.renderCard()}
                <div style={{pointerEvents: "none", position:"absolute", top:0, left:0, overflow:"hidden", width:"100%", height:"100%"}}>
                    {this.renderProgress()}
                </div>
            </div>

            <Dialog open={this.state.disclaimer_open} scroll={"body"} TransitionComponent={Slide}>
                <DialogTitle>
                    CRCWSC Beta Product disclaimer
                </DialogTitle>
                <DialogContent dividers>
                    Disclaimer: You are entering into a beta version of an integrated modelling tool created by the CRC for Water Sensitive Cities. The modelled water sensitive city outcomes are NOT to be used for target setting, but should instead be used for rapid testing of different development or policy scenario. The Geoscape data provided is for research only. For commercial use of this data, please refer to Geoscape data use documentation.
                </DialogContent>
                <DialogActions>
                    <Button onClick={_=>this.setState({disclaimer_open: false})} size={"small"} color={"secondary"} variant={"outlined"}>Decline</Button>
                    <Button style={{marginLeft:"auto"}} size={"small"} color={"primary"} variant={"outlined"}>Agree</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={this.state.nda_open} scroll={"body"} TransitionComponent={Slide}>
                <DialogTitle>
                    CRCWSC Beta Product Non-disclosure agreement
                </DialogTitle>
                <DialogContent dividers>
                    Terms and Conditions - Summary<br/>
                    This is a beta (draft) version and use of it is for internal research, testing, evaluation and feedback purposes only.<br/>
                    <ul>
                        <li>You cannot publish any results using this beta version of the Tool.</li>
                        <li>You cannot share or distribute these files to anyone without permission from the CRCWSC.</li>
                        <li>You will have access to the Tool for a Access Period (term): Up to 30st of December, 2020</li>
                    </ul>
                    Terms and Conditions - Detailed<br/>
                    Please <a href={"https://watersensitivecities.org.au/terms-and-conditions-details/"} target="_blank">click here</a> to read all terms and conditions

                </DialogContent>
                <DialogActions>
                    <Button onClick={_=>this.setState({nda_open: false})} size={"small"} color={"secondary"} variant={"outlined"}>Decline</Button>
                    <FormControlLabel style={{marginLeft:"auto"}} control={<Checkbox onChange={e=>this.setState({nda_signed: !this.state.nda_signed})} value={this.state.nda_signed} color={"primary"} />} label={"I agree"}/>
                    <Button  size={"small"} color={"primary"} variant={"outlined"} disabled={this.state.nda_signed === false} onClick={this.handleSubmitNda}>submit</Button>
                </DialogActions>
            </Dialog>
        </div>;
    }
}


LoginWrapper.propTypes = {
    loginCallback: PropTypes.func.isRequired,
}

export default LoginWrapper;