/**
 * Created by User on 18/06/2020
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ListItem from "@material-ui/core/ListItem";
import FilterIcon from "@material-ui/icons/Filter";
import ListItemText from "@material-ui/core/ListItemText";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Divider from "@material-ui/core/Divider";
import Collapse from "@material-ui/core/Collapse";
import List from "@material-ui/core/List";
import PanelMapFilterListItem from "./PanelMapFilterListItem";

const styles = {
    drawer : {
        backgroundColor: "#D9E2EC",
        color:"#243B53",
        height: "100vh",
        position: "fixed",
        top: "0px",
        left: "0px",
        overflowY: "auto",
        zIndex: 300,
    },
    drawer_heading : {
        backgroundColor: "#044E54",
        color:"#FFFFFF",
        padding: "10px",
        paddingLeft: "17px"
    },
    drawerList : {
        backgroundColor: "#D9E2EC",
        color:"#243B53",
        fontSize: "14px",
        fontVariant: "small-caps",
        fontWeight: "bold"

    },

    drawer_subList: {
        backgroundColor: "#FFFFFF",
        color: "#243B53",
        padding: "3px",
        paddingLeft: "45px",
        borderWidth: "3px",
        borderColor: "#243B53",
        borderStyle: "none none solid none",
    },

    drawPolygonCardHeader:{
        backgroundColor:"#D9E2EC",
        color:"#243B53",
        fontWeight:"bold",
        fontVariant:"small-caps",
    }

};
class CollapsableMenu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open:this.props.initialOpen,
        };
    }

    componentDidMount() {
    };

    componentWillUnmount() {
    };

    handleToggle = () => {
        this.setState({
            open: !this.state.open,
        })

    }

    render() {

        const open_state = this.props.forceClose === true ? false : this.state.open;

        const IconComponent = this.props.icon;
        return <div>
            <ListItem dense button onClick={this.handleToggle} style={styles.drawerList}>

                <IconComponent style={{paddingRight: "5px"}}/>
                {!this.props.minimized &&
                <ListItemText primary={this.props.title} disableTypography={true}/>
                }
                {this.props.minimized ? <div></div> : open_state ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Divider/>
            {!this.props.minimized &&
            <Collapse in={open_state} timeout="auto" >
                {this.props.children}
            </Collapse>
            }
        </div>
    }
}


CollapsableMenu.propTypes = {
    forceClose: PropTypes.bool,
    title: PropTypes.string,
    minimized: PropTypes.bool,
    icon: PropTypes.object,
}


export default CollapsableMenu;
