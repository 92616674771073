import React, {} from 'react'
import PropTypes from 'prop-types'
import mapboxgl from "mapbox-gl";
import MapboxDraw from "@mapbox/mapbox-gl-draw"

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;


const styles = {
    map: {
        flex:"1 0 100%",
        display:"flex",
    },
}

class DrawableMap extends React.Component {

    constructor(props) {
        super(props);
    }

        componentDidMount() {
            mapboxgl.accessToken = 'pk.eyJ1IjoiY2hyaXN0aWFudXJpY2giLCJhIjoiY2tpaWx2dnZpMDN5YTJ5cDBxajNibjBtNSJ9.sd3Q-4QrSLopA-WTs0Fc2g';
            const map = new mapboxgl.Map({
                container: 'map',
                //style: 'mapbox://sprites/mapbox/dark-v9',
                style: 'mapbox://styles/mapbox/satellite-v9',
                zoom: 12,
                center: [144.9231, -37.8136],
                pitch: 0, // pitch in degrees
                bearing: 0, // bearing in degrees
            });

            // const nav = new mapboxgl.NavigationControl();
            // map.addControl(nav, 'bottom-right');
            // map.addControl(new mapboxgl.FullscreenControl(), 'top-right');

            // let modes = MapboxDraw.modes;
            // for (let i in InfrastructureTypes) {
            //     modes[InfrastructureTypes[i].name] = InfrastructureTypes[i];
            // }

            // modes["RegionDrawMode"] = (RegionDrawMode);
            // modes["StaticMode"] = StaticMode;
            // const draw = new MapboxDraw({
            //     displayControlsDefault: false,
            //     modes: modes,
            //     userProperties: true,
            //
            // });

            let draw = new MapboxDraw();

            map.addControl(draw);
            map.on('load', () => {
                console.log(this.props);
                (this.props.registerMapCallback || (_=>{}))({
                    map, mapdraw:draw,
                });
            });

            this.setState({
                map: map,
                mapdraw: draw
            });
        }


    render() {
        return <div id="map" style={styles.map} />;
    }
}

DrawableMap.propTypes = {
    registerMapCallback: PropTypes.func,
};

export default DrawableMap;
