import React from 'react'
import PropTypes from "prop-types"
import mapboxgl from "mapbox-gl";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import {forInStatement} from "@babel/types";
import ContextMenu from "./ContextMenu";
import "mapbox-gl/dist/mapbox-gl.css";
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'


// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

function hexToRgbA(hex, alpha=1){
    var c;
    if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
        c= hex.substring(1).split('');
        if(c.length== 3){
            c= [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c= '0x'+c.join('');
        return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+',' + alpha + ')';
    }
    return null
    //throw new Error('Bad Hex');
}


export const constructGeoJSONSource = (geojsons, geojson_filter) => {
    let feat_coll = {
        type: "FeatureCollection",
        features: []
    }

    let maybe = (o, ...k) => {
        let acc = o;
        for(let i = 0; i < k.length-1; i++){
            acc = acc[k[i]] || {}
        }
        return hexToRgbA(acc[k[k.length-1]],0.4) || null;
    }

    for(let i = 0; i < geojsons.length; i++){
        if(!geojsons[i].active){
            continue;
        }
        let geojson = geojsons[i].geojson;

        if(geojson_filter && !geojson_filter.includes(geojsons[i].id) ){
            continue;
        }
        let result = {}
        if(geojson.type === "FeatureCollection"){
            result = {
                type: "GeometryCollection",
                geometries: []
            }

            for(let j = 0; j < geojson.features.length; j++){
                let feature = geojson.features[j]
                result.geometries.push(feature.geometry);
            }
        }
        feat_coll.features.push({
            type:"Feature",
            properties:{
                highlight:geojsons[i].highlight,
                color: maybe(geojsons[i],"geojson", "properties", "color"),

            },
            geometry: result,
        })
    }
    return {
        'type': 'geojson',
        'data': feat_coll,
    }
}

const styles = {
    map: {
        userSelect:"none",
        top:0,
        bottom:0,
        left:0,
        right:0,
        position:"absolute"
    },
}

class Map extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            map: null,
            mapDraw: null,
            loaded: false,
            menu_location: null,
            menu_data:[],
        }
    }


    handleResize = () => {
        this.state.map.resize()
    }

    componentDidMount() {
        mapboxgl.accessToken = 'pk.eyJ1IjoiY2hyaXN0aWFudXJpY2giLCJhIjoiY2tpaWx2dnZpMDN5YTJ5cDBxajNibjBtNSJ9.sd3Q-4QrSLopA-WTs0Fc2g';
        const map = new mapboxgl.Map({
            container: 'mapContainer',
            //style: 'mapbox://sprites/mapbox/dark-v9',
            style: 'mapbox://styles/mapbox/satellite-v9',
            zoom: 12,
            center: [144.9231, -37.8136],
            pitch: 0, // pitch in degrees
            bearing: 0, // bearing in degrees
            // backgroundOpacity: 0.5,
            logoPosition: "bottom-right"
        });

        // map.getStyle().layers.map((layer) => {
        //     if (layer.type === 'symbol')  {
        //         map.setPaintProperty(layer.id, `icon-opacity`, 0.5);
        //         map.setPaintProperty(layer.id, `text-opacity`, 0.5);
        //     } else {
        //         map.setPaintProperty(layer.id, `${layer.type}-opacity`, 0.5);
        //     }
        // })
        //

        window.addEventListener("resize", this.handleResize);
        let draw = new MapboxDraw();

        const nav = new mapboxgl.NavigationControl();
        map.addControl(nav, 'top-right');
        map.addControl(new mapboxgl.FullscreenControl(), 'top-right');

        map.on('load', () => {
            (this.props.registerMapCallback || (_=>{}))({
                map, mapdraw:draw,
            });

            this.setState({
                loaded: true,
            })

            map.addSource('mapbox-dem', {
                'type': 'raster-dem',
                'url': 'mapbox://mapbox.mapbox-terrain-dem-v1',
                'tileSize': 512,
                'maxzoom': 14
            });
            // add the DEM source as a terrain layer with exaggerated height
            map.setTerrain({ 'source': 'mapbox-dem', 'exaggeration': 1.5 });

            // add a sky layer that will show when the map is highly pitched
            map.addLayer({
                'id': 'sky',
                'type': 'sky',
                'paint': {
                    'sky-type': 'atmosphere',
                    'sky-atmosphere-sun': [0.0, 0.0],
                    'sky-atmosphere-sun-intensity': 15
                }
            });
        });

        map.on("contextmenu", event => {
            let data = this.state.map.queryRenderedFeatures(
                event.point
            )
            this.setState({
                menu_location: event.point,
                menu_data: data
            })
        })





        this.setState({
            map: map,
            mapdraw: draw
        });
    }

    componentWillUnmount(){
        if(this.state.map) {
            this.state.map.remove()

            window.removeEventListener("resize", this.handleResize);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let comp_bbox = JSON.stringify(this.props.bbox) !== JSON.stringify(prevProps.bbox);

        if (this.state.map)
            this.state.map.resize();
        if(this.state.loaded && (comp_bbox || (this.props.bbox && this.state.loaded && !prevState.loaded))){
            let boundingBox = this.props.bbox;
            this.state.map.fitBounds([[boundingBox[0], boundingBox[1]], [boundingBox[2], boundingBox[3]]], {padding:100});

        }
    }

    render() {
        return <div id="mapContainer" style={styles.map}>
            {
                React.Children.map(this.props.children, child => {
                    return React.cloneElement(child, {
                        map: this.state.map,
                        mapLoaded: this.state.loaded
                    })
                })


            }
            <ContextMenu
                data={this.state.menu_data}
                map={this.state.map}
                position={this.state.menu_location}/>
        </div>;
    }
}


Map.propTypes = {
    bbox: PropTypes.array,
    layer_ids: PropTypes.array,
    registerMapCallback: PropTypes.func,

}
export default Map;
