import React from 'react'
import PropTypes from "prop-types"
import {connect} from "react-redux";
import ListItemText from "@material-ui/core/ListItemText/index";
import ListItem from "@material-ui/core/ListItem/index";
import {highlightGeojson} from "../../actions/geojson_actions";


class GeoJSONListItem extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            hovered: false,
        }
    }

    highlight = () => {
        this.props.highlightBoundary(this.props.geojson.id, true)
        this.setState({
            hovered: true,
        })
    }

    lowlight = () => {
        this.props.highlightBoundary(this.props.geojson.id, false)
        this.setState({
            hovered:false,
        })
    }

    render() {
        return <ListItem dense
                         style={{
                             backgroundColor: this.state.hovered ? "#f5f5f5" : "white",
                             cursor:"pointer",
                         }}
                         button={this.props.button}
                         onClick={this.props.onClick}
                         onMouseEnter={this.highlight}
                         onMouseLeave={this.lowlight}
        >
            <ListItemText primary={this.props.geojson.name}/>
        </ListItem>;
    }
}

GeoJSONListItem.propTypes = {
    onClick: PropTypes.func,
    button: PropTypes.bool,
    geojson: PropTypes.object,
}

const mapStateToProps = (state, ownProps) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {

    return {
        highlightBoundary: (id, state) => {
            dispatch(highlightGeojson(id, state))
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(GeoJSONListItem);
