/**
 * Created by User on 9/08/2020
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {dance4water_host} from "../config";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import {Collapse, Select} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DanceAPI from "../actions/DanceAPI";

class AdminManagementScreen extends Component {

    constructor(props) {
        super(props);
        this.state = {
            authorized: false,
            data: [],
            expanded: null,
            nDays: 3,
            loading: false,
        };
    }

    componentDidMount() {
        let access_level = parseInt(localStorage.getItem("access_level"));
        if(access_level < 5){
            this.setState({
                authorized: true
            })

        }
        fetch(dance4water_host + "/admin/scenarios/dead?days=3", {
            method: "GET",
            mode:"cors",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("access_token")
            }
        }).then(f => {
            return f.json();
        }).then(data => {
            this.setState({
                data: data,
            })
        })
    };

    componentWillUnmount() {
    };

    renderScenarioContent = (scenario) => {
        return <div>
            <ListItem dense button onClick={_=>this.setState({
                expanded: this.state.expanded === scenario.id ? null: scenario.id
            })}>
                <ListItemText>
                    {scenario.name}
                </ListItemText>
            </ListItem>
            <Collapse style={{ marginLeft:"10px"}} in={scenario.id === this.state.expanded}>
                <div style={{display:"flex"}}>
                <div>
                        data
                {Object.keys(scenario).map((f,i) => {
                    return <div style={{display:"flex",
                        fontSize:"0.7em",
                            backgroundColor:i%2===1?"rgb(255,255,255)":"rgb(240, 240, 240)",
                            flexDirection:"row"}}>
                        <div style={{fontWeight:"bold", flex:0, minWidth:"200px"}}>{f}:</div>
                        <div>{scenario[f]?.toString()}</div>
                    </div>
                })}
                </div>
                <div style={{flex:1, marginLeft:"10px"}}>
                    actions
                    <div>
                        <Button onClick={_=>this.executeScenario(scenario)} color={"primary"} variant={"contained"}>
                            re-execute
                        </Button>
                    </div>
                </div>
                </div>
            </Collapse>
        </div>
    }

    executeScenario = (scenario) => {

        DanceAPI.scenarioExecute.post(scenario.id).then(f=>{
            console.log(f)
        })
    }

    handleChangeFilter = (e) => {
        this.setState({
            nDays: e.target.value,
            loading: true,
        })

        fetch(dance4water_host + "/admin/scenarios/dead?days="+e.target.value, {
            method: "GET",
            mode:"cors",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("access_token")
            }
        }).then(f => {
            return f.json();
        }).then(data => {
            this.setState({
                data: data,
                loading: false,
            })
        })
    }

    render() {
        if(this.state.authorized){
            return "not available"
        }
        return <div style={{width:"100%", height:"100%", boxSizing:"border-box", padding:"20px"}}>
            <Dialog open={this.state.loading}>
                <DialogContent>
                    loading...
                </DialogContent>
            </Dialog>
            <Paper elevation={10} style={{ height:"100%", boxSizing:"border-box", padding:"10px"}}>
                <div>

                    <Typography style={{display:"inline-block"}} variant={"h6"}>
                        Dead Scenarios
                    </Typography>
                    <div style={{display:"inline-block", marginLeft:"20px"}}>
                        number of days ago:
                        <Select
                            onChange={this.handleChangeFilter}
                            value={this.state.nDays}>
                            {
                                [...new Array(100)].map((v,i) => {
                                    return <MenuItem value={i}>
                                        {i}
                                    </MenuItem>
                                })
                            }
                        </Select>
                    </div>
                </div>
            {
                this.state.data.map(this.renderScenarioContent)
            }
        </Paper>
        </div>;
    }
}


AdminManagementScreen.propTypes = {}


export default AdminManagementScreen;
