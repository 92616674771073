import React from 'react'
import PropTypes from "prop-types"
import {connect} from "react-redux";
import {getScenarioComparisonTemplates} from "../../actions/scenario_comparison_template_actions";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import {DialogContent} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import DynamicList from "./DynamicList";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {createScenarioComparison} from "../../actions/scenario_comparison_actions";


class ScenarioComparisonTemplateDialog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selected_scenarios: [],
            loading_sct: true,
            selected_sct: null,
        }
    }

    componentDidMount() {
        this.props.getScenarioComparisonTemplates().then(f => {
            this.setState({
                loading_sct: false
            })
        })
    }

    renderScenarioSelect = (selected, index) => {
        return <div>
            <Typography variant={"subtitle1"}>Scenario input {index+1}</Typography>
            <Select style={{width:"100%"}} value={selected !== undefined ? selected  : "-1"} onChange={e=>this.updateSelectedScenario(index, e.target.value)}>
            <MenuItem disabled value="-1">
                <em>select scenario</em>
            </MenuItem>
            {
                this.props.scenarios.map(v => {
                    return <MenuItem value={v.id}>{v.name}</MenuItem>
                })
            }
        </Select>
        </div>
    }

    updateSelectedScenario = (index, id) => {
        let new_list = [...this.state.selected_scenarios];
        new_list[index] = id;
        this.setState({
            selected_scenarios: new_list
        })
    }

    onTypeChange = (type) => {
        this.setState({
            selected_sct: type.id,
            selected_scenarios: [...new Array(type.data.number_of_inputs)].map(v=>undefined)
        })
    }

    render() {
        let sct = this.props.scenario_comparison_templates;
        let selected = sct.find(f => f.id === this.state.selected_sct);
        return <Dialog
            onBackdropClick={this.props.onClose}
            onEscapeKeyDown={this.props.onClose}
            maxWidth={"lg"}
            fullWidth
            open={this.props.open}>
            <DialogTitle>Add Scenario Comparison</DialogTitle>
            <DialogContent dividers style={{
                height:"80vh",
                display:"flex",
                flexDirection:"column",
                padding:0,
            }}>
                {
                    this.state.loading_sct ? <div style={{textAlign:"center"}}>
                            <CircularProgress size={20}/>
                        </div> :
                        <div style={{flex:"1", display:"flex"}}>
                            <div style={{height:"100%", flex:"1", maxWidth:"200px", borderRight:"solid 1px lightgrey"}}>
                                <List >
                                    <Typography style={{paddingLeft:"6px"}} variant={"subtitle1"}>Comparison Types</Typography>
                                    <Divider/>
                                    {
                                        sct.map(s => {
                                            return <ListItem
                                                key={s.id}
                                                selected={s.id === this.state.selected_sct}
                                                onClick={_=>this.onTypeChange( s)}
                                                dense
                                                button>
                                                <ListItemText>{s.data.name}</ListItemText>
                                            </ListItem>
                                        })
                                    }
                                </List>
                            </div>

                            <div style={{height:"100%", flex:"3"}}>
                                {
                                    !selected ?
                                        <Typography style={{textAlign:"center"}} variant={"subtitle1"}>Select comparison Type</Typography>
                                        :
                                        <div style={{padding:"10px"}}>
                                            <Typography variant={"h6"}>{selected.data.name}</Typography>
                                            <div>
                                                {
                                                    [...new Array(selected.data.number_of_inputs)].map((v,i) => {
                                                        return <div>
                                                            {this.renderScenarioSelect(this.state.selected_scenarios[i], i)}
                                                            <br/><br/>
                                                        </div>
                                                    })
                                                }
                                            </div>
                                        </div>
                                }
                            </div>
                        </div>
                }
            </DialogContent>
            <DialogActions>
                <Button color={"secondary"} onClick={this.props.onClose}>cancel</Button>
                <Button color={"primary"} onClick={_=>this.props.onConfirm(this.state.selected_sct, this.state.selected_scenarios,
                        res => {
                            this.props.onClose()
                        }
                    )}>confirm</Button>
            </DialogActions>
        </Dialog>
    }
}


ScenarioComparisonTemplateDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
}

const mapStateToProps = (state, ownProps) => {
    return {
        scenario_comparison_templates: state.scenario_comparison_templates,
        scenarios: state.scenarios,
    }
}

const mapDispatchToProps = (dispatch) => {

    return {
        onConfirm: (template_id, scenario_ids, callback) => {
            console.log(template_id, scenario_ids)
            return dispatch(createScenarioComparison(template_id, scenario_ids)).then(callback)
        },
        getScenarioComparisonTemplates: () => {
            return dispatch(getScenarioComparisonTemplates())
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ScenarioComparisonTemplateDialog);
