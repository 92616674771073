import React from 'react'
import PropTypes from "prop-types"
import BarChart from "./BarChart";
import Table from "@material-ui/core/Table";
import {TableHead} from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Card from "@material-ui/core/Card";
import MaterialTable from "material-table";


class ResultTable extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            orderBy : 0,
            order: "asc",
        }
    }



     desc = (a, b, orderBy) => {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

     stableSort = (array, cmp) => {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = cmp(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map(el => el[0]);
    }

    getSorting = (order, orderBy) => {
        return order === 'desc' ? (a, b) => this.desc(a, b, orderBy) : (a, b) => -this.desc(a, b, orderBy);
    }

    formatEntry = (c) => {
        return typeof c === 'number' ? c.toFixed(2) : c;
    }

    create_rows_dict = (q, reference, idx) => {
        let row = {};



        // row.push(q.name);
        //
        row["Scenarios"] = q.name
        q.queries.map((c, index) => {

            let val = c.data[idx][c.query_prototype.query_data.main_data_key];
            if (reference) {
                val =  -reference.queries[index].data[idx][c.query_prototype.query_data.main_data_key] + val;
            }
            row[c.query_prototype.query_data.name] = this.formatEntry(val)
        })

        return row;
    }

    createOrder = (index) => {

        if (this.state.orderBy === index) {
            let order =  (this.state.order === "desc")  ? "asc" : "desc"
            {
                this.setState(
                    {order: order}
                )
            }
            return
        }

        this.setState({
            orderBy: index,
        })
    }

    render_table = () => {

        if (!this.props.all_loaded) {
            let successful = this.props.query_data.flatMap(
                qd => qd.queries.map(q => q.successful)
            );
            if(successful.some(f => !f)){
                return <div>One or more queries encountered an error</div>
            }
            return (<div> loading... </div>)
        }

        let reference = null;
        let ref_name = null;
        if (this.props.reference_scenario)
            reference = this.props.query_data.filter((q) => q.name === this.props.reference_scenario.name)[0]
        if (reference)
            ref_name = reference.name;
        let data_queries = this.props.query_data.filter( (q) => q.name !== ref_name );

        // Get Query Length
        let number_of_rows = data_queries.map((q) => {
            return q.queries[0].data.length
        })[0];

        let table = [];
        data_queries.map((q,index) => {

            for (let i = 0 ; i < number_of_rows; i++) {
                // table.push(this.create_rows(q, reference, i))
            }
        }
        )

        let cols = []
        cols.push({"title": "Scenarios", field: "Scenarios"})
        this.props.query_prototypes.map((q, index) => {
            cols.push({"title": q.query_data.name, field: q.query_data.name})
        })

        let data = [];
        data_queries.map((q,index) => {

            for (let i = 0 ; i < number_of_rows; i++) {
                table.push(this.create_rows_dict(q, reference, i))
            }
        })
        let sorted = this.stableSort(table,  this.getSorting(this.state.order, this.state.orderBy) )

            return (
                <div style={{ maxWidth: "100%" }}>
                    <MaterialTable
                        options={{
                            search: false,
                            exportButton: false,
                            showTitle: false,
                            grouping: true,
                            padding: 'dense',
                            paging: false,
                            filtering: false,
                            toolbar: false
                            // headerStyle: { padding: '0.3em'},

                        }}
                        columns={cols}
                        data={table}

                    />
                </div>
            );
        return (
            <div>
                <Table size={"small"} stickyHeader={true}>
                    <TableHead style={{backgroundColor: "#F0F4F8"}}>
                        <TableCell size={"small"} style={{color:"#243B53", fontWeight: "bold", maxWidth: "200px"}}>
                            Scenarios
                        </TableCell>
                        {this.props.query_prototypes.map((q, index) => {
                            return <TableCell key={index+1}
                                              style={{color:"#243B53", fontWeight: "bold"}}
                                              size={"tiny"}
                                              align={"center"}
                                              sortDirection={this.state.orderBy === index+1 ? this.state.order : false}>
                                <TableSortLabel
                                    active={this.state.orderBy === index+1}
                                    direction={this.state.order}
                                    onClick={ ()=>{
                                        this.createOrder(index+1)
                                    }}
                                >
                                {q.query_data.name} ({q.query_data.field_information.val.units})
                                </TableSortLabel>
                            </TableCell>
                        })}
                    </TableHead>
                    <TableBody>
                        {
                                sorted.map((q, index) => {
                                    return <TableRow style={{backgroundColor: index % 2 === 0 ? "auto" : "#F0F4F8"}}>
                                        {
                                            q.map((c, r) => {
                                                let align = "right";
                                                if (r === 0)
                                                    align = "left"
                                                return <TableCell align={align} size={"small"} style={{
                                                    color: "#243B53", textOverflow: "ellipsis", overflow: "hidden",
                                                    whiteSpace: "nowrap", maxWidth: "200px"
                                                }}>
                                                    {this.formatEntry(c)}
                                                </TableCell>
                                            })
                                        }
                                    </TableRow>
                                })
                        }
                    </TableBody>
                </Table>
            </div>
        )
    };


    render() {
        return (
            <div style={{marginLeft: "5px", width:"100%", overflowX:"auto", overflowY:"auto", maxHeight:"330px"}}>

                {this.render_table()}
            </div>
        )
    }
}


ResultTable.propTypes = {
    query_data: PropTypes.any,
    reference_scenario: PropTypes.any
}

export default ResultTable;
