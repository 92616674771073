import React from 'react'
import PropTypes from "prop-types"
import {connect} from "react-redux";
import CardHeader from "@material-ui/core/CardHeader/index";
import CardContent from "@material-ui/core/CardContent/index";
import Collapse from "@material-ui/core/Collapse/index";
import Card from "@material-ui/core/Card/index";
import Typography from "@material-ui/core/Typography/index";
import {CardActions} from "@material-ui/core/index";
import Button from "@material-ui/core/Button/index";
import Divider from "@material-ui/core/Divider/index";
import {withRouter} from "react-router";
import IconButton from "@material-ui/core/IconButton/index";
import DenseCard from "./Views/DenseCard";


class ScenarioCard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selected: false,
        }
    }


    renderScenarioInfoField = (label, value) => {
        return <div>
            <Typography style={{width:"30%", display:"inline-block"}} variant={"body1"}>
                {label}:
            </Typography>
            <Typography style={{width:"70%", textAlign:"right", display:"inline-block"}} variant={"body2"}>
                {value}
            </Typography>
        </div>
    }


    handleEdit = () => {
        let scenario = this.props.scenario;
        let project_id = this.props.match.params.projectID;
        this.props.history.push("/project/"+project_id+"/scenario/"+scenario.id)
    }

    render() {
        let scenario = this.props.scenario;
        return <DenseCard
            title={scenario.name}
            expandable
            content={this.renderScenarioInfoField("Status", scenario.status_text)}
            actions={[
                <Button size={"small"} variant={"outlined"} color={"secondary"} onClick={this.handleEdit}>edit</Button>,
                <Button style={{marginLeft:"auto"}} size={"small"} variant={"outlined"} color={"primary"}>view info</Button>
            ]}
        />
    }
}


ScenarioCard.propTypes = {
    scenario: PropTypes.object,
}

const mapStateToProps = (state, ownProps) => {
    return {}

}

const mapDispatchToProps = (dispatch) => {

    return {}
}

ScenarioCard = withRouter(ScenarioCard)
export default connect(mapStateToProps, mapDispatchToProps)(ScenarioCard);
