import DanceAPI from "./DanceAPI";
import {initScenarios} from "./scenario_actions";
import {initGeoJSON} from "./geojson_actions";
import {getRangeData, initLayers} from "./layer_actions";
import {initDashTiles} from "./dash_tile_actions";
import {initQueries} from "./query_actions";
import {initQueryPrototypes} from "./query_prototype_actions";
import {initDashTileTemplates} from "./dash_tile_template_actions";
import QueryHandler, {Query, runImmediateQuery} from "../QueryHandler";
import {getComparisonRangeData, initScenarioComparisons} from "./scenario_comparison_actions";
import {initScenarioComparisonTemplates} from "./scenario_comparison_template_actions";
import {initDataSources} from "./data_source_actions";
import {initFilters} from "./filter_actions";
import {initGeoJSONFilterPresets} from "./geojson_filter_preset";




export const runQueriesWithAreas = (areas) => {
    return (dispatch, getState) => {
        let project = getState().project;
        let queries = getState().queries;
        let query_prototypes = getState().query_prototypes;
        let scenarios = getState().scenarios;
        let new_queries = [];

        (queries).map(query => {
            let prototype = query_prototypes.find(qp => qp.id === query.query_prototype_id);
            (scenarios).map(scenario => {
                if (scenario.status !== 7) {
                    return;
                }
                areas.map(area => {
                    let _query = new Query(project.id, query.id, scenario.performance_assessment_id, area, prototype);
                    new_queries.push(_query);
                })
            })
        });

        QueryHandler.addQueries(new_queries);
    }
}

export const runQueries = (project_id, query_prototypes, queries, scenarios) => {
    let new_queries = [];
    (queries).map(query => {
        let prototype = query_prototypes.find(qp => qp.id === query.query_prototype_id);
        (scenarios).map(scenario => {
            if(scenario.status !== 7){
                return;
            }
            let _query = new Query(project_id, query.id, scenario.performance_assessment_id, null, prototype);
            new_queries.push(_query);
        })
    });
    QueryHandler.addQueries(new_queries);
}

export const loadProject = (project_id) => {
    return (dispatch, getState) => {
        return DanceAPI.project.get(project_id).then(result => {

            dispatch(initProject(result.project_data))
            dispatch(initScenarios(result.scenarios || []))
            dispatch(initDashTiles(result.dash_tiles || []));
            dispatch(initDataSources(result.data_sources || []));
            dispatch(initDashTileTemplates(result.dash_tile_templates || []));
            dispatch(initQueryPrototypes(result.query_prototypes || []));
            dispatch(initQueries(result.queries || []));
            dispatch(initGeoJSON(result.geojson || []));
            dispatch(initFilters(result.filters || []));
            dispatch(initGeoJSONFilterPresets(result.geojson_filter_presets || []));
            dispatch(initLayers(result.layers || []));
            dispatch(initScenarioComparisons(result.scenario_comparisons || []));
            dispatch(initScenarioComparisonTemplates(result.scenario_comparison_templates || []));

            if(result.scenarios.length > 0 && result.scenarios.find(s => s.is_baseline === true).status === 7){
                QueryHandler.registerTypeListener("schema", d => {


                    let views = {};

                    d.data.map(d => {
                        if(!views.hasOwnProperty(d.view_name)){
                            views[d.view_name] = [];
                        }
                        views[d.view_name].push(d);
                    })


                    dispatch(initProjectDataStructure(views));
                })
                QueryHandler.submitNonstandardQuery(
                    "SELECT view_name, attribute_name, data_type from dynamind_table_definitions",
                    result.scenarios.find(s => s.is_baseline === true).baseline_id,
                    {
                        type:"schema",
                        project_id: project_id,
                    }
                )
                    .then(d => {
                        if(!d.successful){
                            return;
                        }
                        let views = {};

                        d.data.map(d => {
                            if(!views.hasOwnProperty(d.view_name)){
                                views[d.view_name] = [];
                            }
                            views[d.view_name].push(d);
                        })


                        dispatch(initProjectDataStructure(views));
                    })
            }


            let new_queries = [];
            (result.queries|| []).map(query => {
                let prototype = result.query_prototypes.find(qp => qp.id === query.query_prototype_id);
                (result.scenarios || []).map(scenario => {
                    if(scenario.status !== 7){
                        return;
                    }
                    let _query = new Query(project_id, query.id, scenario.performance_assessment_id, null, prototype);
                    new_queries.push(_query);
                })
            });
            QueryHandler.addQueries(new_queries);

            //do range queries for layers
            //need to put layer in object for compat reasons

            dispatch(getRangeData((getState().layers)))
            dispatch(getComparisonRangeData((getState().scenario_comparisons)
                .map(l=>({
                    layer:{
                        ...l,
                        scenarios: l.scenarios.map(i => getState().scenarios.find(s => s.id === i)),
                        template: getState().scenario_comparison_templates.find(c => c.id === l.template_id)
                    }
                }))))



            return result
        })

    }
}



const initProject = (project) => ({
    type: 'PROJECT_INIT',
    ...project
});

export const initProjectDataStructure = (data_structure) => ({
    type: 'PROJECT_INIT_DATA_STRUCTURE',
    data_structure
});
