import React from 'react'
import PropTypes from "prop-types"


const styles = {

}

class ComparisonLegendItem extends React.Component {

    constructor(props) {
        super(props);
    }

    renderLegend = () => {
        let {stops} = this.props.legend_data;

        let new_stops = [];
        if (stops.length > 8) {
            let step = (stops.length - 2) / 8;
            new_stops.push(stops[0])
            for (let i = 0; i < 6; i++) {
                new_stops.push(stops[Math.round(i * step) + 1]);
            }
            new_stops.push(stops[stops.length - 1]);
            stops = new_stops;
        }
        return (<div style={{fontSize: "13px", width: "100%"}}>
            {stops.map((stop, i) => {
                return <div key={i} style={{
                    display: "inline-block", height: "20px",
                    width: stops.length <= 8 ? 100 / stops.length + "%" : "12.5%"
                }}>
                    <div style={{backgroundColor: stop.color, height: "50%"}}/>
                    <div style={{textAlign: "center"}}>
                        {stop.name}
                    </div>

                </div>
            })}
        </div>);
    }

    render() {
        if(!this.props.legend_data){
            return <div></div>
        }
        return <div >
            {
                this.renderLegend()
            }
        </div>;
    }
}


ComparisonLegendItem.propTypes = {
    legend_data: PropTypes.object
}

export default ComparisonLegendItem;
