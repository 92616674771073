import React from 'react'
import PropTypes from "prop-types"
import Error from "@material-ui/icons/Error"
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import {DialogTitle} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import LinearProgress from "@material-ui/core/LinearProgress";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

class ErrorBoundary extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            has_error: false,
            error: null,
            error_info: null,
            dialog_open: false,
            logging_error: true,
            log_info: null,
        }
    }

    logError = () => {
        setTimeout(_=>{
            this.setState({
                log_info: "234",
                logging_error: false,
            })
        }, 5000)
    }

    componentDidCatch(error, errorInfo) {
        this.logError();
        this.setState({
            has_error: true,
            error: error,
            error_info: errorInfo,
        })
    }

    static getDerivedStateFromError(error){
        return {
            has_error: true,
        }
    }

    render() {

        if(this.state.has_error){
            return <div style={{textAlign:"center"}} >
                <IconButton color={"primary"} onClick={_=>this.setState({dialog_open: true})}>
                    <Error color={"error"} fontSize={"large"}/>
                </IconButton>
                <Dialog open={this.state.dialog_open}>
                    <DialogTitle>
                       Something went wrong!
                    </DialogTitle>
                    <DialogContent dividers>
                        <DialogContentText>
                            Something went wrong with this component :(
                            This issue is being logged with the developers.
                            <br/>
                            {this.state.logging_error ?"":"This error has been successfully logged, your issue reference is #" + this.state.log_info}
                        </DialogContentText>

                        {this.state.logging_error ?
                            <LinearProgress variant="query"/>:""}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={_=>this.setState({dialog_open: false})}>dismiss</Button>
                    </DialogActions>
                </Dialog>
            </div>
        }

        return this.props.children;
    }
}


ErrorBoundary.propTypes = {}

export default ErrorBoundary;