/**
 * Created by User on 6/07/2020
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import AddIcon from "@material-ui/icons/Add"
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Checkbox from "@material-ui/core/Checkbox";
import AddFilterDialog from "../Panel/AddFilterDialog";
import {createGeoJSON, highlightGeojson} from "../../actions/geojson_actions";
import {connect} from "react-redux";
import {createFilter} from "../../actions/filter_actions";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import {createGeoJSONFilterPreset} from "../../actions/geojson_filter_preset";
import CircularProgress from "@material-ui/core/CircularProgress";


const delayPromise = (promise, delay) => {
    let p = new Promise(resolve => {
        setTimeout(resolve, delay);
    });

    return Promise.all([promise, p]).then(res => res[0]);
}

class AddFilterPresetDialog extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedPreset: null,
            addPresetOpen: false,
            addingFilter: false,
            addFilterOpen: false,
            uploadingPreset: false,
            addPresetData: {
                presetName: "",
                selectedArea: null,
                selectedFilters: [],
            }
        };
    }

    componentDidMount() {
    };

    componentWillUnmount() {
    };

    handleSetPresetName = (text) => {
        this.setState({
            addPresetData: {
                ...this.state.addPresetData,
                presetName: text,
            }
        })
    }
    handleAddFilterClick = (filter_id) => {
        if(this.state.addPresetData.selectedFilters.includes(filter_id)){
            this.setState({
                addPresetData: {
                    ...this.state.addPresetData,
                    selectedFilters: this.state.addPresetData.selectedFilters.filter(v => v !== filter_id)
                }
            })
        }else{

            this.setState({
                addPresetData: {
                    ...this.state.addPresetData,
                    selectedFilters: [...this.state.addPresetData.selectedFilters, filter_id]
                }
            })
        }
    }
    renderAddPresetDialog = () => {
        return <Dialog open={this.state.addPresetOpen} maxWidth={"md"} fullWidth>
            <DialogTitle>
                New Preset
            </DialogTitle>
            <DialogContent dividers style={{padding:0}}>
                <div style={{minHeight:"50vh", display:"flex", flexDirection:"column", position:"relative"}}>
                    <div style={{padding:"10px"}}>
                        <div>
                            <Typography>
                                Preset name
                            </Typography>
                            <TextField fullWidth onChange={e => this.handleSetPresetName(e.target.value)} value={this.state.addPresetData.presetName}/>
                        </div>
                    </div>
                    <Divider/>
                    <div style={{minHeight:"50vh", display:"flex", flex:1, flexDirection:"row"}}>
                        <div style={{flex:1, borderRight:"solid 1px rgba(0, 0, 0, 0.12)"}}>
                            <Typography style={{padding:"2px"}}>
                                Select Area
                            </Typography>
                            <List dense>
                                {
                                    this.props.geojsons.map(geojson => {
                                        return <ListItem
                                            button
                                            dense
                                            onClick={_=>this.setState({addPresetData: {...this.state.addPresetData, selectedArea: geojson.id}})}
                                            selected={this.state.addPresetData.selectedArea === geojson.id}>
                                            <ListItemText>
                                                {geojson.name}
                                            </ListItemText>
                                        </ListItem>
                                    })
                                }
                            </List>
                        </div>
                        <div style={{flex:3}}>
                            <Typography style={{padding:"2px"}}>
                                Select Filters
                            </Typography>
                            <List dense>
                                {
                                    this.props.filters.map(f => {
                                        return <ListItem dense button onClick={_=>this.handleAddFilterClick(f.id)}>
                                            <ListItemIcon>
                                                <Checkbox
                                                    size={"small"}
                                                    edge="start"
                                                    checked={this.state.addPresetData.selectedFilters.includes(f.id)}
                                                    tabIndex={-1}
                                                    disableRipple
                                                />
                                            </ListItemIcon>
                                            <ListItemText>
                                                {f.name}
                                            </ListItemText>
                                        </ListItem>
                                    })
                                }
                                <ListItem button dense onClick={_=>this.setState({addFilterOpen: true})}>
                                    <ListItemIcon>
                                        <AddIcon/>
                                    </ListItemIcon>
                                </ListItem>
                            </List>
                        </div>
                    </div>
                </div>
                <div style={{display:this.state.uploadingPreset ? "flex" : "none", alignItems: "center", justifyContent:"center", position:"absolute", top:0, right:0, left:0, bottom:0}}>
                    <div style={{textAlign:"center"}}>
                        <div>uploading</div>
                        <CircularProgress size={20}/>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button color={"primary"} onClick={this.handleAddPreset}>
                    confirm
                </Button>

                <Button color={"secondary"} onClick={_=>this.setState({addPresetOpen: false})}>
                    cancel
                </Button>
            </DialogActions>
        </Dialog>
    }

    handleAddFilter = (name, data) => {

        this.setState({
            addingFilter: true,
        })
        delayPromise(this.props.addSpatialFilter(this.props.project.id, name, data), 1000).then(f => {
            this.setState({
                addFilterOpen: false,
                addingFilter: false,
            })
        })
    }

    handleAddPreset = () => {
        this.setState({
            uploadingPreset: true,
        })

        delayPromise(this.props.addPreset(
            this.props.project.id,
            this.state.addPresetData.presetName,
            this.state.addPresetData.selectedArea,
            this.state.addPresetData.selectedFilters,
        ), 1000).then(f => {
            this.setState({
                uploadingPreset: false,
                addPresetOpen: false,
            })
        })
    }

    renderFilter = (filter) => {
        if(filter.filter_data.filter_type === "properties"){
            return [<Divider/>,
                <ListItem dense style={{paddingLeft:"30px"}}>
                <ListItemText>
                    {filter.name}
                </ListItemText>
            </ListItem>,
            <Divider />,
            <List style={{paddingLeft:"40px", backgroundColor:"rgb(250,250,250)"}}>
                {
                    filter.filter_data.filter_elements.map(f => {
                        return <ListItem dense>
                            <ListItemText>
                                {f.layer}.{f.attribute} {f.comparison} {f.value}
                            </ListItemText>
                        </ListItem>
                    })
                }
            </List>
            ]
        }else{
            return <ListItem dense>
                <ListItemText>{filter.name}</ListItemText>
            </ListItem>
        }
        return "";
    }
    renderPresetPreview = () => {
        if(this.state.selectedPreset === null){
            return <div style={{display:"flex", width:"100%", height:"100%", justifyContent: "center", alignItems:"center"}}>
                <div>
                    select preset from left
                </div>
            </div>
        }

        let preset = this.props.geojson_filter_presets.find(f => f.id === this.state.selectedPreset);

        return <div style={{ padding:"10px", display:"flex", flexDirection:"column", boxSizing:"border-box", width:"100%", height:"100%"}}>
            <div style={{paddingBottom:"10px"}}>
                <span style={{fontWeight:"bold", paddingRight:"4px"}}>
                    Name:
                </span>
                {preset.name}
            </div>
            <div style={{position:"relative", flex:1}}>
                <div style={{overflowY:"auto", position:"absolute", top:0, right:0, left:0, bottom:0}}>
                    <div style={{backgroundColor:"white", zIndex:"3000", fontWeight:"bold", position:"sticky", top:0, paddingRight:"4px"}}>
                        Filters:
                    </div>

                    <List dense>
                        {
                            preset.filters.map(f => {
                                return this.renderFilter(f)
                            })
                        }
                        {
                            preset.filters.map(f => {
                                return this.renderFilter(f)
                            })
                        }
                        {
                            preset.filters.map(f => {
                                return this.renderFilter(f)
                            })
                        }
                    </List>
                </div>
            </div>
        </div>
    }

    handleClose = () => {
        this.setState({
            addFilterOpen: false
        })
    }

    render() {
        return <div>
            <AddFilterDialog
                onConfirm={this.handleAddFilter}
                onClose={this.handleClose}
                loading={this.state.addingFilter}
                geojsons={this.props.geojsons}
                data_structure={this.props.project.data_structure}
                filter_layer_filter={this.props.filter_layer_filter}
                open={this.state.addFilterOpen}/>
            {this.renderAddPresetDialog()}
            <Dialog
                maxWidth={"lg"}
                fullWidth
                open={this.props.open}
        >
            <DialogTitle>
                Spatial Filters
            </DialogTitle>
            <DialogContent dividers style={{padding:0}}>
                <div style={{display:"flex", flexDirection:"row", width:"100%", minHeight:"60vh"}}>
                    <div style={{flex:1, borderRight:"solid 1px rgba(0, 0, 0, 0.12)"}}>
                        {
                            this.props.geojson_filter_presets.length === 0 ?
                                <div>
                                    <div style={{color:"rgb(150,150,150)", textAlign:"center"}}>
                                        no presets yet
                                    </div>
                                    <div style={{display:"flex", flexDirection:"row", justifyContent: "center"}}>
                                        <IconButton size={"small"}
                                            onClick={_=>this.setState({addPresetOpen: true})}
                                        >
                                            <AddIcon/>
                                        </IconButton>
                                    </div>
                                </div>
                                :
                                <List>
                                    {this.props.geojson_filter_presets.map(gfp => {
                                        return <ListItem button selected={this.state.selectedPreset === gfp.id} onClick={_=>this.setState({selectedPreset: gfp.id})}>
                                            <ListItemText>
                                                {gfp.name}
                                            </ListItemText>
                                        </ListItem>
                                    })}

                                    <div style={{display:"flex", flexDirection:"row", justifyContent: "center"}}>
                                        <IconButton size={"small"}
                                                    onClick={_=>this.setState({addPresetOpen: true})}
                                        >
                                            <AddIcon/>
                                        </IconButton>
                                    </div>
                                </List>
                        }
                    </div>
                        <div style={{flex:4}}>
                            {
                                this.renderPresetPreview()
                            }
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button color={"primary"} onClick={this.props.onClose}>
                        close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>;
    }
}


AddFilterPresetDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    filters: PropTypes.array,
    geojsons: PropTypes.array,
    filter_layer_filter: PropTypes.array,
    geojson_filter_presets: PropTypes.array,
}


const mapStateToProps = (state, ownProps) => {
    return {
        project: state.project
    }
}

const mapDispatchToProps = (dispatch) => {

    return {

        addSpatialFilter: (project, name, data) => {
            return dispatch(createFilter(project, name, data));
        },

        addPreset: (project, name, area, filters) => {
            return dispatch(createGeoJSONFilterPreset(project, name, area, filters))
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(AddFilterPresetDialog);
