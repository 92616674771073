import React from 'react'
import PropTypes from "prop-types"
import Marquee from "react-marquee";


class CoolerMarquee extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            timer: null,
            on:false,
        }
    }

    componentDidMount() {
        this.setState({
            timer: setInterval(_=>this.setState({on: !this.state.on}), 1000)
        })
    }
    componentWillMount() {
        clearInterval(this.state.timer);
    }

    render() {
        let on = this.state.on;
        return <div style={{backgroundColor: on ? "yellow" : "red", color:on?"red":"yellow", fontFamily:"Comic Sans MS", fontSize:"2em"}}>
        <Marquee
            hoverToStop={true}
            loop
            text={this.props.text ? (this.props.text + " ").repeat(10) : "SUPER SECRET ADMIN SUPER SECRET ADMIN SUPER SECRET ADMIN SUPER SECRET ADMIN SUPER SECRET ADMIN SUPER SECRET ADMIN SUPER SECRET ADMIN SUPER SECRET ADMIN SUPER SECRET ADMIN"}/>
        </div>
    }
}


CoolerMarquee.propTypes = {
    text: PropTypes.string,
}

export default CoolerMarquee;