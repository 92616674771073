import React from 'react'
import PropTypes from "prop-types"


class Layer extends React.Component {

    constructor(props) {
        super(props);
    }



    render() {
        return "";
    }
}


Layer.propTypes = {}

export default Layer;