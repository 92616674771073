import DanceAPI from "./DanceAPI"
import {tile_server} from "../config";



export const initDataSources = (data_sources) => ({
    type: "DATA_SOURCE_INIT",
    data_sources,
})

export const addDataSource = (data) => ({
    type: "DATA_SOURCE_ADD",
    ...data,
})

export const createDataSource = (data) => {
    return (dispatch, getState) => {
        return DanceAPI.dataSources.post(data).then(r => {
            return dispatch(addDataSource(r));
        })
    }
}
