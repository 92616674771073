
const model_stage_text = (stage) => {
    switch (stage) {
        case 1: {
            return 'alpha'
        }
        case 2: {
            return 'beta'
        }
        case 3:{
            return 'under dev'
        }
    }
}


const assessment_model = (state, action) => {
    switch (action.type) {
        case 'ASS_MODS_SET':{
            return {
                'id': action.id,
                'created_at': action.created_at,
                'creator': action.creator,
                'name': action.name,
                'stage': action.stage,
                'stage_text': model_stage_text(action.stage),
                'author': action.author,
                'active_version_data': action.active_version_data,
                'description': action.description,
            }
        }
        default:
            return state;
    }
}

const assessment_models = (state=[], action) => {
    switch (action.type) {
        case 'ASS_MODS_SET':{
            return action.models.map((data) => assessment_model({}, {type: action.type, ...data}))
        }
        default:
            return state;
    }
}

export default assessment_models;