import React from 'react'
import PropTypes from "prop-types"
import {connect} from "react-redux";

import Divider from "@material-ui/core/Divider/index";

import {TextField, Typography} from "@material-ui/core/index";

import Paper from "@material-ui/core/Paper";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess"
import ExpandMore from "@material-ui/icons/ExpandMore"
import Home from "@material-ui/icons/Home"
import CropOriginalIcon from '@material-ui/icons/CropOriginal';
import Filter from "@material-ui/icons/Filter"
import InputLabel from "@material-ui/core/InputLabel";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import FilterIcon from "@material-ui/icons/Filter";
import FilterListIcon from '@material-ui/icons/FilterList';
import PanelScenarioListItem from "./PanelScenarioListItem";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import Menu from '@material-ui/icons/Menu';
import Grid from "@material-ui/core/Grid";
import MapIcon from '@material-ui/icons/Map';
import PanelMapFilterListItem from "./PanelMapFilterListItem";
import Slide from "@material-ui/core/Slide";
import Popover from "@material-ui/core/Popover";
import {SwatchesPicker} from "react-color";
import StopIcon from "@material-ui/icons/Stop";
import Button from "@material-ui/core/Button";
import Dropzone from "react-dropzone";
import BackIcon from "@material-ui/icons/NavigateBefore";
import {createGeoJSON, highlightGeojson, updateGeoJSON} from "../../actions/geojson_actions";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Checkbox from "@material-ui/core/Checkbox";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import CancelIcon from '@material-ui/icons/Cancel';
import PublishIcon from '@material-ui/icons/Publish';
import CreateIcon from '@material-ui/icons/Create';
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import InputAdornment from '@material-ui/core/InputAdornment';
import Grow from '@material-ui/core/Grow';
import Tooltip from '@material-ui/core/Tooltip';
import LayersIcon from '@material-ui/icons/Layers';
import DashboardControlPanel from "../DashboardControlPanel";
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import TimelineIcon from '@material-ui/icons/Timeline';
import * as jszip from "jszip";
import DataSourceDialog from "../DataSourceDialog";
import CircularProgress from "@material-ui/core/CircularProgress";
import DanceAPI from "../../actions/DanceAPI";
import CollapsableMenu from "./CollapsableMenu";
import AddFilterDialog from "./AddFilterDialog";
import {createFilter} from "../../actions/filter_actions";
import PanelFilterListItem from "./PanelFilterListItem";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";


const styles = {
    drawer: {
        backgroundColor: "#D9E2EC",
        color: "#243B53",
        height: "100%",
        flex: "1 0 0%",
        overflowX: "hidden",
        transition: "linear 0.3s maxWidth",
        overflowY: "auto",
        zIndex: 300,
    },
    drawer_heading: {
        backgroundColor: "#044E54",
        color: "#FFFFFF",
        height: "48px",
        padding: "10px",
        paddingLeft: "17px"
    },
    drawerList: {
        backgroundColor: "#D9E2EC",
        color: "#243B53",
        fontSize: "14px",
        fontVariant: "small-caps",
        fontWeight: "bold"

    },

    drawer_subList: {
        backgroundColor: "#FFFFFF",
        color: "#243B53",
        padding: "3px",
        paddingLeft: "45px",
        borderWidth: "3px",
        borderColor: "#243B53",
        borderStyle: "none none solid none",
    },

    drawPolygonCardHeader: {
        backgroundColor: "#D9E2EC",
        color: "#243B53",
        fontWeight: "bold",
        fontVariant: "small-caps",
    }

};

function getRandomColor() {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}

const delayPromise = (promise, delay) => {
    let p = new Promise(resolve => {
        setTimeout(resolve, delay);
    });

    return Promise.all([promise, p]).then(res => res[0]);
}


class SidePanel extends React.Component {

    constructor(props) {
        super(props);
        let boundaryId = this.props.geojsons.filter(f => f.active).map(a => a.id);
        this.state = {
            selectingArea: false,
            addingArea: false,
            areas_open: false,
            boundaryFile: null,
            area_color: "#7586fe",
            openDeleteAreaDialog: false,
            deleteAreaConfirmation: false,
            deletingGeojsonOpen: false,
            color_picker_open: false,
            color_picker_anchor: null,
            deleteAreasList: [],
            addingFilter: false,
            mapBoundaries: [...boundaryId],
            dataFile: [],
            upload_file: null,
            areasToDelete: [],
            addData: false,
            addType: null,
            drawerWidth: 270,
            minimized: false,
        }

        props.setGeojsonFilterCallback(boundaryId)
    }

    minimize = () => {
        if (this.state.minimized) {
            this.setState({drawerWidth: 270, minimized: false})
            this.props.minimiseCallBack(270)
            return;
        }

        this.setState({drawerWidth: 50, minimized: true})
        this.props.minimiseCallBack(50)
    };


    handleSetDraw = () => {
        this.setState({
            addType: "draw"
        });
        this.props.map.map.addControl(this.props.map.mapdraw,'top-right');
        this.props.map.mapdraw.changeMode("draw_polygon");
    };

    handleSubmitGeojson = () => {
        let project_id = this.props.project.id;
        let geojson = null;
        geojson = this.props.map.mapdraw.getAll();
        this.props.map.mapdraw.deleteAll();
        this.props.map.mapdraw.changeMode("simple_select");
        this.props.map.map.removeControl(this.props.map.mapdraw);
        geojson["properties"] = {
            color: this.state.area_color
        };

        let name = this.state.areaName;
        this.setState({
            uploadingArea: true,
            addingArea: false,
        })
        //minimum upload time set to 1500, so ui dialog doesnt disappear too quick
        let promise = new Promise(resolve => {
            setTimeout(resolve, 1500);
        })
        this.props.createGeoJSON(project_id, geojson, name, _ => {
            promise.then(_ =>
                this.setState({
                    uploadingArea: false,
                    boundaryFile: null,
                    areaName: null,


                }))

        });
    };
    handleSubmitGeojsonFromText = (text, name, color) => {

        let geojson = JSON.parse(text);
        if ("crs" in geojson) {
            delete geojson.crs;
        }
        // let new_polygons = [];
        // for(let i in gj.features){
        //     let current = gj.features[i];
        //
        //     if(current.geometry.type === "Polygon" && current.geometry.coordinates[0].length > 2){
        //         new_polygons.push(current.geometry);
        //     }else if(current.geometry.type === "MultiPolygon"){
        //         let newpolys = MultiPolygonToPolygons(current);
        //         new_polygons.push(...(newpolys.map(p => p.geometry)));
        //     }
        // }

        let project_id = this.props.project.id;

        geojson["properties"] = {
            color: color
        };

        this.setState({
            uploadingArea: true,
            addingArea: false,
        })
        //minimum upload time set to 1500, so ui dialog doesnt disappear too quick
        let promise = new Promise(resolve => {
            setTimeout(resolve, 1500);
        })
        this.props.createGeoJSON(project_id, geojson, name, _ => {
            promise.then(_ =>
                this.setState({
                    uploadingArea: false,
                }))

        });
    }
    handleDrop = (acceptedFiles) => {
        if (acceptedFiles.length !== 1) {
            this.setState({
                errorText: "You can only upload a single boundary",
                errorDialogOpen: true,
            })
        }
        let file = acceptedFiles[0];

        if (file.name.endsWith(".zip")) {
            let zip = new jszip();
            zip.loadAsync(file).then(zip => {
                zip.forEach((relative_path, zip_entry) => {
                    if (!zip_entry.name.startsWith("__MACOSX") && zip_entry.name.endsWith(".geojson")) {
                        zip_entry.async("text").then(text => {
                            this.handleSubmitGeojsonFromText(text, zip_entry.name.substr(0, zip_entry.name.length - 8), getRandomColor())
                        });
                    }
                })
            })
            return;
        }

        this.setState({
            boundaryFile: file.name,
        })

        let reader = new FileReader();
        reader.addEventListener("loadend", () => {
            const geojson = JSON.parse(reader.result);
            if ("crs" in geojson) {
                delete geojson.crs;
            }
            this.props.map.mapdraw.set(geojson);
        });
        reader.readAsText(file);
    };

    renderAddingBoundary = () => {
        if (this.state.addType === "draw") {
            return <div>
                <Card raised style={{margin: "5px", backgroundColor: "transparent"}}>
                    <CardHeader title={"Draw polygon"} style={styles.drawPolygonCardHeader} disableTypography/>
                    <Divider/>
                    <CardContent>
                        <TextField fullWidth
                                   label={"Area Name"}
                                   InputLabelProps={{
                                       shrink: true,
                                   }}
                                   placeholder={"eg. Low Density Residential"}
                                   variant={"outlined"}
                                   value={this.state.areaName}
                                   onChange={e => this.setState({
                                       areaName: e.target.value
                                   })}/>


                        <TextField fullWidth
                                   style={{margin: "15px 0px"}}
                                   label={"Area Color"}
                                   value={this.state.area_color}
                                   InputLabelProps={{
                                       shrink: true,
                                   }}
                                   InputProps={{
                                       startAdornment: (<InputAdornment position="start">
                                           <IconButton onClick={e => this.setState({
                                               color_picker_anchor: e.currentTarget,
                                               color_picker_open: !this.state.color_picker_open
                                           })} size={"small"}>
                                               <StopIcon style={{color: this.state.area_color}} fontSize={"large"}/>
                                           </IconButton>
                                       </InputAdornment>)
                                   }}
                                   variant={"outlined"}

                        />
                        <Popover anchorEl={this.state.color_picker_anchor}
                                 open={this.state.color_picker_open}
                                 anchorOrigin={{
                                     vertical: 'top',
                                     horizontal: 'left',
                                 }}
                                 transformOrigin={{
                                     vertical: 'top',
                                     horizontal: 'right',
                                 }}
                        >
                            <SwatchesPicker color={this.state.area_color} onChange={color => this.setState({
                                color_picker_open: false,
                                area_color: color.hex,
                            })}/>
                        </Popover>

                        <Button onClick={this.handleSubmitGeojson} variant={"outlined"} fullWidth color={"primary"}>
                            submit
                        </Button>
                    </CardContent>
                </Card>
            </div>
        } else if (this.state.addType === "upload") {
            return this.state.boundaryFile === null ?
                <Dropzone onDrop={this.handleDrop}>
                    {({getRootProps, getInputProps}) => (
                        <section style={{
                            borderRadius: "5px",
                            padding: "5px",
                            border: " dashed 2px grey"
                        }}>
                            <div {...getRootProps()}>
                                <input {...getInputProps()} />

                                <Typography variant={"body1"}>

                                    Click here, or drag and drop a file here, to upload your
                                    boundary as GeoJSON file or use a zip file to upload in build. Note the coordinate
                                    system is WSG:84 or EPSG:4326
                                </Typography>
                            </div>
                        </section>
                    )}
                </Dropzone> :
                <div>
                    <Card raised style={{margin: "5px", backgroundColor: "transparent"}}>
                        <CardHeader title={"Upload Areas"} style={styles.drawPolygonCardHeader} disableTypography/>
                        <Divider/>
                        <CardContent>
                            <ListItem dense disableGutters>
                                <ListItemText primary={"File name"} secondary={this.state.boundaryFile}/>
                            </ListItem>
                            <TextField fullWidth
                                       label={"Area Name"}
                                       InputLabelProps={{
                                           shrink: true,
                                       }}
                                       placeholder={"eg. Low Density Residential"}
                                       variant={"outlined"}
                                       value={this.state.areaName}
                                       onChange={e => this.setState({
                                           areaName: e.target.value
                                       })}/>


                            <TextField fullWidth
                                       style={{margin: "15px 0px"}}
                                       label={"Area Color"}
                                       value={this.state.area_color}
                                       InputLabelProps={{
                                           shrink: true,
                                       }}
                                       InputProps={{
                                           startAdornment: (<InputAdornment position="start">
                                               <IconButton onClick={e => this.setState({
                                                   color_picker_anchor: e.currentTarget,
                                                   color_picker_open: !this.state.color_picker_open
                                               })} size={"small"}>
                                                   <StopIcon style={{color: this.state.area_color}} fontSize={"large"}/>
                                               </IconButton>
                                           </InputAdornment>)
                                       }}
                                       variant={"outlined"}

                            />
                            <Popover anchorEl={this.state.color_picker_anchor}
                                     open={this.state.color_picker_open}
                                     anchorOrigin={{
                                         vertical: 'top',
                                         horizontal: 'left',
                                     }}
                                     transformOrigin={{
                                         vertical: 'top',
                                         horizontal: 'right',
                                     }}
                            >
                                <SwatchesPicker color={this.state.area_color} onChange={color => this.setState({
                                    color_picker_open: false,
                                    area_color: color.hex,
                                })}/>
                            </Popover>

                            <Button onClick={this.handleSubmitGeojson} variant={"outlined"} fullWidth color={"primary"}>
                                submit
                            </Button>
                        </CardContent>
                    </Card>
                </div>


        }
    };

    handleAddData = (acceptedFiles) => {

        if (acceptedFiles.length !== 1) {
            this.setState({
                errorText: "You can only upload a single boundary",
                errorDialogOpen: true,
            })
        }
        let file = acceptedFiles[0];
        this.setState({
            upload_file: file.name,
        })

        let reader = new FileReader();
        reader.addEventListener("loadend", () => {
            const geojson = JSON.parse(reader.result);
            if ("crs" in geojson) {
                delete geojson.crs;
            }
            this.props.map.mapdraw.set(geojson);
        });
        reader.readAsText(file);
    };

    handleSubmitData = () => {
        let data_files = [...this.state.dataFile];
        data_files.push(this.state.dataName);
        this.setState({
            upload_file: null,
            dataFile: data_files,
            dataName: null,
            addData: false,
            area_color: "#7586fe",
        })
    };

    renderAddData = () => {
        if (this.state.addData) {
            return this.state.upload_file === null ?
                <Dropzone onDrop={this.handleAddData}>
                    {({getRootProps, getInputProps}) => (
                        <section style={{
                            borderRadius: "5px",
                            padding: "5px",
                            border: " dashed 2px grey"
                        }}>
                            <div {...getRootProps()}>
                                <input {...getInputProps()} />

                                <Typography variant={"body1"}>

                                    Click here, or drag and drop a file here, to upload your
                                    data file
                                </Typography>
                            </div>
                        </section>
                    )}
                </Dropzone> :
                <div>
                    <Card raised style={{margin: "5px", backgroundColor: "transparent"}}>
                        <CardHeader title={"Upload Data"} style={styles.drawPolygonCardHeader} disableTypography/>
                        <Divider/>
                        <CardContent>
                            <ListItem dense disableGutters>
                                <ListItemText primary={"File name"} secondary={this.state.upload_file}/>
                            </ListItem>
                            <TextField fullWidth
                                       label={"Area Name"}
                                       InputLabelProps={{
                                           shrink: true,
                                       }}
                                       placeholder={"eg. Low Density Residential"}
                                       variant={"outlined"}
                                       value={this.state.dataName}
                                       onChange={e => this.setState({
                                           dataName: e.target.value
                                       })}/>


                            <TextField fullWidth
                                       style={{margin: "15px 0px"}}
                                       label={"Area Color"}
                                       value={this.state.area_color}
                                       InputLabelProps={{
                                           shrink: true,
                                       }}
                                       InputProps={{
                                           startAdornment: (<InputAdornment position="start">
                                               <IconButton onClick={e => this.setState({
                                                   color_picker_anchor: e.currentTarget,
                                                   color_picker_open: !this.state.color_picker_open
                                               })} size={"small"}>
                                                   <StopIcon style={{color: this.state.area_color}} fontSize={"large"}/>
                                               </IconButton>
                                           </InputAdornment>)
                                       }}
                                       variant={"outlined"}

                            />
                            <Popover anchorEl={this.state.color_picker_anchor}
                                     open={this.state.color_picker_open}
                                     anchorOrigin={{
                                         vertical: 'top',
                                         horizontal: 'left',
                                     }}
                                     transformOrigin={{
                                         vertical: 'top',
                                         horizontal: 'right',
                                     }}
                            >
                                <SwatchesPicker color={this.state.area_color} onChange={color => this.setState({
                                    color_picker_open: false,
                                    area_color: color.hex,
                                })}/>
                            </Popover>

                            <Button onClick={this.handleSubmitData} variant={"outlined"} fullWidth color={"primary"}>
                                submit
                            </Button>
                        </CardContent>
                    </Card>
                </div>
        }
    };

    handleDeleteAreas = () => {


        this.setState({
            deletingGeojsonOpen: true,
        });
        this.props.deleteAreas(this.state.areasToDelete, _ => {
            this.setState({
                openDeleteAreaDialog: false,
                deleteAreaConfirmation: false,
                deletingGeojsonOpen: false,
                areasToDelete: [],
            })
        });

    }

    onOffBoundary = (id) => {
        let temp = [...this.state.mapBoundaries];
        if (temp.indexOf(id) > -1) {
            temp = temp.filter(a => a !== id);
        } else {
            temp.push(id)
        }
        this.setState({mapBoundaries: temp})
        this.props.setGeojsonFilterCallback(temp)
    }

    handleAreasToDelete = (id) => {
        let areas = [...this.state.areasToDelete];
        if (areas.includes(id)) {
            areas = areas.filter(a => a !== id);
        } else {
            areas.push(id);
        }
        this.setState({areasToDelete: areas})
    }

    renderDeleteAreaDialog = () => {
        return <div>
            <Dialog open={this.state.openDeleteAreaDialog} fullWidth>
                <DialogTitle>Deleting Areas<IconButton size={'small'} style={{float: "right"}} onClick={() => {
                    this.setState({
                        openDeleteAreaDialog: false
                    })
                }}><CloseIcon/></IconButton></DialogTitle>
                <Divider/>
                {this.props.geojsons
                    .filter(f => f.active)
                    .map(area => (
                        <ListItem
                            key={area}
                            value={area}
                            dense
                            button
                            onClick={() => this.handleAreasToDelete(area.id)}
                        >
                            <Checkbox checked={this.state.areasToDelete.includes(area.id)}
                                      checkedIcon={<CancelIcon/>}></Checkbox>
                            {area.name}
                        </ListItem>

                    ))}
                <DialogActions><Button
                    onClick={_ => this.setState({deleteAreaConfirmation: true})}>Delete</Button></DialogActions>
            </Dialog>

            <Dialog open={this.state.deleteAreaConfirmation}>
                <DialogTitle>
                    Deletion Confirmation
                    <IconButton size={'small'}
                                style={{float: "right"}}
                                onClick={() => {
                                    this.setState({deleteAreaConfirmation: false})
                                }}>
                        <CloseIcon/></IconButton></DialogTitle>
                <DialogContent>Are you sure you want to delete selected areas?</DialogContent>
                <DialogActions>
                    <Button style={{float: 'left'}} onClick={this.handleDeleteAreas}>Yes</Button>
                    <Button onClick={_ => {
                        this.setState({deleteAreaConfirmation: false})
                    }}>No</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={this.state.deletingGeojsonOpen}>
                <DialogTitle>
                    Deleting
                </DialogTitle>
                <DialogContent>
                    <CircularProgress size={40}/>
                </DialogContent>
            </Dialog>
        </div>

    };

    renderLayers = () => {
        return <CollapsableMenu minimized={this.state.minimized} initialOpen={false} icon={LayersIcon} title={"Layers"}>
            <DashboardControlPanel setLayersCallback={this.setLayers} activeTab={this.props.activeTab}
                                   currentScenario={this.props.currentScenario}/>
        </CollapsableMenu>
    }

    renderDataUpload = () => {


        return <div>
            <div>
                <DataSourceDialog open={this.state.addData} dismissCallback={_ => this.setState({addData: false})}/>
            </div>
            <ListItem button onClick={this.showDataToggle} style={styles.drawerList}>
                <InsertDriveFileIcon style={{paddingRight: "5px"}}/>
                {!this.state.minimized &&
                <ListItemText primary="Data" disableTypography={true}/>}
                {this.state.minimized ? <div></div> : this.state.openData ? <ExpandLess/> : <ExpandMore/>}

            </ListItem>
            <Divider/>
            {!this.state.minimized &&
            <Collapse in={this.state.openData} timeout="auto" unmountOnExit>
                {this.props.data_sources.length < 1 ? <ListItem style={{height: "30px"}}></ListItem> :
                    this.props.data_sources.map(f => {
                        return <ListItem dense button style={{padding: "3px", paddingLeft: "45px",}}><ListItemText
                            primary={f.name} disableTypography/></ListItem>
                    })}
            </Collapse>
            }
            <Divider/>

            {!this.state.minimized && this.state.openData &&
            <div style={{backgroundColor: "#F0F4F8"}}>
                {this.state.addData ? <Tooltip title={"Back"}><Fab size={"small"} color={"primary"} aria-label="Add"
                                                                   style={{
                                                                       top: "-5px",
                                                                       left: "calc(100% - 70px)",
                                                                       backgroundColor: "#102A43"
                                                                   }} onClick={_ => this.setState({addData: false})}>
                    <BackIcon/>

                </Fab></Tooltip> : <Tooltip title={"Upload data"}><Fab size={"small"} color={"primary"} aria-label="Add"
                                                                       style={{
                                                                           top: "-5px",
                                                                           left: "calc(100% - 70px)",
                                                                           backgroundColor: "#102A43"
                                                                       }} onClick={_ => this.setState({addData: true})}>
                    <AddIcon/>

                </Fab></Tooltip>}

                {this.renderAddData()}
            </div>}
        </div>
    }

    renderContextList = () => {
        return <CollapsableMenu minimized={this.state.minimized} initialOpen={true} icon={CropOriginalIcon}
                                title={"Context"}>
            <div style={{background: "white", padding: "10px"}}>
                <FormControl style={{width: "100%", backgroundColor: "white", pointerEvents: "auto"}}>
                    <InputLabel>Base Scenario</InputLabel>
                    <Select
                        onChange={(e) => this.props.setContextCallback(e.target.value, this.props.secondaryScenario?.id || null, this.props.relativeComparison)}
                        value={this.props.primaryScenario?.id || -1}
                        style={{width: "100%"}}>

                        <MenuItem value={-1}>
                            <em>none</em>
                        </MenuItem>
                        {
                            this.props.scenarios.map(scenario => {
                                return <MenuItem value={scenario.id}>
                                    {scenario.name}
                                </MenuItem>
                            })
                        }
                    </Select>
                </FormControl>
                <FormControl style={{width: "100%", backgroundColor: "white", pointerEvents: "auto"}}>
                    <InputLabel>Comparison Scenario</InputLabel>
                    <Select
                        onChange={(e) => this.props.setContextCallback(this.props.primaryScenario?.id || null, e.target.value, this.props.relativeComparison)}
                        value={this.props.secondaryScenario?.id || -1}
                        style={{width: "100%"}}>

                        <MenuItem value={-1}>
                            <em>none</em>
                        </MenuItem>
                        {
                            this.props.scenarios.map(scenario => {
                                if (scenario.id === this.props.primaryScenario?.id) {
                                    return ""
                                }
                                return <MenuItem value={scenario.id}>
                                    {scenario.name}
                                </MenuItem>
                            })
                        }
                    </Select>
                </FormControl>
                {/*<FormControlLabel control={*/}
                {/*    <Checkbox*/}
                {/*        onChange={(e, v) => {*/}
                {/*            this.props.setContextCallback( this.props.primaryScenario?.id || null, this.props.secondaryScenario?.id || null, v)*/}
                {/*        }}*/}
                {/*        value={this.props.relativeComparison}/>*/}
                {/*} label={"relative comparison"}/>*/}
            </div>
        </CollapsableMenu>
    }

    renderScenariosAsList = () => {
        return <CollapsableMenu minimized={this.state.minimized} initialOpen={true} icon={Filter} title={"Scenarios"}>
            <List component="div" disablePadding style={{maxHeight: "70vh", overflow: "auto"}}>
                {
                    this.props.scenarios.sort((a, b) => a.id - b.id).map(scen => {
                        return <PanelScenarioListItem scenario={scen}
                                                      setScenarioCallback={this.props.setScenarioCallback}
                                                      currentScenario={this.props.currentScenario}/>;
                    })
                }
            </List>

            <div style={{backgroundColor: "#F0F4F8"}}>
                <Tooltip title={"Add Scenarios"}>
                    <Fab size={"small"} color={"primary"} aria-label="Add"
                         style={{
                             top: "-5px",
                             left: "calc(100% - 70px)",
                             backgroundColor: "#102A43"
                         }} onClick={this.props.onAddCallback}>
                        <AddIcon fontSize={"small"}/>

                    </Fab></Tooltip>
            </div>
        </CollapsableMenu>
    }

    renderMapFilters = () => {
        return <CollapsableMenu minimized={this.state.minimized} icon={MapIcon} title={"GIS Data"}>
            <List component="div" disablePadding style={{maxHeight: "70vh", overflow: "auto"}}>
                {
                    this.props.geojsons.filter(f => f.active).map(b => {
                        return <PanelMapFilterListItem geojsons={b}
                                                       active={this.state.mapBoundaries.includes(b.id)}
                                                       onOffBoundary={() => this.onOffBoundary(b.id)}/>
                    })
                }

            </List>
            {!this.state.minimized &&
            <div style={{backgroundColor: "#F0F4F8"}}>
                {this.state.addingArea ?
                    <Tooltip title={"Back"}>
                        <Fab size={"small"}
                             color={"primary"}
                             style={{
                                 top: "-5px",
                                 left: "calc(100% - 70px)", background: "#102A43"
                             }}
                             onClick={_ => this.setState({addingArea: false, addType: null})}>
                            <BackIcon style={{color: "white"}}/>
                        </Fab></Tooltip> :
                    <Tooltip title={"Add New Areas"}><Fab size={"small"} color={"primary"} aria-label="Add"
                                                          style={{
                                                              top: "-5px",
                                                              left: "calc(100% - 70px)",
                                                              backgroundColor: "#102A43"
                                                          }} onClick={_ => this.setState({addingArea: true})}>
                        <AddIcon/>

                    </Fab></Tooltip>}

                {this.state.addingArea ?
                    <Tooltip title={"Draw"}>
                        <Grow in={this.state.addingArea} {...(this.state.addingArea ? {
                            timeout: {
                                enter: 1000,
                                exit: 1000
                            }
                        } : {})}>
                            <Fab size={"small"} style={{top: "-5px", color: "white", backgroundColor: "#19216C"}}
                                 onClick={this.handleSetDraw}>
                                <CreateIcon/>
                            </Fab>
                        </Grow></Tooltip>
                    : <Tooltip title={"Delete Areas"}><Grow in={!this.state.addingArea} {...(this.state.addingArea ? {
                        timeout: {
                            enter: 1000,
                            exit: 1000
                        }
                    } : {})}>
                        <Fab size={"small"}
                             style={{
                                 background: "red", color: "white", top: "-5px",
                             }}
                             onClick={_ => this.setState({openDeleteAreaDialog: true})}><DeleteIcon/></Fab></Grow></Tooltip>}

                {this.state.addingArea ?
                    <Tooltip title={"Upload"}>
                        <Grow in={this.state.addingArea} {...(this.state.addingArea ? {
                            timeout: {
                                enter: 1000,
                                exit: 1000
                            }
                        } : {})}>
                            <Fab size={"small"} style={{
                                top: "-5px",
                                left: "calc(50% - 95px)",
                                color: "white",
                                backgroundColor: "#19216C"
                            }} onClick={_ => {
                                this.setState({
                                    addType: "upload"
                                })
                            }
                            }>
                                <PublishIcon/>
                            </Fab></Grow></Tooltip> : null}
                {this.renderDeleteAreaDialog()}
                <Slide mountOnEnter unmountOnExit direction={"up"} in={this.state.addingArea}>
                    <div style={{background: "#F0F4F8"}}>
                        {
                            this.renderAddingBoundary()
                        }
                    </div>
                </Slide>
            </div>}
        </CollapsableMenu>

    }

    renderSpatialFilters = () => {
        return <CollapsableMenu
            minimized={this.state.minimized}
            icon={FilterListIcon}
            title={"Filters"}>
            <List component="div" disablePadding style={{maxHeight: "70vh", overflow: "auto"}}>
                {
                    this.props.filters.filter(f => f.active).map(b => {
                        return <PanelFilterListItem geojsons={b}
                                                    active={this.state.mapBoundaries.includes(b.id)}
                                                    onOffBoundary={() => this.onOffBoundary(b.id)}/>
                    })
                }

            </List>

            <div style={{backgroundColor: "#F0F4F8"}}>
                {this.state.addingArea ?
                    <Tooltip title={"Back"}>
                        <Fab size={"small"}
                             color={"primary"}
                             style={{
                                 top: "-5px",
                                 left: "calc(100% - 70px)", background: "#102A43"
                             }}
                             onClick={_ => this.setState({addingArea: false, addType: null})}>
                            <BackIcon style={{color: "white"}}/>
                        </Fab></Tooltip> :
                    <Tooltip title={"Add New Filter"}><Fab size={"small"} color={"primary"} aria-label="Add"
                                                           style={{
                                                               top: "-5px",
                                                               left: "calc(100% - 70px)",
                                                               backgroundColor: "#102A43"
                                                           }} onClick={_ => this.setState({addingFilter: true})}>
                        <AddIcon/>

                    </Fab></Tooltip>}
            </div>
            <AddFilterDialog
                loading={this.state.addingFilterLoading}
                onConfirm={(name, data) => {
                    this.setState({
                        addingFilterLoading: true
                    })
                    delayPromise(this.props.addSpatialFilter(this.props.project.id, name, data), 1000).then(f => {
                        this.setState({
                            addingFilter: false,
                            addingFilterLoading: false,
                        })
                    })

                }}
                geojsons={this.props.geojsons}
                onClose={_ => this.setState({addingFilter: false})} open={this.state.addingFilter}/>

        </CollapsableMenu>
    }


    render() {


        return <div style={{...styles.drawer, maxWidth: this.state.drawerWidth}}>

            <div style={{...styles.drawer_heading}}>

                {!this.state.minimized &&
                <Grid container>
                    <Grid item xs={6} md={6} lg={6}>
                        <InputLabel shrink style={{color: "#FFFFFF"}}>Project</InputLabel>
                        <Typography variant={"h6"}>{this.props.project.name} </Typography>
                    </Grid>
                    <Grid item xs={6} md={6} lg={6}>
                        <IconButton style={{color: "#FFFFFF", left: "calc(100% - 50px)"}} onClick={this.minimize}>
                            <ChevronLeft/>
                        </IconButton>
                    </Grid>
                </Grid>}
                {this.state.minimized &&
                < IconButton style={{color: "#FFFFFF", left: "-15px"}} onClick={this.minimize}>
                    <Menu/>
                </IconButton>
                }

            </div>
            <Divider/>
            <ListItem button key={1}
                      onClick={_ => this.props.setScenarioCallback(-3)}
                      style={styles.drawerList}
            >
                <Home style={{paddingRight: "5px"}}/>
                {!this.state.minimized &&
                <ListItemText disableTypography={true}
                              primary={"Overview"}/>
                }
            </ListItem>


            <Divider/>
            {this.renderContextList()}


            <Divider/>
            <div style={{backgroundColor: "#FFFFFF"}}>
                {this.renderScenariosAsList()}
            </div>
            <Divider/>
            <ListItem button key={1}
                      onClick={this.props.onAnalysisClick}
                      style={styles.drawerList}
            >
                <TimelineIcon style={{paddingRight: "5px"}}/>
                {!this.state.minimized &&
                <ListItemText disableTypography={true}
                              primary={"Analysis"}/>
                }
            </ListItem>
            <Divider/>
            {this.renderLayers()}
            <div style={{backgroundColor: "#FFFFFF"}}>
                {this.renderMapFilters()}
            </div>
            <Divider/>
            <div style={{backgroundColor: "#FFFFFF"}}>
                {this.renderSpatialFilters()}
            </div>
            <Divider/>
            {/*    <div style={{backgroundColor: "#FFFFFF"}}>*/}
            {/*        {this.renderDataUpload()}*/}
            {/*    </div>*/}
            {/*<Divider/>*/}

        </div>
    }
}

SidePanel.propTypes = {
    setScenarioCallback: PropTypes.func.isRequired,
    minimiseCallBack: PropTypes.func.isRequired,
    onAddCallback: PropTypes.func.isRequired,
    currentScenario: PropTypes.object.isRequired,
    setGeojsonFilterCallback: PropTypes.func.isRequired,
    activeTab: PropTypes.number.isRequired,
    onAnalysisClick: PropTypes.func,
    primaryScenario: PropTypes.object,
    secondaryScenario: PropTypes.object,
    relativeComparison: PropTypes.bool,
    setContextCallback: PropTypes.func,
}


const mapStateToProps = (state, ownProps) => {
    return {
        data_sources: state.data_sources,
        project: state.project,
        filters: state.filters,
        scenarios: state.scenarios,
        geojsons: state.geojsons,
    }
}

const mapDispatchToProps = (dispatch) => {

    return {
        addSpatialFilter: (project, name, data) => {
            return dispatch(createFilter(project, name, data));
        },
        deleteAreas: (areas, cb) => {
            Promise.all(areas.map(id => {
                DanceAPI.deleteGeojsons.post(id).then(f => dispatch(updateGeoJSON(id, f)))
            })).then(cb)
        },

        highlightBoundary: (id, state) => {
            dispatch(highlightGeojson(id, state))
        },

        createGeoJSON: (project_id, boundary, name, callback) => {
            dispatch(createGeoJSON(project_id, boundary, name)).then(callback)
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(SidePanel);
