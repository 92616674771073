import React from 'react'
import PropTypes from "prop-types"
import {connect} from "react-redux";
import BarChart from "./BarChart";
import QueryHandler from "../../QueryHandler";
import ArrayBarChart from "./ArrayBarChart";
import ScatterChart from "./ScatterChart";
import ResultTable from "./ResultTable";
import TimeSeriesLine from "./TimeSeriesLine";
import TimeSeriesBar from "./TimeSeriesBar";
import Dialog from "@material-ui/core/Dialog";
import {DialogTitle} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FilterList from '@material-ui/icons/FilterList';
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import WaterBalanceDiagram from "./WaterBalanceDiagram";
import WaterBalanceDiagramLot from "./WaterBalanceDiagramLot";
import BigTable from "./BigTable";
import StackedBarChart from "./StackedBarChart";
import WaterCycleDiagram from "./WaterCycleDiagram";


class ChartWrapper extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            query_data: [],
            dialog_open: false,
            all_loaded: false,
            reference_id: 0,
            reference_scenario: null
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if((prevProps.scenario === undefined && this.props.scenario !== undefined) ||
            (prevProps.scenario !== this.props.scenario) || (this.props.areas !== prevProps.areas)){
            this.queryUpdate(QueryHandler.getData());
        }
    }



    queryUpdate = (data) => {
        if(Object.keys(data).length === 0){
            this.setState({
                query_data:[],
                all_loaded: false,
            });
            return;
        }
        let datasets = [];
        let areas = this.props.areas || [];
        let occ = (l, s) => l.reduce((acc, v) => (v === s ? 1 : 0) + acc, 0)
        let existing_names = [];
        let all_loaded = true;
        let data_sources = [];
        if(areas.length === 0){
            data_sources = this.props.scenarios.map(s => {

                existing_names.push(s.name);
                let n = occ(existing_names, s.name) - 1;
                return {
                    scenario: s,
                    name: s.name + (n !== 0 ? ` - (${n})` : "")
                }
            });
        }else {
            data_sources = this.props.scenarios.flatMap(
                scenario => this.props.areas.map(area => {
                    existing_names.push(scenario.name + " - " + area.name);

                    let n = occ(existing_names, scenario.name) - 1;
                    return {
                        scenario,
                        area,
                        name: scenario.name + (n === 0 ? ` - (${n})` : "") + " - " + area.name,
                    }
                })
            )
        }
        data_sources.filter(ds => {
            if(this.props.scenario !== undefined){
                return ds.scenario.id === this.props.scenario.id
            }
            return true;
        })
            .map(ds => {
            if(ds.scenario.status !== 7){
                // datasets.push({
                //     name: ds.name,
                //     queries: [],
                // })

            } else {
                datasets.push({
                    name: ds.name,
                    queries: this.props.queries.map(query => {
                        let query_obj = data[query.id + "." + ds.scenario.performance_assessment_id + "." + (ds.area ? ds.area.id : null)]
                        if (!query_obj) {
                            all_loaded = false;
                            return {
                                successful: false,
                                running: false,
                                data: null,
                                query_prototype: null
                            }
                        } else if (query_obj.running) {
                            all_loaded = false;
                            return {
                                successful: false,
                                running: true,
                                data: query_obj.data,
                                query_prototype: query_obj.query_prototype,
                            }
                        }
                        return {
                            successful: query_obj.successful,
                            running: query_obj.running,
                            data: query_obj.result,
                            query_prototype: query.query_prototype,
                        }
                    })
                });
            }
        })

        this.setState({
            query_data:datasets,
            all_loaded,
        })
    }

    componentDidMount() {
        QueryHandler.registerListener(this.queryUpdate)
    }

    componentWillUnmount() {
        QueryHandler.removeListener(this.queryUpdate)
    }

    handleReferenceScenarioChange = (event) => {
        this.setState({reference_id: event.target.value})
        let scenario = null;
        if (event.target.value > 0) {
            scenario = this.props.scenarios.filter((s) => s.id === event.target.value)[0]
        }
        this.setState({reference_scenario: scenario})
    };

    render() {
        if(this.state.query_data === null || this.state.query_data === undefined){
            return <div/>
        }
        let successful = this.state.query_data.flatMap(
            qd => qd.queries.map(q => q.successful && !q.running)
        );
        if(successful.some(f => !f)){

            return <div>
                One or more queries encountered an error
                <Button onClick={_=>this.setState({dialog_open: true})}>information</Button>
                <Dialog
                    onEscapeKeyDown={_=>this.setState({dialog_open: false})}
                    onBackdropClick={_=>this.setState({dialog_open: false})}
                    maxWidth={"md"} fullWidth open={this.state.dialog_open}>
                    <DialogTitle>Query error information</DialogTitle>
                    <DialogContent>
                        {
                            this.state.query_data.flatMap(
                                (qd,i) => qd.queries.map((q,j) => {
                                    if(!q.query_prototype){
                                        return <div key={i+"."+j}>
                                        </div>
                                    }
                                    if(!q.successful){
                                        return <div key={i+"."+j}>
                                            {q.query_prototype.query_data.name}:{q.data}
                                        </div>
                                    }
                                })
                            )
                        }
                    </DialogContent>
                </Dialog>
            </div>
        }

        let chart_type = this.props.dash_tile_template.data.type;
        let Component = null;
        if(chart_type === "bar_chart"){
            Component = BarChart
        }else if(chart_type === "array_bar_chart") {
            Component = ArrayBarChart
        }else if(chart_type === "scatter" || chart_type === "cdf") {
            Component = ScatterChart
        }else if(chart_type === "result_table") {
            Component = ResultTable
        }else if(chart_type === "timeseries_bar") {
            Component = TimeSeriesLine
        }else if(chart_type === "stacked_bar_chart") {
            Component = StackedBarChart
        }else if(chart_type === "timeseries_line") {
            Component = TimeSeriesBar
        }else if(chart_type === "water_balance_diagram") {
            Component = WaterBalanceDiagram
        }else if(chart_type === "water_cycle_diagram") {
            Component = WaterCycleDiagram
        }else if(chart_type === "water_balance_diagram_lot_scale") {
            Component = WaterBalanceDiagramLot
        // }else if(chart_type === "empty_chart") {
        //     Component = EmptyChart
        }else if(chart_type === "big_table") {
            Component = BigTable
        }else{
            return <div>{this.props.chart_props.type}</div>
        }
        let qd = this.state.query_data;
        // Only display single scenario if global flag is set
        if (qd.length > 0 &&  this.props.dash_tile_template.data.global)
            qd = [qd[0]]

        return <div >

            <div style={{float: "right", marginTop: "-55px"}}>
                <FilterList/>
                <FormControl>

                    <Select
                        style={{
                            fontSize: "12px",
                            color: "#243B53",
                            backgroundColor: "#F0F4F8",
                            paddingLeft: "3px",
                            marginLeft: "5px",
                            border: '1px solid #BCCCDC',
                            borderRadius: "5px"
                        }}
                        value={this.state.reference_id}
                        onChange={this.handleReferenceScenarioChange}

                    >
                        <MenuItem value={0}>None</MenuItem>
                        {this.props.scenarios.map((s) => {
                            return <MenuItem value={s.id}>{s.name}</MenuItem>
                        })}
                    </Select>
                </FormControl>

            </div>


        <Component
            all_loaded={this.state.all_loaded}
            query_data={qd}
            dash_tile_template={this.props.dash_tile_template}
            query_prototypes={this.props.query_prototypes}
            scenarios={this.props.scenarios}
            reference_scenario={this.state.reference_scenario}
        />
        </div>;

    }
}


ChartWrapper.propTypes = {
    dash_tile_template: PropTypes.object,
    chart_props: PropTypes.object,
    scenario: PropTypes.object,
    areas: PropTypes.array,
}

const mapStateToProps = (state, ownProps) => {
    return {
        scenarios: state.scenarios,
        query_prototypes: ownProps.chart_props.queries.map(id => {
            let query = state.queries.find(q => q.id === id);
            return state.query_prototypes.find(q => q.id === query.query_prototype_id)
        }),
        queries: ownProps.chart_props.queries
            .map(f => {
                let query = state.queries.find(q => q.id === f);
                return {
                    ...query,
                    query_prototype: state.query_prototypes.find(q => q.id === query.query_prototype_id)
                }
            })
    }
}

const mapDispatchToProps = (dispatch) => {

    return {}
}


export default connect(mapStateToProps, mapDispatchToProps)(ChartWrapper);
