import React from 'react'
import PropTypes from "prop-types"
import {connect} from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import DialogContent from "@material-ui/core/DialogContent";
import AppBar from "@material-ui/core/AppBar";
import {Toolbar} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Marquee from "react-marquee"
import CoolerMarquee from "../Components/Views/CoolerMarquee";
import ColorFlipper from "../Components/Views/ColorFlipper";
import DanceAPI from "../actions/DanceAPI";


const toSpans = (t) => {
    return t.split("").map((c,i) => <span key={i} style={{animationDelay: (20*i)+"ms"}}>{c === " " ? '\u00A0' : c}</span>)
}
class AdminScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currentScenario: null,
        }
    }

    render() {
        return <Dialog open={this.props.open} fullScreen TransitionComponent={Slide} TransitionProps={{direction:"up"}}>
            <DialogContent style={{padding:0, display:"flex", flexDirection:"column"}}>
                <AppBar position={"relative"}>
                    <Toolbar variant={"dense"}>
                        Admin Panel - Very Secret

                        <Button variant={"contained"} style={{marginLeft:"auto"}} onClick={this.props.onCloseCallback}>
                            Close
                        </Button>
                    </Toolbar>
                </AppBar>

                <div style={{flex:1, display:"flex", flexDirection:"column"}}>
                    <CoolerMarquee/>
                    <div style={{display:"flex", flex:1}}>
                    <div style={{flex:1, backgroundColor:"red"}}>
                        <List>
                            {
                                this.props.scenarios.map(s => {
                                    return <ListItem
                                        key={s.id}
                                        selected={(this.state.currentScenario||{}).id === s.id}
                                        button
                                        onClick={_=>this.setState({currentScenario: s})}
                                        style={{backgroundColor:(this.state.currentScenario||{}).id === s.id ? "magenta": "orange"}}>
                                        <ListItemText primary={s.name}/>
                                    </ListItem>
                                })
                            }
                        </List>
                    </div>
                    <div style={{flex:3, padding:"30px", backgroundColor:"lime"}}>
                        { !this.state.currentScenario &&
                            <div>
                            <ColorFlipper style={{paddingTop:"1em"}} secondary={"#58FA58"} primary={"#FF0040"}>
                                <h2 className={"wavey"}>{toSpans("WELCOME TO THE ADMIN PANEL")}</h2>
                            </ColorFlipper>

                            <Button variant={"contained"} onClick={_=>DanceAPI.projectData.get(this.props.project.id)}>
                                <span className={"wavey"}>{toSpans("dOwNlOaD dAtAbAsE")}</span>
                                </Button>
                            </div>
                        }
                    </div>

                    </div>
                </div>

                <CoolerMarquee/>
            </DialogContent>
        </Dialog>
    }

}


AdminScreen.propTypes = {}

const mapStateToProps = (state, ownProps) => {
    return {
        scenarios: state.scenarios,
        project: state.project,
    }
}

const mapDispatchToProps = (dispatch) => {

    return {}
}


export default connect(mapStateToProps, mapDispatchToProps)(AdminScreen);
