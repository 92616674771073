import React from 'react'
import PropTypes from "prop-types"
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";

const styles = {
    drawerList : {
        backgroundColor: "#D9E2EC",
        color:"#243B53",
        fontSize: "14px",
        fontVariant: "small-caps",
        fontWeight: "bold",


    }
}


class DynamicList extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return <div style={{ position:"relative"}}>
            <div style={styles.drawerList}  position={"sticky"}>
                <Toolbar variant={"dense"}>
                    {this.props.title}
                </Toolbar>

                { this.props.noFAB !== true &&
                    <Fab size={"small"} color={"primary"}
                      disabled={this.props.fabDisabled === true}
                      style={{
                          position: "absolute",
                          top: "35px",
                          right: (20 + (this.props.extraFAB ? 50 : 0)) + "px",
                          backgroundColor:  this.props.fabDisabled  ? "" : "#102A43",
                          color: "#FFFFFF",
                          zIndex: "300"
                      }} onClick={(this.props.onAddClick || (_ => {
                }))}>
                    <AddIcon/>
                </Fab>
                }
                { this.props.extraFAB  &&
                    React.cloneElement(this.props.extraFAB, {
                        style: {
                            ...this.props.extraFAB.props.style,
                            position: "absolute",
                            top: "35px",
                            right: "20px",
                            zIndex: "300"
                        }
                    })
                }
            </div>
            <div style={{margin:"4px", position:"relative" }}>
                {
                    this.props.children
                }
            </div>
        </div>;
    }
}


DynamicList.propTypes = {
    fabDisabled: PropTypes.bool,
    extraFAB: PropTypes.node,
    noFAB: PropTypes.bool,
    onAddClick: PropTypes.func,
    title: PropTypes.string,

}

export default DynamicList;