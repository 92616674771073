import * as socket_handler from "./SocketHandler";
import {query_url, query_url_immediate} from "./config";
import geoCoord from "echarts/src/coord/geo/fix/geoCoord";



export class Query {
    constructor(project_id, query_id, simulation_id, area, prototype){
        this.project_id = project_id;
        this.query_id = query_id;
        this.simulation_id = simulation_id;
        this.area = area;
        this.running = false;
        this.successful = false;
        this.result = null;
        this.query_prototype = prototype;
    }

    cleanGeoJSON = (geojson) => {
        let geometries = [];
        // Need to rearrange the dict to make sure type comes before coordinates otherwise spatialite cracks it
        const do_sub = (feature) => {

            let temp = JSON.parse(JSON.stringify(feature))
            let {type, ...rest} = temp;
            if (type === "FeatureCollection") {
                return rest.features.map(do_sub)
            } else if (type === "Feature") {
                return do_sub(rest.geometry)
            } else if ( type === "GeometryCollection"){
                return rest.geometries.map(do_sub)
            }

            let t;
            t = {type, ...rest};
            geometries.push(t);
        }
        do_sub(JSON.parse(JSON.stringify(geojson)))
        return {
            type:"GeometryCollection",
            geometries: geometries,
        };

    }

    getQueryText = () => {
        if(typeof this.query_prototype.query_data.query == "string"){
            return this.query_prototype.query_data.query;
        }else if(this.area && this.query_prototype.query_data.query["spatial"]) {
            return this.query_prototype.query_data.query["spatial"].replace(/%GEOM%/g, "transform(SetSRID(GeomFromGeoJSON('" + JSON.stringify(this.cleanGeoJSON(this.area.geojson)) + "'),4326), (SELECT srid FROM vector_layers LIMIT 1))");
        }else {
            return this.query_prototype.query_data.query["default"];
        }
    }


}

class QueryHandler {

    constructor(){
        socket_handler.add_listener("query_updates", this.queryUpdateHandler)
        this.query_cache = {};
        this.listeners = [];
        this.type_listeners = {};
    }

    queryUpdateHandler = (data) => {
        data.map(
            query => {
                if(query.type !== undefined){
                    if(this.type_listeners.hasOwnProperty(query.type)){
                        this.type_listeners[query.type].map(f => f(query))
                    }
                    return;
                }

                let key = query.query_id + "." + query.simulation_id + "." + (query.area ? query.area.id : null);
                this.query_cache[key].result = query.data;
                this.query_cache[key].successful = query.successful;
                this.query_cache[key].running = false;
            }
        )
        this.emitUpdate();
    }

    getQuery = (scenario_id, query_id, area) => {
        let key = query_id + "." + scenario_id + "." + (area ? area.id : null);
        return this.query_cache[key];
    }


    submitNonstandardQuery = (query, db_name, extra_data) => {

        let form = new FormData()
        form.append("query", query);
        form.append("db_name", db_name);
        form.append("extra_data", JSON.stringify(extra_data));

        return fetch(query_url,
            {
                body: form,
                method: "post"
            }).then(result => {
            return result.json()
        });
    }

    addQueries = (data) => {
        let new_queries = []
        data.map(query => {
            let key = query.query_id + "." + query.simulation_id + "." + (query.area ? query.area.id : null);
            if(!this.query_cache[key]){

                new_queries.push(query);
            }

        });

        Promise.all(new_queries.map(this.runQuery)).then(_=>{
            this.emitUpdate()
        })
    }

    runQuery = (query) => {

        let form = new FormData()
        form.append("query", query.getQueryText());
        form.append("db_name", query.simulation_id);
        if(query.query_prototype.query_data.post_processing){
            form.append("extra_data", JSON.stringify({
                project_id: query.project_id,
                query_text: query.getQueryText(),
                simulation_id: query.simulation_id,
                post_processing: query.query_prototype.query_data.post_processing,
                query_id: query.query_id,
                area: query.area,
            }));
        }else{
            form.append("extra_data", JSON.stringify({
                project_id: query.project_id,
                query_text: query.getQueryText(),
                simulation_id: query.simulation_id,
                query_id: query.query_id,
                area: query.area,
            }));
        }
        return fetch(query_url,
            {
                body: form,
                method: "post"
            }).then(result => {
            return result.json()
        }).then(result => {
            if(result.successful){
                let key = query.query_id + "." + query.simulation_id + "." + (query.area ? query.area.id : null);
                this.query_cache[key] = query;
                this.query_cache[key].result = result.data;
                this.query_cache[key].successful = true;
            }else{
                let key = query.query_id + "." + query.simulation_id + "." + (query.area ? query.area.id : null);
                this.query_cache[key] = query;
                this.query_cache[key].result = result.data;
                query.running = result.status === "running" || result.status === "submitted";
            }
        });




    }

    clearState = () => {
        this.query_cache = {};
    }

    getCompleteQueries = () => {
        return Object.keys(this.query_cache).map(q => this.query_cache[q]).filter(
            f => f.result !== null
        )
    }

    emitUpdate = () => {
        this.listeners.map(
            f => f(this.query_cache)
        )
    }

    registerTypeListener = (type, func) => {
        if(!this.type_listeners.hasOwnProperty(type)){
            this.type_listeners[type] = []
        }
        this.type_listeners[type].push(func);
    }

    removeTypeListener = (type, func) => {
        if(!this.type_listeners.hasOwnProperty(type)){
            return;
        }
        this.type_listeners[type] = this.type_listeners.filter(f => f !== func);
    }

    registerListener = (func) => {
        this.listeners.push(func);
        func(this.query_cache);
    }

    removeListener = (func) => {
        this.listeners = this.listeners.filter(f => f !== func);
    }

    getData = () => {
        return this.query_cache;
    }
}

const queryHandler = new QueryHandler();
export default queryHandler;
