


const dash_tile_template = (state, action) => {
    switch (action.type) {
        case 'DASHTILETEMPLATES_INIT':{
            return {
                id: action.id,
                summary: action.summary,
                data: action.tile_template_data,
            }
        }
        default:
            return state;
    }
}

const dash_tile_templates = (state=[], action) => {

    switch (action.type) {
        case 'DASHTILETEMPLATES_INIT':{
            return action.templates.map((data) => dash_tile_template({}, {type: action.type, ...data}))
        }
        default:
            return state;
    }
}

export default dash_tile_templates;