import {ReactComponent as tree} from "./tree.svg"
import {ReactComponent as rain} from "./rain.svg"
import {ReactComponent as buildings} from "./buildings.svg"
import {ReactComponent as circle_drop} from "./circle_drop.svg"
import {ReactComponent as double_drop} from "./double_drop.svg"
import {ReactComponent as down_arrows} from "./down_arrows.svg"
import {ReactComponent as flame} from "./flame.svg"
import {ReactComponent as people} from "./people.svg"
import {ReactComponent as tap} from "./tap.svg"
import {ReactComponent as thermometer} from "./thermometer.svg"
import {ReactComponent as up_arrows} from "./up_arrows.svg"
import {ReactComponent as wave} from "./wave.svg"
import {ReactComponent as wave2} from "./wave2.svg"

export const TreeIcon = tree;
export const RainIcon = rain;
export const BuildingsIcon = buildings;
export const CircleDropIcon = circle_drop;
export const DoubleDropIcon = double_drop;
export const DownArrowsIcon = down_arrows;
export const FlameIcon = flame;
export const PeopleIcon = people;
export const TapIcon = tap;
export const ThermometerIcon = thermometer;
export const UpArrowsIcon = up_arrows;
export const WaveIcon = wave;
export const Wave2Icon = wave2;

export default {
    tree: TreeIcon,
    rain: RainIcon,
    buildings: BuildingsIcon,
    circle_drop: CircleDropIcon,
    double_drop: DoubleDropIcon,
    down_arrows: DownArrowsIcon,
    up_arrows: UpArrowsIcon,
    flame: FlameIcon,
    people: PeopleIcon,
    tap: TapIcon,
    thermometer: ThermometerIcon,
    wave: WaveIcon,
    wave2: Wave2Icon,
}