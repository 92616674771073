/**
 * Created by User on 23/06/2020
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Check from "@material-ui/icons/Check"
import Error from "@material-ui/icons/Error"
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import DenseCard from "./DenseCard";
import Tooltip from "@material-ui/core/Tooltip";
import ColorFlipper from "./ColorFlipper";
import CoolerMarquee from "./CoolerMarquee";

class UploadWorkflowDialog extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedNode: null
        };
    }

    componentDidMount() {
    };

    componentWillUnmount() {
    };

    renderHeading = () => {
        const error_data = {
            area:0,
            node_type:0,
            node_version:0,
        }
        const errors = this.props.uploadData.errors.map((v) => {
            error_data.area += v.indexOf("area") > -1 ? 1 : 0;
            error_data.node_type += v.indexOf("node_type") > -1 ? 1 : 0;
            error_data.node_version += v.indexOf("node_version") > -1 ? 1 : 0;
        })
        const has_errors = error_data.area > 0 || error_data.node_type > 0 || error_data.node_version > 0;

        return <div style={{
            padding:"10px",
            fontSize:"1.1em",
            textAlign: "center",
            background: has_errors ? "rgb(248,243,146)": "rgb(182,255,174)"}}>
            {
                has_errors ?
                    "The import encountered some issues":
                    "Imported data looks good"
            }
        </div>
    }

    renderNodeCard = (node, error_data, index) => {
        let node_error = error_data.indexOf("node_type") > -1;
        let node_version_error = error_data.indexOf("node_version") > -1;
        let area_error = error_data.indexOf("area") > -1;

        let node_name = node_error ? "error (" + node.meta_data.node_name + ")": node.node_type.name;
        let area_name = area_error ? "error (" + node.meta_data.area_name + ")": node.area.name;


        return <DenseCard
            cardIcon={node_error || node_version_error || area_error ? <Error/> : ""}
            selectedColor={"rgba(120,255,252,0.25)"}
            selected={index===this.state.selectedNode}
            onSelect={_=>this.setState({selectedNode:index})}
            title={node_name}
            content={<Typography variant={"body2"}>
                <i>Area:</i> {area_name}
            </Typography>}
        />
    }

    renderAreaError = (error) => {
        if(error){
            return <Tooltip placement={"right"} title={"This project does not have access to this area"}><Error style={{color:"red"}}/></Tooltip>
        }
        return <Tooltip placement={"right"} title={"This project has access to this area"}><Check style={{color:"green"}}/></Tooltip>
    }

    renderNodeError = (error, version_error) => {
        if(error){
            return <Tooltip placement={"right"} title={"This project does not have access to this node"}><Error style={{color:"red"}}/></Tooltip>
        }else if(version_error){
            return <Tooltip placement={"right"} title={"This project does not have access to the version of this node"}><Error style={{color:"red"}}/></Tooltip>

        }
        return <Tooltip placement={"right"} title={"This project has access to this node"}><Check style={{color:"green"}}/></Tooltip>
    }

    renderParameters = () => {
        return <CoolerMarquee text={"TODO: CHECK PARAMETERS FOR ISSUES"}/>
    }

    renderNodeInfo = (node, error_data) => {

        let node_error = error_data.indexOf("node_type") > -1;
        let node_version_error = error_data.indexOf("node_version") > -1;
        let area_error = error_data.indexOf("area") > -1;

        let node_name = node_error ? node.meta_data.node_name : node.node_type.name;
        let area_name = area_error ? node.meta_data.area_name : node.area.name;

        return <div style={{
            width:"100%",
            height: "100%",
            display:"flex",
            flexDirection:"column"
        }}>
            <div style={{background:"rgba(200,200,200, 0.3)", padding:"4px", textAlign:"center"}}>
                This data can be edited afterwards
            </div>
            <div style={{padding:"20px"}}>
                <div style={{display:"flex", marginBottom:"10px"}}>
                    <div style={{marginRight:"10px", fontWeight:"bold"}}>Node type:</div>
                    {node_name}
                    {this.renderNodeError(node_error, node_version_error)}
                </div>
                <div style={{display:"flex", marginBottom:"10px"}}>
                    <div style={{marginRight:"10px", fontWeight:"bold"}}>Area:</div>
                    {area_name}
                    {this.renderAreaError(area_error)}
                </div>

                <div>
                    <b>Parameters</b>
                </div>
            </div>
            <div style={{flex:"1", height:"100%", overflowY:"auto", paddingRight:"40px", paddingLeft:"40px"}}>
                {this.renderParameters()}
            </div>
        </div>
    }

    renderBody = () => {
        let selected_node = null;
        if(this.state.selectedNode != null){
            selected_node = this.props.uploadData.nodes[this.state.selectedNode];
        }
        return <div style={{flex:"1", display:"flex", flexDirection:"row", overflowY:"hidden"}}>
            <div style={{flex:"1", height:"100%", overflowY:"auto", padding:"10px"}}>
                <div style={{position:"sticky", top:0}}>
                    Nodes:
                </div>
                {
                    this.props.uploadData.nodes.map((d,index) => {
                        return this.renderNodeCard(d,this.props.uploadData.errors[index], index)
                    })
                }
            </div>
            <Divider orientation={"vertical"}/>
            <div style={{flex:"3", height:"100%", overflowY:"auto"}}>
                {selected_node &&
                    this.renderNodeInfo(selected_node, this.props.uploadData.errors[this.state.selectedNode])
                }
            </div>
        </div>
    }
    render() {
        if(!this.props.open){
            return "";
        }
        return <Dialog open={this.props.open} fullWidth maxWidth={"lg"}>
            <DialogTitle>
                Uploading Workflow
            </DialogTitle>
            <DialogContent dividers style={{padding:"0px"}}>
                <div style={{width:"100%", display:"flex", flexDirection:"column", height:"60vh"}}>
                    {this.renderHeading()}
                    <Divider/>
                    {this.renderBody()}
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.props.onCancel}>cancel</Button>
                <Button onClick={this.props.onConfirm}>confirm</Button>
            </DialogActions>
        </Dialog>;
    }
}


UploadWorkflowDialog.propTypes = {
    open: PropTypes.bool,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
    uploadData: PropTypes.array
}


export default UploadWorkflowDialog;
