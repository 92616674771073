import React from 'react'
import PropTypes from "prop-types"
import {connect} from "react-redux";
import List from "@material-ui/core/List/index";
import GeoJSONListItem from "./Views/GeoJSONListItem";
import Divider from "@material-ui/core/Divider";


class GeoJSONList extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return <List dense subheader={this.props.subheader || ""}>
            {
                this.props.geojsons.map(g => {
                    return <div>
                        <GeoJSONListItem
                        button={this.props.buttons}
                        onClick={_=>this.props.onItemClick(g)}
                        geojson={g}/>
                        <Divider/>
                    </div>
                })
            }
        </List>;
    }
}


GeoJSONList.propTypes = {
    subheader: PropTypes.string,
    geojsons: PropTypes.array,
    onItemClick: PropTypes.func,
    buttons: PropTypes.bool,
}

const mapStateToProps = (state, ownProps) => {
    return {
        geojsons: state.geojsons
    }
}

const mapDispatchToProps = (dispatch) => {

    return {}
}


export default connect(mapStateToProps, mapDispatchToProps)(GeoJSONList);
