


const scenario_comparison = (state, action) => {
    switch (action.type) {
        case 'SCENARIOCOMPARISON_INIT':{
            return {
                id: action.id,
                range_data: null,
                template_id: action.template_id,
                scenarios: action.scenarios,
            }
        }
        case 'SCENARIOCOMPARISON_UPDATE_RANGE':{
            if(action.id !== state.id){
                return state;
            }
            return {
                ...state,
                range_data: action.range_data
            }
        }
        default:
            return state;
    }
}

const scenario_comparisons = (state=[], action) => {
    switch (action.type) {
        case 'SCENARIOCOMPARISON_INIT':{
            return action.comparisons.map((data) => scenario_comparison({}, {type: action.type, ...data}))
        }
        case 'SCENARIOCOMPARISON_UPDATE_RANGE':{
            return state.map((data) => scenario_comparison(data, action))
        }
        default:
            return state;
    }
}

export default scenario_comparisons;
