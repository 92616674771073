/**
 * Created by User on 18/06/2020
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import WarningIcon from '@material-ui/icons/Warning';
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Badge from "@material-ui/core/Badge";
import CircularProgress from "@material-ui/core/CircularProgress";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

class AddFilterDialog extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selected_area: null,
            filter_data: {

                filter_type:"properties",
                filter_elements:[]
            },
            currTab: 0,
        };
    }

    componentDidMount() {
    };

    componentWillUnmount() {
    };

    conditionalRender = (condition, component) => {
        return condition ? component() : "";
    }

    renderFilterName = () => {
        return <div>
            <TextField
                onChange={e => this.setState({filter_name: e.target.value})}
                value={this.state.filter_name}
                fullWidth
                error={!this.state.filter_name}
                helperText={!this.state.filter_name && "Filter name required"}
                label={"Filter Name"}/>
        </div>
    }

    handleSQLChange = (event) => {
        this.setState({
            filter_data: {
                filter_type:"sql",
                sql: event.target.value
            }
        })
    }

    handleAdd = () => {
        this.setState({
            filter_data: {
                ...this.state.filter_data,
                filter_elements: [...this.state.filter_data.filter_elements, {layer:null, attribute: null, value:null, comparison: null}]
            }
        })
    }

    handleDelete = (j) => {
        this.setState({
            filter_data: {
                ...this.state.filter_data,
                filter_elements: this.state.filter_data.filter_elements.filter((f,i) => i !== j)
            }
        })
    }

    handleUpdate = (i, k, v=null) => {
        if(v === null){
            this.setState({
                filter_data: {
                    ...this.state.filter_data,
                    filter_elements: this.state.filter_data.filter_elements.map((f,j) => {
                        if(i===j){
                            return {...f, ...k}
                        }

                        return f
                    })
                }
            })

            return;
        }
        this.setState({
            filter_data: {
                ...this.state.filter_data,
                filter_elements: this.state.filter_data.filter_elements.map((f,j) => {
                    if(i==j){
                        return {...f, [k]:v}
                    }

                    return f
                })
            }
        })
    }


    renderAttrInput = (type, entry_data, index) => {
        if(type === "date"){
            return <TextField
                onChange={e => this.handleUpdate(index, "value", e.target.value)}
                value={entry_data.value}
                type={"date"}/>
        }else if(type === "double"){
            return <TextField
                onChange={e => this.handleUpdate(index, "value", e.target.value)}
                value={entry_data.value}
                type={"number"}
                error={!/^[+-]?([0-9]*[.])?[0-9]+$/.test(entry_data.value)}/>
        }else if(type === "integer"){
            return <TextField

                onChange={e => this.handleUpdate(index, "value", e.target.value)}
                value={entry_data.value}
                type={"number"}
                error={!/^[-+]?[1-9]\d*$/.test(entry_data.value)}/>
        }else if(type === "string"){
            return <TextField

                onChange={e => this.handleUpdate(index, "value", e.target.value)}
                value={entry_data.value}/>
        }
    }



    renderFilterEntry = (data_sources, entry_data, i) => {

        let types = {
            DATE:{
                comps: ["<", "≤", "=", "≠", "≥", ">"],
                input_type: "date",
            },
            DOUBLE:{
                comps: ["<", "≤", "=", "≠", "≥", ">"],
                input_type: "double",
            },
            INTEGER:{
                comps: ["<", "≤", "=", "≠", "≥", ">"],
                input_type: "integer",
            },
            STRING:{
                comps: ["=", "≠"],
                input_type: "string",
            },
        }
        let attr_dtype = null;
        if(entry_data.layer !== null  && entry_data.attribute !== null){
            let attr = data_sources[entry_data.layer].find(f => f.attribute_name === entry_data.attribute);
            attr_dtype = types[attr.data_type];
        }

        return <div style={{display:"flex", width:"100%"}}>
            <div style={{flex:"1 0 0"}}>
                <Select fullWidth onChange={event => this.handleUpdate(i, "layer", event.target.value)} value={entry_data.layer} defaultValue={-1}>
                    <MenuItem value={-1} disabled>select one</MenuItem>
                    {Object.keys(data_sources).map(k => {
                        return <MenuItem value={k}>
                            {k}
                        </MenuItem>
                    })}
                </Select>
            </div>
            <div style={{flex:"1 0 0"}}>
                <Select fullWidth onChange={event => {
                    let k = event.target.value;
                    this.handleUpdate(i, {
                        attribute: k,
                        data_type: types[data_sources[entry_data.layer].find(f => f.attribute_name === k).data_type].input_type
                    });
                }} value={entry_data.attribute} defaultValue={-1}>
                    <MenuItem value={-1} disabled>select one</MenuItem>
                    {(data_sources[entry_data["layer"]]||[]).filter(f => !["NODE", "LINK", "FACE"].includes(f.data_type)).map(k => {
                        return <MenuItem value={k["attribute_name"]}>
                            {k["attribute_name"]}
                        </MenuItem>
                    })}
                </Select>
            </div>
            <div>
                {
                    <Select value={entry_data.comparison} onChange={event => this.handleUpdate(i, "comparison", event.target.value)}>
                        {(attr_dtype||{comps:[]}).comps.map(f => {
                            return <MenuItem value={f}>{f}</MenuItem>
                        })}
                    </Select>
                }
            </div>
            <div style={{flex:"1 0 0"}}>
                {
                    attr_dtype != null &&
                        this.renderAttrInput(attr_dtype.input_type, entry_data, i)
                }
            </div>

            <div style={{flex:"1 1 0"}}>
                <Button onClick={_=>this.handleDelete(i)}>
                    delete
                </Button>
            </div>
        </div>
    }

    renderFilterMaker = () => {
        let filters = {};
        this.props.filter_layer_filter.map( k => {
            filters[k] = this.props.data_structure[k];
        })

        return <div>
            {
                this.state.filter_data.filter_elements.map((f,i) => {
                    return this.renderFilterEntry(filters, f, i)
                })
            }

            <Button onClick={this.handleAdd}>
                add
            </Button>
        </div>
    }

    changeTab = (t) => {
        if(t === 1){
            this.setState({
                filter_data:{
                    filter_type:"sql",
                    sql:"",
                },
                currTab:t
            })
        }else if(t === 0){
            this.setState({
                filter_data:{
                    filter_type:"properties",
                    filter_elements:[]
                },
                currTab:t
            })
        }
    }

    renderContent = () => {
        if(this.props.open === false){
            return "";
        }
        if(this.props.loading){
            return <div style={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
                <div style={{textAlign:"center"}}>
                    <div>uploading</div>
                    <CircularProgress size={20}/>
                </div>
            </div>
        }
        return <div style={{width:"100%", height:"100%", display:"flex", flexDirection:"row"}}>
            <Divider orientation={"vertical"}/>
            <div style={{flex:"3"}}>
                <Tabs value={this.state.currTab} onChange={(_, v)=>this.changeTab(v)}>
                    <Tab label={"Property"}/>
                    <Tab label={"SQL"}/>
                </Tabs>
                <Divider/>
                <div style={{padding:"10px"}}>
                    {this.conditionalRender(this.state.currTab === 1,_=>
                        <div>
                            <div style={{fontSize:"1.1em"}}>SQL Statement</div>
                            <div style={{padding:"5px 10%"}}>
                                {this.renderFilterName()}
                            </div>
                            <div style={{padding:"5px 10%"}}>
                                <TextField
                                    onChange={this.handleSQLChange}
                                    error={!this.state.filter_data.sql}
                                    value={this.state.filter_data.sql}
                                    fullWidth
                                    helperText={!this.state.filter_data.sql && "required field"}
                                    label={"SQL"}
                                    placeholder={"enter SQL statement here"}/>
                            </div>
                        </div>)}
                    {this.conditionalRender(this.state.currTab === 0,_=>
                        <div>
                            <div style={{fontSize:"1.1em"}}>Properties</div>
                            <div style={{padding:"5px 10%"}}>
                                {this.renderFilterName()}
                            </div>
                            <div style={{padding:"5px 10%"}}>
                                {
                                    this.renderFilterMaker()
                                }
                            </div>
                        </div>)}
                </div>
            </div>
        </div>
    }

    renderActions = () => {
        let can_confirm = false;
        if(this.state.currTab === 1){
            can_confirm = this.state.filter_data.sql !== undefined && this.state.filter_data.sql !== "";
        }else if(this.state.currTab === 0){
            let elements = this.state.filter_data.filter_elements;

            can_confirm = elements.map(element => {
                if(element.layer != null && element.comparison != null && element.value != null && element.attribute != null){
                    return true;
                }

                return false;
            }).reduce((prev, current) => prev && current, true);
            can_confirm = can_confirm && this.state.filter_name !== "";
        }
        return [
            <Button color={"secondary"} onClick={this.props.onClose}>cancel</Button>,
            <Button color={"primary"} onClick={e => this.props.onConfirm(this.state.filter_name, this.state.filter_data)} disabled={!can_confirm}>confirm</Button>,
        ]
    }


    render() {
        return <Dialog
                maxWidth={"lg"}
                fullWidth={true}
                open={this.props.open}>
            <DialogTitle>
                Add new filter
            </DialogTitle>
            <DialogContent dividers style={{height:"60vh", padding:"0px"}}>
                {this.renderContent()}
            </DialogContent>
            <DialogActions>
                {this.renderActions()}
            </DialogActions>
        </Dialog>;
    }
}


AddFilterDialog.propTypes = {
    onClose: PropTypes.func,
    loading: PropTypes.bool,
    open: PropTypes.bool,
    geojsons: PropTypes.array,
    onConfirm: PropTypes.func,
    filter_layer_filter: PropTypes.array,
    data_structure: PropTypes.object,
}


export default AddFilterDialog;
