import React from 'react'
import PropTypes from "prop-types"
import {connect} from "react-redux";
import {DialogContent, Select, TextField} from "@material-ui/core/index";
import MenuItem from "@material-ui/core/MenuItem/index";
import FormControlLabel from "@material-ui/core/FormControlLabel/index";
import Typography from "@material-ui/core/Typography/index";
import Dialog from "@material-ui/core/Dialog/index";
import DialogTitle from "@material-ui/core/DialogTitle/index";
import DialogActions from "@material-ui/core/DialogActions/index";
import Button from "@material-ui/core/Button/index";
import Fab from "@material-ui/core/Fab/index";
import AddIcon from "@material-ui/icons/Add"
import {createScenario} from "../actions/scenario_actions";
import Divider from "@material-ui/core/Divider/index";
import Card from "@material-ui/core/Card/index";
import CardHeader from "@material-ui/core/CardHeader/index";
import CardContent from "@material-ui/core/CardContent/index";
import AppBar from "@material-ui/core/AppBar/index";
import Toolbar from "@material-ui/core/Toolbar/index";
import Collapse from "@material-ui/core/Collapse/index";
import ScenarioCard from "./ScenarioCard";
import DynamicList from "./Views/DynamicList";


class ScenarioOverview extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            current_scenario: -1,
            new_scenario_name: "",
            new_scenario_parent: -1,
            new_scenario_dialog_open: false,
        }
    }

    handleAddScenario = () => {
        this.setState({
            new_scenario_dialog_open: true,
        })
    }

    submitNewScenario = () => {
        this.props.createScenario(
            this.state.new_scenario_parent,
            this.props.project.id,
            this.state.new_scenario_name
        );
        this.setState({
            new_scenario_dialog_open: false,
        })
    }


    renderScenarioInfo = () => {
        return this.props.scenarios.map(scen => {
            return <ScenarioCard scenario={scen}/>
        })
    }


    render() {
        return <div>

            <Dialog open={this.state.new_scenario_dialog_open} maxWidth={"xs"} fullWidth>
                <DialogTitle>
                    Add new scenario
                </DialogTitle>
                <DialogContent>
                    <div style={{width:"70%", margin:"auto"}}>
                        <Typography variant={"subtitle1"}>
                            Scenario name
                        </Typography>
                        <TextField onChange={e => this.setState({new_scenario_name:e.target.value})} value={this.state.new_scenario_name} fullWidth placeholder={"Scenario name"}/>
                        <br/>
                        <br/>
                        <br/>
                        <Typography variant={"subtitle1"}>
                            Parent Scenario
                        </Typography>
                        <Select onChange={e => this.setState({new_scenario_parent: e.target.value})} value={this.state.new_scenario_parent} style={{width:"100%"}}>
                            <MenuItem value={-1}>
                                None
                            </MenuItem>
                            {this.props.scenarios.map(scenario => {
                                return <MenuItem  value={scenario.id}>
                                    {scenario.name}
                                </MenuItem>
                            })}
                        </Select>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button color={"secondary"} onClick={_=>this.setState({new_scenario_dialog_open: false,})}>
                        cancel
                    </Button>

                    <Button color={"primary"} onClick={this.submitNewScenario}>
                        confirm
                    </Button>
                </DialogActions>
            </Dialog>

            <DynamicList title={"Scenarios"} onAddClick={this.handleAddScenario}>
                <div style={{margin:"10px"}}>
                    {
                        this.renderScenarioInfo()
                    }
                </div>
            </DynamicList>
        </div>;
    }
}


ScenarioOverview.propTypes = {}

const mapStateToProps = (state, ownProps) => {
    return {
        scenarios: state.scenarios,
        project: state.project,
    }
}

const mapDispatchToProps = (dispatch) => {

    return {

        createScenario: (parent_id, project_id, name) => {

            return dispatch(createScenario(parent_id, project_id, name))
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ScenarioOverview);
