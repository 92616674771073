import React from 'react'
import PropTypes from "prop-types"
import DynamicList from "./Views/DynamicList";
import LayerListItem from "./Views/LayerListItem";
import Divider from "@material-ui/core/Divider";
import LayersIcon from '@material-ui/icons/Layers';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';


class LayerList extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return <DynamicList title={<Box style={{alignItems:"center"}}><LayersIcon fontSize={"small"} style={{paddingRight:"10px", marginBottom:"-5px"}}/>Layers</Box>} noFAB={true}>
            {this.props.layers.map(l => {
                return <div>
                    <LayerListItem
                        active={this.props.layer_filter.findIndex(l2=>l2.id===l.id) !== -1}
                        button={true}
                        layer={l}
                        onClick={_=>this.props.onLayerClicked(l)}/>
                    </div>
            })}
        </DynamicList>
    }

}


LayerList.propTypes = {
    layer_filter: PropTypes.array,
    onLayerClicked: PropTypes.func,
    layers: PropTypes.array,
}

export default LayerList;