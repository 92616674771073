import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import PropTypes from "prop-types";
import Divider from "@material-ui/core/Divider";
import {highlightGeojson} from "../../actions/geojson_actions";
import {connect} from "react-redux";
import Switch from "@material-ui/core/Switch";
import ListItemIcon from '@material-ui/core/ListItemIcon';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const styles = {

    drawer_subList : {
        backgroundColor: "#FFFFFF",
        color:"#243B53",
        padding: "3px",
        paddingLeft: "45px",
        //borderWidth: "3px",
        //borderColor: "#243B53",
        //borderStyle:"none none solid none",
    }
};


class PanelMapFilterListItem extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            hovered: false,
        }
    }


    highlight = (geojson) => {
        this.props.highlightBoundary(geojson.id, true)
        this.setState({
            hovered: true,
        })
    }

    lowlight = (geojson) => {
        this.props.highlightBoundary(geojson.id, false)
        this.setState({
            hovered:false,
        })
    }

    renderTabLabel = (area) => {
        return (<div style={{fontSize:"0.875rem"}}>
            {area.name}
        </div>)
    }


    render() {
        /*let id = -1;
        if (this.props.currentScenario)
            id = this.props.currentScenario.id*/
        return <ListItem button key={this.props.geojsons.id} value={this.props.geojsons.id}
                         onClick={_=>this.props.onOffBoundary(this.props.geojsons.id)}
                         onMouseEnter={()=>this.highlight(this.props.geojsons)}
                         onMouseLeave={()=>this.lowlight(this.props.geojsons)}
                         style={{...styles.drawer_subList,
                             backgroundColor: !this.state.hovered ? "#FFFFFF":"#F0F4F8",}}
        >

            <ListItemText primary={this.renderTabLabel(this.props.geojsons)}/>
            {/*<ListItemIcon><CheckCircleIcon style={{color: this.props.active ?"#18981D":"#BCCCDC"}}/></ListItemIcon>*/}
            <Switch checked={this.props.active} color={"primary"} onChange={_=>this.props.onOffBoundary(this.props.geojsons.id)}/>

        </ListItem>;


    }
}

const mapStateToProps = (state, ownProps) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {

    return {
        highlightBoundary: (id, state) => {
            dispatch(highlightGeojson(id, state))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PanelMapFilterListItem);