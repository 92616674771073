import React from 'react'
import PropTypes from "prop-types"
import DashboardControlPanel from "../Components/DashboardControlPanel";
import {runQueriesWithAreas} from "../actions/project_actions";
import {connect} from "react-redux";
import DashboardTile from "../Components/DashboardTile";
import {CardContent, CardHeader, Collapse, Grid} from "@material-ui/core";
import Map, {constructGeoJSONSource} from "../Components/Map/Map";
import Source from "../Components/Map/Source";
import Layer from "../Components/Map/Layer";
import {build_layer_perspective} from "../map_styling/util";
import {tile_server, tile_server_query} from "../config";
import bbox from "geojson-bbox";
import queryHandler from "../QueryHandler";
import Paper from "@material-ui/core/Paper";
import ResultsDisplayFilterBar from "../Components/ResultsDisplayFilterBar";
import AppBar from "@material-ui/core/AppBar";
import Dial from "../Components/charts/Dial";

import IconMap from "../icons/icons"
import ChartWrapper from "../Components/charts/ChartWrapper";
import DialWrapper from "../Components/charts/DialWrapper";
import ChartDrawer from "../Components/Views/ChartDrawer";
import ChartsModal from "../Components/Views/ChartsModal";
import Slide from "@material-ui/core/Slide";
import ContextDrawer from "../Components/Views/ContextDrawer";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Legend from "../Components/Legend";
import {toggleDisplayLayers} from "../actions/layer_actions";



const buildLayerSource = (layer) => {
    if(layer.type === "comparison"){
        let query_string = {};
        query_string.sources = {};
        let template = layer.layer.template.data;
        let inputs = layer.layer.scenarios;
        for(let i = 0; i < template.number_of_inputs; i++){
            query_string.sources[inputs[i].id + template.suffix] = {name: template.input_aliases[i]}
        }
        query_string.sinks = Object.assign({}, template.query);
        console.log(query_string)
        return {
            id: "layer_comparison_" + layer.layer.id,
            source: {
                type:"vector",
                scheme:"xyz",
                tiles: [tile_server_query + "/{z}/{x}/{y}.pbf?q=" + encodeURIComponent(JSON.stringify(query_string))],
            },
        }
    }else {
        return {
            id: "layer_" + layer.layer.id,
            source: {
                type: "vector",
                scheme: "xyz",
                tiles: [
                    tile_server + "layer/" + layer.layer.scenario_id + "_" + layer.layer.name + "/{z}/{x}/{y}.pbf?"
                ]
            }
        }
    }
}

const section = {
    height: "100%",
    padding: "5px",
    // backgroundColor: "#fff"
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 850,
        },
    },
};

class Overview extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedAreas: [],
            map: null,
            selectedDial: null,
            map2:null,
            layers: [],
            topOpen: true,
            bottomOpen: true,
            isOpen: false,
            charts_modal_open: false,
            filteredDashTiles: [],
            perspectiveFilters: {},
            selectedBaseScenario: null,
            selectedSecondaryScenario: null,
            active_dash_tiles: [...this.props.dash_tiles],
        }

    }

    queryHandlerListener = (event) => {
        this.setState({
            queries: queryHandler.getCompleteQueries()
        })
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.forceChart !== this.props.forceChart){
            this.setState({
                charts_modal_open: this.props.forceChart,
            })
        }
    }

    componentDidMount() {
        queryHandler.registerListener(this.queryHandlerListener)
    }

    componentWillUnmount() {
        queryHandler.removeListener(this.queryHandlerListener)
    }



    handleDialClick = (dial_id) => {
        const summary = this.state.active_dash_tiles.find(f => f.id === dial_id);
        let tagged = this.state.active_dash_tiles.filter(dt => {
            if(dt.template.data.meta_data?.summary){
                return false;
            }
            let tags = dt.template.data.tags || [];

            return tags.some(tag => {
                let md = summary.template.data.meta_data;
                return tag === "all" ||md.tags.includes(tag);
            })
        })
        this.props.toggleLayers(this.props.layers.filter(l=>l.display))
        let selectedDial = this.state.active_dash_tiles.find(f => f.id === dial_id)

        const perspective_filters = {};
        selectedDial = dial_id === this.state.selectedDial ? null : selectedDial
        if(selectedDial !== null) {
            const filtered_layers = this.props.layers.filter(l => {
                if (selectedDial === null) {
                    return false;
                }
                return (l.data.tags || []).some(t =>
                    t === "all" || selectedDial.template.data.meta_data.tags.includes(t)
                );
            })

            filtered_layers.map(l => {
                perspective_filters[l.id] = [];
                if (selectedDial === null) {
                    return false;
                }
                Object.keys(l.data.perspectives).map(k => {
                    let pers = l.data.perspectives[k];
                    if (!pers.tags) return;
                    let tag_rank = pers.tags.filter(t => selectedDial.template.data.meta_data.tags.includes(t)).map(tag => selectedDial.template.data.meta_data.tags.indexOf(tag));
                    if ((pers.tags || []).some(tag => tag === "all" || selectedDial.template.data.meta_data.tags.includes(tag))) {
                        perspective_filters[l.id].push([k, Math.min(...tag_rank)]);
                    }
                })
            })
            Object.keys(perspective_filters).map(l => {
                perspective_filters[l] = perspective_filters[l].sort((a,b)=>a[1]-b[1]).map(v => v[0]);
            })
        }
        this.setState({
            filteredDashTiles: tagged.filter(f => {
                f = f.template.data;
                if(f.meta_data === undefined){
                    return false;
                }
                if(f.meta_data.primary === true){
                    return true;
                }
            }),
            perspectiveFilters:perspective_filters,
            selectedDial: dial_id === this.state.selectedDial ? null : dial_id
        })
    }

    handleBaseChange = (e) => {
        this.setState({
            selectedBaseScenario: e.target.value
        })
    }

    handleSecondaryChange = (e) => {
        this.setState({
            selectedSecondaryScenario: e.target.value
        })
    }

    renderScenarioSelect = () => {
        return <div>
            <FormControl style={{minWidth:"200px", backgroundColor:"white", pointerEvents:"auto"}}>
                <InputLabel>Base Scenario</InputLabel>
                <Select
                    onChange={this.handleBaseChange}
                    value={this.state.selectedBaseScenario}>
                    <MenuItem value={null}>
                        <em>None</em>
                    </MenuItem>
                    {
                        this.props.scenarios.map(s => {
                            return <MenuItem value={s.id} key={s.id}>
                                {s.name}
                            </MenuItem>
                        })
                    }
                </Select>
            </FormControl>
            <FormControl style={{minWidth:"200px", backgroundColor:"white", pointerEvents:"auto"}}>
                <InputLabel>Compare Scenario</InputLabel>
                <Select
                    value={this.state.selectedSecondaryScenario}
                    onChange={this.handleSecondaryChange}>

                    <MenuItem value={null}>
                        <em>None</em>
                    </MenuItem>
                    {
                        this.props.scenarios.map(s => {
                            if(s.id === this.state.selectedBaseScenario){
                                return "";
                            }
                            return <MenuItem value={s.id} key={s.id}>
                                {s.name}
                            </MenuItem>
                        })
                    }
                </Select>
            </FormControl>
        </div>
    }

    render() {
        let active_dash_tiles_id = this.state.active_dash_tiles.map(i=>i.id)
        const summary = this.state.active_dash_tiles.filter(f => {
            return f.template.data.meta_data?.summary
        })
        const drawer_width = 30;
        let selectedDial = summary.find(f => f.id === this.state.selectedDial)
        const filtered_layers = this.props.layers.filter(l => {
            if(selectedDial === undefined){
                return false;
            }
            return (l.data.tags||[]).some(t =>
                t === "all" || selectedDial.template.data.meta_data.tags.includes(t)
            );
        })


        const primary_summary = summary.filter(f => f.template.data.meta_data?.summary && f.template.data.meta_data?.primary);
        const secondary_summary = summary.filter(f => f.template.data.meta_data?.summary && !f.template.data.meta_data?.primary);
        return (
            <div>
                <div style={{position:"absolute", width:"100%", height:"100%", pointerEvents: "none", overflow:"hidden"}}>
                    <div style={{position:"relative", width:(100 - drawer_width) + "%", height:"100%",}}>

                        <Collapse in={this.state.topOpen}>
                            <div style={{
                                position:"absolute",
                                display:"flex",
                                flexDirection:"row",
                                overflowY: "hidden",
                                maxWidth:"100%",
                                height:"150px",
                                paddingBottom:"20px",
                                backgroundColor: "white",
                                pointerEvents: "auto"
                            }}>

                                {
                                    primary_summary.map(f => {
                                        return <DialWrapper
                                            selected={f.id === this.state.selectedDial}
                                            onClick={_=>this.handleDialClick(f.id)}
                                            style={{width:"150px", height:"150px", flexShrink:"0"}}
                                            areas={this.props.areas}

                                            scenario={this.props.context.primaryScenario}
                                            secondaryScenario={this.props.context.secondaryScenario}
                                            chart_props={f}
                                        />
                                    })
                                }
                            </div>
                        </Collapse>

                        <Collapse in={this.state.bottomOpen}>
                            <div style={{
                                position:"absolute",
                                display:"flex",
                                flexDirection:"row",
                                overflowY: "hidden",
                                bottom: 0,
                                maxWidth:"100%",
                                height:"150px",
                                paddingBottom:"20px",
                                backgroundColor: "white",
                                pointerEvents: "auto"
                            }}>
                                {
                                    secondary_summary.map(f => {
                                        return <DialWrapper
                                            onClick={_=>this.handleDialClick(f.id)}
                                            style={{width:"150px", height:"150px", flexShrink:"0"}}
                                            areas={this.props.areas}
                                            scenario={this.props.context.primaryScenario}
                                            secondaryScenario={this.props.context.secondaryScenario}
                                            chart_props={f}
                                        />
                                    })
                                }
                            </div>
                        </Collapse>
                    </div>
                    <div style={{
                        top:0,
                        minWidth:"300px",
                        width:"20%",
                        marginRight:"5px",
                        pointerEvents:"auto",
                        position:"absolute",
                        transition:"225ms cubic-bezier(0, 0, 0.2, 1) 0ms all",
                        right:this.state.selectedDial !== null ? drawer_width + "%" : 0}}>
                        {
                            <Legend
                                context={this.props.context}
                                perspective_filters={this.state.perspectiveFilters} layers={this.state.selectedDial !== null ? this.props.layers
                                .filter(l => {
                                    return l.scenario_id === this.props.context.primaryScenario?.id;
                                }) : []}/>
                        }
                    </div>
                    <Slide direction={"up"} in={this.state.charts_modal_open}>
                        <div style={{position:"absolute", top: 0, width:"100%", pointerEvents:"auto", height:"100%", zIndex:1200, transformStyle: "preserve-3d"}}>
                            <ChartsModal
                                selected_dial={this.props.dash_tiles.find(f => f.id === this.state.selectedDial)}
                                dash_tiles={this.props.dash_tiles}
                                open={this.state.charts_modal_open}
                                onClose={_=>{
                                    this.setState({charts_modal_open: false})
                                    this.props.onAnalysisEvent(false);
                                }}/>
                        </div>
                    </Slide>

                    <ChartDrawer
                        layers={filtered_layers}
                        width={drawer_width + "%"}
                        onExpandClick={_=>{
                            this.setState({charts_modal_open: true})

                            this.props.onAnalysisEvent(true);
                        }}
                        dashTiles={this.state.filteredDashTiles}
                        open={this.state.selectedDial !== null}
                        context={this.props.context}
                        selectedDial={summary.find(f => f.id === this.state.selectedDial)}/>
                </div>

            </div>
        );
    }
}


Overview.propTypes = {
    context: PropTypes.object,
    scenario: PropTypes.object,
    geojsons: PropTypes.array,
    setMapCallback: PropTypes.func.isRequired,
    setLayersCallback: PropTypes.func,
    geojson_filter: PropTypes.array,
    forceChart: PropTypes.bool,
    areas: PropTypes.array,
    onAnalysisClose: PropTypes.func
}


const mapStateToProps = (state, ownProps) => {
    return {
        project: state.project,
        geojsons: state.geojsons,
        layers: state.layers,
        dash_tiles: state.dash_tiles.map(dash_tile => {
            return {
                ...dash_tile,
                template: state.dash_tile_templates.find(dtt => dtt.id === dash_tile.template_id)
            }
        }),
        scenarios: state.scenarios,
        dash_tile_templates: state.dash_tile_templates,
    }
}

const mapDispatchToProps = (dispatch) => {

    return {
        runQueries: (areas) => {
            dispatch(runQueriesWithAreas(areas));
        },
        toggleLayers: (layers) => {
            dispatch(toggleDisplayLayers(layers.map(l=>l.id)))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Overview);
