


const query = (state, action) => {
    switch (action.type) {
        case 'QUERY_INIT':{
            return {
                id: action.id,
                data: action.query_data,
                query_prototype_id: action.query_prototype_id,
            }
        }
        default:
            return state;
    }
}

const queries = (state=[], action) => {

    switch (action.type) {
        case 'QUERY_INIT':{
            return action.queries.map((data) => query({}, {type: action.type, ...data}))
        }
        default:
            return state;
    }
}

export default queries;