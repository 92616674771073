/**
 * Created by User on 28/08/2020
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import Dial from "./Dial";
import IconMap from "../../icons/icons"
import QueryHandler from "../../QueryHandler";
import {CircularProgress} from "@material-ui/core";
import LoadingDial from "./LoadingDial";
import ErrorBoundary from "../ErrorBoundary";
import ErrorDial from "./ErrorDial";

class DialWrapper extends Component {

    constructor(props) {
        super(props);
        this.state = {
            hovered: false,
        };
    }

    queryUpdate = (data) => {
        if(Object.keys(data).length === 0){
            this.setState({
                query_data:[],
                all_loaded: false,
            });
            return;
        }
        let datasets = [];
        let areas = this.props.areas || [];
        let occ = (l, s) => l.reduce((acc, v) => (v === s ? 1 : 0) + acc, 0)
        let existing_names = [];
        let all_loaded = true;
        let data_sources = [];
        if(areas.length === 0){
            data_sources = this.props.scenarios.map(s => {

                existing_names.push(s.name);
                let n = occ(existing_names, s.name) - 1;
                return {
                    scenario: s,
                    name: s.name + (n !== 0 ? ` - (${n})` : "")
                }
            });
        }else {
            data_sources = this.props.scenarios.flatMap(
                scenario => this.props.areas.map(area => {
                    existing_names.push(scenario.name + " - " + area.name);

                    let n = occ(existing_names, scenario.name) - 1;
                    return {
                        scenario,
                        area,
                        name: scenario.name + (n === 0 ? ` - (${n})` : "") + " - " + area.name,
                    }
                })
            )
        }

        data_sources
            .map(ds => {
                if(ds.scenario.status !== 7){
                    // datasets.push({
                    //     name: ds.name,
                    //     queries: [],
                    // })

                } else {
                    datasets.push({
                        name: ds.name,
                        scenario_id: ds.scenario.id,
                        queries: this.props.queries.map(query => {
                            let query_obj = data[query.id + "." + ds.scenario.performance_assessment_id + "." + (ds.area ? ds.area.id : null)]

                            if (!query_obj) {
                                all_loaded = false;
                                return {
                                    successful: false,
                                    running: false,
                                    data: null,
                                    query_prototype: null
                                }
                            } else if (query_obj.running) {
                                all_loaded = false;
                                return {
                                    successful: false,
                                    running: true,
                                    data: query_obj.data,
                                    query_prototype: query_obj.query_prototype,
                                }
                            }
                            return {
                                successful: query_obj.successful,
                                running: query_obj.running,
                                data: query_obj.result,
                                query_prototype: query.query_prototype,
                            }
                        })
                    });
                }
            })

        this.setState({
            query_data:datasets,
            all_loaded,
        })
    }

    componentDidMount() {
        QueryHandler.registerListener(this.queryUpdate)
    }

    componentWillUnmount() {
        QueryHandler.removeListener(this.queryUpdate)
    }

    handleMouseEnter = () => {
        this.setState({
            hovered: true
        })
    }

    handleMouseLeave = () => {

        this.setState({
            hovered: false
        })
    }

    getValue = (query_data) => {
        const template_data = this.props.chart_props.template.data;
        let main_data_key = query_data.queries[0].query_prototype.query_data.main_data_key;
        let value = query_data.queries[0].data[0][main_data_key]
        if (template_data.meta_data.units === "%")
            value = value * 100
        return value
    }

    render() {
        const template_data = this.props.chart_props.template.data;
        const query_data = this.state.query_data;
        if (!query_data || query_data.length === 0) {
            return <div style={{
                width: "100%", height: "100%",
                ...this.props.style
            }}>
                <LoadingDial/>
            </div>
        }
        let all_complete = query_data.reduce((prev, qd) => {
            return !qd.queries[0].running && prev
        }, true);
        let any_errors = query_data.reduce((prev, qd) => {
            return !qd.queries[0].successful || prev
        }, false);
        if (!all_complete) {
            return <div style={{
                width: "100%", height: "100%",
                ...this.props.style
            }}>
                <LoadingDial/>
            </div>
        } else if (any_errors) {
            return <div style={{
                width: "100%", height: "100%",
                ...this.props.style
            }}>
                <ErrorDial/>
            </div>
        }

        let value = 0;
        let progress = 0;
        let arrow = 0;
        if (this.props.scenario !== null && this.props.secondaryScenario === null) {
            const src = query_data.find(qd => qd.scenario_id === this.props.scenario.id);
            value = this.getValue(src);
            if(value === null){
                value = 0;
            }
            if (value === 0) {
                progress = 0;
            } else {
                progress = Math.abs(Math.log(Math.abs(value))) / Math.abs(value);
            }
        } else if (this.props.scenario !== null && this.props.secondaryScenario !== null) {
            const src1 = query_data.find(qd => qd.scenario_id === this.props.scenario.id);
            const src2 = query_data.find(qd => qd.scenario_id === this.props.secondaryScenario.id);
            let value1 = this.getValue(src1);
            let value2 = this.getValue(src2);

            if(value1 === null){
                value1 = 0.0;
            }
            if(value2 === null){
                value2 = 0;
            }
            value = Math.abs(value2 - value1);
            arrow = Math.sign(value2 - value1);
            if (Math.abs(value1) + Math.abs(value2) === 0) {
                progress = 0;
            } else {
                progress = Math.abs(Math.abs(value2) - Math.abs(value1)) / (Math.abs(value1) + Math.abs(value2));
            }
        }

        let units = template_data.meta_data.units;
        if (units.includes("'000")) {
            if (value > 1000000000) {
                value = value / 1000000000
                units = units.replace("'000", "'000,000,000")
            } else if (value > 1000000) {
                value = value / 1000000
                units = units.replace("'000", "'000,000")
            } else if (value > 1000)
                value = value / 1000
             else
                units = units.replace("'000", "")
        }


        return <div
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}
                onClick={this.props.onClick}
                style={{width:"100%", height:"100%",
                    cursor:"pointer",
                    backgroundColor: (this.props.selected || this.state.hovered) ? "rgba(200, 220, 255, 0.5)" : "rgba(255, 240, 240, 0)",
                    ...this.props.style}}>
            <Dial
                value={value.toFixed(1)}
                progress={progress}
                arrow={arrow}
                units={units}
                text={template_data.name}
                icon={IconMap[template_data.meta_data.icon]}
            />
        </div>;
    }
}


DialWrapper.propTypes = {
    chart_props: PropTypes.object,
    scenario: PropTypes.object,
    secondaryScenario: PropTypes.object,
    areas: PropTypes.array,
    onClick: PropTypes.func,
    selected: PropTypes.bool
}

const mapStateToProps = (state, ownProps) => {
    return {
        scenarios: state.scenarios,
        query_prototypes: ownProps.chart_props.data.queries.map(id => {
            let query = state.queries.find(q => q.id === id);
            return state.query_prototypes.find(q => q.id === query.query_prototype_id)
        }),
        queries: ownProps.chart_props.data.queries
            .map(f => {
                let query = state.queries.find(q => q.id === f);
                return {
                    ...query,
                    query_prototype: state.query_prototypes.find(q => q.id === query.query_prototype_id)
                }
            })
    }
}

const mapDispatchToProps = (dispatch) => {

    return {}
}


export default connect(mapStateToProps, mapDispatchToProps)(DialWrapper);
