/**
 * Created by User on 29/08/2020
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Drawer from "@material-ui/core/Drawer";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import {Grid} from "@material-ui/core";
import DashboardTile from "../DashboardTile";
import Slide from "@material-ui/core/Slide";
import FullscreenIcon from "@material-ui/icons/Fullscreen"
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import CloseIcon from "@material-ui/icons/Close";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Switch from "@material-ui/core/Switch";
import LayerListItem from "./LayerListItem";
import {clearState} from "../../actions/global_actions";
import {loadProject} from "../../actions/project_actions";
import {createScenario} from "../../actions/scenario_actions";
import {openHelp} from "../../actions/help_actions";
import {connect} from "react-redux";
import {toggleDisplayLayers} from "../../actions/layer_actions";

class ChartDrawer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            cached_dial: null,
            selectedTab: 0,
        };
    }



    componentDidUpdate(prevProps, prevState, snapshot) {
        const inou = (v) => v === null || v === undefined
        if(inou(prevProps.selectedDial) && !inou(this.props.selectedDial)){
            this.setState({
                cached_dial: this.props.selectedDial,
            })
        }else if(!inou(prevProps.selectedDial) && !inou(this.props.selectedDial) && prevProps.selectedDial.id !== this.props.selectedDial.id){
            this.setState({
                cached_dial: this.props.selectedDial,
            })
        }
    }


    componentDidMount() {
    };

    componentWillUnmount() {
    };

    renderDashTiles = () => {

        if(this.props.dashTiles === null || this.props.dashTiles === undefined){
            return ""
        }

        return this.props.dashTiles.map(d => {
            return (<div key={d.id} style={{paddingLeft:"10px", paddingRight:"10px", paddingTop:"10px"}}>
                <DashboardTile
                    areas={this.state.selectedAreaObjects}
                    context={this.props.context}
                    dash_tile={d}/>
            </div>)
        })
    }

    renderLayers = () => {

        if(this.props.layers === null || this.props.layers === undefined){
            return ""
        }

        return <div>
            {this.props.layers
                .filter(l => {
                    return l.scenario_id === this.props.context.primaryScenario?.id;
                }).map(l => {
                return <LayerListItem
                    secondary_text={this.props.context.primaryScenario?.name||""}
                    layer={l}
                    onClick={_=>this.props.toggleLayer(l)}/>
            })}
        </div>
    }

    handleTabChange = (_, v) => {
        this.setState({
            selectedTab: v
        })
    }

    renderContent = () => {
        if(this.state.cached_dial === undefined || this.state.cached_dial === null){
            return "";
        }else{

            const name = this.state.cached_dial.template.data.name;

            return <div >
                <div style={{position:"sticky", zIndex:1100, backgroundColor: "white", top:0}}>
                    <div style={{display:"flex"}}>
                        <Typography style={{paddingLeft:"10px", paddingTop:"10px", paddingRight:"5px"}}>{name}</Typography>
                        <div style={{flex:"1"}}/>

                        <Tooltip title={"fullscreen"}>
                            <IconButton size={"small"} style={{marginRight:"10px"}} onClick={this.props.onExpandClick} >
                                <FullscreenIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                    <Divider variant={"fullWidth"}/>
                    <div>
                        <Tabs
                            onChange={this.handleTabChange}
                            value={this.state.selectedTab}
                            indicatorColor={"primary"}
                            variant={"fullWidth"}>
                            <Tab label={"Charts"}/>
                            <Tab label={"Layers"}/>
                        </Tabs>
                    </div>
                    <Divider variant={"fullWidth"}/>

                </div>
                <div style={{padding:"5px"}}>
                {
                    this.state.selectedTab === 0 &&
                    this.renderDashTiles()
                }
                {
                    this.state.selectedTab === 1 && this.renderLayers()
                }
                </div>
            </div>
        }
    }

    render() {
        return <Slide unmountOnExit mountOnEnter direction={"left"}  in={this.props.open}>
                <div style={{
                    top:0,
                    position:"absolute",
                    pointerEvents:"auto",
                    transformStyle: "preserve-3d",
                    overflowY:"hidden",
                    right:0,
                    boxSizing:"border-box",
                     width:this.props.width, height:"100%"}}>
                    <div style={{height:"100%", width:"100%", overflowY:"auto", backgroundColor:"white",}}>
                        {this.renderContent()}
                    </div>
                </div>
        </Slide>;
    }
}


ChartDrawer.propTypes = {
    onExpandClick: PropTypes.func,
    selectedDial: PropTypes.object,
    dashTiles: PropTypes.array,
    width: PropTypes.string,
    context: PropTypes.object,
    layers: PropTypes.array,
}



const mapStateToProps = (state, ownProps) => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => {

    return {
        toggleLayer: (layer) => {
            dispatch(toggleDisplayLayers([layer.id] ))
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ChartDrawer);

