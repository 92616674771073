import React from 'react'
import PropTypes from "prop-types"
import ReactEcharts from "echarts-for-react";
import moment from "moment";


class TimeSeriesBar extends React.Component {


    constructor(props) {
        super(props);

    }

    getGraph = () => {

        let main_data_key = this.props.query_data[0].queries[0].query_prototype.query_data.main_data_key;
        let units = this.props.query_data[0].queries[0].query_prototype.query_data.field_information[main_data_key].units

        let exits = -1
        let longest_vector = -1
        for (let i=0; i < this.props.query_data.length; i++) {
            if (this.props.query_data[i].queries[0].data != null) {
                if (this.props.query_data[i].queries[0].data.length > longest_vector) {
                    longest_vector = this.props.query_data[i].queries[0].data.length;
                    exits = i;
                }
            }
        }

        if (exits < 0)
            return null

        let timesteps = this.props.query_data[exits].queries[0].data.map(v => {
                return moment.unix(v.dt).format("DD/MM/YYYY");
        })

        return {
            tooltip:{confine: true},

            toolbox: {
                feature: {
                    dataZoom: {
                        title:{
                            zoom:"zoom",
                            back:"restore zoom"
                        }
                    },
                    brush: {
                        title:{
                            lineX: "range",
                            clear: "clear range"
                        },
                        type: ['lineX', 'clear']
                    },
                    dataView: {title: 'data', lang: ['data', 'close', 'refresh'], readOnly: true,},
                    saveAsImage: {backgroundColor: '#FFFFFF', title: 'save'},

                }
            },
            brush: {
                xAxisIndex: 'all',
                brushLink: 'all',
                outOfBrush: {
                    colorAlpha: 0.1
                }
            },dataZoom: [
                {
                    show: true,
                    bottom: '25',
                },
                {

                    type: 'inside',

                },
            ],
            legend: {
                right: 0,
                bottom: 0,
                type: 'scroll',
                orient: 'horizontal',
                data: this.props.query_data.map((dataset,i) => dataset.name)
            },
            grid: {
                top:30,
                left:10,
                right:0,
                bottom: 60,
                containLabel: true
            },
            xAxis: [{
                type: 'category',
                data: timesteps
            }],
            yAxis : [
                {
                    name: units,
                    type:"value",
                }
            ],
            series : this.props.query_data.filter((dataset,i) => {
                return dataset.queries[0].data != null;

            }).map((dataset,i) => ({
                name: dataset.name,
                type: 'bar',
                xIndex: i,
                data: dataset.queries[0].data.map(v=>v[main_data_key])
            })),
        };
    };



    render() {

        if(this.props.query_data.length === 0 || this.props.query_data[0].queries.length === 0){
            return <div></div>
        }
        let options = this.getGraph()
        if (options == null)
            return <div></div>
        return (
            <div>

                <ReactEcharts
                    option={this.getGraph()}
                    lazyUpdate={true}
                    notMerge={true}
                    style={{height: '270px', width: '95%'}}
                    className='react_for_echarts' />
            </div>

        )
    }
}


TimeSeriesBar.propTypes = {}

export default TimeSeriesBar;
