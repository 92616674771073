


const scenario_comparison_template = (state, action) => {
    switch (action.type) {
        case 'SCENARIOCOMPARISONTEMPLATES_INIT':{
            return {
                id: action.id,
                data: action.data,
            }
        }
        default:
            return state;
    }
}

const scenario_comparison_templates = (state=[], action) => {

    switch (action.type) {
        case 'SCENARIOCOMPARISONTEMPLATES_INIT':{
            return action.templates.map((data) => scenario_comparison_template({}, {type: action.type, ...data}))
        }
        default:
            return state;
    }
}

export default scenario_comparison_templates;
