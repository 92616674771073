import * as io from "socket.io-client";
import {updateScenario} from "./actions/scenario_actions";
import {initProjectDataStructure, runQueries} from "./actions/project_actions";
import {socket_url} from "./config";
import QueryHandler from "./QueryHandler";



const socket_context = {
    listeners: {
        "scenario_updates":[],
        "sim_updates":[],
        "map_updates":[],
        "query_updates":[],
    }
};

export const inject_store = (store) => {
    socket_context.store = store;
}

export const add_listener = (event, listener) => {
    socket_context.listeners[event].push(listener);
}

export const initialize_socket = (project_id) => {
    const token = localStorage.getItem("access_token")
    const socket = io.connect(`${socket_url}?project_id=${project_id}&token=${token}`)

    socket_context.socket = socket;

    socket.on("scenario_updates", data => {
        let scen_id_map = {};
        data = data.data;
        data.map(dp => {
            scen_id_map[dp.scenario_id] = dp;
        })

        let updates = Object.keys(scen_id_map).map(k => {
            let temp = scen_id_map[k];
            return socket_context.store.dispatch(updateScenario(scen_id_map[k].scenario_id, {
                current_stage_progress:0,
                status: temp.status,
            }));
        })

        Promise.all(updates).then(_ => {
            socket_context.listeners["scenario_updates"].map(
                f => f(data)
            )
            if(Object.keys(scen_id_map).some(k => {
                let scen = scen_id_map[k];
                return scen.status === 7;
            })){
                let state = socket_context.store.getState();

                let handler = d => {
                    if(!d.successful){
                        return;
                    }
                    let views = {};

                    d.data.map(d => {
                        if (!views.hasOwnProperty(d.view_name)) {
                            views[d.view_name] = [];
                        }
                        views[d.view_name].push(d);
                    })

                    socket_context.store.dispatch(initProjectDataStructure(views));
                }
                QueryHandler.registerTypeListener("schema", handler)
                QueryHandler.submitNonstandardQuery(
                    "SELECT view_name, attribute_name, data_type from dynamind_table_definitions",
                    socket_context.store.getState().scenarios[0].baseline_id,
                    {
                        type:"schema",
                        project_id: state.project.id,
                    }
                ).then(handler);

                runQueries(state.project.id, state.query_prototypes, state.queries, state.scenarios)
            }
        })
    });

    socket.on("sim_updates", data => {
        data=data.data[0];
        socket_context.store.dispatch(updateScenario(data.scenario_id,{
            current_stage_progress: data.progress,
        }))

        socket_context.listeners["sim_updates"].map(
            f => f(data)
        )
    });

    socket.on("map_updates", data => {
        data = data.data;
        socket_context.listeners["map_updates"].map(
            f => f(data)
        )
    });

    socket.on("query_updates", data => {
        data = data.data;
        socket_context.listeners["query_updates"].map(
            f => f(data)
        )
    });
}
