/**
 * Created by User on 28/08/2020
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {ReactComponent as TreeIcon} from "../../icons/people.svg"

class Dial extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
    };

    componentWillUnmount() {
    };

    render() {

        const c = (a) => Math.cos(a);
        const s = (a) => Math.sin(a);
        const SA = Math.PI*0.775;
        const construct_svg = (angle, color="grey", thickness=0.01, radius=0.35) => {
            return <path
                strokeLinecap={"round"}
                stroke={color}
                fillOpacity={0}
                strokeWidth={thickness}
                d={`
                        M ${c(SA)*radius+0.5} ${s(SA)*radius+0.55 - 0.1}
                        A ${radius} ${radius} ${SA} ${angle<Math.PI ? 0 : 1} 1 ${c(SA+angle)*radius+0.5} ${s(SA+angle)*radius+0.55- 0.1}
                        
                    `}
            />
        }

        const construct_arrow = (direction) => {
            return <g>
                <line x1={0.94} y1={0.6} x2={0.94} y2={0.20} strokeWidth={0.015} stroke={"#2F4653"} >

                </line>
                {direction ?
                    <path d={"M0.88 0.53L0.94 0.6L0.999 0.53"} strokeWidth={0.015} stroke={"#2F4653"} fill={"none"}/> :
                    <path d={"M0.88 0.27L0.94 0.20L0.999 0.27"} strokeWidth={0.015} stroke={"#2F4653"} fill={"none"}/> }
                </g>
        }
        return <div style={{width:"100%", height:"100%"}}>
            <svg

                shapeRendering="geometricPrecision"
                viewBox={"0 0 1 1"}
            >
                {construct_svg(Math.PI*1.45, "grey", 0.04, 0.35)}
                {construct_svg(Math.PI*1.45*this.props.progress, "#BA4339", 0.04, 0.35)}
                <g  transform={` translate(${0.43} ${0.65}) scale(0.15)`}>
                    {React.createElement(this.props.icon)}
                </g>
                <text fill={"#2F4653"} fontFamily={"Helvetica"} letterSpacing={"-0.05em"} fontWeight={470} textAnchor={"middle"} fontSize={"0.26"}  transform={"translate(0.5, 0.47)"} >{this.props.value}</text>
                <text fill={"#6A9DA5"}  fontFamily={"Helvetica"} fontWeight={500} textAnchor={"middle"} fontSize={"0.1"}  transform={"translate(0.5, 0.58)"} >{this.props.units}</text>
                <text fill={"#2F4653"} fontFamily={"Helvetica"} textAnchor={"middle"} fontSize={"0.1"}  transform={"translate(0.5, 0.95)"} >{this.props.text}</text>
                {this.props.arrow !== 0 && construct_arrow(this.props.arrow < 0)}
            </svg>
        </div>;
    }
}


Dial.propTypes = {
    value: PropTypes.number,
    progress: PropTypes.number,
    units: PropTypes.string,
    arrow: PropTypes.number,
    icon: PropTypes.object,
    text: PropTypes.string,
}


export default Dial;
