import React from 'react'
import PropTypes from "prop-types"
import DashboardControlPanel from "../Components/DashboardControlPanel";
import DashboardPanel from "../Components/DashboardPanel";
import ScenarioStateNotifications from "../Components/ScenarioStateNotifications";




class Dashboard extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return <div>
            <DashboardControlPanel setLayersCallback={this.props.setLayersCallback} />
            <DashboardPanel />
            <ScenarioStateNotifications/>
        </div>;
    }
}


Dashboard.propTypes = {
    mapRef: PropTypes.object,
    setLayersCallback: PropTypes.func.isRequired,
}

export default Dashboard;