import React from 'react'
import PropTypes from "prop-types"
import ListItemText from "@material-ui/core/ListItemText/index";
import ListItem from "@material-ui/core/ListItem/index";
import Collapse from "@material-ui/core/Collapse/index";
import List from "@material-ui/core/List/index";
import ListItemIcon from "@material-ui/core/ListItemIcon/index";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import {Typography} from "@material-ui/core/index";
import Divider from "@material-ui/core/Divider/index";

class ScenarioListItem extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
        }

    }

    handleClick = () => {
        this.setState({
            open: !this.state.open,
        })
    }

    render() {
        return <div>
            <Divider/>
            <ListItem dense button onClick={this.handleClick}>
                <ListItemText
                    disableTypography
                    primary={<Typography variant={"body2"}>
                        {this.props.scenario.name}
                        </Typography>
                    }
                    secondary={<Typography style={{fontSize:"0.6em", color:'#666'}}>
                        {this.props.scenario.status_text +
                            ([1,7].includes(this.props.scenario.status) ? "": " - (" + this.props.scenario.current_stage_progress + "%)")
                        }
                    </Typography> }

                />

                {this.state.open ? <ExpandLess /> : <ExpandMore />}

            </ListItem>
            <Divider/>
            <Collapse in={this.state.open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItem onClick={this.props.selectCallback}  button dense>
                        <ListItemText primary="Select" />
                    </ListItem>
                    <Divider/>
                    <ListItem disabled={this.props.index === 0} button dense onClick={this.props.editCallback}>
                        <ListItemText primary="Edit" />
                    </ListItem>
                </List>

                <Divider/>
            </Collapse>
        </div>;
    }
}


ScenarioListItem.propTypes = {
    scenario: PropTypes.object.isRequired,
    editCallback: PropTypes.func.isRequired,
    selectCallback: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
};

export default ScenarioListItem;