import React from 'react'
import PropTypes from "prop-types"
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import {Collapse} from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import IconButton from "@material-ui/core/IconButton";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ExpandLess from "@material-ui/icons/ExpandLess";
import Checkbox from "@material-ui/core/Checkbox";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Switch from "@material-ui/core/Switch";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";

class LayerListItem extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            hovered: false,
        }
    }


    highlight = () => {
        this.setState({
            hovered: true,
        })
    }

    lowlight = () => {
        this.setState({
            hovered:false,
        })
    }


    render() {
        return <div>
                <ListItem
                    button
                    ContainerComponent={"div"}
                    style={{
                        backgroundColor: this.state.hovered ? "white" :
                            (this.props.active ? "white" : "#f5f5f5"),
                        cursor: "pointer",
                        alignItems: "center",
                        justifyItems: "center",
                    }}
                    onClick={this.props.onClick}
                    onMouseEnter={this.highlight}
                    onMouseLeave={this.lowlight}
            >

                    {/*<ListItemIcon><CheckCircleIcon
                            style={{paddingRight:"20px", display:"inline", color: this.props.active ?"#18981D":"#BCCCDC"}}/></ListItemIcon>*/}
                    <ListItemText style={{fontSize:"0.875rem"}} secondary={this.props.secondary_text} >{this.props.layer.data.display_name}</ListItemText>
                    <ListItemSecondaryAction><Switch checked={this.props.layer.display} color={"primary"} onClick={this.props.onClick}/></ListItemSecondaryAction>

            </ListItem>
        </div>;
    }
}


LayerListItem.propTypes = {
    expandable: PropTypes.bool,
    active: PropTypes.bool,
    button: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    layer: PropTypes.object,
    secondary_text: PropTypes.string,
}

export default LayerListItem;