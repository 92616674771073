import React from 'react'
import PropTypes from "prop-types"
import FormControl from "@material-ui/core/FormControl";
import {Select} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import WarningIcon from "@material-ui/icons/Warning"


class RequiredSelect extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return <FormControl style={this.props.style}>
            <Select

                onChange={this.props.onChange || (_ => {
                })} value={this.props.value} style={{width: "100%"}}>
                <MenuItem value={-1}>
                    None
                </MenuItem>
                {
                    this.props.children
                }
            </Select>

            <FormHelperText style={{
                color: "#c2ae45",
                visibility: this.props.value === -1 ? "visible" : "hidden",
                display:"flex",
                flexDirection:"row",
                alignItems:"center",
            }}>
                <WarningIcon/><div style={{flex:1, textAlign:"center"}}>{this.props.warningText}</div>
            </FormHelperText>
        </FormControl>;
    }
}


RequiredSelect.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.any.isRequired,
    warningText: PropTypes.string,
    style: PropTypes.object,
}

export default RequiredSelect;
