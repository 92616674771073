import React from 'react'
import PropTypes from "prop-types"
import BarChart from "./BarChart";
import Table from "@material-ui/core/Table";
import {TableHead} from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Card from "@material-ui/core/Card";
import MaterialTable from "material-table";


class ResultTable extends React.Component {

    formatEntry = (c) => {
        return typeof c === 'number' ? c.toFixed(2) : c;
    }
    render_table = () => {

        if (!this.props.all_loaded) {
            let successful = this.props.query_data.flatMap(
                qd => qd.queries.map(q => q.successful)
            );
            if(successful.some(f => !f)){
                return <div>One or more queries encountered an error</div>
            }
            return (<div> loading... </div>)
        }

        let columns = [];
        this.props.query_prototypes.map(prototype => {
            prototype.query_data.column_keys.map(kc => {
                columns.push(kc)
            });
        })

        let table_data = [];


        this.props.query_data.map(qd => {
            let scen_data = []
            qd.queries[0].data.map(d => scen_data.push({"scenario": qd.name}));
            qd.queries.map(q => {
                q.data.map((data,i) => {
                    for(let j in data){
                        if (columns.includes(j)){
                            scen_data[i][j] = this.formatEntry(data[j])
                        }
                    }
                })
            })
            table_data = table_data.concat(scen_data);
        })


        columns = [{"title":"scenario", "field":"scenario",  defaultGroupOrder: 0},...columns.map(f => ({"title": f, "field":f}))];

        console.log("table_data", table_data)

            return (
                <div style={{ maxWidth: "100%" }}>
                    <MaterialTable
                        options={{
                            search: false,
                            exportButton: true,
                            showTitle: false,
                            grouping: true,
                            padding: 'dense',
                            paging: false,
                            filtering: false,
                            toolbar: true

                            // headerStyle: { padding: '0.3em'},

                        }}
                        columns={columns}
                        data={table_data}

                    />
                </div>
            );

    };


    render() {

        return (
            <div style={{marginLeft: "5px", width:"100%", overflowX:"auto", overflowY:"auto", maxHeight:"330px"}}>

                {this.render_table()}
            </div>
        )
    }
}


ResultTable.propTypes = {
    query_data: PropTypes.any,
    reference_scenario: PropTypes.any
}

export default ResultTable;
