

const project = (state={}, action) => {
    switch (action.type) {
        case 'PROJECT_INIT':{
            return {
                id: action.id,
                name: action.name,
                case_study_area_id: action.case_study_area_id,
                data_structure: null,
            }
        }
        case 'PROJECT_INIT_DATA_STRUCTURE':
            return {
                ...state,
                data_structure: action.data_structure,
            }
        default:
            return state;
    }
}

export default project;
