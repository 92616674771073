import $ from "jquery"
import {fetch} from "whatwg-fetch";
import {dance4water_host} from "../config";



export const DANCE_GET = (url) => {
    return fetch(url, {
        method: "GET",
        mode:"cors",
        headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token")
        }
    }).then(res => res.json());
}


export const DANCE_GET_FILE = (url, download_filename="download.zip") => {
    return fetch(url, {
        method: "GET",
        mode:"cors",
        headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token")
        }
    }).then(response => {
        console.log(response);
        return response.blob()
    }).then(blob => {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            a.download = download_filename;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();
            a.remove();
        }

    );
}
export const DANCE_POST_FILE = (url, file) => {
    let form_data = new FormData();
    form_data.append("file", file);
    form_data.append("file_name", file.name);

    return fetch(url, {
        method: "POST",
        body: form_data,
        mode:"cors",
        headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token")
        }
    }).then(res => res.json());;
}

export const DANCE_POST = (url, data) => {
    return fetch(url, {
        method: "POST",
        body: JSON.stringify(data),
        mode:"cors",
        headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token")
        }
    }).then(res => res.json());
}


export const DANCE_PUT = (url, data, handlers) => {
    return fetch(url, {
        method: "PUT",
        mode:"cors",
        body: JSON.stringify(data),
        headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token")
        }
    }).then(res => res.json());
}

export const DANCE_DELETE = (url) => {
    return fetch(url, {
        method: "DELETE",
        mode:"cors",
        headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token")
        }
    }).then(res => res.json());
}



const getAssessmentModels = () => {
    return DANCE_GET(dance4water_host + "/assessment_models/")
}

const getAssessmentModelInitializationNodes = (ass_mod_id) => {
    return DANCE_GET(dance4water_host + "/assessment_models/"+ass_mod_id+"/init_node")
}

const loginUser = (username, password) => {
    return DANCE_POST(dance4water_host + "/user/login/", {username, password})
}

const registerUser = (username, password, crc_participant, signed_nda) => {
    return DANCE_POST(dance4water_host + "/user/register/", {username, password, crc_participant, signed_nda})
}

const requestReset = (username) => {
    return DANCE_POST(dance4water_host + "/user/request_reset/", {email:username})
}

const resetPassword = (username, token, password) => {
    return DANCE_POST(dance4water_host + "/user/reset_password/", {email:username, token, password})
}


const getProjects = () => {
    return DANCE_GET(dance4water_host + "/projects/");
}


const getProject = (id) => {
    return DANCE_GET(dance4water_host + "/projects/" + id);
}

const deleteProject = (id) => {
    return DANCE_DELETE(dance4water_host + "/projects/" + id);
}


const createProject = () => {
    return DANCE_POST(dance4water_host + "/projects/", {});
}

const createScenario = (parent, project_id, name) => {
    return DANCE_POST(dance4water_host + "/scenario/", {parent, project_id, name});
}

const getScenario = (id) => {
    return DANCE_GET(dance4water_host + "/scenario/" + id);
}

const updateScenario = (id, data) => {
    return DANCE_PUT(dance4water_host + "/scenario/" + id, data);
}

const getProjectLayers = (project_id) => {
    return DANCE_GET(dance4water_host + "/projects/"+project_id+"/layers");
}


const getProjectdata = (project_id) => {
    return DANCE_GET_FILE(dance4water_host + "/projects/"+project_id+"/data");
}

const getDataModelDataSources = (data_model_id) => {
    return DANCE_GET(dance4water_host + "/data_model/"+data_model_id+"/data_sources");
}


const getProjectScenarioComparisonTemplates = (project_id) => {
    return DANCE_GET(dance4water_host + "/projects/"+project_id+"/scenario_comparison_templates");
}

const createScenarioComparison = (project_id, template_id, scenarios) => {
    return DANCE_POST(dance4water_host + "/projects/"+project_id+"/scenario_comparison", {
        template_id: template_id,
        scenarios: scenarios,
    });
}

const getScenarioComparisons = (project_id) => {
    return DANCE_GET(dance4water_host + "/projects/"+project_id+"/scenario_comparison");
}


const createScenarioRevision = (scenario_id, nodes) => {
    return DANCE_POST(dance4water_host + "/scenario/" + scenario_id + "/nodes", nodes);
}

const executeScenario = (scenario_id) => {
    return DANCE_POST(dance4water_host + "/scenario/"+scenario_id+"/execute", {});
}

const uploadGeoJson = (project_id, geojson, name) => {
    return DANCE_POST(dance4water_host + "/geojson/", {project_id, geojson, name});
}

const createFilter = (project_id, name, data) => {
    return DANCE_POST(dance4water_host + "/filter/", {
        project_id: project_id,
        name: name,
        filter_data: data,
    });
}

const shareProject = (project_id, username) => {
    return DANCE_POST(dance4water_host + "/projects/" + project_id + "/share", {
        username: username,
    });
}

const updateProject = (project_id, data) => {
    return DANCE_PUT(dance4water_host + "/projects/" + project_id, data);
}

const updateProjectDataModel = (project_id, data) => {
    return DANCE_POST(dance4water_host + "/projects/" + project_id + "/data_model", data);
}


const setProjectModels = (project_id, model_ids) => {
    return DANCE_PUT(dance4water_host + "/projects/"+project_id+"/models", model_ids);
}

const getScenarioMakerNodes = () => {
    return DANCE_GET(dance4water_host + "/sm_node/?type=1");
}

const getRegionDataModels = (region_id) => {
    return DANCE_GET(dance4water_host + "/regions/"+region_id+"/data_models");
}

const getDataModelInitNode = (data_model_id) => {
    return DANCE_GET(dance4water_host + "/data_model/"+data_model_id+"/init_node");
}

const getDataModelAssessmentModels = (data_model_id) => {
    return DANCE_GET(dance4water_host + "/data_model/"+data_model_id+"/assessment_model");
}


const deleteGeojson = (area_id) => {
    return DANCE_POST(dance4water_host + "/geojson/" + area_id + "/delete");
}

const getProjectDataSources = (project_id) => {
    return DANCE_GET(dance4water_host + "/data_source/" + project_id);
}

const createProjectDataSources = (data_source_data) => {
    return DANCE_POST(dance4water_host + "/data_source/", data_source_data);
}

const setScenarioEnableStatus = (scenario_id, data) => {
    return DANCE_POST(dance4water_host + "/scenario/"+scenario_id+"/enabled_status", data);
}

const getUploads = (project_id) => {
    return DANCE_GET(dance4water_host + "/upload/" + project_id);
}

const getGeoJSONFilterPreset = (project_id) => {
    return DANCE_GET(dance4water_host + "/geojson_filter_preset/" + project_id);
}

const createUpload = (project_id, file) => {
    return DANCE_POST_FILE(dance4water_host + "/upload/" + project_id, file);
}

const createGeoJSONFilterPreset = (project_id, name, geojson, filters) => {
    return DANCE_POST(dance4water_host + "/geojson_filter_preset/", {project_id, name, geojson, filters} );
}


const downloadLayer = (scenario_id, layer_name) => {
    return DANCE_GET_FILE(dance4water_host + "/scenario/"+scenario_id+"/layer/"+layer_name, layer_name+"_download.geojson");
}

const getRegions = () => {
    return DANCE_GET(dance4water_host + "/regions/");
}


const dance_endpoint_injection = (store, func) => {

    return func.bind({
        getState: store.getState,
        dispatch: store.dispatch
    });
}


const noop = _=>{
    throw "Unimplemented api endpoint"
};
class DanceEndpoint {

    constructor(store, handlers) {
        this.get = dance_endpoint_injection(store, handlers.get || noop);
        this.put = dance_endpoint_injection(store, handlers.put || noop);
        this.post = dance_endpoint_injection(store, handlers.post || noop);
        this.delete = dance_endpoint_injection(store, handlers.delete || noop);
    }

}
let DanceAPI = {};

export const constructDanceAPI = (store) => {
    DanceAPI.login = new DanceEndpoint(store,{
        post:loginUser,
    });

    DanceAPI.register = new DanceEndpoint(store,{
        post:registerUser,
    });

    DanceAPI.request_reset = new DanceEndpoint(store,{
        post:requestReset,
    });

    DanceAPI.reset_password = new DanceEndpoint(store,{
        post:resetPassword,
    });

    DanceAPI.assessment_models = new DanceEndpoint(DanceAPI, {
        get:getAssessmentModels,
    });

    DanceAPI.assessment_models_init_node = new DanceEndpoint(DanceAPI, {
        get:getAssessmentModelInitializationNodes,
    });

    DanceAPI.regions = new DanceEndpoint(DanceAPI, {
        get:getRegions,
    });

    DanceAPI.projects = new DanceEndpoint(DanceAPI, {
        get: getProjects,
        post: createProject,

    });

    DanceAPI.project = new DanceEndpoint(DanceAPI, {
        get: getProject,
        put: updateProject,
        delete: deleteProject,
    });

    DanceAPI.upload = new DanceEndpoint(DanceAPI, {
        get: getUploads,
        post: createUpload,
    });

    DanceAPI.projectDataModel = new DanceEndpoint(DanceAPI, {
        post: updateProjectDataModel
    });

    DanceAPI.projectScenarioComparisonTemplates = new DanceEndpoint(DanceAPI, {
        get:getProjectScenarioComparisonTemplates,

    });

    DanceAPI.dataSources = new DanceEndpoint(DanceAPI, {
        get:getProjectDataSources,
        post:createProjectDataSources,

    });

    DanceAPI.scenarioComparison = new DanceEndpoint(DanceAPI, {
        get: getScenarioComparisons,
        post: createScenarioComparison,
    })

    DanceAPI.projectModels = new DanceEndpoint(DanceAPI, {
        put: setProjectModels
    });

    DanceAPI.geojson = new DanceEndpoint(DanceAPI, {
        post: uploadGeoJson
    });

    DanceAPI.filter = new DanceEndpoint(DanceAPI, {
        post: createFilter
    });

    DanceAPI.share = new DanceEndpoint(DanceAPI, {
        post: shareProject
    });

    DanceAPI.scenario = new DanceEndpoint(DanceAPI, {
        post: createScenario,
        put: updateScenario,
        get: getScenario,
    });

    DanceAPI.scenarioRevision = new DanceEndpoint(DanceAPI, {
        post: createScenarioRevision
    });

    DanceAPI.scenarioExecute = new DanceEndpoint(DanceAPI, {
        post: executeScenario
    });

    DanceAPI.scenarioMakerNodes = new DanceEndpoint(DanceAPI, {
        get: getScenarioMakerNodes
    });

    DanceAPI.layers = new DanceEndpoint(DanceAPI, {
        get: getProjectLayers
    });
    DanceAPI.projectData = new DanceEndpoint(DanceAPI, {
        get: getProjectdata
    });
    DanceAPI.geojson_filter_presets = new DanceEndpoint(DanceAPI, {
        get: getGeoJSONFilterPreset,
        post: createGeoJSONFilterPreset
    });

    DanceAPI.deleteGeojsons = new DanceEndpoint(DanceAPI, {
        post: deleteGeojson
    })

    DanceAPI.regionDataModels = new DanceEndpoint(DanceAPI, {
        get: getRegionDataModels
    });

    DanceAPI.dataModelInitNode = new DanceEndpoint(DanceAPI, {
        get: getDataModelInitNode
    });

    DanceAPI.dataModelDataSources = new DanceEndpoint(DanceAPI, {
        get: getDataModelDataSources
    });

    DanceAPI.dataModelAssessmentModel = new DanceEndpoint(DanceAPI, {
        get: getDataModelAssessmentModels
    });

    DanceAPI.scenarioEnableStatus = new DanceEndpoint(DanceAPI, {
        post: setScenarioEnableStatus
    });

    DanceAPI.layerDownload = new DanceEndpoint(DanceAPI, {
        get: downloadLayer
    });


}





export default DanceAPI;
