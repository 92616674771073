import React from 'react'
import PropTypes from "prop-types"
import {connect} from "react-redux";
import List from "@material-ui/core/List/index";
import Button from "@material-ui/core/Button/index";
import App from "../App";
import AppBar from "@material-ui/core/AppBar/index";
import Toolbar from "@material-ui/core/Toolbar/index";
import Typography from "@material-ui/core/Typography/index";
import Fab from "@material-ui/core/Fab/index";
import PlayIcon from "@material-ui/icons/PlayArrow";
import DynamicList from "./Views/DynamicList";
import Card from "@material-ui/core/Card";
import {CardHeader} from "@material-ui/core";
import CloudUpload from "@material-ui/icons/CloudUpload"
import CardContent from "@material-ui/core/CardContent";
import GetApp from "@material-ui/icons/GetApp";
import DenseCard from "./Views/DenseCard";
import SwapCallsIcon from '@material-ui/icons/SwapCalls';
import LayersIcon from "@material-ui/core/SvgIcon/SvgIcon";
import Box from "@material-ui/core/Box";
import {saveStringAsFile} from "../util/file_saver";
import IconButton from "@material-ui/core/IconButton";
import {openFileBrowser} from "../util/file_opener";

const styles = {
    panelInner: {
        width:"100%",
        height:"100%",
        flex:1,
        overflowY:"auto",

    },

    drawerList : {
        backgroundColor: "#D9E2EC",
        color:"#243B53",
        fontSize: "12px",
        fontVariant: "small-caps",
        fontWeight: "bold"

    }
}

class WorkflowNodePanel extends React.Component {

    constructor(props) {
        super(props);
    }

    renderWorkflowNodes = (node, index) => {
        console.log(node)
        if(!node.area  || node.area.type === "area") {
            return <DenseCard
                workflowCard
                expandable
                selectedColor={"rgba(120,255,252,0.25)"}
                selected={index === this.props.selectedNode}
                onSelect={_ => this.props.selectNodeCallback(index)}
                title={node.node_type.name}
                content={<Typography variant={"body2"}>
                    {node.area ? node.area.data.name : "no area selected"}
                </Typography>}
                actions={[
                    <Button
                        onClick={_ => this.props.deleteNodeCallback(index)}
                        style={{marginLeft: "auto"}}
                        variant={"outlined"}
                        color={"secondary"}>
                        delete
                    </Button>
                ]}
            />
        }else {
            return <DenseCard
                workflowCard
                expandable
                selectedColor={"rgba(120,255,252,0.25)"}
                selected={index === this.props.selectedNode}
                onSelect={_ => this.props.selectNodeCallback(index)}
                title={node.node_type.name}
                content={<Typography variant={"body2"}>
                    {node.area ? node.area.data.name + "(filter)" : "no area selected"}
                </Typography>}
                actions={[
                    <Button
                        onClick={_ => this.props.deleteNodeCallback(index)}
                        style={{marginLeft: "auto"}}
                        variant={"outlined"}
                        color={"secondary"}>
                        delete
                    </Button>
                ]}
            />
        }

    }

    downloadWorkflow = () => {

        let extractParameters = (node_type) => {
            const extract = (d,o) => {
                if(d.length === 0){
                    return;
                }
                d.map(data => {
                    if(data.type === "group"){
                        extract(data.fields, o)
                    }else {
                        if(data.parameter === undefined){
                            return;
                        }
                        o[data.parameter] = data.default;
                    }
                })
            }

            let obj = {};
            node_type.models.map(v => extract(v.parameter_description,obj))
            return obj;
        }

        let params = this.props.workflowNodes.map(n => {
            return {
                meta_data: {
                    node_name: n.node_type.name,
                    area_name: n.area.name,
                },
                area: n.area.id,
                node_id: n.node_type.id,
                node_version_id: n.node_version.id,
                parameters: {...extractParameters(n.node_version), ...n.parameters}
            }
        });

        saveStringAsFile(JSON.stringify(params), "scenario_workflow.json");
    }

    handleUpload = () => {
        openFileBrowser().then(this.props.workflowUpload)
    }

    renderImport = () => {
        return <div style={{paddingTop:"100px", display:"flex", justifyContent: "center", alignItems:"center"}}>
            <div>
                <Button
                    onClick={this.handleUpload}
                    size="small" variant={"contained"} startIcon={<CloudUpload />}  color={"primary"}>
                    upload workflow
                </Button>
            </div>
        </div>
    }

    render() {
        let can_run = !(this.props.workflowNodes.length === 0 || this.props.workflowNodes.some(s => !s.area))
        let second_fab;
        if(!this.props.editable) {
            second_fab = <Fab size={"small"}
                  disabled={this.props.workflowNodes.length === 0}
                 style={{background:this.props.workflowNodes.length === 0 ? "":"#044E54"}}
                 onClick={this.downloadWorkflow}>
                <GetApp style={{color: "white"}}/>
            </Fab>
        }else if(!can_run){
            second_fab = <Fab size={"small"}
                  disabled
                 style={{background: can_run && this.props.editable  ? "#044E54" : ""}}
                 onClick={this.props.runScenarioCallback}>
                <PlayIcon style={{color:"white"}}/>
            </Fab>
        }else{
            second_fab = <Fab size={"small"}
                              disabled={!can_run || !this.props.editable}
                              style={{background: can_run && this.props.editable  ? "#044E54" : ""}}
                              onClick={this.props.runScenarioCallback}>
                <PlayIcon style={{color:"white"}}/>
            </Fab>
        }
        return <div style={styles.panelInner}>

            <DynamicList
                fabDisabled={!this.props.editable}
                extraFAB={ second_fab }
                title={<Box style={{alignItems:"center"}}><SwapCallsIcon fontSize={"small"} style={{paddingRight:"10px", marginBottom:"-5px"}}/>Workflow Nodes</Box>}
                onAddClick={(this.props.addNodeCallback||(_=>{}))}>
                <div style={{maxHeight: "60vh"}}>
                {
                    this.props.workflowNodes.length == 0 ?
                        this.renderImport()
                        :this.props.workflowNodes.map(this.renderWorkflowNodes)
                }
                </div>
            </DynamicList>



        </div>;
    }
}


WorkflowNodePanel.propTypes = {
    editable: PropTypes.bool,
    runScenarioCallback: PropTypes.func,
    workflowNodes: PropTypes.object,
    addNodeCallback: PropTypes.func,
    workflowUpload: PropTypes.func,
    selectedNode: PropTypes.number,
    deleteNodeCallback: PropTypes.func,
    selectNodeCallback: PropTypes.func,
}

const mapStateToProps = (state, ownProps) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {

    return {}
}


export default connect(mapStateToProps, mapDispatchToProps)(WorkflowNodePanel);
