import React from 'react'
import PropTypes from "prop-types"
import {connect} from "react-redux";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Card from "@material-ui/core/Card";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';


class ContextMenu extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selected_scenario_id: null,
            selected_sublist: null,
            selected_feature: null
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.position !== this.props.position) {
            this.setState({selected_scenario_id: null, selected_sublist: null, selected_feature: null});
        }
    }


    renderLayerList = (layer_map) => {
        return<List style={{paddingLeft:"10px"}}>

            {Object.keys(layer_map).map(name => {
                let layer = layer_map[name];
                return <div>
                    <ListItem button onClick={_ => this.setState({selected_sublist: layer.layer.id})} dense>
                        <ListItemText primary={layer.layer.data.display_name}/>
                        {this.state.selected_sublist === layer.layer.id ? <ExpandLess/> : <ExpandMore/>}
                    </ListItem>
                    {this.renderSubList(layer)}

                </div>
            })}
        </List>
    }

    renderSubList = (layer) => {
        return <Collapse in={this.state.selected_sublist === layer.layer.id}>
            <div style={{maxHeight:"200px", paddingLeft:"10px", overflowY:"scroll"}}>
                <List>
                {
                    layer.features.map(f => {
                        return <ListItem button dense onClick={_=>this.setState({selected_feature:f.dm_id})}>
                            <ListItemText primary={
                                layer.layer.data.display_name + " " + f.dm_id
                            }/>
                        </ListItem>
                    })
                }
                </List>
            </div>
        </Collapse>
    }


    renderFeature = () => {
        let data = this.props.data.find(f => f.properties.dm_id === this.state.selected_feature);
        let format = (v) => {
            if (typeof v === 'string' || v instanceof String) {
                if(v.length > 20){
                    return v.substr(0, 17) + "..."
                }
                return v;
            }else
                return v;
        }
        return <div style={{maxHeight:"200px", overflowY:"auto"}}>
            {
                Object.keys(data.properties).map(k => {
                    let v = data.properties[k];
                    return <div style={{display:"flex", padding:"2px", flexDirection:"row"}}>
                        <div style={{flex:1}}>
                            {k}
                        </div>
                        <div style={{width:"10px"}}/>
                        <div style={{flex:1, textAlign:"right"}}>
                            {format(v)}
                        </div>
                    </div>
                })
            }
        </div>
    }


    render() {


        if(this.props.position === null){
            return <div/>
        }
        let layer_map = {};



        let data = this.props.data.find(f => f.properties.dm_id === this.state.selected_feature);

        let content;
        content = <List>

            {Object.keys(this.props.scenario_layer_map).map(name => {
                let scenario = this.props.scenarios.find(s => s.id === parseInt(name));
                let layers = this.props.scenario_layer_map[name];
                let scenario_id = parseInt(name)

                return <div>
                    <ListItem button onClick={_ => this.setState({selected_scenario_id: scenario_id})} dense>
                        <ListItemText primary={scenario.name}/>
                        {this.state.selected_scenario_id === scenario_id ? <ExpandLess/> : <ExpandMore/>}
                    </ListItem>
                    <Collapse in={this.state.selected_scenario_id === scenario_id}>
                    {this.renderLayerList(layers)}
                    </Collapse>

                </div>
            })}
        </List>


        return <Card  style={{position:"absolute", top:this.props.position.y + "px", left: this.props.position.x + "px"}}>
            {data !== undefined ? this.renderFeature() : content}

        </Card>;
    }
}


ContextMenu.propTypes = {
    position: PropTypes.object,
    map: PropTypes.object
}

const mapStateToProps = (state, ownProps) => {
    let scenario_layer_map = {};
    let layer_list = {}
    let dm_id_map = {};
    state.layers.map(l => {
        if(scenario_layer_map[l.scenario_id] === undefined){
            scenario_layer_map[l.scenario_id] = {}
        }
        if(dm_id_map[l.scenario_id] === undefined){
            dm_id_map[l.scenario_id] = {}
        }
        dm_id_map[l.scenario_id][l.scenario_id+"_" + l.name] = {};
        layer_list[l.scenario_id+"_" + l.name] = 1;
        scenario_layer_map[l.scenario_id][l.scenario_id+"_" + l.name] = {layer:l, features:[]};
    });
    ownProps.data.map(d => {

        if(layer_list[d.layer["source-layer"]] === undefined){
            return;
        }
        let scenario_id = parseInt(d.layer["source-layer"].split("_")[0]);

        if(dm_id_map[scenario_id][d.layer["source-layer"]][d.properties.dm_id] !== undefined){
            return;
        }
        dm_id_map[scenario_id][d.layer["source-layer"]][d.properties.dm_id] = 1;

        scenario_layer_map[scenario_id][d.layer["source-layer"]].features.push(d.properties);
    })


    return {
        scenarios: state.scenarios,
        layers: state.layers,
        scenario_layer_map: scenario_layer_map,
    }
}

const mapDispatchToProps = (dispatch) => {

    return {}
}


export default connect(mapStateToProps, mapDispatchToProps)(ContextMenu);
