import DanceAPI from "./DanceAPI";


export const createFilter = (project_id, name, data) => {
    return (dispatch, getState) => {
        return DanceAPI.filter.post(project_id, name, data).then(result => {
            dispatch(addFilter(result.id, result, true))
            return result;
        });
    }
}

export const initFilters = (filters) => ({
    type: "FILTERS_INIT",
    filters
});

export const updateFilter = (id, state) => ({
    type: "FILTERS_UPDATE",
    id,
    new_state: state,
});

export const addFilter = (id, data, active) => ({
    type: "FILTERS_ADD",
    id,
    ...data,
    active,
});
