

const layer = (state={}, action) => {
    switch (action.type) {
        case 'LAYERS_INIT':{
            return {
                id: action.id,
                name: action.name,
                data: action.data,
                range_data: {},
                comparison_range_data: {},
                scenario_id: action.scenario_id,
                scenario_revision_id: action.scenario_revision_id,
                publishing: false,
                published: action.published || false,
                perspective: "default",
                display: false,
            }
        }
        case 'LAYERS_COMPARISON_RANGE_UPDATE':{
            if(action.id === state.id){
                let new_state = {...state};
                new_state.comparison_range_data = {
                    ...new_state.comparison_range_data,
                    ...action.range_data
                };
                return new_state;
            }
            return state;
        }
        case 'LAYERS_RANGE_UPDATE':{
            if(action.id === state.id){
                let new_state = {...state};
                new_state.range_data = action.range_data;
                return new_state;
            }
            return state;
        }
        case 'LAYERS_SET_PERSPECTIVE':{
            if(action.id === state.id){
                let new_state = {...state};
                new_state.perspective = action.perspective;
                return new_state;
            }
            return state;
        }
        case 'LAYERS_SET_PERSPECTIVE_MULTIPLE':{
            if(action.map[state.id] !== undefined){
                let new_state = {...state};
                new_state.perspective = action.map[state.id];
                return new_state;
            }
            return state;
        }
        case 'LAYERS_SET_PRESENTATION_DATA':{
            if(action.map[state.id] !== undefined){
                let new_state = {...state};
                new_state.perspective = action.map[state.id].perspective || "default";
                new_state.display = action.map[state.id].display || false;
                return new_state;
            }
            return state;
        }
        case 'LAYERS_TOGGLE_SHOWN':{
            if(action.layer_ids.includes(state.id)){
                let new_state = {...state};
                new_state.display = !state.display;
                return new_state;
            }
            return state;
        }
        case "LAYERS_UPDATE":{
            if(action.id === state.id){
                let new_state = {...state};
                new_state.publishing = action.publishing;
                new_state.published = action.published;
                return new_state;
            }
            return state;
        }

        default:
            return state;
    }
}

const layers = (state=[], action) => {
    switch (action.type) {
        case 'LAYERS_INIT':{

            return action.layers.map(data => layer({}, {type: action.type, ...data}))
        }
        case 'LAYERS_RANGE_UPDATE':{
            return state.map(l => layer(l, action))
        }
        case 'LAYERS_COMPARISON_RANGE_UPDATE':{
            return state.map(l => layer(l, action))
        }
        case 'LAYERS_TOGGLE_SHOWN':{
            return state.map(l => layer(l, action))
        }
        case 'LAYERS_SET_PERSPECTIVE':{
            return state.map(l => layer(l, action))
        }
        case 'LAYERS_SET_PRESENTATION_DATA':{
            return state.map(l => layer(l, action))
        }
        case 'LAYERS_SET_PERSPECTIVE_MULTIPLE':{
            return state.map(l => layer(l, action))
        }
        case 'LAYERS_UPDATE':{
            return state.layers.map(data => layer(data, action))
        }
        default:
            return state;
    }
}

export default layers;
