/**
 * Created by User on 28/08/2020
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {ReactComponent as TreeIcon} from "../../icons/people.svg"

class LoadingDial extends Component {

    constructor(props) {
        super(props);
        this.state = {
            interval: null,
            number: Math.floor(Math.random()*60),
        }
    }

    update = () => {
        this.setState({
            number: (this.state.number%100) + 1,
        })
    }

    componentDidMount() {
        this.setState({
            interval: setInterval(this.update, 20)
        })
    };

    componentWillUnmount() {
        clearInterval(this.state.interval)
    };

    render() {
        const c = (a) => Math.cos(a);
        const s = (a) => Math.sin(a);
        const SA = Math.PI*0.775;
        const construct_svg = (angle, color="grey", thickness=0.01, radius=0.35, start_offset=0) => {
            return <path
                strokeLinecap={"round"}
                stroke={color}
                fillOpacity={0}
                strokeWidth={thickness}
                d={`
                        M ${c(SA + start_offset)*radius+0.5} ${s(SA + start_offset)*radius+0.55 - 0.1}
                        A ${radius} ${radius} ${SA} ${(angle-start_offset)<Math.PI ? 0 : 1} 1 ${c(SA+angle)*radius+0.5} ${s(SA+angle)*radius+0.55- 0.1}
                        
                    `}
            />
        }


        return <div style={{width:"100%", height:"100%"}}>
            <svg

                shapeRendering="geometricPrecision"
                viewBox={"0 0 1 1"}
            >
                {construct_svg(Math.PI*1.45, "grey", 0.04, 0.35)}
                {construct_svg(Math.PI*1.45*(this.state.number/100), "rgba(115,227,191,0.94)", 0.04, 0.35, Math.PI*1.45*Math.pow(this.state.number/100,2))}
                {construct_svg(Math.PI*1.45*(((this.state.number+50)%100)/100), "rgba(115,227,191,0.94)", 0.04, 0.35, Math.PI*1.45*Math.pow(((this.state.number+50)%100)/100,2))}

                <text fill={"#2F4653"} textAnchor={"middle"} fontSize={"0.1"}  transform={"translate(0.5, 0.95)"} >loading...</text>
            </svg>
        </div>;
    }
}


LoadingDial.propTypes = {
}


export default LoadingDial;
