import React from 'react'
import PropTypes from "prop-types"
import Marquee from "react-marquee";


class ColorFlipper extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            timer: null,
            on:false,
        }
    }

    componentDidMount() {
        this.setState({
            timer: setInterval(_=>this.setState({on: !this.state.on}), 1000)
        })
    }
    componentWillMount() {
        clearInterval(this.state.timer);
    }

    render() {
        let on = this.state.on;
        let {
            primary,
            secondary
        } = this.props;
        return <div style={{...(this.props.style||{}),backgroundColor: on ? primary : secondary, color:on? secondary:primary, fontFamily:"Comic Sans MS", fontSize:"2em"}}>
            {
                this.props.children
            }</div>
    }
}


ColorFlipper.propTypes = {
    style: PropTypes.object,
    primary: PropTypes.string.isRequired,
    secondary: PropTypes.string.isRequired,
}

export default ColorFlipper;