import DanceAPI from "./DanceAPI"
import {tile_server} from "../config";
import {get_perspective_column_keys} from "../map_styling/util";



export const initLayers = (layers) => ({
    type: "LAYERS_INIT",
    layers,
})

export const toggleDisplayLayers = (layer_ids) => {
    return (dispatch, getState) => {
        dispatch(getRangeData(getState().layers.filter(l => layer_ids.includes(l.id))))
        dispatch({
                type: "LAYERS_TOGGLE_SHOWN",
                layer_ids,
        })
    }
}

export const setLayerPresentationData = (map) => {
    return (dispatch, getState) => {
        let ids = Object.keys(map).map(m => parseInt(m));
        console.log(ids)
        dispatch(getRangeData(getState().layers.filter(l => ids.includes(l.id))))
        dispatch({
            type: "LAYERS_SET_PRESENTATION_DATA",
            map
        })
    }
}


export const setLayerPerspective = (id, perspective) => ({
    type: "LAYERS_SET_PERSPECTIVE",
    id,
    perspective
})

export const setLayerPerspectiveMultiple = (map) => ({
    type: "LAYERS_SET_PERSPECTIVE_MULTIPLE",
    map
})

export const setComparisonRangeData = (id, range_data) => ({
    type: "LAYERS_COMPARISON_RANGE_UPDATE",
    id,
    range_data
})


export const updateLayers = (layer_id, publishing, published) => ({
    type: "LAYERS_UPDATE",
    id:layer_id,
    publishing,
    published,
})

export const setLayerRangeData = (layer_id, range_data) => ({
    type: "LAYERS_RANGE_UPDATE",
    id:layer_id,
    range_data
});

export const getLayers = () => {
    return (dispatch, getState) => {
        return DanceAPI.layers.get(getState().project.id).then(result => {
            return Promise.all(result.layers.map(layer => {
                return fetch(tile_server + "probe/"+layer.scenario_id + "_" + layer.name).then(result => {
                    return {layer, exists: result.exists};
                })
            })).then(results => {
                dispatch(initLayers(results.map(temp => ({...temp.layer, published: temp.exists}))))
            });
        })
    }
}

export const getRangeData = (layers) => {

    return (dispatch, getState) => {
        layers.map(layer => {
            console.log(layer)

            if ( Object.keys(layer.range_data).length !== 0) {
                return;
            } else {
                let cols = get_perspective_column_keys(layer);
                fetch(tile_server + "range/" + layer.scenario_id + "_" + layer.name + "/" + encodeURIComponent(JSON.stringify(cols)))
                    .then(f => {
                        return f.json()
                    }).then(f => {
                        dispatch(setLayerRangeData(layer.id, f))
                    }).catch(error => {
                        console.error("range query error ("+layer.name+")", error)
                    })
            }
        })
    }
}

export const getComparisonRangeData = (layer, perspective, scenario_1, scenario_2) => {
    return (dispatch, getState) => {
        if(!layer.data.perspectives[perspective].comparison){
            return;
        }
        console.log(layer.data.perspectives[perspective].comparison)
        let column_name = Object.keys(
            layer.data.perspectives[perspective].comparison.query[layer.data.perspectives[perspective].comparison.sourcelayer]
        )[0];

        let query_string = {};
        query_string.sources = {};
        let template = layer.data.perspectives[layer.perspective].comparison;
        let inputs = [scenario_1, scenario_2];

        for(let i = 0; i < template.number_of_inputs; i++){
            query_string.sources[inputs[i].id + template.suffix] = {name: template.input_aliases[i]}
        }
        query_string.sinks = Object.assign({}, template.query);
        dispatch(setComparisonRangeData(layer.id,
            {
                [perspective]: {
                    loading: true,
                }
            }
        ))
        fetch(tile_server + "range_query/" +  encodeURIComponent(JSON.stringify([column_name])) +"?q=" +  encodeURIComponent(JSON.stringify(query_string)))
            .then(f => {
                return f.json()
            }).then(f => {
                dispatch(setComparisonRangeData(layer.id,
                    {
                        [perspective]: {
                            ...f[column_name]
                        }
                    }
                    ))
        }).catch(error => {
            console.error("range query error (" + layer.name + ")", error)
        })
    }
}
