import React from 'react'
import PropTypes from "prop-types"
import {connect} from "react-redux";
import Card from "@material-ui/core/Card/index";
import {CardContent, CardHeader} from "@material-ui/core/index";
import ChartWrapper from "./charts/ChartWrapper";
import ErrorBoundary from "./ErrorBoundary";
import Divider from "@material-ui/core/Divider";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";


class DashboardTile extends React.Component {

    constructor(props) {
        super(props);
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if(this.props.scenario !== nextProps.scenario){
            return true;
        }else if(this.props.dash_tile !== nextProps.dash_tile){
            return true;
        }else if(this.props.areas !== nextProps.areas){
            return true;
        }

        return false;
    }

    render() {
        return <Card style={{height:"100%"}} elevation={1} square={true}>
                <CardHeader
                    style={{
                            backgroundColor: this.props.backgroundColor!==undefined ?this.props.backgroundColor:"#D9E2EC",
                            color:this.props.textColor!==undefined ?this.props.textColor:"#243B53",
                            fontSize: "14px",
                            fontVariant: "small-caps",
                            fontWeight: "bold"

                    }}
                    disableTypography={true}
                    title={this.props.dash_tile.template.data.name}/>
                    <Divider/>


                <CardContent style={{maxHeight:"350px"}}>
                    <ErrorBoundary>
                        <ChartWrapper
                            areas={this.props.areas}
                            scenario={this.props.scenario}
                            context={this.props.context}
                            dash_tile_template={this.props.dash_tile_template}
                            chart_props={this.props.dash_tile.data}/>
                    </ErrorBoundary>
                </CardContent>
            </Card>
    }
}


DashboardTile.propTypes = {
    dash_tile: PropTypes.object,
    scenario: PropTypes.object,
    areas: PropTypes.array,
    context: PropTypes.object,
}

const mapStateToProps = (state, ownProps) => {
    return {
        dash_tile_template: state.dash_tile_templates.find(dtt => dtt.id === ownProps.dash_tile.template_id)
    }
}

const mapDispatchToProps = (dispatch) => {

    return {}
}


export default connect(mapStateToProps, mapDispatchToProps)(DashboardTile);
