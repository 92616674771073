import DanceAPI from "./DanceAPI";


export const createGeoJSONFilterPreset = (project_id, name, geojson, filters) => {
    return (dispatch, getState) => {
        return DanceAPI.geojson_filter_presets.post(project_id, name, geojson, filters).then(result => {
            dispatch(addGeoJSONFilterPreset(result.id, result, true))
            return result;
        });
    }
}

export const initGeoJSONFilterPresets = (geojson_filter_presets) => ({
    type: "GEOJSON_FILTER_PRESETS_INIT",
    geojson_filter_presets
});

export const addGeoJSONFilterPreset = (id, data, active) => ({
    type: "GEOJSON_FILTER_PRESETS_ADD",
    id,
    ...data,
    active,
});
