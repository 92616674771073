/**
 * Created by User on 29/08/2020
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import DashboardTile from "../DashboardTile";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CloseIcon from "@material-ui/icons/Close"
import {Grid} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

class ChartsModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedTags: [],
        };
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if(!prevProps.open && this.props.open && this.props.selected_dial){
            this.setState({
                selectedTags: this.props.selected_dial.template.data.meta_data.tags.filter(t=>t!=="all")
            })
        }
    }

    componentDidMount() {
    };

    componentWillUnmount() {
    };

    handleTagsChange = (e) => {
        const values = e.target.value;

        this.setState({
            selectedTags: values.includes("") ? [] : values
        })
    }

    render() {
        const non_summary = this.props.dash_tiles.filter(f => {
            return !f.template.data.meta_data?.summary;
        })

        let tags = Array.from(new Set(non_summary.map(f => f.template.data.tags || []).reduce((prev, curr) => [...prev, ...curr], [])));
        let filtered_non_summary = non_summary.filter(f => {
            if(this.state.selectedTags.length === 0) {
                return true
            }
            return (f.template.data.tags||[]).some(t => t === "all" || this.state.selectedTags.includes(t))
        })
        return <div style={{width:"100%", height:"100%", backgroundColor:"white", overflow:"hidden"}}>
            <div style={{width:"100%", height:"100%", boxSizing:"border-box", overflowY:"auto"}}>
                <div style={{position:"sticky", zIndex:1000, top:0, backgroundColor:"white"}}>
                    <div style={{display:"flex", flexDirection:"row"}}>
                        <Typography style={{padding:"10px"}}>Charts</Typography>
                        <div style={{flex:"1"}}/>
                        <Tooltip title={"close"}>
                            <IconButton  style={{marginRight:"10px"}} onClick={this.props.onClose}>
                                <CloseIcon/>
                            </IconButton>
                        </Tooltip>
                    </div>
                    <Divider/>
                    <div style={{padding:"10px"}}>
                        <Typography style={{display:"inline-block", marginRight:"10px"}}>Filter by tag</Typography>
                        <Select
                            anchorOrigin={{
                                vertical:"top",
                                horizontal:"left"
                            }}
                            onChange={this.handleTagsChange}
                            multiple
                            style={{minWidth:"100px"}}
                            label={"tags"}
                            value={this.state.selectedTags}>

                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {tags.map(f => {
                                return <MenuItem value={f}>{f}</MenuItem>
                            })}
                        </Select>
                    </div>
                    <Divider/>
                </div>

                <div style={{padding:"10px"}}>
                <Grid container spacing={2}>
                    {
                        filtered_non_summary.map(m => {
                            return <Grid key={m.id} item xs={12} md={12} lg={6}>
                                <DashboardTile dash_tile={m}/>
                            </Grid>
                        })
                    }
                </Grid>
                </div>
            </div>
        </div>;
    }
}


ChartsModal.propTypes = {
    selected_dial: PropTypes.object,
    open: PropTypes.bool,
    dash_tiles: PropTypes.array,
    onClose: PropTypes.func,
}


export default ChartsModal;
