/**
 * Created by User on 30/08/2020
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Slide from "@material-ui/core/Slide";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import Divider from "@material-ui/core/Divider";
import {fetchAssessmentModels} from "../../actions/assessment_models_actions";
import {openHelp} from "../../actions/help_actions";
import {createGeoJSON} from "../../actions/geojson_actions";
import DanceAPI from "../../actions/DanceAPI";
import {createScenario} from "../../actions/scenario_actions";
import {loadProject} from "../../actions/project_actions";
import {withRouter} from "react-router";
import {connect} from "react-redux";

class ContextDrawer extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
    };

    componentWillUnmount() {
    };

    renderContent = () => {
        return  <div style={{position:"sticky", zIndex:1100, backgroundColor: "white", top:0}}>
            <div style={{display:"flex"}}>
                <Typography style={{paddingLeft:"10px", paddingRight:"5px"}}>{this.props.project.name}</Typography>
                <div style={{flex:"1"}}/>

            </div>
            <Divider variant={"fullWidth"}/>
        </div>
    }

    render() {
        return <Slide direction={"right"}  in={this.props.open}>
            <div style={{
                top:0,
                position:"absolute",
                pointerEvents:"auto",
                transformStyle: "preserve-3d",
                overflowY:"hidden",
                left:0,
                boxSizing:"border-box",
                width:this.props.width, height:"100%",  padding:"10px 10px"}}>
                <div style={{height:"100%", width:"100%", overflowY:"auto", backgroundColor:"white",
                    borderRadius:"4px"}}>
                    {this.renderContent()}
                </div>
            </div>
        </Slide>;;
    }
}


ContextDrawer.propTypes = {
    open: PropTypes.bool,
    width: PropTypes.string,
}


const mapStateToProps = (state) => {
    return {
        project: state.project,
        assessment_models: state.assessment_models,
        data_sources: state.data_sources,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ContextDrawer);

