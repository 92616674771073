import React from 'react'
import PropTypes from "prop-types"
import {connect} from "react-redux";
import queryHandler from "../QueryHandler";
import DashboardTile from "./DashboardTile";
import {runQueriesWithAreas} from "../actions/project_actions";
import DynamicList from "./Views/DynamicList";
import Paper from '@material-ui/core/Paper';
import Button from "@material-ui/core/Button/index";
import Popover from "@material-ui/core/Popover";
import {SwatchesPicker} from "react-color";
import IconButton from "@material-ui/core/IconButton";
import StopIcon from "@material-ui/icons/Stop";
import Dropzone from "react-dropzone";
import ListItem from "@material-ui/core/ListItem";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import Fab from "@material-ui/core/Fab";
import Slide from "@material-ui/core/Slide";
import BoundariesDropDownList from "./BoundariesDropDownList";
import {ListItemText, TextField, Typography} from "@material-ui/core/index";
import BackIcon from "@material-ui/icons/NavigateBefore";
import DeleteIcon from '@material-ui/icons/Delete';
import Divider from "@material-ui/core/Divider";
import Checkbox from "@material-ui/core/Checkbox";
import DialogActions from "@material-ui/core/DialogActions";
import CloseIcon from '@material-ui/icons/Close'
import List from "@material-ui/core/List";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import {createGeoJSON, highlightGeojson} from "../actions/geojson_actions";
import MenuItem from "@material-ui/core/MenuItem";
import LayersIcon from "@material-ui/core/SvgIcon/SvgIcon";
import Box from "@material-ui/core/Box";
import AssignmentIcon from '@material-ui/icons/Assignment';
import RoomIcon from '@material-ui/icons/Room';
import CheckIcon from '@material-ui/icons/Check';
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Chip from "@material-ui/core/Chip";
import FormControl from "@material-ui/core/FormControl";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import FormHelperText from '@material-ui/core/FormHelperText';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import Switch from "@material-ui/core/Switch";


const styles = {
    performanceTitle:{backgroundColor:"white", color:"black"}
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


class ScenarioViewDashboardTiles extends React.Component {

    constructor(props) {
        super(props);
        let boundaryId = this.props.geojsons
            .filter(f => f.active)
            .map(a=>a.id);
        this.state = {
            selectedAreas: [],
            isOpen: false,
            boundaryFile: null,
            mapBoundaries:[...boundaryId],
        }
    }

    queryHandlerListener = (event) => {
        this.setState({
            queries: queryHandler.getCompleteQueries()
        })
    }

    componentDidMount() {
        queryHandler.registerListener(this.queryHandlerListener)
    }

    componentWillUnmount() {
        queryHandler.removeListener(this.queryHandlerListener)
    }


    renderDeleteAreaDialog = () => {
        return <div>
            <Dialog open={this.state.openDeleteAreaDialog} fullWidth>
                <DialogTitle>Deleting Areas<IconButton size={'small'} style={{float:"right"}} onClick={() =>{this.setState({
                    openDeleteAreaDialog: false})}}><CloseIcon /></IconButton></DialogTitle>
                <Divider/>
                {this.props.geojsons.filter(f => f.active).map(area => (
                    <ListItem
                        key={area}
                        value={area}
                        dense
                        button
                    >
                        <Checkbox></Checkbox>
                        {area.name}
                    </ListItem>

                ))}
                <DialogActions><Button onClick={_=>this.setState({deleteAreaConfirmation:true})}>Delete</Button></DialogActions>
            </Dialog>

            <Dialog open={this.state.deleteAreaConfirmation}>
                <DialogTitle>
                    Deletion Confirmation
                    <IconButton size={'small'}
                                style={{float:"right"}}
                                onClick={() =>{this.setState({deleteAreaConfirmation: false})}}>
                        <CloseIcon /></IconButton></DialogTitle>
                <DialogContent>Are you sure you want to delete selected areas?</DialogContent>
                <DialogActions>
                    <Button style={{float:'left'}} onClick={_=>{this.setState({openDeleteAreaDialog: false,deleteAreaConfirmation: false})}}>Yes</Button>
                    <Button onClick={_=>{this.setState({deleteAreaConfirmation: false})}}>No</Button>
                </DialogActions>
            </Dialog>
        </div>

    };

    handleAreaChange = (event) => {
        if(event.target.value.includes(-1)){
            this.setState({
                selectedAreas: [],
                isOpen: false,
            });
            return;
        }

        this.setState({
            selectedAreas: [...event.target.value],
        });
        this.props.runQueries(this.props.geojsons
            .filter(f => f.active)
            .filter(g => [...event.target.value].includes(g.id)));

    }

    setOpen = () =>{
        this.setState({
            isOpen: true
        })
    };

    handleDrop = (acceptedFiles) => {
        if(acceptedFiles.length !== 1){
            this.setState({
                errorText: "You can only upload a single boundary",
                errorDialogOpen:true,
            })
        }
        let file = acceptedFiles[0];
        this.setState({
            boundaryFile: file.name,
        })

        let reader = new FileReader();
        reader.addEventListener("loadend", ()=> {
            const geojson = JSON.parse(reader.result);
            if ("crs" in geojson) {
                delete geojson.crs;
            }
            this.props.map.mapdraw.set(geojson);
        });
        reader.readAsText(file);
    }

    handleSubmitGeojson = () => {
        let project_id = this.props.project.id;
        let geojson = null;
        geojson = this.props.map.mapdraw.getAll();
        this.props.map.mapdraw.deleteAll();
        this.props.map.mapdraw.changeMode("simple_select");
        geojson["properties"] = {
            color: this.state.area_color
        };

        let name = this.state.areaName;
        this.setState({
            uploadingArea: true,
            addingArea: false,
        })
        //minimum upload time set to 1500, so ui dialog doesnt disappear too quick
        let promise = new Promise(resolve=>{
            setTimeout(resolve, 1500);
        })
        this.props.createGeoJSON(project_id, geojson, name, _=>{
            promise.then(_=>
                this.setState({
                    uploadingArea: false,

                }))

        });
    }


    handleSetDraw = () => {
        this.setState({
            addType: "draw"
        });
        this.props.map.mapdraw.changeMode("draw_polygon");
    }


    renderAddingBoundary = () => {
        if(this.state.addType === "draw"){
            return <div>
                <Typography>
                    Draw polygon
                </Typography>
                <div>
                    <Typography variant={"subtitle2"}>
                        Area name
                    </Typography>
                    <TextField fullWidth
                               value={this.state.areaName}
                               onChange={e => this.setState({
                                   areaName: e.target.value
                               })}/>


                    <div>
                        <Typography variant={"subtitle2"}>
                            Area color
                        </Typography>
                        <Popover anchorEl={this.state.color_picker_anchor}
                                 open={this.state.color_picker_open}
                                 anchorOrigin={{
                                     vertical: 'top',
                                     horizontal: 'left',
                                 }}
                                 transformOrigin={{
                                     vertical: 'top',
                                     horizontal: 'right',
                                 }}
                        >
                            <SwatchesPicker color={this.state.area_color} onChange={color => this.setState({
                                color_picker_open: false,
                                area_color: color.hex,
                            })}/>
                        </Popover>
                        <IconButton onClick={e=>this.setState({color_picker_anchor:e.currentTarget, color_picker_open: !this.state.color_picker_open})} size={"small"}>
                            <StopIcon style={{color:this.state.area_color}} fontSize={"large"}/>
                        </IconButton> {this.state.area_color}
                    </div>
                    <br/>
                    <Button onClick={this.handleSubmitGeojson} variant={"outlined"} fullWidth color={"primary"}>
                        submit
                    </Button>
                </div>
            </div>
        }else if(this.state.addType === "upload"){
            return this.state.boundaryFile === null ?
                <Dropzone onDrop={this.handleDrop}>
                    {({getRootProps, getInputProps}) => (
                        <section style={{
                            borderRadius: "5px",
                            padding: "5px",
                            border: " dashed 2px grey"
                        }}>
                            <div {...getRootProps()}>
                                <input {...getInputProps()} />

                                <Typography variant={"body1"}>

                                    Click here, or drag and drop a file here, to upload your
                                    boundary
                                </Typography>
                            </div>
                        </section>
                    )}
                </Dropzone>:
                <div>
                    <ListItem dense>
                        <ListItemText primary={"File name"} secondary={this.state.boundaryFile}/>
                    </ListItem>
                    <Typography variant={"subtitle2"}>
                        Area name
                    </Typography>
                    <TextField fullWidth
                               value={this.state.areaName}
                               onChange={e => this.setState({
                                   areaName: e.target.value
                               })}/>
                    <div>
                        <Typography variant={"subtitle2"}>
                            Area color
                        </Typography>
                        <Popover anchorEl={this.state.color_picker_anchor}
                                 open={this.state.color_picker_open}
                                 anchorOrigin={{
                                     vertical: 'top',
                                     horizontal: 'left',
                                 }}
                                 transformOrigin={{
                                     vertical: 'top',
                                     horizontal: 'right',
                                 }}
                        >
                            <SwatchesPicker color={this.state.area_color} onChange={color => this.setState({
                                color_picker_open: false,
                                area_color: color.hex,
                            })}/>
                        </Popover>
                        <IconButton onClick={e=>this.setState({color_picker_anchor:e.currentTarget, color_picker_open: !this.state.color_picker_open})} size={"small"}>
                            <StopIcon style={{color:this.state.area_color}} fontSize={"large"}/>
                        </IconButton> {this.state.area_color}
                    </div>
                    <ListItem dense>
                        <Button onClick={this.handleSubmitGeojson} variant={"outlined"} fullWidth color={"primary"}>
                            submit
                        </Button>
                    </ListItem>
                </div>


        }

        return  <div>
            <Button
                onClick={this.handleSetDraw}
                color={"primary"} fullWidth variant={"outlined"}>
                draw
            </Button>
            <Button
                onClick={_=>{
                    this.setState({
                        addType: "upload"
                    })}
                }
                color={"primary"} fullWidth variant={"outlined"}>
                upload
            </Button>
        </div>
    };




    highlight = (geojson) => {
        this.props.highlightBoundary(geojson.id, true)

    }

    lowlight = (geojson) => {
        this.props.highlightBoundary(geojson.id, false)

    };


    renderBoundary = () => {
        {/*let listTitle;
        if(this.state.addingBoundary && !this.state.addType){
            listTitle = "Adding boundary"
        }else if(this.state.addingBoundary && this.state.addType === "draw"){
            listTitle = "Drawing Boundary"
        }else if(this.state.addingBoundary && this.state.addType === "upload"){
            listTitle = "Uploading Boundary"
        }else{
            listTitle = "Areas"
        }
        return <div>
            <Dialog open={this.state.uploadingArea}>
                <DialogTitle>
                    Uploading Area
                </DialogTitle>
                <DialogContent style={{textAlign:"center"}}>
                    <CircularProgress variant={"indeterminate"}/>
                </DialogContent>
            </Dialog>
            <DynamicList
                noFAB={this.state.addingArea}
                extraFAB={this.state.addingArea ?
                    <Fab size={"small"}
                         style={{background: "red"}}
                         onClick={_=>this.setState({addingArea: false})}>
                        <BackIcon style={{color:"white"}}/>
                    </Fab>:
                    <Fab size={"small"}
                         style={{background:"red", color:"white"}}
                         onClick={_=>this.setState({openDeleteAreaDialog: true})}><DeleteIcon /></Fab>
                }
                title={<Box style={{alignItems:"center"}}>
                    <RoomIcon fontSize={"small"} style={{paddingRight:"10px", marginBottom:"-5px"}}/>
                    {listTitle}
                </Box>} onAddClick={_=>this.setState({addingArea: true})}>
                {this.renderDeleteAreaDialog()}*/}
                return <div  style={{maxHeight:"300px", minHeight:"200px", position:"relative", overflowY:"hidden"}}>
                    <FormControl style={{width:"90%", paddingLeft:"10px"}} margin={"dense"}>
                        <InputLabel style={{position:"relative", marginBottom:"-15px"}} margin={"dense"}>{this.state.selectedAreas.length<1 ?"No areas selected":"Areas selected"}</InputLabel>
                        <Select
                            multiple
                            value={this.state.selectedAreas}
                            IconComponent={KeyboardArrowDownIcon}
                            onChange={this.handleAreaChange}
                            MenuProps={MenuProps}
                            renderValue={selected =>(
                                <div style={{display:'flex',flexWrap:'wrap'}}>{
                                    selected.map(s => (
                                        <Chip key={this.props.geojsons.find(g => g.id === s).name}
                                              label={this.props.geojsons.find(g => g.id === s).name}
                                              size={"small"}
                                        style={{margin:"2px"}}/>))}

                                </div>)}
                            variant={"outlined"}
                            >

                            <MenuItem value={-1}><CheckIcon style={{paddingRight:"10px", color: (this.state.selectedAreas.length<1) ?"#31B237":"rgba(0,0,0,0)"}}/><em>None</em></MenuItem>
                            {
                                this.props.geojsons
                                    .filter(f => f.active)
                                    .map(g => {
                                    return <MenuItem key={g.id} value={g.id} onMouseEnter={()=>this.highlight(g)}
                                                     onMouseLeave={()=>this.lowlight(g)}>
                                        <CheckIcon style={{paddingRight:"10px", color: (this.state.selectedAreas.indexOf(g.id)>-1) ?"#31B237":"rgba(0,0,0,0)"}}/>{g.name}
                                    </MenuItem>
                                })
                            }
                        </Select>
                        <FormHelperText>Filter results by areas</FormHelperText>
                    </FormControl>



                    {/*<Slide mountOnEnter unmountOnExit direction={"left"} style={{position:"absolute", overflowY:"auto", top:0, left:0, right:0, bottom:0}} in={this.state.addingArea}>
                        <div style={{background:"#f5f5f5"}}>
                            {
                                this.renderAddingBoundary()
                            }
                        </div>
                    </Slide>
                </div>


            </DynamicList>*/}
        </div>
    };


    render() {
        return <div>

            <DynamicList
                title={
                    <Box style={{alignItems:"center"}}>
                        <AssignmentIcon fontSize={"small"} style={{paddingRight:"10px", marginBottom:"-5px"}}/>
                        Analysis
                    </Box>}
                noFAB={true}>
                <div style={{position:"relative"}}>
                    {this.renderBoundary()}
                </div>

            {
                this.props.dash_tiles.map(d => {
                    return <DashboardTile areas={this.state.selectedAreas.map(v => this.props.geojsons.find(g => g.id === v))}
                                          scenario={this.props.scenario}
                                          dash_tile={d}
                                          backgroundColor={"#FCEFC7"}
                                          textColor={"#513C06"}
                    />
                })

            }</DynamicList>
        </div>

    }

}


ScenarioViewDashboardTiles.propTypes = {
    scenario: PropTypes.object,
    geojsons: PropTypes.array,
    project: PropTypes.object,
    areas: PropTypes.array,
    map: PropTypes.object
}


const mapStateToProps = (state, ownProps) => {
    return {
        geojsons: state.geojsons,
        dash_tiles: state.dash_tiles.map(dash_tile => {
            return {
                ...dash_tile,
                template: state.dash_tile_templates.find(dtt => dtt.id === dash_tile.template_id)
            }
        }),
        project: state.project,
        dash_tile_templates: state.dash_tile_templates,
    }
}

const mapDispatchToProps = (dispatch) => {

    return {
        runQueries: (areas) => {
            dispatch(runQueriesWithAreas(areas));
        },
        highlightBoundary: (id, state) => {
            dispatch(highlightGeojson(id, state))
        },

        createGeoJSON: (project_id, boundary, name, callback) => {
            dispatch(createGeoJSON(project_id, boundary, name)).then(callback)
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ScenarioViewDashboardTiles);
