import {help_url} from "../config";

const initial_state = {
    help_url: {help_url},
    open: true,
}

export const help = (state = initial_state, action) => {
    switch (action.type) {
        case "HELP_OPEN":
            return {
                help_url: action.url,
                drawer_open: true,
            }
        case "HELP_CLOSE":
            return {
                drawer_open: false,
            }
        default:
            return state;
    }
}