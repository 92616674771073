import React from 'react'
import PropTypes from "prop-types"
import ReactEcharts from "echarts-for-react";


class ScatterChart extends React.Component {


    constructor(props) {
        super(props);

    }

    getGraph = () => {
        // if(!this.props.all_loaded){
        //     return {};
        // }

        let main_data_key = this.props.query_data[0].queries[0].query_prototype.query_data.main_data_key;
        let units = this.props.query_data[0].queries[0].query_prototype.query_data.field_information[main_data_key].units


        let reference = null
        let ref_name = null
        if (this.props.reference_scenario)
            reference = this.props.query_data.filter((q) => q.name === this.props.reference_scenario.name)[0]
        if (reference)
            ref_name = reference.name;
        let data_queries = this.props.query_data.filter( (q) => q.name !== ref_name)


        return {
            tooltip:{confine: true},

            toolbox: {
                feature: {
                    dataZoom: {
                        title:{
                            zoom:"zoom",
                            back:"restore zoom"
                        }
                    },
                    brush: {
                        title:{
                            lineX: "range",
                            clear: "clear range"
                        },
                        type: ['lineX', 'clear']
                    },
                    dataView: {title: 'data', lang: ['data', 'close', 'refresh'], readOnly: true,},
                    saveAsImage: {backgroundColor: '#FFFFFF', title: 'save'},

                }
            },
            brush: {
                xAxisIndex: 'all',
                brushLink: 'all',
                outOfBrush: {
                    colorAlpha: 0.1
                }
            },
            legend: {
                right: 0,
                bottom: 10,
                type: 'scroll',
                orient: 'horizontal',
                data: this.props.query_data.map((dataset,i) => dataset.name)
            },
            grid: {
                left: '3%',
                right: '3%',
                bottom: '40',
                containLabel: true
            },
            xAxis : [
                {
                    type : 'value',
                }
            ],
            yAxis : [
                {
                    name: units,
                    type:"value",
                }
            ],

            series : data_queries.map((dataset,i) => ({
                name: dataset.name,
                type: 'scatter',
                xIndex: i,
                symbolSize: 3,
                data: dataset.queries[0].data.map((v, index) => {
                    // if(d.running || !d.data || !d.data[0] || null==d.data[0][main_data_key]){
                    //     return undefined;
                    // }
                    // if (!d.data[0][main_data_key]) {
                    //     return  undefined;
                    // }
                    let val = v["y"]
                    if (reference) {
                        val =  -reference.queries[0].data[index]["y"] + val;

                    }

                    return [v.x, val]
                })
                //data: dataset.queries[0]? dataset.queries[0].data.map(v=>[v.x, v.y]):undefined
            })),
        };
    };



    render() {

        if(this.props.query_data.length === 0 || this.props.query_data[0].queries.length === 0){
            return <div></div>
        }
        try {
            let option = this.getGraph()
        }catch (e) {
            console.log(e);
            return <div/>
        }

        return (
            <div>

                <ReactEcharts
                    option={this.getGraph()}
                    lazyUpdate={true}
                    notMerge={true}
                    style={{height: '270px', width: '95%'}}
                    className='react_for_echarts' />
            </div>

        )
    }
}


ScatterChart.propTypes = {}

export default ScatterChart;
