import Layer from "../Components/Map/Layer";
import React from "react";
import {func_map as sfm} from "./styling_functions";
import {func_map as lfm, func_feature_map as lfmf} from "./legend_gen_functions";
import {tile_server_query} from "../config";

export const get_perspective_column_keys = (layer) => {

    return Object.keys(layer.data.perspectives).map(k => {
        return layer.data.perspectives[k]
    }).filter(f => {
        return f.style.__func !== undefined
    }).map(p => {
        return p.style.args[1];
    })
}

export const get_comparison_perspective_column_keys = (layer) => {


    return Object.keys(layer.layer.template.data.perspectives).map(k => {
        return layer.layer.template.data.perspectives[k];
    }).map(p => {
        return p.paint["fill-color"].property;
    })
}

export const build_layer_perspective = (layer, context) => {
    let perspective_name = layer.perspective;
    let perspective = layer.data.perspectives[layer.perspective];
    let is_comparison = context.secondaryScenario !== null && layer.data.perspectives[layer.perspective].comparison !== undefined;
    if (is_comparison) {
        perspective = layer.data.perspectives[layer.perspective].comparison
    }
    let range_data;

    if (perspective.style.__func !== undefined) {
        let id;
        let layer_source;
        let source_layer;
        if (context.secondaryScenario !== null && layer.data.perspectives[layer.perspective].comparison !== undefined) {
            let perspective_name = layer.perspective;
            range_data = layer.comparison_range_data[layer.perspective];
            id = layer.id + "_" + perspective_name + "_" + context.primaryScenario.id + "_" + context.secondaryScenario.id
            layer_source = "layer_comparison_" + layer.id;
            source_layer = layer.data.perspectives[layer.perspective].comparison.sourcelayer
        } else {
            id = layer.id + "_" + perspective_name;
            let perspective_attribute = perspective.style.args[1];
            range_data = layer.range_data[perspective_attribute];
            layer_source = "layer_" + layer.id;
            source_layer = layer.scenario_id + "_" + layer.name;
        }
        let res = null;
        if (perspective.dynamic_range !== false && range_data !== null && range_data !== undefined &&
            range_data["min"] !== null &&
            range_data["max"] !== null) {

            let func = sfm[perspective.style.__func];
            let args = [...perspective.style.args];
            args[2] = range_data["min"];
            args[3] = range_data["max"];
            res = func(...args);
        } else {
            let func = sfm[perspective.style.__func];
            res = func(...perspective.style.args);
        }

        console.log(res, {

            id: id,
            "source": layer_source,
            "source-layer": source_layer,
            paint: res["style"],
            type: res["type"]
        })
        return {

            id: id,
            "source": layer_source,
            "source-layer": source_layer,
            paint: res["style"],
            type: res["type"]
        }
    }


    return {
        id: layer.id + "_" + perspective_name,
        "source": "layer_" + layer.id,
        "source-layer": layer.scenario_id + "_" + layer.name,
        type: perspective.maptype,
        paint: perspective.style,
    }
    if (layer.type === "comparison") {
        let perspective_name = layer.perspective;
        let perspective = layer.layer.template.data.perspectives[perspective_name];
        return {
            id: layer.layer.id + "_" + perspective_name + "_comparison",
            "source": "layer_comparison_" + layer.layer.id,
            "source-layer": layer.layer.template.data.sourcelayer,
            paint: perspective.paint,
            type: layer.layer.template.data.maptype,
        }
    } else {

    }

}

export const build_legend = (layer, perspective_name, perspective, context) => {
    let range_data;
    if (perspective.legend && perspective.legend.__func !== undefined) {
        if (context.primaryScenario !== null && context.secondaryScenario !== null) {
            if (perspective.comparison === undefined) {
                return {};
            }
            perspective = perspective.comparison;
            range_data = layer.comparison_range_data[perspective_name];
        } else {

            let perspective_attribute = perspective.legend.args[1];
            range_data = layer.range_data[perspective_attribute]
        }

        let res = null;
        if (perspective.dynamic_range !== false && range_data !== null && range_data !== undefined &&
            range_data["min"] !== null && range_data["min"] !== undefined &&
            range_data["max"] !== null && range_data["max"] !== undefined) {
            console.log(range_data)
            let func = lfm[perspective.style.__func];
            let func_feats = lfmf[perspective.legend.__func];

            if (!func_feats.supports_range) {
                let func = lfm[perspective.legend.__func];
                res = func(...perspective.legend.args);
                return res
            }
            let args = [...perspective.legend.args];
            args[2] = range_data["min"];
            args[3] = range_data["max"];
            res = func(...args);
        } else {
            let func = lfm[perspective.legend.__func];
            res = func(...perspective.legend.args);
        }
        return res;
    }


    return {}

}


