import React, {} from 'react'
import PropTypes from 'prop-types'
import Stepper from "@material-ui/core/Stepper/Stepper";
import Step from "@material-ui/core/Step/Step";
import StepLabel from "@material-ui/core/StepLabel/StepLabel";
import Button from "@material-ui/core/Button/Button";
import DrawableMap from "../Components/DrawableMap";
import Dialog from "@material-ui/core/Dialog";
import {DialogActions, DialogContent, DialogContentText, Slide} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Container from "@material-ui/core/Container";
import StepContent from "@material-ui/core/StepContent";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CardActions from "@material-ui/core/CardActions";
import DanceAPI from "../actions/DanceAPI";
import {connect} from "react-redux";
import {fetchAssessmentModels} from "../actions/assessment_models_actions";
import Paper from "@material-ui/core/Paper";
import Dropzone from "react-dropzone";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {withRouter} from "react-router";
import Divider from "@material-ui/core/Divider";
import FolderIcon from "@material-ui/icons/Folder"
import {createScenario, createScenarioRevision} from "../actions/scenario_actions";
import {createGeoJSON} from "../actions/geojson_actions";
import {openHelp} from "../actions/help_actions";
import {loadProject} from "../actions/project_actions";
import RequiredSelect from "../Components/Views/RequiredSelect";
import CreateIcon from "@material-ui/icons/Create"
import CloudUploadIcon from "@material-ui/icons/CloudUpload"
import {geometry} from "@mapbox/geojson-area"
import {case_study_area_limit} from "../config";
import CircularProgress from "@material-ui/core/CircularProgress";
import DenseCard from "../Components/Views/DenseCard";
import ScenarioMakerNodePropertiesNonPanel from "../Components/Views/ScenarioMakerNodePropertiesNonPanel";
import IconButton from "@material-ui/core/IconButton";
import DataSourceDialog from "../Components/DataSourceDialog";
import CreateProjectScenarioMakerNodeProperties from "../Components/Views/CreateProjectScenarioMakerNodeProperties";
import Box from "@material-ui/core/Box";
import DnsIcon from '@material-ui/icons/Dns';
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";

const styles = {
    root: {
        top:0,
        right:0,
        bottom:0,
        left:0,
        display:"flex",
        position:"absolute",
        pointerEvents:"none",
    },

    boundaryPanel: {
        overflow:"hidden",
        padding:"8px 24px",
        position:"absolute",
        right:0,
        top:0,
        bottom:0,
        width:"400px",
        pointerEvents:"none",
    },

    ribbonWrapper: {
        transform: "rotate(45deg)",
        position: "absolute",
        color:"white",
        background: "teal",
        textAlign:"center",
        width: "200px",
        padding:"4px 0px",
        top:"30px",
        right:"-55px",
        left:"auto",
        zIndex:10,
    }
}


const STEPS = [
    {
        stepperText: "Basic Information",
        getDialogState: function(){
            return true;
        },
        getButtonState: function(){
            return {
                back: false,
                forward: this.state.selectedRegion !== -1
            }
        },
        load: function(resolve, reject){
            DanceAPI.regions.get().then(d => {
                let regs = d;
                regs = regs.map(r => {
                    let defs = r.defaults;
                    let dss = [];
                    for(let k in defs){
                        let data = defs[k];
                        if(data.type === "data_source"){
                            let temp = dss.find(j => j.id === data.data_source.id);
                            if(temp !== undefined){
                                continue;
                            }
                            dss.push(data.data_source);
                        }
                    }

                    return {
                        ...r,
                        data_sources: dss
                    }
                });

                this.setState({
                    regions: regs
                });

                resolve();
            })
        },
        render: function(){
            const curr_region = this.state.regions.find(r => r.id === this.state.selectedRegion);
            return <Container maxWidth={"sm"} style={{display:"flex",padding:"8px 24px", flexDirection:"column", height:"100%"}}>
                <div style={{}}>
                    <TextField value={this.state.project_name}
                               onChange={e => this.setState({project_name:e.target.value})}
                               label={"Project Name"}
                               fullWidth
                               style={{minWidth:"300px"}}/>
                    <br/>
                    <br/>
                    <br/>

                    <RequiredSelect
                        style={{
                            width:"100%"
                        }}
                        warningText="You must select a region"
                        onChange={this.handleSelectedRegion }
                        value={this.state.selectedRegion}
                    >
                        {
                            this.state.regions.map(region => {
                                return <MenuItem key={region.id} value={region.id}>
                                    {region.name}
                                </MenuItem>
                            })
                        }
                    </RequiredSelect>
                </div>
                <br/>
            </Container>
        }
    },
    {
        stepperText: "Select Data Model",
        getDialogState: function(){
            return true;
        },
        getButtonState: function(){
            return {
                back: true,
                forward: this.state.selectedDataModel !== -1
            }
        },
        load: function(resolve, reject){
            DanceAPI.regionDataModels.get(this.state.selectedRegion).then(f => {
                this.setState({
                    data_models: f
                })
                resolve();
            })
        },

        render: function() {
            return  <Container maxWidth={"lg"} style={{height:"100%", overflow:"hidden",padding:"8px 24px"}}>
                <Grid container spacing={2} style={{height:"100%", overflowY:"auto", alignItems:"stretch"}}>
                    {this.state.data_models.map(model => {
                        let image="../../resources/images/retro_city.jpg";
                        let selected = this.state.selectedDataModel === model.id;
                        return <Grid key={model.id} item xs={12} sm={6} md={4} xl={3}>
                            <Card style={{position:"relative", height:"100%", display:"flex", flexDirection:"column"}} raised={false}>
                                {/*<div style={styles.ribbonWrapper}>{model.stage_text}</div>*/}
                                <CardMedia
                                    component={"img"}

                                    image={image}
                                    title={"asdads"}
                                />
                                <CardContent style={{backgroundColor:selected ? "#eeffff" : "white", flex:1}}>
                                    <Typography gutterBottom variant="body2" component="h2">
                                        {model.name}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        {model.description}
                                    </Typography>
                                </CardContent>
                                <CardActions  style={{backgroundColor:selected ? "#eeffff" : "white"}}>
                                    <Button  disabled color={"secondary"}>more info</Button>
                                    <Button style={{marginLeft:"auto"}}
                                            onClick={_=>
                                                this.setState({
                                                    selectedDataModel: selected ? -1:model.id
                                                })
                                            }
                                            color={"primary"}>{selected ? "deselect" : "select"}</Button>
                                </CardActions>

                            </Card>
                        </Grid>
                    })}
                </Grid>
            </Container>
        }
    },
    {
        stepperText: "Setup Data Model",
        getDialogState: function(){
            return true;
        },
        getButtonState: function(){
            return {
                back: true,
                forward: true
            }
        },
        load: function (resolve, reject) {
            Promise.all([
                DanceAPI.dataModelInitNode.get(this.state.selectedDataModel),
                DanceAPI.dataModelDataSources.get(this.state.selectedDataModel),
            ])
                .then(f => {

                    let extractParameters = (node_type) => {
                        const extract = (d,o) => {
                            d.map(data => {
                                o[data.parameter] = data.default;
                            })
                        }

                        let obj = {};
                        node_type.models.map(v => extract(v.parameter_description,obj))
                        return obj;
                    }

                    let params = extractParameters(f[0].version_data);

                    let data_model = this.state.data_models.find(f => f.id === this.state.selectedDataModel);

                    for(let k in params){
                        if(data_model.defaults.hasOwnProperty(k)){
                            let par = data_model.defaults[k];
                            console.log(par);
                            if(par.type === "data_source"){
                                params[k] = par.id;
                            }else{
                                params[k] = par.value;
                            }
                        }
                    }
                    this.setState({
                        dataModelInitNode: f[0],
                        dataModelDataSources: f[1],
                        dataModelInitNodeData:{
                            node_type: f[0],
                            node_version: f[0].version_data,
                            area: null,
                            parameters: params,
                        }
                    });
                    resolve();
                })
        },
        render: function(){
            if(!this.state.dataModelInitNode){
                return <div/>
            }
            return <div style={{display:"flex", height:"100%", width:"100%",padding:"8px 24px"}}>

                <div style={{flex:2, padding:"4px"}}>
                    <Typography variant={"h5"} style={{
                        borderBottom:"solid 1px rgba(0,0,0,0.23)",
                        marginLeft:"-28px",
                        marginRight:"-28px",
                    }}>
                        <div style={{paddingLeft:"30px"}}>
                            Data Model Parameters
                        </div>
                    </Typography>
                    <CreateProjectScenarioMakerNodeProperties
                        open={true}
                        data_sources={
                            [
                                ...this.state.dataModelDataSources,
                                ...this.props.data_sources
                            ]
                        }
                        node={this.state.dataModelInitNodeData}
                        parameterCallback={ (parameter, value) => {
                            let node = {
                                ...this.state.dataModelInitNodeData
                            };
                            node.parameters[parameter] = value;

                            this.setState({
                                dataModelInitNodeData: node,
                            })
                        }
                        }
                        noArea={true}
                        setAreaCallback={_=>{}}
                    />

                </div>
                {this.state.advancedSetup === null ? <div style={{background:"rgba(0,0,0,.83)",
                    display:"flex",
                    alignItems:"center",
                    justifyContent: "center",
                    position:"absolute", left:0, top:0, right:0, bottom:0}}>
                    <Paper elevation={1} style={{width:"40%", padding:"10px"}}>
                        <Typography>Setup Type</Typography>
                        What kind of project setup would you like?
                        <br/>
                        <br/>
                        <br/>
                        <Button
                            onClick={_=>this.setState({advancedSetup: true})}
                            fullWidth color={"primary"} variant={"contained"}>
                            advanced
                        </Button>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <Button

                            onClick={_=>{this.setState({advancedSetup: false}); this.nextStep()}}
                            fullWidth color={"primary"} variant={"contained"}>
                            Simple
                        </Button>
                        <div style={{textAlign:"center"}}>
                        (suitable for first time or non-advanced users)
                        </div>
                    </Paper>
                </div> : ""}
            </div>
        }
    },
    {
        stepperText: "Select Assessment Model",
        getDialogState: function(){
            return true;
        },
        getButtonState: function(){
            return {
                back: true,
                forward: this.state.selectedModels.length > 0
            }
        },
        load: function (resolve, reject) {
            DanceAPI.dataModelAssessmentModel.get(this.state.selectedDataModel)
                .then(f => {
                    this.setState({
                        allowedAssessmentModels: f
                    })
                    resolve();
                })
        },
        render: function(){
            return <Container maxWidth={"lg"} style={{height:"100%", overflow:"hidden",padding:"8px 24px"}}>
                <Grid container spacing={2} style={{height:"100%", overflowY:"auto", alignItems:"stretch"}}>
                    {this.state.allowedAssessmentModels.map(model => {
                        let selected = this.state.selectedModels.indexOf(model.id) > -1;
                        let image="../../resources/images/retro_city.jpg";
                        switch (model.name) {
                            case "Land Surface Temperature":
                                image="../../resources/images/lst.png";
                                break;
                            case "TARGET Urban Heat Island assessment":
                                image="../../resources/images/heat-island.jpg";
                                break;
                            case "Water Cycle Model":
                                image="../../resources/images/water_cycle_model.jpg";
                                break;
                            case "Water Cycle Model":
                                image="../../resources/images/water_cycle_model.jpg";
                                break;

                        }
                        return <Grid key={model.id} item xs={12} sm={6} md={4} xl={3}>
                            <Card style={{position:"relative", height:"100%", display:"flex", flexDirection:"column"}} raised={selected}>
                                <div style={styles.ribbonWrapper}>{model.stage_text}</div>

                                <CardMedia
                                    component={"img"}

                                    image={image}
                                    title={model.name}
                                />
                                <CardContent style={{backgroundColor:selected ? "#eeffff" : "white", flex:1}}>

                                    <Typography gutterBottom variant="body2" component="h2">
                                        {model.name}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        {model.description}
                                    </Typography>
                                </CardContent>
                                <CardActions  style={{backgroundColor:selected ? "#eeffff" : "white"}}>
                                    <Button onClick={_=>this.props.openHelp(model.active_version_data.meta_data.help_url)} color={"secondary"}>more info</Button>
                                    <Button style={{marginLeft:"auto"}} onClick={_=>this.handleSelect(model.id)} color={"primary"}>{selected ? "deselect" : "select"}</Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    })}
                </Grid>
            </Container>
        }
    },
    {
        stepperText: "Setup Assessment Model",
        getDialogState: function(){
            return true
        },
        getButtonState: function(){
            return {
                back: true,
                forward: this.state.selectedModels.length > 0
            }
        },
        load: function (resolve, reject) {
            Promise.all(this.state.selectedModels.map(id => {
                return DanceAPI.assessment_models_init_node.get(id).then(v => ({id:id, result:v}));
            })).then(results => {

                let params = results.map(v => v.result.version_data).map(this.extractParameters)

                this.setState({
                    modelNodes: results,
                    initNodes: results.map((v,i) => {
                        let node_type = v.result;
                        let ass_mod_id = v.id;
                        return {
                            assessment_model_id: v.id,
                            node_type: node_type,
                            node_version: node_type.version_data,
                            area: null,
                            parameters: params[i],
                        }
                    })
                })
                resolve();
            });
        }
        ,
        render: function(){
            return <div style={{height:"100%", width:"100%"}}>
                <div style={{marginLeft:"20px", paddingTop:"5px", paddingBottom:"5px"}}>
                    <Typography variant={"h6"}>Initialization Model Setup</Typography>
                </div>
                <div style={{
                    borderTop:"solid 1px rgba(0,0,0,0.23)",
                    width:"100%",
                    height:"100%",
                    display:"flex",
                }}>

                    <div style={{flex:"1 0 0", borderRight:"solid 1px rgba(0,0,0,0.23)"}}>
                        <List>
                            {
                                this.state.initNodes.map((node, index) => {
                                    return <ListItem
                                        onClick={_=>this.setState({selectedNode:index})}
                                        selected={index===this.state.selectedNode}
                                        dense
                                        button
                                        divider
                                    >
                                        {node.node_type.name}
                                    </ListItem>
                                })
                            }
                        </List>
                    </div>
                    <div style={{flex:"3 0 0", padding:"4px"}}>
                        {
                            this.state.selectedNode === -1 ?
                                <div style={{textAlign:"center"}}>
                                    Please select an performance assessment from the list
                                </div>
                                :
                                <div/>
                        }
                        {
                            <CreateProjectScenarioMakerNodeProperties
                                open={true}
                                data_sources={
                                    [
                                        ...(this.state.selectedRegion < 0 ? [] : this.state.regions.find(f => f.id === this.state.selectedRegion).data_sources),
                                        ...this.props.data_sources
                                    ]
                                }
                                node={this.state.initNodes[this.state.selectedNode]}
                                parameterCallback={(parameter, value) => {
                                    let node = {
                                        ...this.state.initNodes[this.state.selectedNode]
                                    };
                                    node.parameters[parameter] = value;
                                    let new_nodes = [...this.state.initNodes];
                                    new_nodes[this.state.selectedNode] = node;
                                    console.log(parameter, value);
                                    this.setState({
                                        initNodes: new_nodes,
                                    })
                                }}
                                noArea={true}
                                setAreaCallback={_=>{}}
                            />
                        }
                    </div>
                </div>
                {this.state.advancedSetup === false ? <div style={{background:"rgba(0,0,0,.83)",
                    display:"flex",
                    alignItems:"center",
                    justifyContent: "center",
                    position:"absolute", left:0, top:0, right:0, bottom:0}}>
                    <Paper elevation={1} style={{width:"40%", padding:"10px"}}>
                        <br/>
                        <div style={{textAlign:"center"}}>Not available in simple setup</div>
                        <br/>
                        <br/>
                        <br/>

                        <Button
                            fullWidth
                            onClick={_=>this.nextStep()}
                            variant={"contained"} color={"primary"}>
                            continue
                        </Button>
                    </Paper>
                </div> : ""}
            </div>
        }
    },
    {
        stepperText: "Define Project Boundary",
        getDialogState: function(){
            return !this.state.boundaryType;
        },
        getButtonState: function(){
            return {
                back: true,
                forward: this.state.selectedModels.length > 0
            }
        },
        load: function (resolve, reject) {
            DanceAPI.dataModelAssessmentModel.get(this.state.selectedDataModel)
                .then(f => {
                    this.setState({
                        allowedAssessmentModels: f
                    })
                    resolve();
                })
        },
        render: function(){
            if(!!this.state.boundaryType){


                return <div style={styles.boundaryPanel}>
                    <Slide direction={"left"} in={!!this.state.boundaryType}>
                        <Paper elevation={3} style={{pointerEvents:"all", boxSizing:"border-box", background: "white", padding:"10px", width:"100%",  marginTop:"10%", marginBottom:"10%"}}>
                            {
                                this.state.boundaryType === "draw" ?
                                    <div>
                                        <Typography variant={"h6"}>Draw Boundary</Typography>
                                        <Typography varient={"body1"}>
                                            Click to add a vertex. complete your boundary
                                            and click confirm to continue with the project setup
                                        </Typography>
                                        <br/>
                                        <div style={{display:"flex"}}>
                                            <Button style={{display:"inline-block"}} variant={"contained"} color={"secondary"} onClick={this.handleClear}>clear</Button>
                                            <div style={{flex:1}}/>
                                            <Button style={{display:"inline-block"}} variant={"contained"} color={"primary"} onClick={this.handleBoundaryConfirm}>confirm</Button>
                                        </div>
                                    </div>:
                                    <div>
                                        <Typography variant={"h6"}>Upload boundary</Typography>
                                        { this.state.boundaryFile === null ?
                                            <Dropzone onDrop={this.handleDrop}>
                                                {({getRootProps, getInputProps}) => (
                                                    <section style={{
                                                        borderRadius: "5px",
                                                        padding: "5px",
                                                        border: " dashed 2px grey"
                                                    }}>
                                                        <div {...getRootProps()}>
                                                            <input {...getInputProps()} />

                                                            <Typography variant={"body1"}>

                                                                Click here, or drag and drop a file here, to upload your
                                                                boundary
                                                            </Typography>
                                                        </div>
                                                    </section>
                                                )}
                                            </Dropzone>:
                                            <ListItem>
                                                <ListItemText>
                                                    {this.state.boundaryFile}
                                                </ListItemText>
                                            </ListItem>
                                        }
                                        <br/>
                                        <div style={{display:"flex"}}>
                                            <Button style={{display:"inline-block"}} variant={"contained"} color={"secondary"} onClick={this.handleClear}>clear</Button>
                                            <div style={{flex:1}}/>
                                            <Button style={{display:"inline-block"}} variant={"contained"} color={"primary"} onClick={this.handleBoundaryConfirm}>confirm</Button>
                                        </div>
                                    </div>
                            }
                        </Paper>
                    </Slide>
                </div>
            }

            return <Grid container style={{height:"100%"}}  spacing={8} >
                <Grid item xs={6} style={{height:"90%"}}>
                    <Card style={{ display:"flex", flexDirection:"column"}}>
                        <CardContent style={{flex:"1 1 0", textAlign: 'center'}}>
                            <CreateIcon style={{width:"100px", height:"100px", padding:"25px"}}/>
                            <div>
                                draw the project boundary by hand
                            </div>
                        </CardContent>
                        <CardActions>
                            <Button variant={"contained"} color={"primary"}  onClick={_=>this.handleBoundary("draw")} fullWidth>Draw</Button>
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item xs={6} style={{height:"90%"}}>
                    <Card style={{display:"flex", flexDirection:"column"}}>

                        <CardContent style={{flex:"1 1 0", textAlign: 'center', alignItems:"center"}}>
                            <CloudUploadIcon style={{width:"100px", height:"100px", padding:"25px"}}/>
                            <div>
                                upload geojson boundary
                            </div>

                        </CardContent>
                        <CardActions>
                            <Button variant={"contained"} color={"primary"} fullWidth onClick={_=>this.handleBoundary("upload")}>upload</Button>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>
        }
    },
    {
        stepperText: "Confirm",
        getDialogState: function(){
            return true;
        },
        getButtonState: function(){
            return {
                back: true,
                forward: false
            }
        },
        load: function (resolve, reject) {
            resolve();
        },
        render: function(){

            return <div style={{textAlign:"center",  alignItems:"center"}}>
                <Container>
                    <Card style={{display:"flex", height:"100%", flexDirection:"column" ,  alignItems:"center"}}>

                        <CardContent>
                            <Typography>
                                You're all set! Click below to confirm your project settings
                            </Typography>
                        </CardContent>
                        <CardActions>

                            <Button variant={"contained"}  color="primary"  onClick={this.handleSubmit}>submit</Button>
                        </CardActions>
                    </Card>
                </Container>
            </div>;
        }
    }


]



class CreateProjectScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            dialogOpen: true,
            dialogEntering: false,
            currentStep: 0,
            models: [],
            regions: [],
            data_models: [],
            area_error: null,
            loadingCurrentStep: false,
            advancedSetup: null,
            project_name: props.project.name,
            dataSourceDialogOpen: false,
            allowedAssessmentModels: [],
            selectedRegion: -1,
            selectedDataModel: -1,
            selectedModels: [],
            initNodes: [],
            errorText: "",
            loadingNodes: false,
            boundaryGeojson: null,
            errorDialogOpen: false,
            boundaryFile: null,
            selectedNode: -1
        }
    }

    componentDidMount() {

        this.setState({
            loadingCurrentStep: true,
        })
        new Promise((resolve, reject) => {
            STEPS[0].load.call(this, resolve, reject);
        }).then(f => {
            this.setState({loadingCurrentStep: false});
        })

    }



    handleBoundary = (type) => {

        this.setState({
            dialogOpen: false,
            boundaryType: type,
        })

        if(type === "draw") {
            this.props.mapRef.map.addControl(this.props.mapRef.mapdraw,'top-right');
            this.props.mapRef.mapdraw.changeMode("draw_polygon");
        }
    }


    handleSubmit = () => {

        let nodes = this.state.initNodes.map(node => {
            return {
                node_type_id: node.node_type.id,
                area: null,
                parameters: node.parameters,
            }
        });
        console.log(nodes);
        this.props.uploadProjectData(
            this.props.project.id,
            this.state.project_name,
            this.state.selectedRegion,
            this.state.selectedDataModel,
            this.state.dataModelInitNodeData.parameters,
            this.state.selectedModels.map(v => ({
                assessment_model_id: v,
                parameters: this.state.initNodes.find(node => node.assessment_model_id === v).parameters
            })),
            this.state.boundaryGeojson,
        )
    }


    handleSelect = (id) => {
        let temp = [...this.state.selectedModels];
        if(temp.includes(id)){
            temp = temp.filter(f => f!==id);
        }else{
            temp.push(id);
        }

        this.setState({
            selectedModels: temp
        })
    };


    extractParameters = (node_type) => {
        const extract = (d,o) => {
            d.map(data => {
                o[data.parameter] = data.default;
            })
        }

        let obj = {};
        node_type.models.map(v => extract(v.parameter_description,obj))
        return obj;
    }

    handleSelectedRegion = (e) => {
        this.setState({
            selectedRegion: e.target.value
        })


        const curr_region = this.state.regions.find(r => r.id === e.target.value);
        if (!curr_region)
            return;
        if (!this.props.mapRef)
            return;

        this.props.mapRef.map.flyTo(curr_region.geo_data)

    };


    renderStepper = () => {
        return <Stepper style={{paddingLeft:"0px", paddingRight:"0px"}} activeStep={this.state.currentStep} orientation={"vertical"}>
            {
                STEPS.map(v => {
                    return <Step>
                        <StepLabel>
                            {v.stepperText}
                        </StepLabel>
                    </Step>
                })
            }
        </Stepper>
    }

    renderStep = (index) => {
        return <div style={{position:"absolute", zIndex:this.state.currentStep===index?100:99, top:0, left:0, bottom:0, right:0}}>
            <Slide in={this.state.currentStep === index} direction={this.state.currentStep <= index ? "up" : "down"}>
                {STEPS[index].render.call(this)}
            </Slide>
            <Slide in={this.state.loadingCurrentStep && this.state.currentStep === index}>
                <div style={{
                    top:0,
                    left:0,
                    position:"absolute",
                    width:"100%",
                    height:"100%",
                    display:"flex",
                    alignItems:"center",
                    justifyContent: "center",
                    zIndex:"10",
                    background:"rgba(155,155,155,0.80)"}}>
                    <CircularProgress
                        thickness={4}
                        size={100} color={"secondary"}/>
                </div>
            </Slide>
        </div>
    }

    handleDrop = (acceptedFiles) => {
        if(acceptedFiles.length !== 1){
            this.setState({
                errorText: "You can only upload a single boundary",
                errorDialogOpen:true,
            })
        }
        let file = acceptedFiles[0];
        this.setState({
            boundaryFile: file.name,
        })

        let reader = new FileReader();
        reader.addEventListener("loadend", ()=> {
            const geojson = JSON.parse(reader.result);
            if ("crs" in geojson) {
                delete geojson.crs;
            }
            this.props.mapRef.map.addControl(this.props.mapRef.mapdraw,'top-right');
            this.props.mapRef.mapdraw.set(geojson);
        });
        reader.readAsText(file);
    }

    handleBoundaryConfirm = () => {
        const area = this.props.mapRef.mapdraw.getAll();
        const access_level = localStorage.getItem("access_level");
        this.props.mapRef.map.removeControl(this.props.mapRef.mapdraw);



        const filtered_area = {
            type:"GeometryCollection",
            geometries: area.features.map(f => f.geometry)
        }

        if(access_level < 2 && geometry(filtered_area) > case_study_area_limit){
            this.setState({
                area_error: "The provided area is larger than the maximum allowed"
            })
            return;
        }



        this.setState({
            boundaryType: null,
            boundaryGeojson: area,
            currentStep: this.state.currentStep + 1,
            dialogOpen: true,
            loadingNodes: true,
        })
    }

    handleClear = () => {
        this.props.mapRef.mapdraw.deleteAll();
        this.props.mapRef.mapdraw.changeMode("simple_select");
        this.props.mapRef.map.removeControl(this.props.mapRef.mapdraw);

        this.setState({
            boundaryGeojson: {},
            boundaryFile: null,
        })
    }

    nextStep = () => {
        let target_step = this.state.currentStep + 1;

        this.setState({
            currentStep: target_step,
            loadingCurrentStep: true,
        })
        Promise.all([new Promise((resolve, reject) => {
            STEPS[target_step].load.call(this, resolve, reject);
        }), new Promise(r => setTimeout(_=>r(),500))]).then(f => {
            this.setState({loadingCurrentStep: false});
        })
    }

    prevStep = () => {

        let target_step = this.state.currentStep - 1;

        this.setState({
            currentStep: target_step,
            loadingCurrentStep: true,
        })

        new Promise((resolve, reject) => {
            STEPS[target_step].load.call(this, resolve, reject);
        }).then(f => {
            this.setState({loadingCurrentStep: false});
        })
    }

    render() {
        let steps = [];
        let button_state = {};
        let dialog_open = false;

        button_state = STEPS[this.state.currentStep].getButtonState.call(this);
        dialog_open = STEPS[this.state.currentStep].getDialogState.call(this);

        for(let i = Math.max(0, this.state.currentStep - 1);
            i <= Math.min(this.state.currentStep + 1, STEPS.length-1);
            i++){
            steps.push(i);
        }
        return <div style={styles.root}>
            <Dialog open={!!this.state.area_error}
                    onBackdropClick={_=>this.setState({area_error:null})}
                    onEscapeKeyDown={_=>this.setState({area_error:null})}>
                <DialogTitle>Case Study Area Error</DialogTitle>
                <DialogContent>
                    {this.state.area_error}
                </DialogContent>
                <DialogActions>
                    <Button onClick={_=>this.setState({area_error:null})}>close</Button>
                </DialogActions>
            </Dialog>
            <div style={styles.boundaryPanel}>
                <Slide direction={"left"} in={!!this.state.boundaryType}>
                    <Paper elevation={3} style={{pointerEvents:"all", boxSizing:"border-box", background: "white", padding:"10px", width:"100%",  marginTop:"10%", marginBottom:"10%"}}>
                        {
                            this.state.boundaryType === "draw" ?
                                <div>
                                    <Typography variant={"h6"}>Draw Boundary</Typography>
                                    <Typography varient={"body1"}>
                                        Click to add a vertex. complete your boundary
                                        and click confirm to continue with the project setup
                                    </Typography>
                                    <br/>
                                    <div style={{display:"flex"}}>
                                        <Button style={{display:"inline-block"}} variant={"contained"} color={"secondary"} onClick={this.handleClear}>clear</Button>
                                        <div style={{flex:1}}/>
                                        <Button style={{display:"inline-block"}} variant={"contained"} color={"primary"} onClick={this.handleBoundaryConfirm}>confirm</Button>
                                    </div>
                                </div>:
                                <div>
                                    <Typography variant={"h6"}>Upload boundary</Typography>
                                    { this.state.boundaryFile === null ?
                                        <Dropzone onDrop={this.handleDrop}>
                                            {({getRootProps, getInputProps}) => (
                                                <section style={{
                                                    borderRadius: "5px",
                                                    padding: "5px",
                                                    border: " dashed 2px grey"
                                                }}>
                                                    <div {...getRootProps()}>
                                                        <input {...getInputProps()} />

                                                        <Typography variant={"body1"}>

                                                            Click here, or drag and drop a file here, to upload your
                                                            boundary
                                                        </Typography>
                                                    </div>
                                                </section>
                                            )}
                                        </Dropzone>:
                                        <ListItem>
                                            <ListItemText>
                                                {this.state.boundaryFile}
                                            </ListItemText>
                                        </ListItem>
                                    }
                                    <br/>
                                    <div style={{display:"flex"}}>
                                        <Button style={{display:"inline-block"}} variant={"contained"} color={"secondary"} onClick={this.handleClear}>clear</Button>
                                        <div style={{flex:1}}/>
                                        <Button style={{display:"inline-block"}} variant={"contained"} color={"primary"} onClick={this.handleBoundaryConfirm}>confirm</Button>
                                    </div>
                                </div>
                        }
                    </Paper>
                </Slide>
            </div>
            {
                !dialog_open ?
                    STEPS[this.state.currentStep].render.call(this)
                    :""
            }
            <Dialog
                TransitionComponent={Slide}
                open={dialog_open}
                onEnter={_=>this.setState({dialogEntering: true})}
                onEntered={_=>this.setState({dialogEntering: false})}
                maxWidth={"lg"}
                fullWidth
            >
                <DialogTitle>
                    Setup new project
                    <div style={{float:"right"}}>
                        <IconButton style={{margin:"-5px"}}
                                    onClick={_=>this.setState({dataSourceDialogOpen: true})}
                        >
                            <FolderIcon/>
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent dividers style={{height:"68vh", overflow:"hidden", padding:"0px"}}>
                    {this.state.dialogEntering ? <div></div> :
                        <div style={{height: "100%", width: "100%", display: "flex", flexDirection: "row"}}>
                            <div style={{borderRight:"solid 1px rgba(0,0,0,0.12)", padding:"8px 24px"}}>
                                <Slide direction={"right"} in={true}>
                                    {this.renderStepper()}
                                </Slide>
                            </div>
                            <div style={{flex: 3, position: "relative", height: "100%"}}>
                                {steps.map(v => {
                                    return this.renderStep(v)
                                })}
                            </div>
                        </div>
                    }

                </DialogContent>
                <DialogActions>
                    <Button variant="contained"  color={"secondary"} style={{marginRight:"auto"}} onClick={() =>{
                        this.setState({
                            dialogOpen: false
                        })
                        this.props.history.push("")
                    }} /* delete project and go home*/>
                        cancel

                    </Button>

                    <Button variant="contained"  color={"secondary"}  disabled={!button_state.back}
                            onClick={this.prevStep}
                    >
                        back
                    </Button>

                    <Button variant="contained" color={"primary"}  disabled={!button_state.forward} onClick={this.nextStep}>
                        next
                    </Button>
                </DialogActions>
            </Dialog>


            <DataSourceDialog
                open={this.state.dataSourceDialogOpen}
                dismissCallback={_=>this.setState({dataSourceDialogOpen: false})}
            />

            <Dialog  onClose={_=>this.setState({errorDialogOpen: false})} open={!!this.state.errorDialogOpen}>
                <DialogTitle>
                    Error!
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {this.state.errorText}
                    </DialogContentText>
                </DialogContent>
            </Dialog>

        </div>;
    }
}

CreateProjectScreen.propTypes = {
    mapRef: PropTypes.object,
    project: PropTypes.object,
};

const mapStateToProps = (state) => {
    return {
        assessment_models: state.assessment_models,
        data_sources: state.data_sources,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getAssessmentModels: () => {
            return dispatch(fetchAssessmentModels())
        },

        openHelp: (url) => {
            dispatch(openHelp(url))
        },
        uploadProjectData: (id, name, region, data_model, data_model_node_parameters, assessment_models, boundary) => {

            dispatch(createGeoJSON(id, boundary, "Case study area")).then(geojson_result => {
                return DanceAPI.project.put(id, {
                    name,
                    region_id: region,
                    case_study_area_id: geojson_result.id,
                })
            }).then(result => {
                return DanceAPI.projectDataModel.post(id, {
                    data_model_id: data_model,
                    parameters: data_model_node_parameters
                });
            }).then(result => {
                return DanceAPI.projectModels.put(id, assessment_models);
            }).then(result => {
                return dispatch(createScenario(null, id, "Baseline"))
            }).then(result => {
                return DanceAPI.scenarioExecute.post(result.id)
            }).then(result => {
                return dispatch(loadProject(id))
            })
        }
    }
}
CreateProjectScreen = withRouter(CreateProjectScreen)
export default connect(mapStateToProps, mapDispatchToProps)(CreateProjectScreen);
