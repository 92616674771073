import React, {} from 'react'
import PropTypes from 'prop-types'
import ScreenWrapper from "./ScreenWrapper";
import Card from "@material-ui/core/Card/Card";
import CardContent from "@material-ui/core/CardContent"
import CardHeader from "@material-ui/core/CardHeader"
import Container from "@material-ui/core/Container/Container";
import Grid from "@material-ui/core/Grid/Grid";
import Typography from "@material-ui/core/Typography/Typography";
import Divider from "@material-ui/core/Divider/Divider";
import CardActions from "@material-ui/core/CardActions/CardActions";
import Button from "@material-ui/core/Button/Button";
import {withRouter} from "react-router";
import DanceAPI from "../actions/DanceAPI";
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import HomeIcon from "@material-ui/core/SvgIcon/SvgIcon";
import IconButton from "@material-ui/core/IconButton/IconButton";
import {clearState} from "../actions/global_actions";
import {loadProject} from "../actions/project_actions";
import connect from "react-redux/es/connect/connect";
import CardMedia from "@material-ui/core/CardMedia/CardMedia";
import {Tooltip} from "@material-ui/core";

import Avatar from '@material-ui/core/Avatar';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import {
    createMuiTheme,
    MuiThemeProvider,
    withStyles
} from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import CloseIcon from '@material-ui/icons/Close';



const AddingButton = withStyles(theme => ({
    root: {
        margin:"auto",
        backgroundColor:"rgba(0,103,114,0.2)",
        color:"rgba(0,103,114,1)",
        '&:hover': {
            backgroundColor: "rgba(0,103,114,0.5)",
        },
    },
}))(IconButton);

const OpenButton = withStyles(theme => ({
    root: {
        margin:"auto",
        backgroundColor:"rgba(0,103,114,0.2)",
        color:"rgba(0,103,114,1)",
        '&:hover': {
            backgroundColor: "rgba(0,103,114,0.5)",
        },
    },
}))(IconButton);

const styles = {
    date:{
        backgroundColor:"rgba(0,0,0,0)",
        color:"#FFFFFF",
        fontSize:"12px",
        verticalAlign: "baseline",
        marginTop:"-40px",
        padding:"21px"
    },

    title:{
        color:"#FFFFFF",
        backgroundColor:"rgba(0,0,0,0)",
        fontWeight: "bold",
        fontSize: "28px",
        padding:"20px",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        // textShadow:["0 1px 1px #D9E2EC",
        //     "0px 2px 0 #D9E2EC",
        //     "1px 3px #D9E2EC",],
    },

    create:{
        color:"#0A6C74",
        fontWeight: "bold",
        fontSize: "28px",
        padding:"15px",
    },

    avatar:{
        color:"#FFFFFF",
        backgroundColor:"#627D98",
        marginLeft:"auto",
    },

    author:{
        backgroundColor:"rgba(0,0,0,0)",
        color:"#829AB1",
        paddingBottom:"15px",
    },

    actions:{
        backgroundColor: "#F0F4F8",
    }
};

const theme = createMuiTheme({
    overrides: {
        MuiTooltip: {
            tooltip: {
                fontSize: "1em",
                backgroundColor:"rgba(16, 42, 67, 0.5)",
                color:"#FFFFFF",
                boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                maxWidth:"150px",
                wordWrap:"break-word",
            }
        },
    }
});

class ProjectsScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loadingProjects: true,
            selected: null,
            projects: [],
            snackbarOpen: false,
            snackMessage: "",
            removed: []
        }
    }

    componentDidCatch(error, info) {
        // Display fallback UI
        this.setState({ snackbarOpen: true , snackMessage: info});

        // console.error(error, info);

    };

    handleLogout = () => {
        localStorage.removeItem("access_token");
        this.props.history.push('/login');
        window.location.reload();

    };

    handleClose = () => {
        this.setState({
            snackbarOpen: false,
        })

        this.handleLogout()

    };

    componentDidMount() {
        this.props.clearState();

        const query = new URLSearchParams(
            this.props.location.search
        );

        let data= {};
        for (let params of query.entries()) {
            data[params[0]] = params[1];
        }

        if ("jwt" in data) {
            localStorage.setItem("access_token", data.jwt)
            localStorage.setItem("access_level", 1)
        }

        const access_token = localStorage.getItem("access_token");

        if (access_token === null || access_token === undefined) {
            this.props.history.push("/login")
        }

        DanceAPI.projects.get().then(result => {
            // project not properly loaded. Redirect to login
            if (!("projects" in result)){
                this.setState({
                    snackbarOpen: true,
                    snackMessage: "Unable to load projects. Either the server is down our your " +
                        "token has expired. You will been redirected to the login page."
                });
                return
            }
            this.setState({
                loadingProjects: true,
                projects: result["projects"]
            })
        });
    }

    handleCreateProject = () => {
        DanceAPI.projects.post().then(result => {
            this.props.history.push("/project/"+result.id)
        })
    }

    handleDeleteProject =(proj_id) => {
        console.log("deactive me")
        DanceAPI.project.delete(proj_id).then(result => {
            this.setState({removed: [...this.state.removed,proj_id]})
        })
    }


    handleProjectClick = (proj_id) => {
        this.props.history.push("/project/"+proj_id)
    }



    renderProjectCard = (proj, index, selected) => {

        const projectTime = new Date(proj.created_at*1000);
        const months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
        const year = projectTime.getFullYear();
        const month = months[projectTime.getMonth()];
        const date = projectTime.getDate();
        const hour = projectTime.getHours();
        const min = "0" + projectTime.getMinutes();
        const timeToDisplay = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min.substr(-2);

        if (this.state.removed.includes(proj.id))
            return <div></div>

        return <Grid item xl={2} lg={3} sm={5} xs={11}>
            <Card style={{borderRadius:"0px", backgroundImage:"linear-gradient(#044E54,#044E54)"}} raised>
                <Tooltip title={proj.name} placement={'bottom-end'}>
                    <CardContent style={styles.title}>{proj.name.toUpperCase()}</CardContent>
                </Tooltip>
                <CardContent style={styles.date}>
                    {timeToDisplay}
                </CardContent>
                <Divider/>
                <CardActions style={styles.actions}>
                    {/*<IconButton style={{marginLeft:"auto", backgroundColor: "#FACDCD", color: "#780A0A" }} size={"small"}><DeleteIcon/></IconButton>*/}
                    <Tooltip title="View project" placement={'bottom-end'}>

                        <Button variant="outlined" color="inherit" size={"small"} style={{backgroundColor: "#F0F4F8", color: "#243B53"}}
                                onClick={_=>this.handleProjectClick(proj.id)}>Open
                            <KeyboardArrowRightIcon/>
                        </Button>
                    </Tooltip>
                        <Tooltip title="Remove project" placement={'bottom-end'}>
                    <Button variant="outlined" color="inherit" size={"small"}  style={{marginLeft:"auto", backgroundColor: "FFEEEE", color: "#780A0A" }}
                            onClick={_=>this.handleDeleteProject(proj.id)}>Remove
                        <DeleteIcon/>
                    </Button>


                    </Tooltip>
                </CardActions>
            </Card>
        </Grid>
    }

    render() {
        return <ScreenWrapper title={"Projects"} backgroundImage>
            <Container maxWidth={"xl"} >

             <Grid container spacing={3} xl={12} direction="row" style={{padding:"20px", marginTop:"40px"}}>
                <Grid item xs={5} sm={3} md={2} lg={2}>

                    <div style={{position:"sticky", top:"68px"}}>
                        <Tooltip title="Create new project" placement={'bottom-end'}>
                                <AddingButton style={{backgroundColor:"#044E54", color: "#FFFFFF"}} onClick={()=>{this.handleCreateProject()}} >
                                    <AddIcon  fontSize="large" />
                                </AddingButton>
                        </Tooltip>
                    </div>

                </Grid>
                 <Grid item xs={7} sm={9} md={9} lg={10} container spacing={3} >
                     {this.state.projects.map((p, i) => this.renderProjectCard(p, i, this.state.selected === i))}
                 </Grid>
             </Grid>


            </Container>

            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={this.state.snackbarOpen}
                onClose={this.handleClose}
                autoHideDuration={6000}>
                <SnackbarContent
                    message={<span id="message-id" >{this.state.snackMessage}</span>}
                    style={{backgroundColor: "#d32f2f"}}
                    action={[
                        <IconButton
                            key="close"
                            aria-label="close"
                            color="inherit"
                            onClick={this.handleClose}
                        >
                            <CloseIcon />
                        </IconButton>,
                    ]}
                />
            </Snackbar>
        </ScreenWrapper>;
    }
}

ProjectsScreen.propTypes = {};

const mapStateToProps = (state, ownProps) => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => {

    return {
        clearState: () => {
            dispatch({type:"RESET_STATE"});
        },
    }
}

ProjectsScreen = withRouter(ProjectsScreen);
export default connect(mapStateToProps, mapDispatchToProps)(ProjectsScreen);