


const scenario_maker_node = (state, action) => {
    switch (action.type) {
        case 'SMO_INIT':{
            return {
                'id': action.data.id,
                'name': action.data.name,
                'description': action.data.description,
                'active_version': action.data.active_version,
                'tags': action.data.tags,
                'versions': action.data.versions,
            }
        }
        default:
            return state;
    }
}

const scenario_maker_nodes = (state=[], action) => {
    switch (action.type) {
        case 'SMO_INIT':{
            return action.nodes.map((data) => scenario_maker_node({}, {type: action.type, data:data}))
        }
        default:
            return state;
    }
}

export default scenario_maker_nodes;
