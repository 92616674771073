/**
 * Created by User on 7/09/2020
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import IconMap from "../../icons/icons";

class WaterCycleDiagram extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
    };

    componentWillUnmount() {
    };

    renderCircle(c, r, v, units, icon, text){
        return <g>
            <circle
                fill={"none"}
                strokeWidth={0.02}
                stroke={"#DAE3ED"}
                r={r}
                cx={c[0]}
                cy={c[1]}
            />
            <text
                fill={"#2F4653"}
                letterSpacing={"-0.1em"}
                fontWeight={470}
                textAnchor={"middle"} fontSize={"0.05"}
                transform={`translate(${c[0]}, ${c[1] - 5*r/6 + 0.05})`} >{v}</text>

            <text
                fill={"#6A9EA6"}
                letterSpacing={"-0.1em"}
                fontWeight={470}
                textAnchor={"middle"} fontSize={"0.03"}
                transform={`translate(${c[0]}, ${c[1] - r/2 + 0.04})`} >{units}</text>
            <g transform={`translate(${c[0]- 0.03} , ${c[1]-0.015}) scale(0.06, 0.06)`}>
                {icon}
            </g>
            <text
                fill={"#2F4653"}
                letterSpacing={"-0.01em"}
                fontWeight={500}
                textAnchor={"middle"} fontSize={"0.02"}
                transform={`translate(${c[0]}, ${c[1] + 19*r/20 - 0.04})`} >{text}</text>

        </g>
    }

    renderCurvedArrow(w, p1, p2, sweep=false) {
        let temp = p1;
        if(!sweep) {
            p1 = p2;
            p2 = temp;
        }
        let r = Math.sqrt((p1[0]-p2[0])**2 + (p1[1]-p2[1])**2)/2;
        let center = [(p1[0] + p2[0])/2, (p1[1] + p2[1])/2]
        let n = 4;
        let l = Math.sqrt((p2[0]-p1[0])**2+(p2[1]-p1[1])**2);
        let d = [(p2[0]- p1[0])/l,  (p2[1] - p1[1])/l];


        let cx1 = center[0] + (sweep ? -1: 1)*1.414*r;
        let cy1 = center[1] + (d[1] > 0 ? -1: 1)*(n-1)*r/n;

        let cx2 = center[0] + (sweep ? -1: 1)*1.414*r;
        let cy2 = center[1] - (d[1] > 0 ? -1: 1)*(n-1)*r/n;

        let o = [((d[1] < 0) ? -1 : 1)*d[1], d[0]];
        let sweep_factor = sweep ? -1 : 1
        return <g>
            <path
                strokeWidth={w}
                stroke={"#DAE3ED"}
                fill={"none"}
                d={sweep ? `
                    M ${p1[0]-w*0.9} ${p1[1]}
                    C ${cx1} ${cy1}, ${cx2} ${cy2}, ${p2[0]} ${p2[1]}
                `:`
                    M ${p1[0]} ${p1[1]}
                    C ${cx1} ${cy1}, ${cx2} ${cy2}, ${p2[0] + w*0.9} ${p2[1]}
                `}
            />

            <polygon
                fill={"#DAE3ED"}
                points={`
                         ${(sweep?p1:p2)[0] + d[0]*w + sweep_factor*o[0]*w},${(sweep?p1:p2)[1] + d[1]*w + o[1]*w}
                         ${p2[0]},${(sweep?p1:p2)[1]}
                         ${(sweep?p1:p2)[0] - d[0]*w + sweep_factor*o[0]*w},${(sweep?p1:p2)[1] - d[1]*w + o[1]*w}
                    `
                }
            />
        </g>
    }

    renderArrow(w, p1, p2, dir=true){
        let temp = p1;
        if(dir) {
            p1 = p2;
            p2 = temp;
        }
        let l = Math.sqrt((p2[0]-p1[0])**2+(p2[1]-p1[1])**2);
        let d = [(p2[0] - p1[0])/l, (p2[1] - p1[1])/l];
        let o = [-d[1], d[0]];
        return <g>
            <path
                strokeWidth={w}
                stroke={"#DAE3ED"}
                d={`
                        M ${p1[0]} ${p1[1]}
                        L ${p2[0] - d[0]*w} ${p2[1] - d[1]*w}
                    `}
            />
            <polygon
                fill={"#DAE3ED"}
                points={`
                         ${p2[0] + o[0]*w - d[0]*w},${p2[1] + o[1]*w - d[1]*w}
                         ${p2[0]},${p2[1]}
                         ${p2[0] - o[0]*w - d[0]*w},${p2[1] - o[1]*w - d[1]*w}
                    `}
            />
        </g>
    }

    formatNumber(value) {
        value = value || 0;
        // if (units.includes("'000")) {
            if (value > 1000000000) {
                value = value / 1000000000
                // units = units.replace("'000", "'000,000,000")
            } else if (value > 1000000) {
                value = value / 1000000
                // units = units.replace("'000", "'000,000")
            } else if (value > 1000)
                value = value / 1000
            // else
            //     units = units.replace("'000", "")
        // }
        return value.toFixed(1)
    }

    renderSvg(query_formats, values){
        values = values.map(v => v || 0);
        let min_value = Math.min(...values.map(parseFloat));
        let max_value = Math.max(...values.map(parseFloat));
        let mods;
        if(max_value - min_value === 0) {
            mods = values.map(v => 1);
        }else{
            mods = values.map(v => (v - min_value) / (max_value - min_value));
        }
        values = values.map((v) => this.formatNumber(v))
        const C = (x) => Math.cos(x)
        const S = (x) => Math.sin(x)
        const BuildingIcon = IconMap["buildings"];
        return <div style={{height:"375px"}}>
        <svg
            height={"100%"}
            shapeRendering="geometricPrecision "
            viewBox={"-0.1 0  1.2 1"}>

            <text
                fill={"#2F4653"}
                letterSpacing={"-0.02em"}
                fontWeight={600}
                textAnchor={"middle"} fontSize={"0.024"}
                transform={`translate(0.5, ${0.445-0.012})`} >
                URBAN AREA
            </text>
            <text
                fill={"#2F4653"}
                letterSpacing={"-0.02em"}
                fontWeight={600}
                textAnchor={"middle"} fontSize={"0.024"}
                transform={`translate(0.5, ${0.575+0.012})`} >
                WATER CYCLE
            </text>

            <g  transform={"translate(0.44, 0.44) scale(0.12, 0.12)"}>
                <BuildingIcon/>
            </g>
            {[...new Array(8)].map((_,i) => {
                let w = 0;
                let dx = C(Math.PI * 2.0 * i / 8.0 + Math.PI / 8);
                let dy = S(Math.PI * 2.0 * i / 8.0 + Math.PI / 8);
                return <g >
                    {this.renderCircle(
                        [
                            dx * 0.36 + 0.5,
                            dy * 0.36 + 0.5
                        ],
                        0.125,
                        values[i],
                        query_formats[i].units,
                        React.createElement(IconMap[query_formats[i].icon]),
                        query_formats[i].label
                    )}

                    {this.renderArrow(mods[i]*0.03 + 0.03,
                        [
                            dx * 0.225 + 0.5,
                            dy * 0.225 + 0.5
                        ],
                        [

                            dx * 0.11 + 0.5,
                            dy * 0.11 + 0.5
                        ],
                        i < 4
                    )}
                </g>
            })}
            {
                this.renderCurvedArrow(0.025,

                    [0.97, 0.44],
                    [0.97, 0.56],
                    false
                )
            }
            {
                this.renderCurvedArrow(0.025,

                    [0.03, 0.44],
                    [0.03, 0.56],
                    true
                )
            }
        </svg>
            </div>
    }
    render() {

        if(!this.props.query_data || this.props.query_data.length === 0){
            return "BAD"
        }
        let getMDK = (qd) =>qd.query_prototype.query_data.main_data_key;

        let main_data_keys = this.props.query_data[0].queries.map(getMDK);
        let query_formats = this.props.dash_tile_template.data.meta_data.query_formats;
        console.log("queries", this.props.query_data)
        let reference = null;
        let ref_name = null;
        if (this.props.reference_scenario)
            reference = this.props.query_data.filter((q) => q.name === this.props.reference_scenario.name)[0]
        if (reference)
            ref_name = reference.name;
        let data_queries = this.props.query_data.filter( (q) => q.name !== ref_name );

        let values = query_formats.map((qf, i) => {

            let query_entry = data_queries[0].queries
                .find(qd => {
                    return qd.query_prototype.query_data.name === qf.source_value
                })
            return query_entry.data[0][query_entry.query_prototype.query_data.main_data_key]
        })




        return this.renderSvg(query_formats, values);
        return "";
    }
}


WaterCycleDiagram.propTypes = {
    query_data: PropTypes.any,
    dash_tile_template: PropTypes.object,
    reference_scenario: PropTypes.any,
    scenario : PropTypes.object,
    context: PropTypes.object,
}


export default WaterCycleDiagram;
