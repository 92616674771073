import React from 'react'
import PropTypes from "prop-types"
import ReactEcharts from "echarts-for-react/lib/index";

class BarChart extends React.Component {

    constructor(props) {
        super(props);
    }

    getGraph = () => {
        let main_data_key = this.props.query_data[0].queries[0].query_prototype.query_data.main_data_key;
        let units = this.props.query_data[0].queries[0].query_prototype.query_data.field_information[main_data_key].units
        let xAxisLabel = this.props.query_prototypes.map(q => q.query_data.name)
        let xAxisLabelName = (xAxisLabel.map(q => q.replace(" ","\n"))).map(q => q.replace(" ","\n"))

        let reference = null
        let ref_name = null
        if (this.props.reference_scenario)
            reference = this.props.query_data.filter((q) => q.name === this.props.reference_scenario.name)[0]
        if (reference)
            ref_name = reference.name;
        let data_queries = this.props.query_data.filter( (q) => q.name !== ref_name )

        let temp = {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                },
                confine: true
            },
            toolbox: {
                feature: {

                    magicType: {
                        type: ['line', 'bar', 'stack', 'tiled'],
                        title: {line: 'line', bar: 'bar', stack: 'stack', tiled: 'tiled'}
                    },
                    dataView: {title: 'data', lang: ['data', 'close', 'refresh'], readOnly: true,
                        optionToContent: function(opt) {
                            const axisData = opt.xAxis[0].data;
                            console.error(opt.series);
                            const series = opt.series;
                            let toprow_csv = "Category, " + (series.map(s => s.name)).join(", ");
                            let toprow = "<td>Category, </td><td>" + (series.map(s => s.name)).join(",</td> <td>") + "</tr>"
                            console.error(toprow)

                            let data;
                            let data_csv;

                            data_csv = toprow_csv;
                            data = "<table>" + toprow;
                            for(let i = 0; i < axisData.length; i++){
                                let line = "<tr><td>";
                                let line_csv;
                                let cat = axisData[i];
                                line_csv = cat.replace("\n", " ") + "," + series.map(s => s.data[i]).join(", ");
                                line += cat + ",</td><td>";
                                line += (series.map(s => s.data[i])).join(",</td> <td>");
                                data_csv += "\n" + line_csv;
                                data += "</tr>" + line;
                            }
                            data += "</tr></table>";
                            let dl_link = "<a download='data.csv' href='data:text/plain;charset=utf-8,"+encodeURIComponent(data_csv)+"'>" +
                                "<button>save</button>" +
                                "</a>"
                            return dl_link + data;
                        }
                    },
                    saveAsImage: {backgroundColor: '#FFFFFF', title: 'save'},
                },
            },
            grid: {
                top:30,
                left:10,
                right:0,
                bottom: 22,
                containLabel: true
            },
            // color: graph_color,
            legend: {
                // right: 0,
                // top: 40,
                bottom: 0,
                type: 'scroll',
                orient: 'horizontal',
                data: this.props.query_data.map((dataset,i) => dataset.name)
            },
            xAxis: [{
                axisLabel: {
                    interval: 0,
                    textStyle: {
                        baseline: "top",
                        fontSize: 10
                    }
                },
                type: 'category',
                data: xAxisLabelName,
            }],
            yAxis: [{
                name: units,
                type: 'value'
            }],
            series: data_queries.map((dataset,i) => ({
                name: dataset.name,
                type: 'bar',
                xIndex: i,
                data: dataset.queries.map((d, index) => {
                    if(d.running || !d.data || !d.data[0] || null==d.data[0][main_data_key]){
                        return undefined;
                    }
                    // if (!d.data[0][main_data_key]) {
                    //     return  undefined;
                    // }
                    let val = d.data[0][main_data_key]
                    if (reference) {
                        val = val - reference.queries[index].data[0][main_data_key] ;
                        //console.log(reference, index,main_data_key,reference.queries[index].data[0][main_data_key], d.data[0][main_data_key], val)
                    }

                    return val.toFixed(2)
                })
            }))

        };
        return temp;
    };

    render() {
        if(this.props.query_data.length === 0 || this.props.query_data[0].queries.length === 0 || this.props.all_loaded === false){
            return <div></div>
        }

        return <div>
            <ReactEcharts
                option={this.getGraph()}
                lazyUpdate={true}
                notMerge={true}
                style={{height: '270px', width: '95%'}}
                className='react_for_echarts' />
        </div>
    }
}


BarChart.propTypes = {
    query_data: PropTypes.any,
    reference_scenario: PropTypes.any
}

export default BarChart;
