import React from 'react'
import PropTypes from "prop-types"
import {connect} from "react-redux";
import {ListItemText, TextField, Typography} from "@material-ui/core/index";
import Button from "@material-ui/core/Button/index";
import Slide from "@material-ui/core/Slide/index";
import ListItem from "@material-ui/core/ListItem/index";
import List from "@material-ui/core/List/index";
import {createGeoJSON, highlightGeojson} from "../actions/geojson_actions";
import GeoJSONList from "./GeoJSONList";
import {SwatchesPicker} from "react-color"
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Paper from "@material-ui/core/Paper";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import StopIcon from "@material-ui/icons/Stop"
import Fab from "@material-ui/core/Fab";
import DynamicList from "./Views/DynamicList";
import BackIcon from "@material-ui/icons/NavigateBefore";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import Dropzone from "react-dropzone";
import BoundariesDropDownList from "./BoundariesDropDownList"
import DeleteIcon from '@material-ui/icons/Delete';
import Checkbox from "@material-ui/core/Checkbox";
import CloseIcon from '@material-ui/icons/Close'
import DialogActions from '@material-ui/core/DialogActions';
import Popover from "@material-ui/core/Popover";
import InputLabel from "@material-ui/core/InputLabel";
import {help_url, tile_server} from "../config";
import Help from '@material-ui/icons/Help';
import HelpDrawer from "../Components/HelpDrawer";
import {openHelp} from "../actions/help_actions";
import Box from "@material-ui/core/Box";
import RoomIcon from '@material-ui/icons/Room';
import DnsIcon from '@material-ui/icons/Dns';

import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import GeoJSONSelector from "./GeoJSONSelector";
import AddFilterPresetDialog from "./Views/AddFilterPresetDialog";
import OutlinedInput from "@material-ui/core/OutlinedInput";


const styles = {
    panel:{
        width:"100%",
        right:0,
        height:"100%",
        position:"relative",
        bozSizing:"border-box",
    },
    panelInner:{

        width:"100%",
        display:"flex",
        height:"100%",
        position:"absolute",
        flexDirection:"column",
        pointerEvents:"all",
        overflow:"hidden",
        bozSizing:"border-box",
        background:"#f5f5f5"
    },

    areaListWrapper: {
        maxHeight:"400px"
    }
};


class ScenarioMakerNodePropertiesPanel extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectingArea: false,
            addingArea: false,
            areas_open: false,
            boundaryFile:null,
            addingFilterPreset: false,
            area_color: "#7586fe",
            openDeleteAreaDialog:false,
            deleteAreaConfirmation:false,
            color_picker_open: false,
            color_picker_anchor: null,
            deleteAreasList:[],
            parameters: {},
        };
    }

     componentDidUpdate = (prevProps, prevState, snapshot) => {
         if (prevProps.node !== this.props.node)
            this.setState({parameters: {}})
    }

    hasError = (parameter, value ) => {


        let v = value
        if (v === undefined) {
            v = this.state["parameters"][parameter.parameter]
        }
        if (v === undefined) {
            v = this.props.node.parameters[parameter.parameter]
        }
        if (v === undefined && parameter.type === "number")
            return false
        if (v === undefined)
            return false
        if (parameter.value_range === undefined)
            return false

        if (parameter.type === "number") {

            if (parameter.value_range[0] > Number(v))
                return true
            if (parameter.value_range[1] < Number(v))
                return true
        }
        return false
    }

    errorMessage = (parameter,  value) => {
        if (this.hasError(parameter,  value)) {
            if (parameter.type === "number") {
                return "Values should be " + parameter.value_range.join("-");
            }
        }
        return ""
    }
    handleOnChange = (parameter,  value) => {

        let updateParameter = {};
        updateParameter[parameter.parameter] = value;


        if (this.hasError(parameter, value) === false) {
            this.props.parameterCallback(parameter.parameter,  value)
        }
        this.setState({parameters: updateParameter});
    }


    renderParameter = (parameter) => {
        let has_error = this.hasError(parameter);
        let errorMessage = this.errorMessage(parameter);

        if (parameter.advanced_setting !== undefined)
            return <div/>


        let is_derived = parameter.derived;

        if(is_derived === true){
            if(parameter.type === "discrete"){
                let source = parameter.source;
                let source_parts = source.split("@");
                let source_field = source_parts.slice(0, -1).join("");
                let source_key = source_parts[source_parts.length-1];

                let source_ds =this.state["parameters"][source_field] !== undefined ?
                    this.state["parameters"][source_field]:
                    this.props.node.parameters[source_field] !== undefined ?
                        this.props.node.parameters[source_field]: undefined;

                if(source_ds === undefined){
                    return "";
                }
                source_ds = this.props.data_sources.find(ds => ds.id === source_ds);
                console.log(source_ds.data);
                let ds_data = JSON.parse(source_ds.data);

                if(ds_data[source_key] === undefined){
                    return "";
                }

                console.log(source_ds);
                return <div style={{marginLeft:"10px", display:"flex"}}>
                    <div style={{flex:"1 0 0", display:"flex", alignItems:"center"}}>
                        {parameter.name}
                    </div>
                    <div style={{width:"10px"}}/>
                    <div style={{flex:"2 0 0"}}>
                        <Select

                            input={<OutlinedInput margin="dense" name={parameter.name} id={parameter.parameter}/>}
                            style={{width:"100%", flex:"1"}}
                            label={parameter.name}
                            onChange={e=> {
                                if(e.target.value === -2){
                                    this.setState({
                                        dataSourceDialogOpen: true
                                    })
                                    return;
                                }
                                this.handleOnChange(parameter, e.target.value)
                            }}
                            value={this.state["parameters"][parameter.parameter] !== undefined ? this.state["parameters"][parameter.parameter]:
                                this.props.node.parameters[parameter.parameter] !== undefined ?
                                    this.props.node.parameters[parameter.parameter] :
                                    -1}
                        >
                            {
                                ds_data[source_key].map(d => {
                                    return <MenuItem value={d}>
                                        {d}
                                    </MenuItem>
                                })


                            }
                        </Select>
                    </div>

                </div>
            }
        }

        if(parameter.type === "group"){
            return <div style={{ marginLeft:"4px"}}>
                <Typography variant={"caption"}>
                    {parameter.name}
                </Typography>
                <div>
                    {
                        parameter.fields.map(this.renderParameter)
                    }
                </div>
            </div>
        }else if(parameter.type === "text" || parameter.type === "number") {

            return <div style={{marginLeft:"10px", display:"flex"}}>

                <TextField
                    error={ has_error}
                    label={parameter.name}
                    style={{flex:1, textAlign:"right", marginRight:"10px"}}
                    margin={"dense"}
                    inputProps={{
                        style:{
                            "padding": "10px",
                            textAlign:"right",
                        }
                    }}
                    type={parameter.type}
                    variant={"outlined"}
                    helperText={errorMessage}
                    fullWidth
                    onChange={e=>this.handleOnChange(parameter, e.target.value)}
                    value={this.state["parameters"][parameter.parameter] !== undefined ? this.state["parameters"][parameter.parameter]:
                        this.props.node.parameters[parameter.parameter] !== undefined ?
                        this.props.node.parameters[parameter.parameter] :
                        parameter.default}/>
            </div>
        }else if(parameter.type === "discrete") {

            return <div style={{marginLeft:"10px", display:"flex"}}>
                <FormControl

                    error={this.props.node.parameters[parameter.parameter] === undefined && parameter.default === undefined}

                    style={{width:"100%", marginRight:"10px", marginTop:"5px"}}>
                    <InputLabel htmlFor={parameter.parameter}>{parameter.name}</InputLabel>
                    <Select
                        input={<Input name={parameter.name} id={parameter.parameter}/>}
                        style={{width:"100%"}}
                        variant={"filled"}
                        onChange={e=>this.handleOnChange(parameter, e.target.value)}
                        label={parameter.name}
                        value={this.state["parameters"][parameter.parameter] !== undefined ? this.state["parameters"][parameter.parameter]:
                            this.props.node.parameters[parameter.parameter] !== undefined ?
                                this.props.node.parameters[parameter.parameter] :
                                parameter.default}
                    >
                        <MenuItem value="-1" disabled>
                            select one
                        </MenuItem>
                        {
                            parameter.values.map(d => {
                                return <MenuItem value={d.value}>
                                    {d.text}
                                </MenuItem>
                            })
                        }
                    </Select>
                </FormControl>
            </div>
        }else if(parameter.type === "area") {

            return <div style={{marginLeft:"10px"}}>
                <div>
                    {parameter.name}
                </div>
                <div style={{marginLeft:"10px", marginRight:"10px"}}>
                    <GeoJSONSelector
                        allows_filter={false}
                        geojsons={this.props.geojsons}
                        geojson_filter_presets={this.props.geojson_filter_presets}
                        selectedArea={this.state["parameters"][parameter.parameter] !== undefined ? this.state["parameters"][parameter.parameter]:
                            this.props.node.parameters[parameter.parameter] !== undefined ?
                                this.props.node.parameters[parameter.parameter] :
                                parameter.default}
                        setAreaCallback={e=>this.handleOnChange(parameter, e)}
                    />
                </div>
            </div>
        }else if(parameter.type === "area_filter") {

            return <div style={{marginLeft:"10px"}}>
                <div>
                    {parameter.name}
                </div>
                <div style={{marginLeft:"10px", marginRight:"10px"}}>
                    <GeoJSONSelector
                        allows_filter
                        geojsons={this.props.geojsons}
                        geojson_filter_presets={this.props.geojson_filter_presets}
                        selectedArea={this.state["parameters"][parameter.parameter] !== undefined ? this.state["parameters"][parameter.parameter]:
                            this.props.node.parameters[parameter.parameter] !== undefined ?
                                this.props.node.parameters[parameter.parameter] :
                                parameter.default}
                        setAreaCallback={e=>this.handleOnChange(parameter, e)}
                    />
                </div>
            </div>
        }
    }

    renderParameters = (parameters) => {
        return <div >
            {
                parameters.map(this.renderParameter)
            }
        </div>
    }

    hasParameters = (parameter_description) => {
        for (var i = 0; i < parameter_description.length;  i++) {
            if (parameter_description[i].advanced_setting === undefined)
                return true;
        }
        return false
    }

    renderModels = (models) => {
        return <div>

            {console.log("models", models)}

        <DynamicList noFAB={true}
                     title={<Box style={{alignItems:"center"}}>
            <DnsIcon fontSize={"small"} style={{paddingRight:"10px", marginBottom:"-5px", marginLeft:"-10px"}}/>
            Parameters
        </Box>}>


        </DynamicList>
        {

            models.map((model,i) => {
                if (!this.hasParameters(model.parameter_description))
                    return <div/>
                return <div> <Divider variant={"fullWidth"} /> <div style={{backgroundColor: "#FCEFC7", color: "#513C06", padding: "10px"}}>
                    <div style={{fontWeight: "bold", fontVariant: "small-caps"}}>{model.name}</div>
                </div>
                    <div >
                        {
                            this.renderParameters(model.parameter_description)
                        }
                    </div>
                </div>

            })
        }
            <Divider variant={"fullWidth"} />
        </div>
    }

    handleSetDraw = () => {
        this.setState({
            addType: "draw"
        });
        this.props.map.mapdraw.changeMode("draw_polygon");
    }

    handleSubmitGeojson = () => {
        let project_id = this.props.project.id;
        let geojson = null;
        geojson = this.props.map.mapdraw.getAll();
        this.props.map.mapdraw.deleteAll();
        this.props.map.mapdraw.changeMode("simple_select");
        geojson["properties"] = {
            color: this.state.area_color
        };

        let name = this.state.areaName;
        this.setState({
            uploadingArea: true,
            addingArea: false,
        })
        //minimum upload time set to 1500, so ui dialog doesnt disappear too quick
        let promise = new Promise(resolve=>{
            setTimeout(resolve, 1500);
        })
        this.props.createGeoJSON(project_id, geojson, name, _=>{
            promise.then(_=>
            this.setState({
                uploadingArea: false,

            }))

        });
    }

    handleDrop = (acceptedFiles) => {
        if(acceptedFiles.length !== 1){
            this.setState({
                errorText: "You can only upload a single boundary",
                errorDialogOpen:true,
            })
        }
        let file = acceptedFiles[0];
        this.setState({
            boundaryFile: file.name,
        })

        let reader = new FileReader();
        reader.addEventListener("loadend", ()=> {
            const geojson = JSON.parse(reader.result);
            if ("crs" in geojson) {
                delete geojson.crs;
            }
            this.props.map.mapdraw.set(geojson);
        });
        reader.readAsText(file);
    }

    renderAddingBoundary = () => {
        if(this.state.addType === "draw"){
            return <div>
                <Typography>
                    Draw polygon
                </Typography>
                <div>
                    <Typography variant={"subtitle2"}>
                        Area name
                    </Typography>
                    <TextField fullWidth
                               value={this.state.areaName}
                               onChange={e => this.setState({
                                   areaName: e.target.value
                               })}/>


                    <div>
                        <Typography variant={"subtitle2"}>
                            Area color
                        </Typography>
                        <Popover anchorEl={this.state.color_picker_anchor}
                                 open={this.state.color_picker_open}
                                 anchorOrigin={{
                                     vertical: 'top',
                                     horizontal: 'left',
                                 }}
                                 transformOrigin={{
                                     vertical: 'top',
                                     horizontal: 'right',
                                 }}
                        >
                            <SwatchesPicker color={this.state.area_color} onChange={color => this.setState({
                                color_picker_open: false,
                                area_color: color.hex,
                            })}/>
                        </Popover>
                        <IconButton onClick={e=>this.setState({color_picker_anchor:e.currentTarget, color_picker_open: !this.state.color_picker_open})} size={"small"}>
                            <StopIcon style={{color:this.state.area_color}} fontSize={"large"}/>
                        </IconButton> {this.state.area_color}
                    </div>
                    <br/>
                    <Button onClick={this.handleSubmitGeojson} variant={"outlined"} fullWidth color={"primary"}>
                        submit
                    </Button>
                </div>
            </div>
        }else if(this.state.addType === "upload"){
            return this.state.boundaryFile === null ?
                    <Dropzone onDrop={this.handleDrop}>
                        {({getRootProps, getInputProps}) => (
                            <section style={{
                                borderRadius: "5px",
                                padding: "5px",
                                border: " dashed 2px grey"
                            }}>
                                <div {...getRootProps()}>
                                    <input {...getInputProps()} />

                                    <Typography variant={"body1"}>

                                        Click here, or drag and drop a file here, to upload your
                                        boundary
                                    </Typography>
                                </div>
                            </section>
                        )}
                    </Dropzone>:
                <div>
                    <ListItem dense>
                        <ListItemText primary={"File name"} secondary={this.state.boundaryFile}/>
                    </ListItem>
                    <Typography variant={"subtitle2"}>
                        Area name
                    </Typography>
                    <TextField fullWidth
                               value={this.state.areaName}
                               onChange={e => this.setState({
                                   areaName: e.target.value
                               })}/>
                        <div>
                            <Typography variant={"subtitle2"}>
                                Area color
                            </Typography>
                            <Popover anchorEl={this.state.color_picker_anchor}
                                     open={this.state.color_picker_open}
                                     anchorOrigin={{
                                         vertical: 'top',
                                         horizontal: 'left',
                                     }}
                                     transformOrigin={{
                                         vertical: 'top',
                                         horizontal: 'right',
                                     }}
                            >
                                <SwatchesPicker color={this.state.area_color} onChange={color => this.setState({
                                    color_picker_open: false,
                                    area_color: color.hex,
                                })}/>
                            </Popover>
                            <IconButton onClick={e=>this.setState({color_picker_anchor:e.currentTarget, color_picker_open: !this.state.color_picker_open})} size={"small"}>
                                <StopIcon style={{color:this.state.area_color}} fontSize={"large"}/>
                            </IconButton> {this.state.area_color}
                        </div>
                    <ListItem dense>
                        <Button onClick={this.handleSubmitGeojson} variant={"outlined"} fullWidth color={"primary"}>
                            submit
                        </Button>
                    </ListItem>
                </div>


        }

        return  <div>
            <Button
                onClick={this.handleSetDraw}
                color={"primary"} fullWidth variant={"outlined"}>
                draw
            </Button>
            <Button
                onClick={_=>{
                    this.setState({
                        addType: "upload"
                    })}
                }
                color={"primary"} fullWidth variant={"outlined"}>
                upload
            </Button>
        </div>
    };


    handleAreaClick = (area) => {
        let new_list = this.state.deleteAreasList;
        if (new_list.indexOf(area) >-1) {
            new_list = new_list.filter(area2 => area2 !== area)
        }
        else {
            new_list.push(area)
        }
        this.setState({deleteAreasList:new_list})
    }

    renderDeleteAreaDialog = () => {
        return <div>
            <Dialog open={this.state.openDeleteAreaDialog} fullWidth>
                <DialogTitle>Deleting Areas<IconButton size={'small'} style={{float:"right"}} onClick={() =>{this.setState({
                openDeleteAreaDialog: false})}}><CloseIcon /></IconButton></DialogTitle>
                <Divider/>
                {this.props.geojsons
                    .filter(f=>f.active)
                    .map(area => (
                    <ListItem
                        key={area}
                        value={area}
                        dense
                        button
                        onClick={_=>this.handleAreaClick(area)}
                        >
                        <Checkbox checked={this.state.deleteAreasList.indexOf(area)>-1}></Checkbox>
                        {area.name}
                    </ListItem>

                ))}
                    <DialogActions><Button onClick={_=>this.setState({deleteAreaConfirmation:true})}>Delete</Button></DialogActions>
            </Dialog>

            <Dialog open={this.state.deleteAreaConfirmation}>
                <DialogTitle>
                    Deletion Confirmation
                    <IconButton size={'small'}
                                style={{float:"right"}}
                                onClick={() =>{this.setState({deleteAreaConfirmation: false})}}>
                        <CloseIcon /></IconButton></DialogTitle>
                <DialogContent>Are you sure you want to delete selected areas?</DialogContent>
                <DialogActions>
                    <Button style={{float:'left'}} onClick={_=>{this.setState({openDeleteAreaDialog:false, deleteAreaConfirmation:false})}}>Yes</Button>
                    <Button onClick={_=>{this.setState({deleteAreaConfirmation: false})}}>No</Button>
                </DialogActions>
            </Dialog>
        </div>

    };

    handleAddFilterPreset = () => {
        this.setState({
            addingFilterPreset: true,
        })
    }

    renderBoundary = () => {
        let listTitle;
        if(this.state.addingBoundary && !this.state.addType){
            listTitle = "Adding boundary"
        }else if(this.state.addingBoundary && this.state.addType === "draw"){
            listTitle = "Drawing Boundary"
        }else if(this.state.addingBoundary && this.state.addType === "upload"){
            listTitle = "Uploading Boundary"
        }else{
            listTitle = "Area"
        }
        return <div>
            <AddFilterPresetDialog
                onClose={_=>this.setState({addingFilterPreset: false})}
                geojsons={this.props.geojsons}
                filters={this.props.filters}
                open={this.state.addingFilterPreset}
                filter_layer_filter={[
                    "building",
                    "household",
                    "parcel",
                    "district",
                    "city",
                ]}
                geojson_filter_presets={this.props.geojson_filter_presets}
            />
            <Dialog open={this.state.uploadingArea}>
                <DialogTitle>
                    Uploading Area
                </DialogTitle>
                <DialogContent style={{textAlign:"center"}}>
                    <CircularProgress variant={"indeterminate"}/>
                </DialogContent>
            </Dialog>
            <DynamicList
                title={
                    <Box style={{alignItems:"center"}}>
                        <RoomIcon fontSize={"small"} style={{paddingRight:"10px", marginBottom:"-5px", marginLeft:"-10px"}}/>
                        Areas
                    </Box>}
                noFAB={true}>

                    <div>
                    <GeoJSONSelector

                        geojsons={this.props.geojsons}
                        addFilterPresetCallback={this.handleAddFilterPreset}
                        geojson_filter_presets={this.props.geojson_filter_presets}
                        selectedArea={this.props.node.area}
                        setAreaCallback={this.props.setAreaCallback}/>
                    </div>

        </DynamicList>
        </div>
    };


    render() {
        if(this.props.node == null){
            return <div/>
        }

        return <div style={styles.panel}>
            <div style={styles.panelInner}>
                <div position={"relative"}  style={{
                    backgroundColor:"#F9DA8B",
                    color: "#513C06",
                    padding: "10px"}}>
                    <div>
                        {/*<Typography variant={"h6"}>*/}
                        <InputLabel shrink  style={{color:"#513C06"}}>Node Setup</InputLabel>
                        <Typography style={{
                            fontSize: "20px",
                            fontWeight: "bold",
                            fontVariant:"small-caps"}}>
                            {this.props.node.node_type.name}</Typography>

                            <IconButton color="inherit"
                                 onClick={_=>this.props.openHelp(help_url)}
                                 size={"small"}
                                 style={{float:"right", marginTop:-30, marginRight:5}}>
                                <Help/>
                            </IconButton>

                    </div>
                </div>
                <Divider/>

                <div style={{flex:1, overflowY:"auto"}}>
                    <div>
                        {this.renderBoundary()}
                    </div>
                    <div>
                        {

                            this.renderModels(this.props.node.node_version.models)
                        }
                    </div>
                </div>
            </div>

        </div>;
    }
}



ScenarioMakerNodePropertiesPanel.propTypes = {
    open: PropTypes.bool,
    node: PropTypes.object,
    map: PropTypes.object,
    parameterCallback: PropTypes.func,
    setAreaCallback: PropTypes.func,
    setFilterCallback: PropTypes.func,
    geojsons: PropTypes.array,
}

const mapStateToProps = (state, ownProps) => {
    return {
        geojsons: state.geojsons,
        project: state.project,
        filters: state.filters,
        geojson_filter_presets: state.geojson_filter_presets
    }
}

const mapDispatchToProps = (dispatch) => {

    return {
        highlightBoundary: (id, state) => {
            dispatch(highlightGeojson(id, state))
        },

        createGeoJSON: (project_id, boundary, name, callback) => {
            dispatch(createGeoJSON(project_id, boundary, name)).then(callback)
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ScenarioMakerNodePropertiesPanel);
