import DanceAPI from "./DanceAPI";
import QueryHandler, {Query} from "../QueryHandler";
import {loadProject} from "./project_actions";


export const initScenarios = (scenarios) => ({
    type:"SCENARIOS_INIT",
    scenarios,
});

export const createScenario = (parent_id, project_id, name) => {
    return (dispatch, getState) => {
        return DanceAPI.scenario.post(parent_id, project_id, name).then(result => {
            dispatch(addScenario(result));
            return result;
        })
    }
}

export const getScenario = (scenario_id) => {
    return (dispatch, getState) => {
        return DanceAPI.scenario.get(scenario_id).then(result => {
            dispatch(updateScenario(scenario_id, result));
            return result;
        })
    }
}

export const createScenarioRevision = (scenario_id, nodes) => {
    return (dispatch, getState) => {
        return DanceAPI.scenarioRevision.post(scenario_id, nodes).then(result => {
            return dispatch(getScenario(scenario_id));
        })
    }
}

export const addScenario = (data) => ({
    type:"SCENARIOS_ADD",
    ...data,
});



export const updateScenario = (id, data) => ({
    type:"SCENARIOS_UPDATE",
    id,
    data,
});
