import React from 'react'
import PropTypes from "prop-types"
import {connect} from "react-redux";
import LoginWrapper from "../Components/Login/LoginWrapper";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import {clearState} from "../actions/global_actions";
import {withRouter, useHistory} from "react-router";



class Login extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            userAuthenticated: false,
            loggingIn: true,
            snackbarOpen: false,
            snackMessage: ""
        }
    }

    handleLogin = (result) => {
        localStorage.setItem("access_token", result.access_token)
        localStorage.setItem("access_level", result.access_level)
        this.setState({
            loggingIn:false,
            userAuthenticated:true,
        })
        console.log("loc", this.props.location)
        this.props.history.goBack();
        //this.props.history.push(this.props.location.pathname)

    };

    render() {
        return  <div>
            <LoginWrapper loginCallback={this.handleLogin}/>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={this.state.snackbarOpen}
                autoHideDuration={6000}>
                <SnackbarContent
                    message={<span id="message-id" >{this.state.snackMessage}</span>}
                    style={{backgroundColor: "#d32f2f"}}
                    action={[
                        <IconButton
                            key="close"
                            aria-label="close"
                            color="inherit"
                            onClick={this.handleClose}
                        >
                            <CloseIcon />
                        </IconButton>,
                    ]}
                />
            </Snackbar>
        </div>
    }
}



Login = withRouter(Login);
export default connect(null, null)(Login);
