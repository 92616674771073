import { combineReducers } from "redux"
import assessment_models from "./assessment_models"
import scenarios from "./scenarios"
import project from "./project"
import geojsons from "./geojson";
import layers from "./layers";
import scenario_maker_nodes from "./scenario_maker_nodes";
import dash_tiles from "./dash_tiles";
import queries from "./queries";
import query_prototypes from "./query_prototypes";
import dash_tile_templates from "./dash_tile_templates";
import {help} from "./help";
import scenario_comparison_templates from "./scenario_comparison_templates";
import scenario_comparisons from "./scenario_comparisons";
import data_sources from "./data_sources";
import filters from "./filters";
import geojson_filter_presets from "./geojson_filter_presets";



const appReducer = combineReducers({
    assessment_models,
    scenarios,
    project,
    geojsons,
    layers,
    help,
    scenario_maker_nodes,
    dash_tiles,
    queries,
    query_prototypes,
    dash_tile_templates,
    data_sources,
    scenario_comparison_templates,
    scenario_comparisons,
    geojson_filter_presets,
    filters: filters,
})

export default (state, action) => {
    if (action.type === 'RESET_STATE') {
        state = undefined
    }
    return appReducer(state, action)
}
