import React from "react";
import {connect} from "react-redux";
import {makeStyles, Toolbar} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Chip from "@material-ui/core/Chip";
import {runQueriesWithAreas} from "../actions/project_actions";
import Popover from "@material-ui/core/Popover";
import Popper from "@material-ui/core/Popper";
import InputLabel from "@material-ui/core/InputLabel";
import FilterListIcon from '@material-ui/icons/FilterList';
import RoomIcon from '@material-ui/icons/Room';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import CheckIcon from '@material-ui/icons/Check';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Box from '@material-ui/core/Box';
import AppBar from "@material-ui/core/AppBar";
import CardHeader from "@material-ui/core/CardHeader";
import Divider from "@material-ui/core/Divider";


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            flexWrap: "wrap",
        },
    },
    style:{
        position:"fixed",
        zIndex:111111,
    }
};

const SelectDisplayProps = {
    style:{
        height: "30px",
        position:"relative",
        zIndex:11111,
    },
};

const styles = {
    filterBar: {
        backgroundColor: "#F0F4F8",
        color: "#243B53",
        width:"calc(100% - 10px)",
        padding: "0px",



        alignContent: "center",
    },

    title:{
        fontSize:"18px",
        flexBasis:"20%",
        textAlign:"center",
        alignSelf:"center",
        //paddingBottom:"-20px"
        marginBottom:"-10px",
    },

    /*chip:{
        color: "#0A6C74",
        backgroundColor: "#BEF8FD",
        marginBottom: "5px",
        position:"relative",
        zIndex: 11111,
    },*/

    inputLabel:{
        marginBottom:"0px",
        paddingBottom:"0px",
        color:"#829AB1",
    },

    filters:{
        flexBasis: "40%",
        margin: "0px",
        padding: "0px",
        alignSelf: "center"
    },

    paperProps:{
        display:"flex",
        flexWrap:"wrap",
        position:"fixed",
        zIndex:1111,
        maxWidth:"300px",
    }

}

class ResultsDisplayFilterBar extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            expandResults: false,
            expandAreas: false,
            resultsAnchor: null,
            areasAnchor: null,
        } ;
    }


    openResults = (e) => {
        this.setState({
            resultsAnchor: e.currentTarget,
            expandResults: true,
        })
    }

    closeResults = (e) => {
        this.setState({
            resultsAnchor: null,
            expandResults: false,
        })
    }

    openAreas = (e) => {
        this.setState({
            areasAnchor: e.currentTarget,
            expandAreas: true,
        })
    }

    closeAreas = (e) => {
        this.setState({
            areasAnchor: null,
            expandAreas: false,
        })
    }



    renderAreaDropDownList = () =>{
        return <FormControl style={{width:"90%",}} margin={"dense"}>
            <InputLabel id="area-mutiple-name-label">Select Area</InputLabel>
            <Select
                labelId="area-mutiple-name-label"
                id="area-mutiple-name"
                multiple
                value={this.props.selectedAreas}
                onChange={this.props.handleAreaChange}
                input={<Input />}
                MenuProps={MenuProps}
            >
                {this.props.geojsons.map(s => (
                    <MenuItem value={s.id} key={s.id}>
                        {s.name}
                    </MenuItem>

                ))}
            </Select>
        </FormControl>
        return (
            <FormControl style={{width:"90%",}} margin={"dense"}>
                <InputLabel style={styles.inputLabel} margin={"dense"}>{this.props.selectedAreas.length<1 ?"No areas selected":"Areas selected"}</InputLabel>
                <Select
                    multiple
                    value={this.props.selectedAreas}
                    IconComponent={KeyboardArrowDownIcon}
                    onChange={this.props.handleAreaChange}
                    MenuProps={MenuProps}
                    SelectDisplayProps={SelectDisplayProps}
                    renderValue={selected =>(
                        <div style={{display:'flex',flexWrap:'wrap'}}
                             onMouseEnter={this.openAreas}
                             onMouseLeave={this.closeAreas}>
                            {selected.map(s => (
                                    <Chip key={this.props.geojsons.find(g => g.id === s).name}
                                          label={this.props.geojsons.find(g => g.id === s).name}
                                          size={"small"}
                                          style = {{
                                              color: this.state.expandAreas ?"rgba(0,0,0,0)":"#0A6C74",
                                              backgroundColor: this.state.expandAreas ?"rgba(0,0,0,0)":"#BEF8FD",
                                              marginBottom: "5px",
                                              position:"relative",
                                              zIndex: 11111,}}
                                    />))}

                        </div>)}
                    variant={"outlined"}
                    style={{width:"100%", height:"32px"}}>

                    <MenuItem value={-1}><CheckIcon style={{paddingRight:"10px", color: (this.props.selectedAreas.length<1) ?"#31B237":"rgba(0,0,0,0)"}}/><em>None</em></MenuItem>
                    {
                        this.props.geojsons
                            .filter(f => f.active)
                            .map(g => {
                            return <MenuItem key={g.id} value={g.id}>
                                <CheckIcon style={{paddingRight:"10px", color: (this.props.selectedAreas.indexOf(g.id)>-1) ?"#31B237":"rgba(0,0,0,0)"}}/>{g.name}
                            </MenuItem>
                        })
                    }
                </Select>
            </FormControl>
        )
    }

    renderResultType = () => {


        return <FormControl style={{width:"90%", }} margin={"dense"} >
                <InputLabel style={styles.inputLabel}>Results to display</InputLabel>
                <Select
                    multiple
                    value={this.props.active_dash_tiles}
                    IconComponent={KeyboardArrowDownIcon}
                    onChange={this.props.tilesOnChange}
                    MenuProps={MenuProps}
                    SelectDisplayProps={SelectDisplayProps}

                    renderValue={selected => (
                        <div style={{display:"flex", flexWrap:"wrap",}}
                             onMouseEnter={this.openResults}
                             onMouseLeave={this.closeResults}
                        >{selected.map(s => (
                            <Chip key={this.props.dash_tiles.find(g => g.id === s).template.data.name}
                                  label={this.props.dash_tiles.find(g => g.id === s).template.data.name}
                                  size={"small"}
                                  style = {{
                                      color: this.state.expandResults ?"rgba(0,0,0,0)":"#0A6C74",
                                      backgroundColor: this.state.expandResults ?"rgba(0,0,0,0)":"#BEF8FD",
                                      marginBottom: "5px",
                                      position:"relative",
                                      zIndex: 11111,
                                  }}/>))}
                        </div>)}
                    style={{width:"100%", height:"30px"}}>
                    {
                        this.props.dash_tiles.map(d => {
                            return <MenuItem key={d.id} value={d.id}>
                                <CheckIcon style={{paddingRight:"10px", color: (this.props.active_dash_tiles.indexOf(d.id)>-1) ?"#31B237":"rgba(0,0,0,0)"}}/>{d.template.data.name}
                            </MenuItem>
                        })
                    }
                </Select>
            </FormControl>


    }

    render() {
        let selected_tiles = this.props.active_dash_tiles.map(n => this.props.dash_tiles.find(g => g.id ===n));
        let selected_tiles_name = selected_tiles.map(n => n.template.data.name);
        let selected_areas = this.props.selectedAreas.map(n => this.props.geojsons.find(g => g.id ===n));
        let selected_areas_name = selected_areas.map(n => n.name);
        //console.log(this.state.expandAreas)
        //console.log(this.state.expandResults)

        return (
            <Card square={true}  elevation={1} style={{...styles.filterBar, marginLeft: "5px"}}>
                <CardHeader
                    style={{
                        backgroundColor: "#D9E2EC",
                        color:"#243B53",
                        fontSize: "14px",
                        fontVariant: "small-caps",
                        fontWeight: "bold"

                    }}
                    disableTypography={true}
                    title={"Results Filter"}/>
                <Divider/>
                {/*<CardHeader>*/}
                {/*    <Box style={styles.title}>*/}
                {/*        <FilterListIcon style={{paddingRight:"10px", marginBottom:"-5px"}}/>*/}
                {/*        Results Filter*/}
                {/*    </Box>*/}
                {/*</CardHeader>*/}
                <div style={{ display: "flex", backgroundColor: "#FFFFFF"}}>

                <RoomIcon style={{alignSelf:"flex-end", paddingRight:"5px", marginBottom:"10px"}}/>
                <CardContent style={styles.filters}>
                    <Popover anchorEl={this.state.areasAnchor}
                             open={this.state.expandAreas}
                             style={styles.paperProps}>{selected_areas_name.map(s =>
                        <Chip key={s}
                              label={s}
                              style = {{marginBottom:"5px"}}/>)}
                    </Popover>
                    {this.renderAreaDropDownList()}
                </CardContent>

                {/*<EqualizerIcon style={{alignSelf:"flex-end", paddingRight:"5px", marginBottom:"10px"}}/>*/}
                {/*<CardContent style={styles.filters}>*/}
                {/*    <Popover anchorEl={this.state.resultsAnchor}*/}
                {/*             open={this.state.expandResults}*/}
                {/*                style={styles.paperProps}>{selected_tiles_name.map(s =>*/}
                {/*            <Chip key={s}*/}
                {/*                  label={s}*/}
                {/*                  style = {{marginBottom:"5px"}}/>)}*/}
                {/*    </Popover>*/}
                {/*    {this.renderResultType()}*/}
                {/*</CardContent>*/}
                    </div>
            </Card>
        )

    }
}


const mapStateToProps = (state, ownProps) => {
    return {
        dash_tiles: state.dash_tiles.map(dash_tile => {
            return {
                ...dash_tile,
                template: state.dash_tile_templates.find(dtt => dtt.id === dash_tile.template_id)
            }
        }),
        dash_tile_templates: state.dash_tile_templates,
    }
}

const mapDispatchToProps = (dispatch) => {

    return {
        runQueries: (areas) => {
            dispatch(runQueriesWithAreas(areas));
        }}
}

export default connect(mapStateToProps, mapDispatchToProps)(ResultsDisplayFilterBar);
