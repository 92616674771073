


const dash_tile = (state, action) => {
    switch (action.type) {
        case 'DASHTILE_INIT':{
            return {
                id: action.id,
                data: action.data,
                template_id: action.template_id,
            }
        }
        default:
            return state;
    }
}

const dash_tiles = (state=[], action) => {

    switch (action.type) {
        case 'DASHTILE_INIT':{
            return action.dash_tiles.map((data) => dash_tile({}, {type: action.type, ...data}))
        }
        default:
            return state;
    }
}

export default dash_tiles;