import React from 'react'
import PropTypes from "prop-types"
import {TextField} from "@material-ui/core";
import Popover from "@material-ui/core/Popover";
import List from "@material-ui/core/List";
import MenuItem from "@material-ui/core/MenuItem";
import Popper from "@material-ui/core/Popper";
import Fade from "@material-ui/core/Fade";
import Grow from "@material-ui/core/Grow";
import Slide from "@material-ui/core/Slide";
import Paper from "@material-ui/core/Paper";


class SearchableTags extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            popperAnchor: null,
            popperOpen: false,
            filterText:"",
        }
    }

    handleClick = (value) => {
        this.props.onChange([...this.props.value, value]);
        this.setState({
            filterText: "",
        })
    }

    openPopper = (e) => {
        this.setState({
            popperAnchor: e.target,
            popperOpen: true,
        })
    }

    closePopper = (e) => {
        this.setState({
            popperAnchor: null,
            popperOpen: false,
            filterText: "",
        })
    }

    handleTextChange = (e) => {
        this.setState({
            filterText: e.target.value,
        })
    }

    onKeyPressed = (k) => {
        let allowed = this.props.tags.filter(t => this.props.value.findIndex(o => t.value === o.value) === -1 && t.label.includes(this.state.filterText));
            if(this.state.filterText.length !== 0 && (k.key === "Enter" || k.key === "Tab")){
            k.stopPropagation();
            k.preventDefault();
            if(allowed.length >0){
                this.handleClick(allowed[0])
            }
        }else if(k.key === "Tab") {
                k.stopPropagation();
                k.preventDefault();
        }
    }

    render() {
        return <div><TextField onKeyDown={this.onKeyPressed}
                               label={this.props.textLabel || ""}
                               tabIndex="0" value={this.state.filterText} onFocus={this.openPopper} onChange={this.handleTextChange} onBlur={this.closePopper}>
            {this.props.children}
        </TextField>
            <Popper
                placement={"bottom-start"}
                style={{
                    zIndex:9001//above dialogs
                }}
                anchorEl={this.state.popperAnchor}
                open={this.state.popperOpen}
                transition >
                {({ TransitionProps }) => (
                    <Grow {...TransitionProps} timeout={350}>
                        <Paper>
                            <List>
                                {
                                    this.props.tags.filter(t => this.props.value.findIndex(o => t.label === o.label) === -1 &&  t.label.includes(this.state.filterText)).map(t =>{
                                        return <MenuItem button onClick={_=>this.handleClick(t)}>
                                            {t.label}
                                        </MenuItem>
                                    })
                                }
                            </List>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>;
    }
}


SearchableTags.propTypes = {
    tags: PropTypes.array,
    value: PropTypes.array
}

export default SearchableTags;
