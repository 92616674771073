import DanceAPI from "./DanceAPI";


export const loadScenarioMakerNodes = () => {
    return (dispatch, getState) => {
        return DanceAPI.scenarioMakerNodes.get().then(result => {
            dispatch(initScenarioMakerNodes(result.scenario_maker_nodes))
            return result;
        })
    }
}


export const initScenarioMakerNodes = (nodes) => ({
    type: 'SMO_INIT',
    nodes
})