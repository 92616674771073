import React from 'react'
import PropTypes from "prop-types"
import Paper from "@material-ui/core/Paper/index";
import {DialogContent, Select, TextField, Typography} from "@material-ui/core/index";
import Divider from "@material-ui/core/Divider/index";
import Tabs from "@material-ui/core/Tabs/index";
import Tab from "@material-ui/core/Tab/index";
import ListIcon from "@material-ui/icons/List";
import List from "@material-ui/core/List/index";
import ListItem from "@material-ui/core/ListItem/index";
import ListItemText from "@material-ui/core/ListItemText/index";
import ListSubheader from "@material-ui/core/ListSubheader/index";
import {connect} from "react-redux";
import Button from "@material-ui/core/Button/index";
import DialogTitle from "@material-ui/core/DialogTitle/index";
import Dialog from "@material-ui/core/Dialog/index";
import MenuItem from "@material-ui/core/MenuItem/index";
import DialogActions from "@material-ui/core/DialogActions/index";
import {createScenario} from "../actions/scenario_actions";
import ScenarioListItem from "./ScenarioListItem";
import {withRouter} from "react-router";
import Legend from "./Legend";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from "@material-ui/core/Collapse/index";
import ListItemIcon from "@material-ui/core/ListItemIcon";
// import StarIcon from "@material-ui/icons/Star"
import StarEmptyIcon from "@material-ui/icons/StarBorder"
import StarIcon from "@material-ui/icons/Star"
import IconButton from "@material-ui/core/IconButton";
import DynamicList from "./Views/DynamicList";
import {getScenarioComparisonTemplates} from "../actions/scenario_comparison_template_actions";
import CircularProgress from "@material-ui/core/CircularProgress";
import ScenarioComparisonTemplateDialog from "./Views/ScenarioComparisonTemplateDialog";
import ComparisonLegend from "./ComparisonLegend";
import InputLabel from "@material-ui/core/InputLabel";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import TurnedInIcon from '@material-ui/icons/TurnedIn';
import Switch from "@material-ui/core/Switch";
import Home from "@material-ui/core/SvgIcon/SvgIcon";
import {getRangeData, toggleDisplayLayers} from "../actions/layer_actions";
import {getComparisonRangeData} from "../actions/scenario_comparison_actions";

const styles = {
    wrapper: {
        // position:"absolute",
        left:0,
        right:0,
        top: "0px",
        bottom:0,
        zIndex:11,
        pointerEvents:"none",
        // minWidth:"250px",
    },
    inner:{
        width:"100%",
        // minHeight:"180px",
        // maxHeight:"300px",
        display:"flex",
        //borderBottom:"solid 1px #888",
        background:"#f5f5f5",
        pointerEvents:"auto",
        flexDirection:"column",
        boxSizing:"border-box",
        // position:"absolute",
    },
    legend:{
        // width:"17%",
        // top: "100px",
        // bottom: "40px",
        // right: "25px",
        minWidth:"340px",
        // minHeight:"180px",
        // maxHeight:"300px",
        // display:"flex",
        // borderBottom:"solid 1px #888",
        // background:"#f5f5f5",
        // pointerEvents:"auto",
        // flexDirection:"column",
        // boxSizing:"border-box",
        // zIndex: "2000px",
        position:"absolute",

    },
    innerRight:{
        width:"20%",
        top:0,
        height:"100%",
        display:"flex",
        position:"absolute",
        right:0,
        borderBottom:"solid 1px #888",
        background:"#f5f5f5",
        pointerEvents:"auto",
        flexDirection:"column",
        boxSizing:"border-box",
    },
    scenarioSelection : {
        background:"#D9E2EC",
        padding: "10px"
    },
    scenarioSelectionField : {
        color:  "#243B53",
        width: "100%",
        fontSize: "14px",
        fontWeight: "bold",
        // fontVariant: "small-caps"
    },
    tab :{
        minWidth:0,
        minHeight:0,
        backgroundColor: "#F0F4F8",
        color: "#102A43",
        fontSize: "10px",
        fontWeight: "bold",
        fontVariant: "small-caps"

    },
    legendHeader :{

        backgroundColor: "#D9E2EC",
        color: "#102A43",
        fontSize: "20px",
        fontVariant: "small-caps",
        fontWeight: "bold",
        padding: "5px"
    },
    panel: {
        flex:1,
        display:"flex",
        zIndex:11,
        flexDirection:"column",
        overflow:"hidden",
    },

    panelInner: {
        width:"100%",
        flex:1,
        overflowY:"auto"
    },

    panelHeader: {
        background:"#fff",
        position:"sticky",
        top:0,
    },
    // legendWrapper: {
    //     left:"0",
    //     // paddingTop:"20px",
    //     // width: "300px",
    //     top:"0",
    //     // zIndex:"1000",
    //     // position:"absolute",
    // },

    comparisonLegendWrapper: {
        // right:"100%",
        // paddingTop:"0px",
        // top:-10,
        // position:"absolute",
    }

}

class DashboardControlPanel extends React.Component {

    constructor(props) {
        super(props);
        let per_map = {};
        props.layers.map(layer => {
            per_map[layer.id] = "default";
        })

        let comp_per_map = {};

        props.scenario_comparisons.map(l => {
            comp_per_map[l.id] = Object.keys(l.template.data.perspectives)[0]
        })

        let default_layer = this.props.layers.filter(layer => layer.scenario_id === this.props.scenarios[0].id).map(l=>l.id);

        this.state = {
            open:false,
            pinned_layers: [],
            current_tab: 0,
            selected_scenario: props.scenarios[0],
            new_scenario_name: "",
            new_scenario_parent: -1,
            scenario_comparison_dialog_open: false,
            layer_filter:[...default_layer],
            comparison_filter: [],
            layer_perspective_map: per_map,
            comparison_perspective_map: comp_per_map,
            new_scenario_dialog_open: false,
            hover:false,
        }
    }

    setSelectedScenario = (scenario) => {
        if(scenario.id === this.state.selected_scenario.id){
            return;
        }
        let new_filter = [...this.state.pinned_layers.filter(l => this.state.layer_filter.indexOf(l) > -1)];
        this.setState({
            selected_scenario: scenario,
            layer_filter: new_filter,
        });

    }

    componentWillUpdate(nextProps, nextState, nextContext) {
        if (this.props.activeTab === nextProps.activeTab) {
            return;
        }
        if (nextProps.currentScenario) {
            this.setSelectedScenario(nextProps.currentScenario)
        } else {
            if (this.props.scenarios)
                this.setSelectedScenario( this.props.scenarios[0])
        }


    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    updatePinned = (layer) => () => {
        let index = this.state.pinned_layers.indexOf(layer.id);
        let new_filter = [];
        if(index > -1){
            new_filter = this.state.pinned_layers.filter(v => v !== layer.id)
        }else{
            new_filter = [...this.state.pinned_layers, layer.id]
        }
        this.setState({
            pinned_layers: new_filter,
        })
    }

    renderLegend = () => {

        return <div style={styles.legendWrapper}>
            <Legend layers={this.props.layers.filter(layer => layer.scenario_id === this.state.selected_scenario.id)
                .filter( layer => this.state.layer_filter.indexOf(layer.id) > -1)
                }
                layer_perspective_map={this.state.layer_perspective_map}
                perspectiveCallback={this.perspectiveCallback}
            />
        </div>
    }

    renderComparisonLegend = () => {
        return <div style={styles.comparisonLegendWrapper}>
            <ComparisonLegend comparisons={this.props.scenario_comparisons.filter( layer => this.state.comparison_filter.indexOf(layer.id) > -1)}
                comparison_perspective_map={this.state.comparison_perspective_map}
                perspectiveCallback={this.perspectiveCallback}
            />
        </div>
    }

    renderScenarioSelect = () => {
        return <div>
            <InputLabel shrink>
                Scenario
            </InputLabel>
            <Select style={styles.scenarioSelectionField}
                    value={this.state.selected_scenario.id}
                    label={"Scenario"}
                    onChange={e=>this.setSelectedScenario(this.props.scenarios.find(s =>s.id === e.target.value))}>
            {
                this.props.scenarios.map(scenario => {
                    return <MenuItem key={scenario.id} value={scenario.id}>{scenario.name}</MenuItem>
                })
            }
        </Select>
        </div>
    }

    renderLayerItem = (layer, with_scenario=false) => {
        let active = layer.display;
        return <div key={layer.id} style={{position:"relative"}}>
            <div style={{position:"absolute", top:0,bottom:0, left:0, paddingLeft:"5px"}}>
                <div style={{marginRight:"4px",height:"100%", display:"flex", flexDirection:"column", justifyContent:"center"}}>
                    <div>
                        <IconButton size={"small"}  onClick={this.updatePinned(layer)}>
                            {
                                this.state.pinned_layers.indexOf(layer.id) > -1 ?
                                    <TurnedInIcon style={{color:"gold"}}/>:<BookmarkBorderIcon style={{color:"gold"}}/>
                            }
                        </IconButton>
                    </div>
                </div>
            </div>
            <div style={{position:"relative", top:0,bottom:0, left:0, marginLeft:"30px"}}>
                <ListItem
                          style={{
                    flex:"1 1 0" ,
                    paddingTop: "3px",
                    paddingBottom: "3px",
                    color:"#243B53",
                    backgroundColor: this.state.hover ?"#D9E2EC":"transparent"
                          }}
                          dense selected={active}>

                        <ListItemText primary={layer.data.display_name}
                        secondary={with_scenario ? this.props.scenarios.find(s => s.id === layer.scenario_id).name : "" }/>
                    <Switch checked={active} color={"primary"} onChange={e=>{
                        this.props.toggleLayer(layer);
                    }}/>
                </ListItem>
            </div>
        </div>

    }


    renderLayerList = () => {
        return <div  style={{backgroundColor: "#F0F4F8"}}>
            <div style={styles.scenarioSelection}>

                {this.renderScenarioSelect()}
            </div>

            <Tabs onChange={(e,v) => this.setState({current_tab:v})}
                style={{minHeight:"0px", padding:"0px"}} indicatorColor={"primary"} value={this.state.current_tab} scrollButtons={"off"} variant={"fullWidth"}>
                <Tab style={styles.tab} value={0} label={"Layers"}/>
                <Tab style={styles.tab} value={1} label={"Comparison"}/>
                <Tab style={styles.tab} value={2} label={"Pinned"}/>
            </Tabs>

            <Divider/>
            {this.state.current_tab === 0 &&
                <div>

                    <List dense style={{backgroundColor: "#FFFFFF"}}>
                        {this.props.layers.filter(layer => layer.scenario_id === this.state.selected_scenario.id).map(l => this.renderLayerItem(l))}
                    </List>
                </div>
            }
            {this.state.current_tab === 1 &&
            <div>
                <DynamicList onAddClick={this.handleAddScenarioComparison}>
                    {
                        this.props.scenario_comparisons.map(this.renderScenarioComparison)
                    }
                </DynamicList>
            </div>
            }
            {this.state.current_tab === 2 &&
                <div>
                    <List dense style={{backgroundColor: "#FFFFFF"}}>
                        {this.props.layers.filter(layer => this.state.pinned_layers.indexOf(layer.id) > -1).map(v => this.renderLayerItem(v,true))}
                    </List>
                </div>
            }

        </div>
    }

    handleAddScenarioComparison = () => {
        this.setState({
            scenario_comparison_dialog_open: true,
        })
    }


    closeScenarioComparisonDialog = () => {
        this.setState({
            scenario_comparison_dialog_open: false
        })
    }

    renderScenarioComparisonDialog = () => {
        return <ScenarioComparisonTemplateDialog open={this.state.scenario_comparison_dialog_open} onClose={this.closeScenarioComparisonDialog}/>
    }

    renderScenarioComparison = (scenario_comparison, index) => {
        return <ListItem
                selected={this.state.comparison_filter.indexOf(scenario_comparison.id) > -1}
                dense
                button
                onClick={_=>this.filter_comparison(scenario_comparison)}
        >
            <ListItemText
                primary={scenario_comparison.template.data.name}
                secondary={
                    <div>
                        {scenario_comparison.scenarios.map(s => {
                            return <div style={{marginLeft:"10px", fontSize:"0.8em"}}>{s.name}</div>
                        })}
                    </div>
                }
            />
        </ListItem>
    }

    render() {
        let legend_offset = "300px";
        let legend_top = "100px";
        if (this.props.activeTab !== -3) {
            legend_offset = "540px";
            legend_top = "180px";
        }


        return (
            <div style={styles.wrapper}>

                {this.renderScenarioComparisonDialog()}

                <div style={styles.inner} >
                    <Paper>
                    <div style={styles.panel}>
                        <Divider/>
                        <div style={styles.panelInner}>
                            {this.renderLayerList()}

                        </div>
                        <div>

                        </div>
                    </div>
                    </Paper>

                    </div>
                {/*<div style={{...styles.legend, left: legend_offset, top: legend_top}}>*/}

                {/*    {this.renderLegend()}*/}
                {/*    {this.renderComparisonLegend()}*/}
                {/*</div>*/}

        </div>);
    }
}


DashboardControlPanel.propTypes = {
    project: PropTypes.object,
    setLayersCallback: PropTypes.func.isRequired,
    // setComparisonCallback: PropTypes.func.isRequired,
    currentScenario: PropTypes.object.isRequired,
    activeTab: PropTypes.number.isRequired
}

const mapStateToProps = (state, ownProps) => {
    return {
        scenarios: state.scenarios,
        project: state.project,
        layers: state.layers,
        scenario_comparison_templates: state.scenario_comparison_templates,
        scenario_comparisons: state.scenario_comparisons.map(sc => {
            return {
                ...sc,
                scenarios: sc.scenarios.map(id => state.scenarios.find(s => s.id == id)),
                template: state.scenario_comparison_templates.find(f => f.id === sc.template_id)
            }
        }),
    }
}

const mapDispatchToProps = (dispatch) => {

    return {
        updateRangeData: (layers) => {
            dispatch(getRangeData(layers))
        },
        updateComparisonRangeData: (comparisons) => {
            dispatch(getComparisonRangeData(comparisons))
        },
        getScenarioComparisonTemplates: () => {
            return dispatch(getScenarioComparisonTemplates())
        },
        toggleLayer: (layer) => {
            dispatch(toggleDisplayLayers([layer.id]))
        }
    }
}

DashboardControlPanel = withRouter(DashboardControlPanel)
export default  connect(mapStateToProps, mapDispatchToProps)(DashboardControlPanel);
