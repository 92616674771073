import React from 'react'
import PropTypes from "prop-types"
import {connect} from "react-redux";
import ScreenWrapper from "./ScreenWrapper";
import {initialize_socket} from "../SocketHandler";
import {clearState} from "../actions/global_actions";
import {loadProject} from "../actions/project_actions";
import {createScenario} from "../actions/scenario_actions";
import ProjectContentScreen from "./ProjectContentScreen";
import Dialog from "@material-ui/core/Dialog/index";
import DialogTitle from "@material-ui/core/DialogTitle/index";
import DialogContent from "@material-ui/core/DialogContent/index";
import DialogContentText from "@material-ui/core/DialogContentText/index";
import LinearProgress from "@material-ui/core/LinearProgress/index";
import AdminScreen from "./AdminScreen";
import Drawer from "@material-ui/core/Drawer";
import {Route} from "react-router";

const styles = {
    root: {
        width:"100%",
        height:"100%",
        display:"flex",
        flexDirection:"row",
        position:"relative"
    },
    screenRoot: {
        width:"100%",
        // height:"100%",
        flex:"1 1 0",
        display:"flex",
        flexDirection:"column",
        position:"relative"
    },
    contentRoot: {
        position:"relative",
        flex: "1 1 100%",
        display:"flex",
        flexDirection: "column",
        // height: "100px"
    },

    tabContainer: {
        flex:0,
        width:"100%",
    },

    wrapper: {
        position:"absolute",
        left:0,
        top:0,
        bottom:0,
        width:"20%",
        display:"flex",
        flexDirection:"column",
        boxSizing:"border-box",
        minWidth:"200px",
        background:"#f5f5f5"
    },
};


class ProjectScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loadingProject: true,
            adminScreenOpen: false,
        }
    }

    componentDidMount(){

        let project_id = this.props.match.params.projectID;

        const query = new URLSearchParams(
            this.props.location.search
        );

        let data= {};
        for (let params of query.entries()) {
            data[params[0]] = params[1];
        }

        if ("jwt" in data) {
            localStorage.setItem("access_token", data.jwt)
            localStorage.setItem("access_level", 1)
        }

        const access_token = localStorage.getItem("access_token");

        if (access_token === null || access_token === undefined) {
            this.props.history.push("/login")
        }

        initialize_socket(project_id);
        this.props.loadProject(project_id).then(result => {
            this.setState({
                loadingProject: false,
            })
        })
    }
    handleLogout = () => {
        localStorage.removeItem("access_token");
        this.props.history.push('/');
        window.location.reload();
    };



    render() {
        const initializingProject = this.props.scenarios.length === 1 && this.props.scenarios[0].status < 7;
        const creatingNewProject = this.props.scenarios.length === 0;

        return <div style={{
            display:"flex",
            height:"100%",
            flexDirection: "column",
        }}>
            <Dialog open={this.state.loadingProject} >
                <DialogTitle>
                    Loading Project
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        please wait while your project is loaded..
                    </DialogContentText>

                    <LinearProgress color="primary" />
                </DialogContent>
            </Dialog>
            <AdminScreen open={this.state.adminScreenOpen} onCloseCallback={_=>this.setState({adminScreenOpen: false})}/>

            <div style={styles.root}>
                <Route exact  path={"/project/:projectID"}>
                    {!this.state.loadingProject && <ProjectContentScreen handleLogout={this.handleLogout} history={this.props.history}/>}
                </Route>

            </div>

            </div>
        {/*</ScreenWrapper>;*/}
    }
}


ProjectScreen.propTypes = {
}

const mapStateToProps = (state, ownProps) => {
    return {
        project: state.project,
        layers: state.layers,
        scenarios: state.scenarios,
        geojsons: state.geojsons,
    }
}

const mapDispatchToProps = (dispatch) => {

    return {

        clearState: () => {
            dispatch(clearState);
        },
        loadProject: (project_id) => {
            return dispatch(loadProject(project_id));
        },
        createScenario: (parent_id, project_id, name) => {

            return dispatch(createScenario(parent_id, project_id, name))
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ProjectScreen);
