

const status_text = (i) => {
    return ["",
        "Scenario created",
        "Baseline in Queue",
        "Baseline running",
        "Baseline complete",
        "Performance Assessment in Queue",
        "Performance Assessment running",
        "Performance Assessment complete",
    ][i]
}


const model_stage_text = (stage) => {
    switch (stage) {
        case 1: {
            return 'alpha'
        }
        case 2: {
            return 'beta'
        }
        case 3:{
            return 'under dev'
        }
    }
}


const scenario = (state, action) => {
    switch (action.type) {
        case 'SCENARIOS_ADD':
        case 'SCENARIOS_INIT':{
            return {
                'id': action.id,
                'name': action.name,
                'status': action.status,
                'status_text': status_text(action.status),
                'baseline_id': action.baseline_id,
                'parent': action.parent,
                'revisions': action.revisions,
                'active_revision': action.active_revision,
                'performance_assessment_id': action.performance_assessment_id,
                'current_stage_progress': 0,
            }
        }
        case 'SCENARIOS_UPDATE':{
            if(action.id !== state.id){
                return state;
            }
            let new_state = {...state, ...action.data};
            new_state.status_text = status_text(new_state.status);
            return new_state;
        }
        default:
            return state;
    }
}

const scenarios = (state=[], action) => {
    switch (action.type) {
        case 'SCENARIOS_INIT':{
            return action.scenarios.map((data) => scenario({}, {type: action.type, ...data}))
        }
        case 'SCENARIOS_ADD':{
            return [...state, scenario({}, action)]
        }
        case 'SCENARIOS_UPDATE':{
            return state.map((data) => scenario(data, action))
        }
        default:
            return state;
    }
}

export default scenarios;