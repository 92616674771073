

const data_source = (state={}, action) => {
    switch (action.type) {
        case 'DATA_SOURCE_INIT':{
            return {
                id: action.id,
                name: action.name,
                created_at:action.created_at,
                data: JSON.parse(action.data),
                creator:action.creator,
                data_source_type: action.data_source_type,
                data_source_name: action.data_source_name,
            }
        }
        case "DATA_SOURCE_ADD":{
            return {
                id: action.id,
                name: action.name,
                created_at:action.created_at,
                data: JSON.parse(action.data),
                creator:action.creator,
                data_source_type: action.data_source_type,
                data_source_name: action.data_source_name,
            }
        }


        default:
            return state;
    }
}

const data_sources = (state=[], action) => {
    switch (action.type) {
        case 'DATA_SOURCE_INIT':{

            return action.data_sources.map(data => data_source({}, {type: action.type, ...data}))
        }
        case 'DATA_SOURCE_ADD':{
            return [...state, data_source({}, action)]
        }
        default:
            return state;
    }
}

export default data_sources;
