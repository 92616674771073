import React from 'react'
import PropTypes from "prop-types"
import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import CardContent from "@material-ui/core/CardContent";
import Collapse from "@material-ui/core/Collapse";
import {CardActions} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ExpandLess from "@material-ui/icons/ExpandLess";


const styles = {

    header : {
        backgroundColor: "#F8E3A3",
        color:"#513C06",
        fontSize: "14px",
        fontVariant: "small-caps",
        fontWeight: "bold",
        paddingTop:"5px",
        paddingBottom:"5px"

    }
};


class DenseCard extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            selected:false,
        }
    }

    render() {

        if(this.props.selected !== undefined && this.props.onSelect === undefined){
            throw "Can provide selected state without onSelect listener";
        }

        let selected = this.props.selected === undefined ?
            this.state.selected :
            this.props.selected;

        let onSelect =  this.props.selected === undefined ?
            (_=>this.setState({
                selected: !this.state.selected
            })) :
            this.props.onSelect;


        let header_action = this.props.expandable ?
            <IconButton>
                {
                    selected ?
                        <ExpandLess/>:
                        <ExpandMore/>
                }
            </IconButton>:
            "";
        let background = selected ? "#F0F4F8" : "white";

        return <Card
            raised={selected}
            style={{
                marginTop:"10px",
                background: background,
            }}>
            <CardHeader
                style={styles.header}
                avatar={
                    this.props.cardIcon || ""
                }


                action={header_action}
                onClick={onSelect}
                disableTypography={true}
                title={this.props.title}/>

            <Divider/>
            <CardContent
                style={{
                    paddingTop:"5px",
                    paddingBottom:"5px"
                }}>
                {this.props.content}
            </CardContent>
            {
                this.props.expandable ?
                    <Collapse in={selected}>
                        <CardActions>
                            {
                                this.props.actions
                            }
                        </CardActions>
                    </Collapse>:
                    <CardActions>
                        {
                            this.props.actions
                        }
                    </CardActions>
            }
        </Card>;
    }
}


DenseCard.propTypes = {
    title: PropTypes.string,
    content: PropTypes.node,
    actions: PropTypes.array,
    expandable: PropTypes.bool,
    selected: PropTypes.bool,
    cardIcon: PropTypes.node,
    onSelect: PropTypes.func,
    selectedColor: PropTypes.string,
}

export default DenseCard;