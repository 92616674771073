import React, {} from 'react'
import PropTypes from 'prop-types'
import AppBar from "@material-ui/core/AppBar/AppBar";
import Typography from "@material-ui/core/Typography/Typography";
import Toolbar from "@material-ui/core/Toolbar/Toolbar";
import {withRouter} from "react-router";
import Breadcrumbs from "@material-ui/core/Breadcrumbs/Breadcrumbs";
import Link from "@material-ui/core/Link/Link";
import HomeIcon from '@material-ui/icons/Home';
import SaveIcon from '@material-ui/icons/Save';
import IconButton from '@material-ui/core/IconButton';
import HelpDrawer from "../Components/HelpDrawer";
import Button from "@material-ui/core/Button";
import {help_url} from "../config";
import Help from "@material-ui/core/SvgIcon/SvgIcon";
import Paper from "@material-ui/core/Paper";
import ExitToApp from '@material-ui/icons/ExitToApp';
import {openHelp} from "../actions/help_actions";


const styles = {
    root: {
        display:"flex",
        flexDirection: "column",
        height:"100%",


    },
    contentWrapper:{
        flex:1,
        backgroundImage: 'url(' + "https://scontent-syd2-1.xx.fbcdn.net/v/t1.15752-9/70903070_2384180191830264_5037389265407836160_n.png?_nc_cat=110&_nc_oc=AQnA0vBfqJbx70035KrbjbNp0quJvZcfArZXCMgc0meTRVnK3xgc_uPbgRlEZGoE5A4&_nc_ht=scontent-syd2-1.xx&oh=26c5398aeca58df7c8046d57e3427c0b&oe=5DFAAAFA" + ')',
        backgroundSize: 'cover',
        backgroundAttachment: 'fixed'

    },
    contentWrapperNoIm:{
        flex:1,
    },
    appBar: {
        backgroundColor:"#044E54",
        color: "#FFFFFF"
    }
}



class ScreenWrapper extends React.Component {

    constructor(props) {
        super(props);
    }

    handleLogout = () => {
        localStorage.removeItem("access_token");
        this.props.history.push('/');
        window.location.reload();
    };

    handleHelp = () => {
        window.open(help_url,"_blank")
    }

    render() {
        let access_level = parseInt(localStorage.getItem("access_level"))
        return <div style={styles.root}>
            <AppBar position={"fixed"} style={styles.appBar}>
                <Toolbar variant={"dense"} >
                    <Typography variant={'h5'} >
                         Scenario Tool
                    </Typography>
                    <Typography style={{marginLeft:"30px"}} variant={'h6'} >
                        {this.props.title}
                    </Typography>


                    {/*<IconButton style={{marginLeft:"auto", color:"#ffffff"}}*/}
                    {/*            onClick={() =>this.props.history.push("/")}*/}
                    {/*>*/}
                    {/*    <HomeIcon fontSize="inherit" />*/}
                    {/*</IconButton>*/}
                    { access_level === 5 &&
                    <Button color="inherit"
                            onClick={this.props.onAdminClick}>Admin</Button>
                    }

                    <div style={{position: "fixed", right: "10px", paddingTop:"6px"}}>
                        <Button color="inherit"
                                onClick={this.handleHelp}>Help
                            <Help/>
                        </Button>
                        <Button variant="outlined" color="inherit" size={"small"}
                                onClick={this.handleLogout}>Sign Out
                            <ExitToApp/>
                        </Button>

                    </div>


                </Toolbar>
            </AppBar>
            <div style={this.props.backgroundImage ?styles.contentWrapper:styles.contentWrapperNoIm}>
                <HelpDrawer/>
                {this.props.children}
            </div>
        </div>;
    }
}

ScreenWrapper.propTypes = {
    title: PropTypes.string,
    onAdminClick: PropTypes.func,
};

export default withRouter(ScreenWrapper);
