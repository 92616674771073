

export const openFileBrowser = () => {
    return new Promise((resolve, reject) => {

        const input = document.createElement("input");
        const readFile = (e) =>{
            let file = e.target.files[0];
            if(!file){
                resolve(null);
            }

            let fileReader = new FileReader();
            fileReader.onload = (e) => {
                let data = e.target.result;

                document.body.removeChild(input);
                resolve(data);
            }
            fileReader.readAsText(file);
        }


        input.type = "file";
        input.style.display = "none";
        input.onchange = readFile;
        document.body.appendChild(input);
        input.click()

    })
}