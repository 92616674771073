import React from "react";
import {connect} from "react-redux";
import {makeStyles} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import {highlightGeojson} from "../actions/geojson_actions";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";


class BoundariesDropDownList extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            currentValue: "No area selected",
            hovered: false,
        } ;
    }

    highlight = (geojson) => {
        this.props.highlightBoundary(geojson.id, true)
        this.setState({
            hovered: true,
        })
    }

    lowlight = (geojson) => {
        this.props.highlightBoundary(geojson.id, false)
        this.setState({
            hovered:false,
        })
    }

    handleChange = (event) => {
        this.props.setAreaCallback(event.target.value)

    };

    testFunc = (value) => {
            if(value){
                return value.name
            }
            return "No area selected"
    }
    render() {
        const ITEM_HEIGHT = 48;
        const ITEM_PADDING_TOP = 8;
        const MenuProps = {
            PaperProps: {
                style: {
                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                    width: 250,
                },
            },
        };


        return (
            <div>
                <FormControl style={{width:"100%"}}>
                    <Select
                        value={this.props.selectedArea}
                        IconComponent={KeyboardArrowDownIcon}
                        onChange={this.handleChange}
                        MenuProps={MenuProps}
                        displayEmpty
                        renderValue={this.testFunc}
                    >

                        {this.props.geojsons
                            .filter(f => f.active)
                            .map(area => (
                            <MenuItem
                                onMouseEnter={()=>this.highlight(area)}
                                onMouseLeave={()=>this.lowlight(area)}
                                key={area}
                                value={area}>
                                {area.name}
                            </MenuItem>

                        ))}

                    </Select>
                </FormControl>
            </div>

        )
    }
}



const mapStateToProps = (state, ownProps) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {

    return {
        highlightBoundary: (id, state) => {
            dispatch(highlightGeojson(id, state))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BoundariesDropDownList);
