

function componentToHex(c) {
    const hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
}

function rgbToHex(r, g, b) {
    return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}


const color_range = function (fill_type, attribute, start_val, end_val, steps = 20, colors = [
    [43, 145, 194],
    [254, 248, 0],
    [231, 25, 11]
]) {
    let lerp_colors = (a,b,n) => {
        return a.map((v,i) => {
            return Math.round((1-n)*a[i] + n*b[i]);
        })
    }
    // let colors = [
    //     [43,145,194],
    //     [254,248,0],
    //     [231,25,11],
    // ]
    // let steps = 20;
    // let start_val = 30;
    // let end_val = 62;
    let fraction = 1/steps;

    let delta_val = (end_val - start_val)/steps;
    let stops = [];
    let blank = []

    for(let i = 0; i < steps; i++){
        let frac = i/steps;
        let c_index =  Math.floor((colors.length-1) * frac);
        let c1 = colors[c_index];
        let c2 = colors[c_index + 1]
        let interp = (colors.length-1)*frac - Math.floor((colors.length-1)*frac);
        let lerp_c = lerp_colors(c1, c2,  interp);
        let color = rgbToHex(lerp_c[0],lerp_c[1], lerp_c[2]);
        let val = (start_val + delta_val*i) * 100;
        stops.push([Math.round(val)/100, color]);
        blank.push([start_val + delta_val*i, "rgba(0, 0, 0, 0)"]);

    }

    let filling = {};
    filling["fill-color"] = {
        property: attribute,
        stops: stops,
    };
    filling["fill-opacity"] = 0.75;

    filling["fill-outline-color"] = "rgba(0, 0, 0, 0)"; //removes ugly outline

    let style = {};
    style["style"] = filling;
    style["type"] = fill_type;
    style["legend"] = {
        stops: stops
    };
    return style;

};


export const func_map = {
    color_range,
};
