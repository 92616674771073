import React from 'react'
import {connect} from "react-redux";
import {Drawer} from "@material-ui/core/index";
import {closeHelp} from "../actions/help_actions";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Cancel from '@material-ui/icons/Cancel';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import Slide from "@material-ui/core/Slide";
import Paper from "@material-ui/core/Paper";

const styles = {
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
    },
}
class HelpDrawer extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return <Slide
            in={this.props.openDrawer && this.props.drawer_open}
            onExit={this.props.closeHelp}
            direction={"left"}

            mountOnEnter
            unmountOnExit
        >
            <Paper style={{width:"30vw", right:"25%", top:"0px", height:"100%", position:"absolute",zIndex:1000}}>
                <div style = {styles.drawerHeader}>
                    <IconButton onClick={this.props.closeHelp}>
                        <ChevronLeft />
                    </IconButton>
                </div>

                <iframe
                    width={"100%"}
                    height={"100%"}
                    frameBorder={0}
                    src={this.props.help_url}>
                    iframe
                </iframe>
            </Paper>
        </Slide>;
    }
}


HelpDrawer.propTypes = {}

const mapStateToProps = (state, ownProps) => {
    return {
        help_url: state.help.help_url,
        drawer_open: state.help.drawer_open,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {

        closeHelp: () => {
            dispatch(closeHelp())
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(HelpDrawer);
