import React from 'react'
import PropTypes from "prop-types"
import {connect} from "react-redux";
import ScreenWrapper from "./ScreenWrapper";
import Tabs from "@material-ui/core/Tabs/index";
import Tab from "@material-ui/core/Tab/index";
import Divider from "@material-ui/core/Divider/index";
import AddIcon from "@material-ui/icons/Add"
import Map, {constructGeoJSONSource, getBoundingBox} from "../Components/Map/Map";
import Source from "../Components/Map/Source";
import Layer from "../Components/Map/Layer";
import {build_layer_perspective, get_perspective_column_keys} from "../map_styling/util";
import {dance4water_host, help_url, tile_server, tile_server_query} from "../config";
import {initialize_socket} from "../SocketHandler";
import bbox from "geojson-bbox"
import {clearState} from "../actions/global_actions";
import {loadProject} from "../actions/project_actions";
import {DialogContent, Select, TextField, Typography, withStyles} from "@material-ui/core/index";
import {createScenario} from "../actions/scenario_actions";
import Dialog from "@material-ui/core/Dialog/index";
import DialogTitle from "@material-ui/core/DialogTitle/index";
import MenuItem from "@material-ui/core/MenuItem/index";
import DialogActions from "@material-ui/core/DialogActions/index";
import Button from "@material-ui/core/Button/index";
import Dashboard from "./Dashboard";
import ScenarioScreen from "./ScenarioScreen";
import CreateProjectScreen from "./CreateProjectScreen";
import InitializingProjectScreen from "./InitializingProjectScreen";
import RequiredSelect from "../Components/Views/RequiredSelect";
import Overview from "./Overview";
import Paper from "@material-ui/core/Paper";
import SidePanel from "../Components/Panel/SidePanel";
import ExitToApp from '@material-ui/icons/ExitToApp';
import Help from '@material-ui/icons/Help';
import HelpDrawer from "../Components/HelpDrawer";
import {openHelp} from "../actions/help_actions";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import Popper from '@material-ui/core/Popper';
import Grow from '@material-ui/core/Grow';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Legend from "../Components/Legend";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload"
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import DanceAPI from "../actions/DanceAPI"
import Toolbar from "@material-ui/core/Toolbar";
import ContextDrawer from "../Components/Views/ContextDrawer";
import {toggleDisplayLayers} from "../actions/layer_actions";
import {getComparisonRangeData} from "../actions/layer_actions";
import {Share} from "@material-ui/icons";
import DialogContentText from "@material-ui/core/DialogContentText";
import {createFilter} from "../actions/filter_actions";
import {shareProject} from "../actions/share_actions";





const styles = {
    root: {
        width:"100%",
        height:"100%",
        display:"flex",
        flexDirection:"row",
        position:"relative"
    },
    screenRoot: {
        userSelect:"none",
        height:"100%",
        flex:"1 1 auto",
        display:"flex",
        flexDirection:"column",
        position:"relative",
        backgroundColor: "#0E7C86",
    },
    contentRoot: {
        position:"relative",
        display:"flex",
        flexDirection:"column",
        flex: "1",
        marginBottom:"4px",
    },
    contentHeader: {
        zIndex:"1201",
        marginTop: "0px",
        flex: "0 0 68px",
        height: "68px",
        backgroundColor: "#044E54",
        color:"#FFFFFF",
    },
    legendWrapper: {

        // paddingTop:"0px",
        width: "300px",

        position:"absolute",
    },
};


const buildLayerSource = (layer, context) => {
    if(context.secondaryScenario !== null && layer.data.perspectives[layer.perspective].comparison !== undefined){
        let query_string = {};
        query_string.sources = {};
        let template = layer.data.perspectives[layer.perspective].comparison;
        let inputs = [context.primaryScenario, context.secondaryScenario];

        for(let i = 0; i < template.number_of_inputs; i++){
            query_string.sources[inputs[i].id + template.suffix] = {name: template.input_aliases[i]}
        }
        query_string.sinks = Object.assign({}, template.query);

        console.log({
            id: "layer_comparison_" + layer.id,
            source: {
                type:"vector",
                scheme:"xyz",
                tiles: [tile_server_query + "/{z}/{x}/{y}.pbf?q=" + encodeURIComponent(JSON.stringify(query_string))],
            },
        })
        return {
            id: "layer_comparison_" + layer.id,
            source: {
                type:"vector",
                scheme:"xyz",
                tiles: [tile_server_query + "/{z}/{x}/{y}.pbf?q=" + encodeURIComponent(JSON.stringify(query_string))],
            },
        }
    }
    return {
        id: "layer_" + layer.id,
        source: {
            type: "vector",
            scheme: "xyz",
            tiles: [
                tile_server + "layer/" + layer.scenario_id + "_" + layer.name + "/{z}/{x}/{y}.pbf?"
            ]
        }
    }

    if(layer.type === "comparison"){
        let query_string = {};
        query_string.sources = {};
        let template = layer.layer.template.data;
        let inputs = layer.layer.scenarios;
        for(let i = 0; i < template.number_of_inputs; i++){
            query_string.sources[inputs[i].id + template.suffix] = {name: template.input_aliases[i]}
        }
        query_string.sinks = Object.assign({}, template.query);

        return {
            id: "layer_comparison_" + layer.layer.id,
            source: {
                type:"vector",
                scheme:"xyz",
                tiles: [tile_server_query + "/{z}/{x}/{y}.pbf?q=" + encodeURIComponent(JSON.stringify(query_string))],
            },
        }
    }else {


    }
}

class ProjectContentScreen extends React.Component {

    constructor(props) {
        super(props);
        let layer_perspective_map = {};
        props.layers.map(l => layer_perspective_map[l.id] = "default");
        this.state = {
            layer_perspective_map,
            activeTab: -3,
            settingUpNewProject: false,
            currentScenario: null,
            downloadOpen: false,
            shareOpen: false,
            context: {
                primaryScenario: null,
                secondaryScenario: null,
                relative: false,
            },
            screen_state: {},
            downloadState: {scenario: -1, layer:-1},
            map: null,
            mapComp: null,
            new_scenario_name: "",
            new_scenario_parent: -1,
            new_scenario_dialog_open: false,
            formattedLayers: [],
            forceChart: false,
            layers: [],
            open: true,
            drawerWidth: 260,
            geojson_filter: [],
            popperOpen: false,
            popperAnchor: null,
            layer_state: [...this.props.layers],
        }
    }

    setField = (key) => (e) => {
        this.setState({
            screen_state: {...this.state.screen_state, [key]: e.target.value}
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevState.context.primaryScenario === null && this.props.scenarios.length > 0){
            this.setState({
                context:{
                    ...this.state.context,
                    primaryScenario: this.props.scenarios[0],
                }
            })
        }
    }

    getCSABbox = () => {
        if(this.props.geojsons.length === 0 || !this.props.project || !this.props.project.case_study_area_id){
            return null;
        }
        return bbox(this.props.geojsons.find(g => g.id === this.props.project.case_study_area_id).geojson)
    }

    renderMap = () => {
        let height = "100%";
        let lengendLeft = "30px";
        let legendHeight = "10px";
        if (this.state.activeTab !== -3) {
            height = "100vh";
            lengendLeft = "265px";
            legendHeight = "100px";
        }
        if (this.props.scenarios.length === 0) {
            height = "100vh";

        }

        return <div>

        <Map
            layer_ids={this.props.layers.map(l => l.id + "_default")}
            bbox={this.getCSABbox()}
            registerMapCallback={v => this.setState({map:v})}>
            <Source source={{
                id: "geojson_src",
                source: constructGeoJSONSource(this.props.geojsons, this.state.geojson_filter)
            }}>
                <Layer description={{
                    'id': 'infrastructureArea',
                    'type': 'fill',

                    "source": "geojson_src",

                    "paint": {
                        'fill-color': ["case", ["get", "highlight"],
                            "rgba(220,220,0,0.9)",
                            [
                                "coalesce", ["get", "color"], "rgba(220,220,255,0.7)",
                            ],
                        ],
                        "fill-antialias": true,
                    },
                }}/>
            </Source>

            {
                this.props.layers
                    .filter((l,i) => l.display)
                    .map((l, i) => {
                        if(this.state.context.primaryScenario !== null && this.state.context.secondaryScenario !== null){
                            if(l.comparison_range_data[l.perspective] === undefined) {
                                this.props.test(l, this.state.context.primaryScenario, this.state.context.secondaryScenario)
                            }
                        }
                        let id = l.id +"_" +
                            (this.state.context.primaryScenario ? this.state.context.primaryScenario.id : "null") + "_" +
                            (this.state.context.secondaryScenario ? this.state.context.secondaryScenario.id : "null") + "_" +
                            l.perspective;
                    return <Source key={id} source={buildLayerSource(l, this.state.context)} layer={l}>
                        <Layer description={
                            build_layer_perspective(l, this.state.context)
                        }/>
                    </Source>
                })
            }
        </Map>

            <div style={{...styles.legendWrapper, left:lengendLeft, top:legendHeight}}>
                {/*<Legend layers={this.state.layers}*/}
                {/*        perspectiveCallback={this.perspectiveCallback}*/}
                {/*/>*/}
            </div>
        </div>
    }


    handleAddScenario = () => {
        this.setState({
            new_scenario_dialog_open: true,
        })
    }

    submitNewScenario = () => {
        this.props.createScenario(
            this.state.new_scenario_parent,
            this.props.project.id,
            this.state.new_scenario_name
        );
        this.setState({
            new_scenario_dialog_open: false,
        })
    }



    renderNewScenarioDialog = () => {
        return <Dialog open={this.state.new_scenario_dialog_open} maxWidth={"xs"} fullWidth>
            <DialogTitle>
                Add new scenario
            </DialogTitle>
            <DialogContent>
                <div style={{width:"70%", margin:"auto"}}>
                    <Typography variant={"subtitle1"}>
                        Scenario name
                    </Typography>
                    <TextField onChange={e => this.setState({new_scenario_name:e.target.value})} value={this.state.new_scenario_name} fullWidth placeholder={"Scenario name"}/>
                    <br/>
                    <br/>
                    <br/>
                    <Typography variant={"subtitle1"}>
                        Parent Scenario
                    </Typography>
                        <RequiredSelect
                            warningText={"you must select a base scenario"}
                            onChange={e => this.setState({new_scenario_parent: e.target.value})} value={this.state.new_scenario_parent+""} style={{width:"100%"}}>
                            {this.props.scenarios.map(scenario => {
                                return <MenuItem key={scenario.id}  value={scenario.id}>
                                    {scenario.name}
                                </MenuItem>
                            })}
                        </RequiredSelect>
                </div>
            </DialogContent>
            <DialogActions>
                <Button color={"secondary"} onClick={_=>this.setState({new_scenario_dialog_open: false,})}>
                    cancel
                </Button>

                <Button color={"primary"}
                        disabled={!this.state.new_scenario_dialog_open || this.state.new_scenario_parent === -1}
                        onClick={this.submitNewScenario}>
                    confirm
                </Button>
            </DialogActions>
        </Dialog>
    }

    renderScreenContent = () => {

        let tab = this.state.activeTab;


        if(tab === -2) {
            return  <Dashboard setLayersCallback={this.setLayers} mapRef={this.state.map}/>
        }
        else if(tab === -3){
                return <Overview
                    onAnalysisEvent={t=>this.setState({forceChart: t})}
                    forceChart={this.state.forceChart}
                    context={this.state.context}
                    setLayersCallback={this.setLayers}
                    setMapCallback={v=>this.setState({map:v})}
                    geojson_filter={this.state.geojson_filter}/>
        }else{
            return <ScenarioScreen setLayersCallback={this.setLayers}
                                   scenario_id={this.state.activeTab}
                                   map={this.state.map}
                                   openHelp={this.props.openHelp}
                                   drawerWidth={this.state.drawerWidth}
            />
        }
    }

    setScenario = (scenario_id) => {
        let scenario = this.props.scenarios.filter(s => scenario_id == s.id)[0]
        this.setState({
            activeTab: scenario_id,
            currentScenario: scenario
        })
    }

    minimized = (drawerWidth) => {
        this.setState({drawerWidth: drawerWidth-10})
    }

    handleClickPopper = (e) =>{
        this.setState({
            popperAnchor: e.currentTarget,
            popperOpen: !this.state.popperOpen,
        })
    }

    openNewTabForHelp = () =>{
        window.open(help_url,"_blank")
        this.setState({
            popperOpen: !this.state.popperOpen,
        })
    }

    handleDownloadClick = () => {
        this.setState({
            downloadOpen: true
        })
    }
    handleShareClick = () => {
        this.setState({
            shareOpen: true
        })
    }



    renderNavMenu = () => {
        return <Popper
            open={this.state.popperOpen}
                anchorEl={this.state.popperAnchor}

                placement={"bottom-end"}  disablePortal transition>
            {({ TransitionProps }) => (
                <Grow {...TransitionProps}>
                    <Paper>
                        <ClickAwayListener onClickAway={_=>this.setState({popperOpen:false})}>
                            <div>
                                <MenuItem onClick={_=>this.props.history.push('/')}><ViewModuleIcon style={{marginLeft:"-10px", paddingRight:"10px"}}/>View All Projects</MenuItem>
                                <MenuItem><AccountCircleIcon style={{marginLeft:"-10px", paddingRight:"10px"}}/>Account</MenuItem>
                                {
                                    localStorage.getItem("access_level") >= 4 &&
                                    <MenuItem onClick={this.handleDownloadClick}><CloudDownloadIcon
                                        style={{marginLeft: "-10px", paddingRight: "10px"}}/>Download</MenuItem>
                                }<Divider/>
                                <MenuItem onClick={this.handleShareClick}><Share style={{marginLeft:"-10px", paddingRight:"10px"}}/>Share Project</MenuItem>
                                <MenuItem onClick={this.openNewTabForHelp}><Help style={{marginLeft:"-10px", paddingRight:"10px"}}/>Help</MenuItem>
                            </div>
                        </ClickAwayListener>
                    </Paper>
                </Grow>)}
        </Popper>
    }

    setGeojsonFilter = (filter) => {
        this.setState({
            geojson_filter:filter,
        })
    }

    handleDownloadLayer = (scenario_id, layer_id) => {
        DanceAPI.layerDownload.get(scenario_id, layer_id);
    }

    handleShare = () => {
        console.log(this.state.screen_state["email"])

        this.props.shareProject(this.props.project.id, this.state.screen_state["email"])

        this.setState({
            shareOpen:false,
        })

    }

    renderShareDialog = () => {
        return <Dialog open={this.state.shareOpen} maxWidth={"lg"} fullWidth onClose={_ => this.setState({
                shareOpen: false
            })} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Share Project</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                       Please enter the email of the user you want to share your project with.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Email Address"
                        type="email"
                        fullWidth
                        value={this.state.screen_state["email"]}
                        onChange={this.setField("email")}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={_ => this.setState({
                        shareOpen: false
                    })} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={_ => this.handleShare()} color="primary">
                        Share
                    </Button>
                </DialogActions>
            </Dialog>
    }



    renderDownloadDialog = () => {
        return <Dialog open={this.state.downloadOpen} maxWidth={"lg"} fullWidth>
            <DialogTitle>Download Project Data</DialogTitle>
            <DialogContent>
                <div style={{width:"100%", height:"100%", display:"flex"}}>
                    <div style={{flex:"1", borderRight:"solid 1px rgba(0,0,0,0.23)"}}>
                        <Typography style={{marginLeft:"10px"}}>Scenarios</Typography>
                        <Divider/>
                        <List>
                            {
                                this.props.scenarios.map(s => {
                                    return <ListItem
                                        onClick={_=>this.setState({
                                            downloadState: {
                                                ...this.state.downloadState,
                                                scenario: s.id,
                                            }
                                        })}
                                        button
                                        dense
                                        selected={this.state.downloadState.scenario === s.id}
                                    >
                                        <ListItemText>
                                            {s.name}
                                        </ListItemText>
                                    </ListItem>
                                })
                            }
                        </List>
                    </div>
                    <div style={{flex:"1", borderRight:"solid 1px rgba(0,0,0,0.23)"}}>
                        {
                            this.state.downloadState.scenario !== -1 ?
                                <div>
                                    <Typography style={{marginLeft:"10px"}}>Layers</Typography>
                                    <Divider/>
                                    <List>
                                        {
                                            this.props.layers.filter(l => l.scenario_id===this.state.downloadState.scenario).map(l => {
                                                return <ListItem
                                                    button
                                                    dense
                                                    onClick={_=>this.setState({
                                                        downloadState: {
                                                            ...this.state.downloadState,
                                                            layer: l.id,
                                                        }
                                                    })}
                                                >
                                                    <ListItemText>
                                                        {l.name}
                                                    </ListItemText>
                                                </ListItem>
                                            })
                                        }
                                    </List>
                                </div>:
                                <div>

                                </div>
                        }
                    </div>
                    <div style={{flex:"1"}}>
                        {this.state.downloadState.layer !== -1 ?
                            <div>
                                <Button onClick={_=>this.handleDownloadLayer(
                                    this.state.downloadState.scenario,
                                    this.props.layers.find(l=>l.id===this.state.downloadState.layer).name)}>
                                    download
                                </Button>
                            </div>:
                            <div></div>
                        }
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button color={"secondary"} onClick={_=>this.setState({
                downloadOpen:false,
                downloadState:{
                layer:-1,
                scenario:-1,
            }
                })}>
                    dismiss
                </Button>
            </DialogActions>
        </Dialog>
    }

    render() {
        const initializingProject = this.props.scenarios.length === 1 &&
            this.props.scenarios[0].status > 1 &&
            this.props.scenarios[0].status < 7;
        const creatingNewProject = this.props.scenarios.length === 0;
        let title = "Overview"
        if (this.state.currentScenario)
            title = this.state.currentScenario.name

        if(this.state.context.primaryScenario && !this.state.context.secondaryScenario){
            title = this.state.context.primaryScenario.name;
        }else if(this.state.context.primaryScenario && this.state.context.secondaryScenario){
            title = this.state.context.primaryScenario.name + " - " + this.state.context.secondaryScenario.name;
        }
        let drawerWith =  this.state.drawerWidth;

        if (creatingNewProject)
            drawerWith = 0;

        return <div style={{width:"100%", height:"100%", display:"flex", flexDirection:"row"}}>
            {this.renderDownloadDialog()}
            {this.renderShareDialog()}
            {!creatingNewProject &&
            <SidePanel map={this.state.map}
                       onAnalysisClick={_=>this.setState({
                           forceChart: !this.state.forceChart,
                       })}
                       relativeComparison={this.state.context.relative}
                       primaryScenario={this.state.context.primaryScenario}
                       secondaryScenario={this.state.context.secondaryScenario}
                       setContextCallback={(p,s,r) => {
                           if(p === s){
                               p = this.props.scenarios.find(s => s.id === p);
                               s = null;
                           }else{
                               p = this.props.scenarios.find(s => s.id === p);
                               s = this.props.scenarios.find(sc => sc.id === s);
                           }
                           this.setState({
                               context: {
                                   primaryScenario: p || null,
                                   secondaryScenario: s || null,
                                   relative: r || false,
                               },
                           })
                       }}
                       setGeojsonFilterCallback={this.setGeojsonFilter}
                       geojsons={this.props.geojsons}
                       activeTab={this.state.activeTab}
                       minimiseCallBack = {this.minimized}
                       setScenarioCallback={this.setScenario}
                       onAddCallback={this.handleAddScenario}
                       currentScenario={this.state.currentScenario}
                       setLayersCallback={this.setLayers}/> }



            {/*<HelpDrawer/>*/}

            <div style={{flex: "5", display:"flex", height:"100%", flexDirection:"column"}}>
                {this.renderNewScenarioDialog()}
                <Toolbar style={{backgroundColor:"#044E54", height:"68px", color:"white", zIndex:"1201"}}>

                        <Typography variant={"h5"}
                                    style={{paddingTop: "7px", paddingBottom: "7px"}}>{title}
                        </Typography>

                        <div style={{position: "fixed", right: "10px", marginTop: "-3px",}}>

                            <IconButton color="inherit" onClick={this.handleClickPopper}><MoreVertIcon/></IconButton></div>
                        {this.renderNavMenu()}
                </Toolbar>
                <div style={{...styles.contentRoot}}>
                        {this.renderMap()}
                    {!creatingNewProject && !initializingProject && this.renderScreenContent()}
                    {creatingNewProject && !initializingProject && <CreateProjectScreen project={this.props.project} mapRef={this.state.map}/>}
                    {
                        initializingProject &&
                            <InitializingProjectScreen
                                step={[0,1,1,1,2,2,2,3][this.props.scenarios[0].status]}
                                percentage={this.props.scenarios[0].current_stage_progress}
                                mapRef={this.state.map}
                            />
                    }
                </div>
            </div>
        </div>

    }
}


ProjectContentScreen.propTypes = {
    handleLogout : PropTypes.func.isRequired
}

const mapStateToProps = (state, ownProps) => {
    return {
        project: state.project,
        layers: state.layers,
        scenario_comparisons: state.scenario_comparisons,
        scenario_comparison_templates: state.scenario_comparison_templates,
        scenarios: state.scenarios,
        geojsons: state.geojsons,
    }
}

const mapDispatchToProps = (dispatch) => {

    return {

        clearState: () => {
            dispatch(clearState);
        },
        loadProject: (project_id) => {
            return dispatch(loadProject(project_id));
        },
        createScenario: (parent_id, project_id, name) => {

            return dispatch(createScenario(parent_id, project_id, name))
        },
        test: (l,s1,s2) => {
            dispatch(getComparisonRangeData(l, l.perspective, s1, s2));
        },
        openHelp: (url) => {
            dispatch(openHelp(url))
        },
        shareProject: (project,username) => {
            return dispatch(shareProject(project, username));
        },
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ProjectContentScreen);
